import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import { Draggable } from 'react-beautiful-dnd';
import CloseQuestion from './CloseQuestion';
import OpenQuestion from './OpenQuestion';

const QuestionsUI = ({
  questions,
  setQuestions,
  setIndexIndicator,
  setToggleValue,
  toggleValue,
  error,
  setError,
  listVariables,
  saveQuestions,
}) => {
  const handleExpand = (i) => {
    setIndexIndicator(i);
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      if (i === j) {
        qs[i].open = true;
      } else {
        qs[j].open = false;
      }
    }
    setQuestions(qs);
  };

  const deleteQuestion = (i) => {
    let qs = [...questions];
    const remove = qs[i];
    const sub_qs = qs.filter((r) => r?.parent_question_id === remove?.id);
    if (sub_qs.length !== 0) {
      //TODO hacer que pregunte si desea eliminar todas las subpreguntas
      if (questions.length > 1) {
        qs.splice(i, sub_qs.length + 1);
      }
      qs.map((r, n) => {
        r.position = n + 1;
      });
      setQuestions(qs);
      console.log(questions);
    } else {
      if (questions.length > 1) {
        qs.splice(i, 1);
      }
      qs.map((r, n) => {
        r.position = n + 1;
      });
      setQuestions(qs);
      saveQuestions();
    }
  };

  const showAsQuestion = (i) => {
    let qs = [...questions];

    if (qs[i]?.label === '') {
      setError({ text: 'No puede dejar campos vacios', error: true });
    } else {
      if (Object.keys(qs[i]?.variable).length === 0) {
        setError({
          text: 'Elije al menos un tipo de pregunta o asocia un indicador',
          error: true,
        });
      } else {
        setError({ text: '', error: false });
        qs[i].open = false;
        setQuestions(qs);
        saveQuestions();
      }
    }
  };

  return questions?.map((ques, i) => (
    <Draggable key={i} draggableId={i + 'id'} index={i}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div>
            <div style={{ marginBottom: '30px' }}>
              <div
                style={{
                  alignItems: 'center',
                  width: '100%',
                  marginBottom: '-7px',
                }}
              >
                <i
                  className="pi pi-th-large"
                  style={{ color: 'black', fontSize: '1rem' }}
                ></i>
              </div>
              <Accordion
                onChange={() => {
                  handleExpand(i);
                }}
                expanded={questions[i].open}
              >
                {/*Contenedor que muestra cuando la pregunta esta cerrada */}
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  elevation={1}
                >
                  {!questions[i].open ? (
                    <CloseQuestion
                      ques={questions}
                      questions={ques}
                      index={i}
                    />
                  ) : (
                    ''
                  )}
                </AccordionSummary>

                {/*Contenedor que muestra cuando la pregunta esta desplejada para ser editada */}
                <OpenQuestion
                  setIndexIndicator={setIndexIndicator}
                  toggleValue={toggleValue}
                  listVariables={listVariables}
                  questions={questions}
                  setQuestions={setQuestions}
                  index={i}
                  question={ques}
                  error={error}
                />

                <Divider />

                <AccordionActions>
                  <IconButton
                    aria-label="Indicators"
                    onClick={() => {
                      setToggleValue(!toggleValue);
                    }}
                  >
                    <i className="pi pi-list"></i>
                  </IconButton>

                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      deleteQuestion(i);
                    }}
                  >
                    <i className="pi pi-trash"></i>
                  </IconButton>

                  <Divider orientation="vertical" flexItem />

                  <IconButton
                    aria-label="View"
                    onClick={() => {
                      showAsQuestion(i);
                    }}
                  >
                    <i className="pi pi-check"></i>
                  </IconButton>
                </AccordionActions>
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  ));
};

export default QuestionsUI;
