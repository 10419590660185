import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { getComponentByVariable } from '../../utils/helpers/formsHelpers';
import { Toast } from 'primereact/toast';

const CreateInputIndicator = ({ opRef, indicators, setRelationships, relationships, setFormSchema, formSchema }) => {

    const [ indicator, setIndicator ] = useState({});
    const [ variable, setVariable ] = useState({});
    const toast = useRef(null);

    useEffect(() => {
        console.log(indicator);
    }, [indicator ])
    

    const handleAdd = () => {

        const component = getComponentByVariable(variable);

        console.log(component);

        if (relationships.findIndex(rel => rel.input.key === component.key) !== -1) {
            toast.current.show({ 
                severity: 'error', 
                summary: 'Not created', 
                detail: `You can't repeat the same relationship`,
                life: 3000 }
            );
            return;
        }

        setRelationships((relationships) => [
            ...relationships,
            {
                input: {
                    label: component.label,
                    key: component.key,
                },
                variable: {
                    label: variable?.label,
                    id: variable?.id
                },
            },
        ]);

        setFormSchema({
            ...formSchema,
            components: [
                ...formSchema.components,
                component,
            ],
        });

        // Clear and close
        opRef.current.hide();
        setIndicator({});
        setVariable({});
    }

    return (
        <div>
            <Toast ref={ toast } />

            <OverlayPanel 
                ref={ opRef } 
                appendTo={document.body} 
                showCloseIcon 
                id="overlay_panel" 
                style={{ width: '300px' }} >

                <div>
                    <h5 className='p-text-center'>Create new Indicator/Field</h5>
                    
                </div>

                <div className='p-text-center p-mb-4'>
                    <div className='p-mb-1'>
                        Indicator
                        <span className='color-danger'> *</span>
                    </div>
                    <Dropdown
                        id='dropdown-indicators'
                        className=''
                        style={{ width: '95%' }}
                        value={ indicator } 
                        onChange={(e) => setIndicator(e.value)}
                        options={ indicators } 
                        optionLabel="name"
                        autoFocus
                        placeholder="Select a indicator" />
                </div>

                <div className='p-text-center'>
                    <div className='p-mb-1'>
                        Variable
                        <span className='color-danger'> *</span>
                    </div>
                    <Dropdown
                        id='dropdown-indicators'
                        className=''
                        style={{ width: '95%' }}
                        value={ variable } 
                        onChange={(e) => setVariable(e.value)}
                        options={ indicator?.variables } 
                        optionLabel="label"
                        autoFocus
                        placeholder="Select a variable"
                        disabled={ (Object.keys(indicator).length > 0) ? false:true } />
                </div>

                <div className='p-text-center p-mt-4'>
                    <Button
                        style={{ width: '95%' }}
                        label='Create'
                        className='p-button-text p-shadow-5'
                        onClick={ handleAdd }
                        disabled={ (Object.keys(variable).length > 0) ? false:true } />
                </div>
                
            </OverlayPanel>
        </div>
    )
}

CreateInputIndicator.propTypes = {
    setRelationships: PropTypes.func.isRequired,
    relationships: PropTypes.array.isRequired,
}

export default CreateInputIndicator;