import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const SaveChangesDialog = ({ open, setOpen, oldValues, values, setValues, labels=null }) => {

    const rowsChanges = [];

    React.useEffect(()=>{
      if (rowsChanges.length === 0) {
        setOpen(false)
      }
    },[rowsChanges]);

    const handleSave = () => {
        setOpen(false);
    }

    const handleUndo = (key) => {
        setValues({
            ...values,
            [key]: oldValues[key],
        });
    }

    const dialogHeader = (
        <div className="p-mx-3">
            <h5>
                <a>You are sure to save the changes?</a>
            </h5>
        </div>
    );

    const dialogFooter = (
        <div>
            <Button
                type="button" label="Save"
                onClick={ handleSave }
                icon="pi pi-save" className="p-button-success p-button-text" />

            <Button
                type="button" label="Cancel"
                onClick={() => setOpen(false)}
                icon="pi pi-times" className="p-button-danger p-button-text" />
        </div>
    );

    const dialogBody = () => {

        let counter = -1;

        for (const key in values) {

            counter++;

            if (values[key] !== oldValues[key]) {
                rowsChanges.push(
                    <>
                        {
                            (labels != null) &&
                            <div className="p-grid" >
                                <div className="p-col-6 p-pt-3 p-pb-0 p-text-bold" >
                                    { labels[counter] }
                                </div>
                                <div className="p-col-6 p-text-right p-pt-3 p-pb-0">
                                    <Button
                                        style={{ height: '1.7rem' }}
                                        className="p-button-text p-ml-2"
                                        label="UNDO"
                                        onClick={ () => handleUndo(key) } />
                                </div>
                            </div>
                        }
                        <div className="card p-shadow-7 p-my-2 p-pb-2 bg-dark" style={{ borderRadius: '7px' }}>
                            <div className="p-grid">
                                <div className="p-col-5 p-p-0" style={{ textAlign: 'left', textDecoration: 'line-through' }}>
                                    { oldValues[key] }
                                </div>
                                <div className="p-col-2 p-p-0" style={{ textAlign: 'center' }}>
                                    <i className="pi pi-arrow-right"/>
                                </div>
                                <div className="p-col-5 p-p-0" style={{ textAlign: 'right' }}>
                                    { values[key] }
                                </div>
                            </div>
                        </div>
                    </>
                );
            }
        }
    }

    dialogBody();

    return (
        <div>
            <Dialog
                visible={ open } modal
                onHide={ () => setOpen(false) }
                footer={ dialogFooter }
                header={ dialogHeader } >

                {
                    rowsChanges.map((row, i) => (
                        <div key={ i }>
                            { row }
                        </div>
                    ))
                }

            </Dialog>
        </div>
    )
}

SaveChangesDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default SaveChangesDialog;
