import React from "react";
import ResultsList from "./ResultsList";

const ResultsProcess = () => {

    return (
        <>
            <div className="card card-w-title">
                <ResultsList />
            </div>
        </>
    );
};

export default ResultsProcess;
