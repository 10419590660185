import React from 'react';
import PropTypes from 'prop-types';
import HelpButton from '../../../../components/UI/HelpButton';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { _downloadPNG, _downloadSVG, _downloadXML } from '../../../utils/helpers/bpmnHelpers';
import { useTranslation } from 'react-i18next';

const MenuOptions = ({ saving, diagramXML, diagramSVG }) => {

    const { t: tGlobal } = useTranslation(['global']);

    const menuItems = [
        {
            label: 'Image (SVG)',
            icon: 'pi pi-image',
            disabled: true,
            command: function(e) {
                _downloadSVG(diagramSVG);
            },
        },
        {
            label: 'Image (PNG)',
            icon: 'pi pi-image',
            command: function() {
                _downloadPNG(diagramSVG);
            },
        },
        {
            separator: true,
        },
        {
            label: 'File (XML)',
            icon: 'pi pi-file',
            command: function() {
                _downloadXML(diagramXML);
            },
        },
        {
            label: 'File (PDF)',
            icon: 'pi pi-file',
            disabled: true,
            command: function() {
                // console.log('PDF');
            },
        },
    ];

    return (
        <div className='p-grid'>
            <div className='p-col'>
                <h3>
                    {
                        tGlobal('vocabulary.plural.activities')
                    }
                    <HelpButton
                        message='Ingrese el flujo BPMN del proceso' />
                </h3>
            </div>

            <div className="p-col p-text-right">
                <Button
                    className="p-button-text p-button-info p-mr-1"
                    label={ (saving) ? 'Guardando cambios ... ' : 'Cambios guardados' }
                    icon={ (saving) ? 'pi pi-spin pi-spinner': 'pi pi-check' }
                    disabled />
                <SplitButton
                    onClick={ () => _downloadSVG(diagramSVG) }
                    label={ tGlobal('button.download') } icon="pi pi-download" model={ menuItems }
                    className="" />
            </div>
        </div>  
    );
}

MenuOptions.propTypes = {
    saving: PropTypes.bool.isRequired,
    process: PropTypes.object.isRequired,
}

export default MenuOptions