import axios from 'axios';
import { createAction } from 'redux-actions';
import {
    URL_BASE,
    urlCreateModel,
    urlUpdateModel,
    urlDeleteModel,
    urlListModelsByTeam,
    urlModelsAcquiredByTeam,
    urlGetModelAdquired,
    urlRetrieveModel,
    urlModelCategories,
    urlBasicInfo,
    urlPublishModel,
    urlUpdateBasicInfo,
    urlRetrieveModelGeneralitiesByModel,
    urlExecuteModel,
    urlUpdateModelGeneralities,
    urlUpdateModelLocation,
} from '../../api/urls';

export const setModelState = createAction('SET_MODEL_STATE');
export const setCreateModelErrorAction = createAction('SET_CREATE_MODEL_ERROR_ACTION');
export const setDeleteModelErrorAction = createAction('SET_DELETE_MODEL_ERROR_ACTION');

export const resetModelState = () => (dispatch) => {
    dispatch(setModelState({}));
};

export const createModel = (model) => async (dispatch, getState) => {

    const userId = getState().LoginState?.data?.id;
    const teamId = getState().LoginState?.data?.team?.id;
    const accessToken = getState().LoginState?.data?.access_token;

    try {
        const new_model = {
            name: model.name,
            description: model.description,
            long_description: model.description,
            user_id: userId,
            price: 0,
            image: model.image,
            categories_ids: model.categories_ids,
            category_id: 1, // Legacy
            team_id: teamId,
            hashtag: '',
        };

        const { data, status } = await axios.post(`${URL_BASE}${urlCreateModel}`, new_model, {
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        });

        if (status === 200 || status === 201) {
            dispatch(setModelState(data));
            return data;
        } else {
            throw new Error('Error creating new model');
        }
    } catch (error) {
        throw error;
    }
};

export const editModel = (model, role) => async (dispatch, getState) => {

    const userId = getState().LoginState?.data?.id;
    const teamId = getState().LoginState?.data?.team?.id;
    const accessToken = getState().LoginState?.data?.access_token;
    const stage_role = '/' + role;

    const edit_model = {
        id: model.id,
        name: model.name,
        description: model.description,
        long_description: model.description,
        user_id: userId,
        price: 0,
        image: model.image,
        category_id: model.category_id, // Legacy
        categories_ids: model.categories_ids,
        team_id: teamId,
        hashtag: model.hashtag,
    };

    try {
        const { data, status } = await axios.put(URL_BASE + urlUpdateModel + model.id + stage_role, edit_model, {
            headers: {
              Authorization: 'Bearer ' + accessToken,
            },
        });

        if (status === 200 || status === 201) {
            return data;
        } else {
            throw new Error('Error editing model');
        }
    } catch (error) {
        throw error;
    }
};

export const deleteModel = (modelId, token, role) => async (dispatch, getState) => {

    const stage_role = '/' + role;

    try {
        const { status } = await axios.delete(URL_BASE + urlDeleteModel + modelId + stage_role, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });

        if (status === 200) {
            dispatch(
                setDeleteModelErrorAction({ message: 'Success' })
            );
        }
        return status;

    } catch (error) {
        dispatch(
            setDeleteModelErrorAction({
                message: 'Error deleting model',
            })
        );
    }
};

export const getModelsAdquiredByTeamId = async (teamId, token) => {

    const response = await axios.get(`${URL_BASE}${urlModelsAcquiredByTeam}/${teamId}`, {
        headers: {
            Authorization: 'Bearer ' + token,  
        },
    });

    if (response.status === 200) {
        return response.data;
    } else {
        throw new Error('No hay servicio para recolectar');
    }
};

export const getModelsByTeam = async (teamId, token, role=1) => {

    const stage_role = '/' + role;

    const response = await axios.get(URL_BASE + urlListModelsByTeam + teamId + stage_role, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
    });
    
    return response.data;
};

export const getModelAdquired = (modelId, teamId, token) => async (dispatch) => {

    const response = await axios.get(`${URL_BASE}${urlGetModelAdquired}/${modelId}`, {
        headers: {
            Authorization: 'Bearer ' + token,  
        },
    });

    if (response.status === 200) {
        dispatch(setModelState(response.data));
        dispatch(
            setDeleteModelErrorAction({
                message: 'Success',
            })
        );
    } else {
        throw new Error('No hay servicio para recolectar');
    }
};

export const getModelById = (modelId) => async (dispatch, getState) => {

    const token = getState().LoginState?.data?.access_token;
    const role = '/' + getState().RolNavigationState?.state;

    const response = await axios.get(URL_BASE + urlRetrieveModel + modelId + role, {
        headers: {
            Authorization: 'Bearer ' + token,  
        },
    });

    if (response.status === 200) {
        dispatch(setModelState(response.data));
        dispatch(setDeleteModelErrorAction({ message: 'Success' }));
    } else {
        // eslint-disable-next-line no-throw-literal
        throw 'No se pudo obtener modelo';
    }
};

// Route for getting the categories for when creating a new model
export const getModelsCategories = () => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;

    try {
        const { data, status } = await axios.get(`${urlModelCategories}`, {
            headers: {
              Authorization: 'Bearer ' + accessToken,
            },
        });

        if (status === 200) {
            return data;
        } else {
            throw new Error('Error retrieving categories');
        }
    } catch (error) {
        throw error;
    }
};

// ********** BASIC INFO SECTION: PRINCIPLES, PURPOSE, SCOPE

export const getBasicInfo = (modelId, role) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const stage_role = '/' + role;

    try {
        const { status, data } = await axios.get(urlBasicInfo + modelId + stage_role, {
            headers: {
                Authorization: 'Bearer ' + accessToken,
            },
        });

        if (status === 200) {
            return data;
        } else {
            throw new Error('Error retrieving basic info');
        }
    } catch (error) {
        throw error;
    }
};

export const updateBasicInfo = (attribute, description, role) => async (dispatch, getState) => {

    const accessToken = getState().LoginState.data.access_token;
    const modelId = getState().ModelState.model.id;
    const stage_role = role;

    try {
      const { status } = await axios.put(urlUpdateBasicInfo + stage_role, {
          id: modelId,
          description: description,
          attribute: attribute,
      }, {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
      });

        if (status !== 200) {
            throw new Error(`Error updating ${attribute}`);
        }
    } catch (error) {
        throw error;
    }
};

export const publishModel = (modelId) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;

    try {
        const { status } = await axios.post(`${urlPublishModel}/${modelId}`, {}, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }
        });

        if (status === 200) {
            return true;
        } else {
            throw new Error('Error publishing model.');
        }
    } catch (error) {
        throw error;
    }
};

export const executeModel = async (model_id, token) => {
    
    const response = await axios.put(urlExecuteModel + model_id, { }, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return response.data;
}

/**
 * MODEL GENERALITIES ACTIONS
 */

export const retrieveModelGeneralitiesByModel = async (model_id, token) => {
    
    const response = await axios.get(urlRetrieveModelGeneralitiesByModel + model_id, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });

    return response.data;
}

export const updateModelGeneralities = (model_id, data) => async (dispatch, getState) => {
    
    const token = getState().LoginState?.data?.access_token;

    try {
        const response = await axios.put(urlUpdateModelGeneralities + model_id, data, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Error publishing model.');
        }
    } catch (error) {
        throw error;
    }
}

export const updateModelLocation = (model_location_id, data) => async (dispatch, getState) => {
    
    const token = getState().LoginState?.data?.access_token;

    try {
        const response = await axios.put(urlUpdateModelLocation + model_location_id, data, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        });
        
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Error publishing model.');
        }
    } catch (error) {
        throw error;
    }
}