import React from 'react';
import PropTypes from 'prop-types';

const GoogleText = ({ style={}, className='' }) => {
    return (
        <span style={{ fontSize: '1.1rem', ...style }} className={ className }>
            <span style={{ color: '#0266C8 ' }}>
                G
            </span>
            <span style={{ color: '#F90101' }}>
                o
            </span>
            <span style={{ color: '#F2B50F' }}>
                o
            </span>
            <span style={{ color: '#0266C8 ' }}>
                g
            </span>
            <span style={{ color: '#00933B' }}>
                l
            </span>
            <span style={{ color: '#F90101' }}>
                e
            </span>
        </span>
    )
}

GoogleText.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
}

export default GoogleText
