import axios from "axios";
import * as urls from  '../../api/urls';

export const listMoments = async (token) => {

    const response = await axios.get(`${urls.URL_BASE}${urls.urlListMoments}`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}