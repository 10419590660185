import store from "../../../../redux/store";
import { createStartEvent, deleteStartEventByStartEventId, retrieveStartEventByStartEventId } from "../../../../redux/actions/start-event.actions";
import { createActivity, deleteActivityByTask, retrieveActivityByTask, updateActivityByTask } from "../../../../redux/actions/activity.actions";
import { createEndEvent, deleteEndEventByEndEventId, getEndEventByEndEventId } from "../../../../redux/actions/end-event.actions";
import { createGateway, deleteGatewayByGatewayId, retrieveGatewayByGatewayId } from "../../../../redux/actions/gateway.actions";
import { createConnectionShape, deleteNextConnection, deletePreviousConnection } from "../../../../redux/actions/connection-shape.actions";
import { NEXT_TYPE, PREVIUOS_TYPE } from "../types/bpmnTypes";

export const downloadFile = (blobContent, fileName) => {

    var reader = new FileReader();

    reader.onload = function (event) {
        
        var save = document.createElement('a');

        save.href = event.target.result;
        save.target = '_blank';
        save.download = fileName || 'archivo.dat';

        var clicEvent = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
        });

        save.dispatchEvent(clicEvent);

        (window.URL || window.webkitURL).revokeObjectURL(save.href);
    };

    reader.readAsDataURL(blobContent);
};

export const _downloadXML = (xml) => {
    const _xml = new Blob([xml], {
        type: 'text/plain'
    });
    downloadFile(_xml, 'modelo.xml');
};

export const _downloadSVG = (svg) => {
    var svgBlob = new Blob([svg], {
        type: 'image/svg+xml'
    });
    downloadFile(svgBlob, 'model.svg');
}

export const _getSVG = async (modeler, setDiagramSVG) => {
    try {
        const result = await modeler.saveSVG({ format: true });
        const { svg } = result;
        setDiagramSVG(svg);
    } catch (err) {
        setDiagramSVG('');
    }
}

export const _downloadPNG = (svg) => {
    svgToPng(svg,(imgData)=>{
        fetch(imgData).then(res => res.blob()).then(blob => downloadFile(blob, 'model.png'));
    });
}

function svgToPng(svg, callback) {
    const url = getSvgUrl(svg);
    svgUrlToPng(url, (imgData) => {
        callback(imgData);
        URL.revokeObjectURL(url);
    });
}

function getSvgUrl(svg) {
    return  URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }));
}

function svgUrlToPng(svgUrl, callback) {
    const svgImage = document.createElement('img');
    // imgPreview.style.position = 'absolute';
    // imgPreview.style.top = '-9999px';
    document.body.appendChild(svgImage);
    svgImage.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = svgImage.clientWidth;
        canvas.height = svgImage.clientHeight;
        const canvasCtx = canvas.getContext('2d');
        canvasCtx.drawImage(svgImage, 0, 0);
        const imgData = canvas.toDataURL('image/png');
        callback(imgData);
        document.body.removeChild(svgImage);
    };
    svgImage.src = svgUrl;
}

// Import XML
export const importXML = (setDiagramXML, setDiagramSVG, modeler, xml) => {
    
    modeler.importXML(xml).then(({ warnings }) => {
        
        if (warnings.length) console.log("Warnings", warnings);
        
        const canvas = modeler.get("canvas");
        canvas.zoom("fit-viewport", 'auto');
        
        setDiagramXML(xml);
        _getSVG(modeler, setDiagramSVG);

    }).catch((err) => {
        console.log("error", err);
    });
}

/* Custom color of BPMN elements ******************************************************************************************************/
export const setCustomColorBPMN = (modeling, element, elementType) => {

    const gateways = [ /* Decision/Rhombus list */
        'bpmn:EventBasedGateway',
        'bpmn:InclusiveGateway',
        'bpmn:ExclusiveGateway',
        'bpmn:ParallelGateway', 
        'bpmn:ComplexGateway',
    ];

    const intermediates = [
        'bpmn:IntermediateCatchEvent',
        'bpmn:IntermediateThrowEvent',
    ]

    if (elementType === 'bpmn:StartEvent') {
        modeling.setColor([ element ], {
            stroke: 'green',
            fill: 'greenyellow',
        });
    }

    if (intermediates.includes(elementType)) {
        modeling.setColor([ element ], {
            stroke: 'black',
            fill: '#a8ffec',
        });
    }

    if (elementType === 'bpmn:Task') {
        modeling.setColor([ element ], {
            // stroke: stroke,
            fill: 'orange',
        });
    }

    if (gateways.includes(elementType)) {
        modeling.setColor([ element ], {
            // stroke: stroke,
            fill: 'yellow',
        });
    }

    // if (elementType === 'bpmn:IntermediateCatchEvent') {
    //     modeling.setColor([ element ], {
    //         stroke: 'black',
    //         fill: '#a8ffec',
    //     });
    // }

    // if (elementType === 'bpmn:Participant' || elementType === 'bpmn:Lane') {
    //     modeling.setColor([ element ], {
    //         stroke: stroke,
    //     });
    // }

    if (elementType === 'bpmn:EndEvent') {
        modeling.setColor([ element ], {
            stroke: 'red',
            fill: 'pink',
        });
    }
}

/* Handle CRUD of task ********************************************************************************************/
export const handleTask = (element, process, user, toast, event) => {

    const ROLE = store.getState().RolNavigationState?.state;
    const taskID = element?.id; //Shape id (task_id)
    let taskName = (element?.businessObject?.name) ? element?.businessObject?.name : '';

    if (event === 'shape.changed') {

        retrieveActivityByTask(element?.id, process?.id, user?.access_token, ROLE).then(response => {
    
            // If activity isn´t registered, a new activity will be created
            if (!response || Object.keys(response).length === 0) { 
    
                const body = {
                    name: taskName,
                    processId: process?.id,
                    taskId: taskID,
                }

                createActivity(body, user?.access_token, ROLE).then(responseCreated => {
                    toast.current.show({ severity: 'success', summary: 'Actividad Creada', detail: `${taskName}`, life: 2000 });
                });
    
            // If status is equals to 0, the activity will be restored
            } else if (response?.status === 0) { 
                
                deleteActivityByTask(taskID, process?.id, user?.access_token, ROLE).then(responseDeleted => {
                    toast.current.show({ severity: 'success', summary: 'Actividad Restaurada', detail: `${taskName}`, life: 2000 });
                });
    
            // If task name isn´t equals to activity name, name will be updated
            } else if (taskName && response?.name !== taskName) {
                
                updateActivityByTask(response?.task_id, process?.id, { name: taskName }, user?.access_token, ROLE).then(responseUpdated => {
                    toast.current.show({ severity: 'success', summary: 'Actividad Actualizada', detail: `${responseUpdated?.name}`, life: 2000 });
                });
            }
        });
    } else if (event === 'shape.removed') {
        deleteActivityByTask(taskID, process?.id, user?.access_token, ROLE).then(response => {
            toast.current.show({ 
                severity: 'success', 
                summary: 'Actividad Eliminada', 
                detail: `${(taskName) ? taskName : ''}`, life: 2000 }
            );
        });
    }
}

/* Handle CRUD of gateways ********************************************************************************************/
export const handleGateway = (element, process, user, toast, event) => {
    
    const ROLE = store.getState().RolNavigationState?.state;
    const gatewayID = element?.id;
    let gatewayName = (element?.businessObject?.name) ? element?.businessObject?.name : '';
    
    if (event === 'shape.changed') {

        retrieveGatewayByGatewayId(gatewayID, process?.id, user?.access_token, ROLE).then((gatewayRetrieved) => {
    
            if (!gatewayRetrieved || Object.keys(gatewayRetrieved).length === 0) {
    
                const body = {
                    gateway_id: gatewayID,
                    name: gatewayName,
                    process_id: process?.id,
                }
    
                createGateway(body, user?.access_token, ROLE).then((gatewayCreated) => {
                    toast.current.show({ severity: 'success', summary: 'Condición Creada', detail: `${gatewayName}`, life: 2000 });
                });
            }
        })
    } else if (event === 'shape.removed') {

        deleteGatewayByGatewayId(gatewayID, process?.id, user?.access_token, ROLE).then(response => {
            toast.current.show({ 
                severity: 'success', 
                summary: 'Condición Eliminada', 
                detail: `${gatewayName}`, life: 2000 }
            );
        });
    }
}

/* Handle CRUD of start event ********************************************************************************************/
export const handleStartEvent = (element, process, user, toast, event) => {
    
    const ROLE = store.getState().RolNavigationState?.state;
    const startEventID = element?.id;
    let startEventName = (element?.businessObject?.name) ? element?.businessObject?.name : '';
    
    if (event === 'shape.changed') {

        retrieveStartEventByStartEventId(startEventID, process?.id, user?.access_token, ROLE).then((dataRetrieved) => {
    
            if (!dataRetrieved || Object.keys(dataRetrieved).length === 0) {
    
                const body = {
                    start_event_id: startEventID,
                    name: startEventName,
                    process_id: process?.id,
                }
    
                createStartEvent(body, user?.access_token, ROLE).then((dataCreated) => {
                    toast.current.show({ severity: 'success', summary: 'Inicio Creado', detail: `${startEventName}`, life: 2000 });
                });
            }
        })
    } else if (event === 'shape.removed') {

        deleteStartEventByStartEventId(startEventID, process?.id,user?.access_token, ROLE).then(response => {
            toast.current.show({
                severity: 'success', 
                summary: 'Inicio Eliminado', 
                detail: `${startEventName}`, life: 2000 },
            );
        });
    }
}

/* Handle CRUD of end event ********************************************************************************************/
export const handleEndEvent = (element, process, user, toast, event) => {

    const ROLE = store.getState().RolNavigationState?.state;
    const endEventID = element?.id;
    let endEventName = (element?.businessObject?.name) ? element?.businessObject?.name : '';
    
    if (event === 'shape.changed') {

        getEndEventByEndEventId(endEventID, process?.id, user?.access_token, ROLE).then((dataRetrieved) => {
    
            if (!dataRetrieved || Object.keys(dataRetrieved).length === 0) {
    
                const body = {
                    end_event_id: endEventID,
                    name: endEventName,
                    process_id: process?.id,
                }
    
                createEndEvent(body, user?.access_token, ROLE).then((dataCreated) => {
                    toast.current.show({ severity: 'success', summary: 'Fin creado', detail: `${endEventName}`, life: 2000 });
                });
            }
        })
    } else if (event === 'shape.removed') {

        deleteEndEventByEndEventId(endEventID, process?.id, user?.access_token, ROLE).then(response => {
            toast.current.show({ 
                severity: 'success', 
                summary: 'Fin Eliminado', 
                detail: `${endEventName}`, life: 2000 }
            );
        });
    }
}

/*  Handle connections */
export const handleConnections = (element, process, user, event) => {

    const ROLE = store.getState().RolNavigationState?.state;
    const source = element?.source;
    const sourceType = source?.type.split(':')[1];
    const target = element?.target;
    const targetType = target?.type.split(':')[1];

    if (event === 'connection.changed') {

        const bodyPrevious = {
            type: PREVIUOS_TYPE,
            parent_shape_id: target?.id,
            shape_id: source?.id,
            parent_shape_type_label: targetType,
            shape_type_label: sourceType,
            process_id: process?.id,
        }

        const bodyNext = {
            type: NEXT_TYPE,
            parent_shape_id: source?.id,
            shape_id: target?.id,
            parent_shape_type_label: sourceType,
            shape_type_label: targetType,
            process_id: process?.id,
        }

        createConnectionShape(bodyPrevious, user?.access_token, ROLE);
        createConnectionShape(bodyNext, user?.access_token, ROLE);
        
    } else if (event === 'connection.removed') {

        deletePreviousConnection(target?.id, source?.id, process?.id, user?.access_token, ROLE);
        deleteNextConnection(source?.id, target?.id, process?.id, user?.access_token, ROLE);
    }
}