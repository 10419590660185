import React, { useState, useEffect, useRef } from 'react';
import ProcessFormDialog from '../../components/UI/ProcessConsole/ProcessFormDialog';
import Process from './Process';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { getProcessByMomentModelId } from '../../../redux/actions/moments-model.actions';
import { useSelector,useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { listMoments } from '../../../redux/actions/moments.actions';
import { GenericDialog } from '../../components/UI';
import * as processActions from "../../../redux/actions/process.actions";
import * as tableTemplates from '../utils/templates/tableTemplates';
import { RolesEnum } from '../../enums/roles';

const Processes = () => {

    const dt = useRef(null);
    const message = useRef();
    const dispatch = useDispatch();
    const modelState = useSelector((state) => state.ModelState.model);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ selectedMoments,setSelectedMoments ]= useState([])
    const [ models, setModels ] = useState([]); //Data od table
    const [ globalFilter, setGlobalFilter ] = useState([]);
    const [ process, setProcess ] = useState({});
    const [ openProcessCreateDialog, setOpenProcessCreateDialog ] = useState(false);
    const [ openProcessEditDialog, setOpenProcessEditDialog ] = useState(false);
    const [ openProcessDialog, setOpenProcessDialog ] = useState(false);
    const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
    const [ momentsOptions, setMomentsOptions ] = useState([]);

    useEffect(() => {
        listMoments(user?.access_token).then((data) => {
            setMomentsOptions(data);
        });
    }, [ user ])

    useEffect(() => {
        loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ selectedMoments ]);

    const showMessage = (severity, summary, detail) => {
        message?.current?.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: 3000,
        });
    };
    
    const loadData = () => {
        getProcessByMomentModelId(modelState?.id, selectedMoments, user?.access_token, role).then(data => {
            setModels(data);
        });
    }

    const handleWorkProcess = async (data) => {
        await dispatch(processActions.getProcessById(data?.id,user?.access_token,{},false));
        setProcess(data);
        setOpenProcessDialog(true);
    };

    const handleDeleteProcess = (data) => {
        processActions.deleteProcess(data?.id, user?.access_token, role).then((response) => {
            showMessage('success', 'Proceso eliminado', data?.name);
            setOpenDeleteDialog(false);
            loadData();
        }).catch((err) => {
            setOpenDeleteDialog(false);
            showMessage('error', 'Proceso no eliminado', 'Error de conexión');
        });
    }

    const processButtonsTemplate = (data) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <Button 
                    icon='pi pi-pencil'
                    className='p-button-warning p-button-rounded'
                    title='Editar'
                    onClick={() => {
                        setProcess(data);
                        setOpenProcessEditDialog(true);
                    }} />
                <Button 
                    onClick={ () => handleWorkProcess(data) } icon="pi pi-briefcase" 
                    className="p-button-sucess p-button-rounded p-mx-1" 
                    title="Trabajar" />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-danger p-button-rounded p-mx-1" 
                    title="Eliminar"
                    onClick={ () => {
                        setProcess(data);
                        setOpenDeleteDialog(true);
                    }} />
            </div>
        );  
    }

    const header = (
        <div className="table-header">
            <div className='p-grid'>
                <div className="p-col" style={{ display: 'flex', alignItems: 'center' }}>
                    <h5 className='p-m-0'>Procesos</h5>
                </div>
                <div className="p-col">
                    <Button 
                        label="Nuevo" 
                        icon="pi pi-plus" 
                        className="p-button-success"
                        onClick={ () => {
                            setOpenProcessCreateDialog(true);
                        }}/>
                </div>
            </div>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder='Buscar' />
            </span>
        </div>
    );

    const momentTemplate = (data) => (
        <div>
            {(role === RolesEnum.Designer)
                ? <i style={{ fontSize: '.9rem' }}>{ data?.moment_model?.moment?.name }</i>
                : <i style={{ fontSize: '.9rem' }}>{ data?.a_moment_model?.moment?.name }</i>
            }
        </div>
    );

    return (
        <>
            <Toast ref={ message } />
            
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        <div className='p-text-center p-mt-4 p-mb-3'>
                            <h5 className='p-mb-1'>Momentos</h5>
                            <SelectButton
                                value={ selectedMoments }
                                onChange={(e) => setSelectedMoments(e.value)} 
                                options={ momentsOptions } 
                                optionLabel="name" multiple />
                        </div>

                        <DataTable 
                            ref={ dt } value={ models } rowHover
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                            globalFilter={ globalFilter } emptyMessage="No tiene procesos agreados" header={ header } >

                            <Column field="name" header="Nombre" sortable body= { tableTemplates.nameBodyTemplate } />
                            <Column field="description" header="Descripción" sortable body= { tableTemplates.descriptionBodyTemplate } />
                            {(selectedMoments.length !== 1)
                                && <Column 
                                    field={(role === RolesEnum.Designer) 
                                        ? 'moment_model.moment.name'
                                        : 'a_moment_model.moment.name'}
                                    header="Momento" sortable 
                                    body={ momentTemplate } />
                            }
                            <Column style={{ width: '12rem' }} field="status" header="Estado" sortable body= { tableTemplates.statusBodyTemplate } />
                            <Column style={{ width: '12rem' }} body={ processButtonsTemplate  } />
                        </DataTable>
                    </div>
                </div>
            </div>
            
            {/* Dialog for create process */}
            <ProcessFormDialog
                setOpen={ setOpenProcessCreateDialog }
                open={ openProcessCreateDialog }
                showMessage={ showMessage }
                loadData={ loadData }
                momentsOptions={ momentsOptions } />

            {/* Dialog for update process */}
            <ProcessFormDialog
                setOpen={ setOpenProcessEditDialog }
                open={ openProcessEditDialog }
                showMessage={ showMessage }
                loadData={ loadData }
                momentsOptions={ momentsOptions }
                process={ process } />

            {/* Dialog for delete process */}
            <GenericDialog
                visible={ openDeleteDialog }
                header={"Confirmar"}
                onCancelAction={() => setOpenDeleteDialog(false) }
                onConfirmationAction={() => handleDeleteProcess(process) }
                onHide={ () => setOpenDeleteDialog(false) }
                message={<span>¿Desea eliminar <b>{process?.name}</b>?</span>}
                condition={ process?.id } />

            {/* Dialog for work process */}
            <Process
                open={ openProcessDialog }
                setOpen={ setOpenProcessDialog }
                process={ process } />
        </>
    );
}

export default Processes;