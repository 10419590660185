const ECU_0_JSON = require('./ECU_0.json');
const ECU_1_JSON = require('./ECU_1.json');
const ECU_2_JSON = require('./ECU_2.json');
const ECU_3_JSON = require('./ECU_3.json');

// GET ALL

export const getCountries = () => {
    return ECU_0_JSON.features;
}

export const getEcuProvinces = () => {
    return ECU_1_JSON.features;
}

export const getEcuCantons = () => {
    return ECU_2_JSON.features;
}

export const getEcuCantonalHeaders = () => {
    return ECU_3_JSON.features;
}

// GET BY PARAMS

export const getEcuCantonsByGID_1 = (gid_1) => {
    return ECU_2_JSON.features.filter(feature => feature.properties.GID_1 === gid_1);
}

export const getEcuCantonalHeadersByGID_2 = (gid_2) => {
    return ECU_3_JSON.features.filter(feature => feature.properties.GID_2 === gid_2);
}