import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import img from '../../../assets/img/avatar-6.png';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { Fieldset } from 'primereact/fieldset';
import { getStatusCls } from '../../utils/helpers/monitoringHelpers';
import { Chip } from 'primereact/chip';
import { useSelector } from 'react-redux';
import { listUserAssigmentByModel } from '../../../../redux/actions/acquireds/activity.assignment.actions';
import { assignmentStatusOptions } from '../../utils/constants/monitoringConstants';
import { listActivityByModel } from '../../../../redux/actions/activity.actions';
import { listProcessByModel } from '../../../../redux/actions/acquireds/process.actions';
import { Toast } from 'primereact/toast';
import { listByFilters } from '../../../../redux/actions/activity.actions';

const ActivityFilters = ({ setActivities, membersOptions, setMembersOptions }) => {

    const toast  = useRef(null);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const { model } = useSelector(state => state.ModelState);
    const [ status, setStatus ] = useState([]);
    // For process autocomplete input
    const [ processesOptions, setProcessesOptions ] = useState([]);
    const [ selectedProcesses, setSelectedProcesses ] = useState([]);
    const [ processesSuggestions, setProcessesSuggestions ] = useState([]);
    // For member autocomplete input
    const [ selectedMembers, setSelectedMembers ] = useState([]);
    const [ membersSuggestions, setMembersSuggestions ] = useState([]);

    useEffect(() => {
        if (model?.id) {
            listUserAssigmentByModel(model?.id, user?.access_token).then(listResponse => {
                if (listResponse) setMembersOptions(listResponse);
            });
            listProcessByModel(model?.id, user?.access_token).then(listResponse => {
                if (listResponse) setProcessesOptions(listResponse);
            });
        }
    }, [ user, model ])

    const searchMember = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setMembersSuggestions([ ...membersOptions ]);
            } else {
                setMembersSuggestions( membersOptions.filter((member) => {
                    return member?.user?.username.toLowerCase().includes(event.query.toLowerCase());
                }));
            }
        }, 100);
    };

    const searchProcess = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setProcessesSuggestions([ ...processesOptions ]);
            } else {
                setProcessesSuggestions( processesOptions.filter((process) => {
                    return process?.name.toLowerCase().includes(event.query.toLowerCase());
                }));
            }
        }, 100);
    };

    // This handler resolve the bug that repeat responses in MultiSelect
    const handleStatusChange = ({ value: values }) => {

        const valuesFiltered = []

        if (values) {
            values.forEach(value => {
                const index = valuesFiltered.findIndex((item) => item.id === value.id);
                if (index === -1) valuesFiltered.push(value);
         
            })
        }

        setStatus(valuesFiltered);
    }

    const selectedStatusItemTemplate = (value) => {
        if (value) {
            return <span className={'status-' + getStatusCls(value?.id)}>{ value?.label }</span>
        } else {
            return <span>Seleccione</span>
        }
    }

    const itemMemberTemplate = (e) => {
        return (
            <Chip label={ e?.user?.username } image={ img } className="p-mr-2 p-mb-2" />
        );
    }

    const itemSelectedMemberTemplate = (e) => {
        return (
            <span>{ e?.user?.username }</span>
        );
    }

    const applyFilters = () => {

        if (status.length > 0 || selectedMembers.length > 0 || selectedProcesses.length > 0) {            

            const filters = {
                a_model_id: model?.id,
                status: status,
                members: selectedMembers,
                processes: selectedProcesses,
            }
            
            listByFilters(filters, user?.access_token).then((data) => {
                setActivities(data);
            }).catch(error => {
                const detail = error?.response?.data?.detail;
                if (detail) {
                    toast.current.show({ severity: 'error', summary: 'Error al filtrar', detail: detail, life: 2000 });
                }
            });
        } else {
            listActivityByModel(model?.id, user?.access_token, role).then(data => {
                setActivities(data);
            })
        }
    }

    return (
        <div>
            <Toast ref={ toast } />

            <Fieldset legend='Filtros' toggleable >
                <div className='p-grid' >
                    <div className="p-col">
                        <div className='p-mb-1'>Estados(s)</div>
                        <MultiSelect
                            showClear
                            value={ status } 
                            onChange={ handleStatusChange } 
                            options={ assignmentStatusOptions } 
                            optionLabel="label"
                            selectedItemTemplate={ selectedStatusItemTemplate } />
                    </div>
                    <div className='p-col'>
                        <div className='p-mb-1'>Asignado(s)</div>
                        <AutoComplete
                            itemTemplate={ itemMemberTemplate }
                            className="p-mr-1" multiple
                            placeholder="Busque integrantes"
                            dropdown value={ selectedMembers } 
                            onChange={(e) => setSelectedMembers(e.value)}
                            suggestions={ membersSuggestions }
                            completeMethod={ searchMember }
                            selectedItemTemplate={ itemSelectedMemberTemplate } />
                    </div>
                    <div className='p-col'>
                        <div className='p-mb-1'>Proceso(s):</div>
                        <AutoComplete
                            className="p-mr-1" multiple
                            placeholder="Busque procesos"
                            dropdown value={ selectedProcesses } 
                            onChange={(e) => setSelectedProcesses(e.value)}
                            suggestions={ processesSuggestions }
                            completeMethod={ searchProcess }
                            field='name' />
                    </div>
                    <div className='p-col' style={{ textAlign: 'center' }}>
                        <Button
                            className='p-button-outlined p-button-info p-mt-3'
                            label='Aplicar'
                            onClick={ applyFilters } />
                    </div>
                </div>
            </Fieldset>
        </div>
    );
}

ActivityFilters.propTypes = {
    setMembersOptions: PropTypes.func.isRequired,
}

export default ActivityFilters;