import axios from 'axios';
import { createAction } from 'redux-actions';
import * as urls from  '../../api/urls';

export const setLoadingToolAction = createAction('SET_LOADING_TOOL_ACTION');
export const setToolDataAction = createAction('SET_TOOL_DATA_ACTION');
export const setToolErrorAction = createAction('SET_TOOL_ERROR_ACTION');
export const setResetToolAction = createAction('SET_RESET_TOOL_ACTION');

export const setLoadingCurrentToolAction = createAction('SET_LOADING_CURRENT_TOOL_ACTION');
export const setCurrentToolDataAction = createAction('SET_CURRENT_TOOL_DATA_ACTION');
export const setCurrentToolErrorAction = createAction('SET_CURRENT_TOOL_ERROR_ACTION');
export const setResetCurrentToolAction = createAction('SET_RESET_CURRENT_TOOL_ACTION');

export const setLoadingDistinctToolAction = createAction('SET_LOADING_DISTINCT_TOOL_ACTION');
export const setDistinctToolDataAction = createAction('SET_DISTINCT_TOOL_DATA_ACTION');
export const setDistinctToolErrorAction = createAction('SET_DISTINCT_TOOL_ERROR_ACTION');
export const setResetDistinctToolAction = createAction('SET_RESET_DISTINCT_TOOL_ACTION');

export const setLoadingComplementaryToolAction = createAction('SET_LOADING_COMPLEMENTARY_TOOL_ACTION');
export const setComplementaryToolDataAction = createAction('SET_COMPLEMENTARY_TOOL_DATA_ACTION');
export const setComplementaryToolErrorAction = createAction('SET_COMPLEMENTARY_TOOL_ERROR_ACTION');
export const setResetComplementaryToolAction = createAction('SET_RESET_COMPLEMENTARY_TOOL_ACTION');

export const setLoadingCreateToolAction = createAction('SET_LOADING_CREATE_TOOL_ACTION');
export const setToolCreateDataAction = createAction('SET_TOOL_CREATE_DATA_ACTION');
export const setToolCreateErrorAction = createAction('SET_TOOL_CREATE_ERROR_ACTION');
export const setResetCreateToolAction = createAction('SET_RESET_CREATE_TOOL_ACTION');

/* ELEMENT ACTIONS */

export const listElementsByModel = async (model_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.URL_BASE + urls.urlListElementByModel + model_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const createElement = async (element, token, role) => {

    const stage_role = role;

    const body = {
        model_id: element?.model_id,
        name: element?.name,
        description: element?.description,
        image: element?.image,
    }

    const response = await axios.post(urls.URL_BASE + urls.urlCreateElement + stage_role, body, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const updateElement = async (element, element_id, token, role) => {

    const stage_role = '/' + role;

    const body = {
        name: element?.name,
        description: element?.description,
        image: element?.image,
    }

    const response = await axios.put(urls.URL_BASE + urls.urlUpdateElement + element_id + stage_role, body, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const deleteElement = async (element_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.delete(urls.URL_BASE + urls.urlDeleteElement + element_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

/* TOOLBOX ACTIONS */

export const getToolByElement = async (element_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.URL_BASE + urls.urlRetrieveToolByElement + element_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const getToolsByModel = async (model_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.URL_BASE + urls.urlListToolByModel + model_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const updateAllTool = async (data, toolbox_id, token, role) => {

    const stage_role = '/' + role;

    const body = {
        ...data,
    }

    const response = await axios.put(urls.URL_BASE + urls.urlUpdateAllTool + toolbox_id + stage_role, body, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

/* COMPLEMENTARY TOOLBOX ACTIONS */

export const listComplementaryByToolbox = async (toolbox_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.URL_BASE + urls.urlListComplemantaryByToolbox + toolbox_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const createComplementaryToolbox = async (data, token, role) => {

    const stage_role = role;

    const body = {
        toolbox_id: data?.toolbox_id,
        related_toolbox_id: data?.related_toolbox_id,
    }

    const response = await axios.post(urls.URL_BASE + urls.urlCreateComplementaryToolbox + stage_role, body, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const deleteComplementaryToolbox = async (complementary_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.delete(urls.URL_BASE + urls.urlDeleteComplementaryToolbox + complementary_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}