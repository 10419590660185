import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { useTeam } from '../../../redux/hooks/useTeam';
import * as templates from '../utils/templates/tableTemplates';

export const Teams = () => {

    const {listTeams, teamsData} = useTeam();

    const [selectedTeams, setSelectedTeams] = useState();
    const [globalFilter, setGlobalFilter] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);


    useEffect(()=>{
      listTeams();
    },[])

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={ exportCSV } />
            </>
        )
    }

    const leftToolbarTemplate = () =>{
        return (
            <>
                <h4>Gearth Administration Teams</h4>
            </>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage Teams</h5>
            <i>{ (selectedTeams && selectedTeams.length > 0) && `Selected (${selectedTeams.length})` }</i>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={ toast } />
                        <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={ rightToolbarTemplate }></Toolbar>

                        <DataTable ref={ dt } value={ teamsData } selection={ selectedTeams } onSelectionChange={(e) => setSelectedTeams(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            globalFilter={ globalFilter } selectionMode="single" emptyMessage="No teams found." header={ header }>

                            <Column style={{ width: '110px' }} field="id" header="ID" sortable body={ templates.idBodyTemplate }></Column>
                            <Column field="name" header="Name" sortable body= { templates.nameBodyTemplate }></Column>
                            <Column style={{ width: '130px' }} field="logo" header="Logo" body={ templates.logoBodyTemplate }></Column>
                            <Column field="description" header="Description" sortable body= { templates.descriptionBodyTemplate }></Column>
                            <Column field="plan" header="Plan" sortable body={ templates.planNameBodyTemplate }></Column>
                            <Column field="status" header="Status" sortable body= { templates.statusBodyTemplate }></Column>

                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    )
}

Teams.propTypes = {

}

export default Teams;
