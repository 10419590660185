const COLORS = [
    {
        inner: "#ca8dfd",
        outer: "#9300ff",
        hover: "#9300ff",
    },
    {
        inner: "#f16667",
        outer: "#eb2728",
        hover: "#eb2728",
    },
    {
        inner: "#4c8eda",
        outer: "#2861a1",
        hover: "#2861a1",
    },
    {
        inner: "#8dcc93",
        outer: "#5db665",
        hover: "#5db665",
    },
    {
        inner: "#f79767",
        outer: "#f36924",
        hover: "#f36924",
    },
    {
        inner: "#ffc454",
        outer: "#d7a013",
        hover: "#d7a013",
    },
    {
        inner: "#a5d3f2",
        outer: "#5baeb7",
        hover: "#5baeb7",
    },
    {
        inner: "#d2a56d",
        outer: "#bd7e4a",
        hover: "#bd7e4a",
    },
    {
        inner: "#00468b",
        outer: "#ffe45c",
        hover: "#ffe45c",
    }
    ,
    {
        inner: "#e63b60",
        outer: "#151a7b",
        hover: "#151a7b",
    }
]

export default COLORS;
