import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import img from '../../../../assets/img/avatar-4.png'
import { getAllUsers } from '../../../../../redux/actions/user.action';
import { Fieldset } from 'primereact/fieldset';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import { createActivityAssignment, deleteActivityAssignment, listAssigmentByActivity } from '../../../../../redux/actions/acquireds/activity.assignment.actions';
import { useSelector } from 'react-redux';
import { retrieveActivity } from '../../../../../redux/actions/activity.actions';

const Assignments = ({ toast, activity, process, setActivity }) => {

    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const { model } = useSelector(state => state.ModelState);
    const [ assigneds, setAssigneds ] = useState([])
    const [ members, setMembers ] = useState([]);

    useEffect(() => {
        if (activity?.id) {
            listAssigmentByActivity(activity?.id, user?.access_token).then(assigments => {
                setAssigneds(assigments);
            })
        }
    }, [ user, activity ])

    useEffect(() => {
        getAllUsers(user?.access_token).then((data) => {
            setMembers(data);
        });
    }, [ user ])

    const handleSelectAsigned = ({ value }) => {

        const body = {
            process_id: process?.id,
            activity_id: activity?.id,
            model_id: model?.id,
            user_id: value?.id,
        }

        createActivityAssignment(body, user?.access_token).then((assignment) => {
            if (assignment) {
                setAssigneds([ ...assigneds, assignment ]);
                toast.current.show({ severity: 'success', summary: 'Asignación creada', detail: value?.username, life: 2000 });

                retrieveActivity(activity?.id, user?.access_token, role).then((activityRetrieved) => {
                    setActivity(activityRetrieved);
                });
            }
        }).catch(err => {
            const detail = err?.response?.data?.detail;
            if (detail) {
                toast.current.show({ severity: 'error', summary: 'Asignación no creada', detail: detail, life: 2000 });
            }
        });
    }

    const handleRemoveAsigned = (assigned, i) => {
        deleteActivityAssignment(assigned?.id, user?.access_token).then(() => {

            toast.current.show({ severity: 'success', summary: 'Asignación eliminada', detail: assigned?.user?.username, life: 2000 });

            retrieveActivity(activity?.id, user?.access_token, role).then((activityRetrieved) => {
                setActivity(activityRetrieved);
            });
        }).catch((err) => {
            const detail = err?.response?.data?.detail;
            if (detail) {
                toast.current.show({ severity: 'error', summary: 'Asignación no eliminada', detail: detail, life: 2000 });
            }
        })
    }

    return (
        <div>
            <Fieldset legend='Responsables' toggleable >
                {
                    assigneds.map((assigned, index) => {
                        return (
                            <Chip
                                key={ assigned?.id + 'chip' } image={ img } className="custom-chip p-mb-2"
                                label={`${ assigned?.user?.username }`} removable
                                onRemove={ () => handleRemoveAsigned(assigned, index) } />
                        );
                    })
                }
                <hr />

                <div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-plus"></i>
                        </span>
                        <Dropdown
                            filter
                            placeholder='Asignar'
                            options={ members }
                            optionLabel='username'
                            onChange={ handleSelectAsigned } />
                    </div>
                </div>
            </Fieldset>
        </div>
    )
}

Assignments.propTypes = {
    activity: PropTypes.object.isRequired,
}

export default Assignments;