import axios from "axios";
import * as urls from  '../../api/urls';

export const createEndEvent = async (data, token, role) => {

    const body = {
        end_event_id: data?.end_event_id,
        process_id: data?.process_id,
    };

    const response = await axios.post(urls.urlCreateEndEvent + role, body, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const getEndEventByEndEventId = async (end_event_id, process_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.urlRetrieveEndEventByEndEventId + end_event_id + '/' + process_id + stage_role, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deleteEndEventByEndEventId = async (end_event_id, process_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.delete(urls.urlDeleteEndEventByEndEventId + end_event_id + '/' + process_id + stage_role,  {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}