import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StatusMessages, { useMessages } from './StatusMessages';
import SavedPaymentMethods from './SavedPaymentMethod';
import { Button } from 'primereact/button';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { 
    getOrCreateStripeCustomer, 
    getPaymentMethodsByCustomer, 
    createStripeSubscription 
} from '../../../redux/actions/payment-method.actions';

const PAYMENT_METHOD_TYPE = 'card'
const SETUP_FUTURE_USAGE = 'off_session'

// Estructure objects
// Customer bbject require: names, last_names, email, code

const StripeSubscriptionPayment = ({ customer, product, submited, setSubmited, callback }) => {
    
    const elements = useElements();
    const stripe = useStripe();
    const toast  = useRef(null);
    const history = useHistory();
    const [ messages, addMessage ] = useMessages();
    const [ stripePaymentMethods, setStripePaymentMethods ] = useState([]);
    const [ stripePaymentMethod, setStripePaymentMethod ] = useState(null);

    useEffect(() => {
        getPaymentMethodsByCustomer(customer?.code).then(response => {
            if (response?.payment_methods) {
                setStripePaymentMethods(response?.payment_methods);
            }
        });
    }, [])

    const handleSubmit = async (e) => {

        e.preventDefault()
        if (!stripe || !elements) return;
        
        setSubmited(true);
        
        if (!stripePaymentMethod) {
            newPayment();
        } else {
            savedPayment();
        }
    }

    const newPayment = () => {
        // Create a payment method
        // https://stripe.com/docs/billing/subscriptions/fixed-price
        stripe.createPaymentMethod({
            type: PAYMENT_METHOD_TYPE, 
            card: elements.getElement(CardElement), 
        }).then( async (result) => {

            if (result.error) {
                addMessage(result?.error?.message);
                setSubmited(false)
            } else {

                getOrCreateStripeCustomer(customer, result.paymentMethod.id).then(response => {
                    createStripeSubscription(customer?.code, product?.code, result.paymentMethod.id).then(response => {
                        addMessage('Successful subscription');
                        toast.current.show({ severity: 'success', summary: 'Successful subscription, Thanks!', life: 3000 });
                        setTimeout(() => {
                            history.replace('/login');
                        }, 2000);
                    });
                }).catch(error => {
                    const { error: stripeError } = error.response.data;
                    addMessage(stripeError?.message);
                    setSubmited(false);
                });
            }
        });
    }

    const savedPayment = () => {

        createStripeSubscription(customer?.code, product?.code, stripePaymentMethod.code).then(response => {
            addMessage('Successful subscription');
            toast.current.show({ severity: 'success', summary: 'Successful subscription, Thanks!', life: 3000 });
        });
    }

    return (
        <div>
            <Toast ref={ toast } />

            { (stripePaymentMethods.length > 0) &&
                stripePaymentMethods.map(paymentMethod => (
                    <SavedPaymentMethods 
                        key={ paymentMethod?.code }
                        paymentMethod={ paymentMethod }
                        value={ stripePaymentMethod }
                        setValue={ setStripePaymentMethod } />
                ))
            }

            { (!stripePaymentMethod) ?
                <form
                    id='payment-form'
                    className='bg-dark-intense p-p-3' 
                    style={{ maxWidth: '400px', margin: 'auto', borderRadius: '5px', }}
                    onSubmit={ handleSubmit } >

                    <label htmlFor='card-element'>
                        <a>Card Checkout</a>
                        <hr />
                    </label>

                    <CardElement
                        id='card-element'
                        iconStyle="solid"
                        options={{
                            iconStyle: 'solid',
                            style: {
                                base: {
                                    iconColor: '#c4f0ff',
                                    color: '#fff',
                                    fontSize: '16px',
                                },
                                invalid: {
                                    iconColor: '#FFC7EE',
                                    color: '#FFC7EE',
                                },
                            },
                        }} />

                    <Button
                        className='p-mt-5 p-button-text p-shadow-5'
                        style={{ width: '100%' }}
                        icon={ (submited) && 'pi pi-spin pi-spinner' }
                        disabled={!stripe || !elements || submited}
                        type='submit'
                        label='Subscribe' />
                </form>
                :
                <div style={{ maxWidth: '240px', margin: 'auto' }} >
                    <Button
                        className='p-button-text p-shadow-5'
                        style={{ width: '240px' }}
                        type='submit'
                        label='Subscribe'
                        disabled={ submited }
                        icon={ (submited) && 'pi pi-spin pi-spinner' }
                        onClick={ handleSubmit } />
                </div>
            }

            <StatusMessages messages={ messages } />
        </div>
    )
}

StripeSubscriptionPayment.propTypes = {
    customer: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    submited: PropTypes.bool.isRequired,
    setSubmited: PropTypes.func.isRequired,
}

export default StripeSubscriptionPayment;