import {useDispatch,useSelector} from 'react-redux';

import { useHistory } from 'react-router-dom';
import { getCategories,getCategoryById,createCategory, getCategoriesDropdown } from '../actions/category.actions';

export const useCategories = () =>{
    const history = useHistory();
    const state = useSelector((state) => state.CategoriesState);
    const dispatch = useDispatch();

    const listCategories = () =>{
        dispatch(getCategories(history));
    }
    const listCategoriesDropdown = () =>{
        dispatch(getCategoriesDropdown(history));
    }
    const listCategoriesById = (id) =>{
        dispatch(getCategoryById(history,id));
    }
    
    const insertCategory = (data) =>{
        dispatch(createCategory(data));
    }



    return {
        ...state,
        listCategories,
        listCategoriesDropdown,
        listCategoriesById,
        insertCategory,
        CategoriesData: state?.data,
        loading:state?.loading,
        CategoriesDropdownData: state?.dataDropdown,
        loadingDropdown:state?.loadingDropdown
    }
}