import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';
import { createProcess, updateProcess } from '../../../../redux/actions/process.actions'
import useForm from '../../../../hooks/useForm';
import { RolesEnum } from '../../../enums/roles';

const ProcessFormDialog = ({ open, setOpen, showMessage, loadData, momentsOptions, process={} }) => {

    const user = useSelector((state) => state.LoginState.data);
    const modelState = useSelector((state) => state.ModelState.model);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const emptyProcess = {
        name: '',
        moment: null,
        description: '',
        model_id: modelState?.id,
    }

    const [ submitted, setSubmitted ] = useState(false);
    const { values, setValues, handleInputChange } = useForm(emptyProcess);

    useEffect(() => {
        if (process?.id) {

            const moment = (role === RolesEnum.Designer) 
                ? process?.moment_model?.moment_id 
                : process?.a_moment_model?.moment_id;

            setValues({
                name: process?.name,
                moment: moment,
                description: process?.description,
                model_id: modelState?.id,
            })
        }
    }, [ process, setValues, modelState, role ])
    
    const saveProcess = async () => {

        setSubmitted(true);

        if (values?.name?.trim() && values?.description?.trim() && values?.moment !== null) {

            if (process?.id) {

                const body = {
                    name: values?.name,
                    description: values?.description,
                    model_id: values?.model_id,
                    moment_id: values?.moment,
                };

                updateProcess(process?.id, body, user?.access_token, role).then((data) => {
                    showMessage('success', 'Proceso actualizado', data?.name);
                    reset();
                }).catch((err) => {
                    showMessage('error', 'Proceso no Actualizado', 'Error inesperado');
                });
            } else {
                createProcess(values, user?.access_token, role).then((data) => {
                    showMessage('success', 'Proceso creado', data?.name);
                    reset();
                }).catch(err => {
                    showMessage('error', 'Proceso no creado', 'Error inesperado');
                })
            }
        }
    };

    const reset = () => {
        setOpen(false);
        setSubmitted(false);
        loadData();
        setValues(emptyProcess);
    }

    const dialogFooter = (
        <div>
            <Button
                label='Cancelar'
                icon='pi pi-times'
                className='p-button-text'
                onClick={ () => setOpen(false) }
            />
            <Button
                label={(process?.id) ? 'Editar' : 'Crear'}
                icon='pi pi-check'
                className='p-button-text'
                onClick={ saveProcess }
            />
        </div>
    );

    const headerDialog = () => (
        <div>
            <h5>
                {(process?.id)
                    ? 'Editar proceso'
                    : 'Nuevo Proceso'
                }
            </h5>
        </div>
    );

    return (
        <>
            <Dialog
                header={ headerDialog }
                visible={ open }
                style={{ width: '450px' }} modal
                footer={ dialogFooter }
                onHide={ () => setOpen(false) } >

                <div className='p-fluid'>

                    <div className='p-field'>

                        <label htmlFor='moments'>Momento</label>

                        <Dropdown
                            name='moment'
                            placeholder='Seleccione un momento'
                            value={ values?.moment }
                            autoFocus
                            onChange={ handleInputChange }
                            options={ momentsOptions }
                            optionLabel='name' optionValue='id'
                            className={classNames({'p-invalid': submitted && !values?.moment})} />

                        {submitted && !values?.moment && (
                            <small className='p-invalid color-danger'>Momento requerido</small>
                        )}
                    </div>

                    <div className='p-field'>

                        <label htmlFor='name'>Nombre</label>

                        <InputText
                            id='name' name='name'
                            value={ values?.name }
                            onChange={ handleInputChange }
                            className={classNames({'p-invalid': submitted && !values?.name})} />

                        {submitted && !values?.name && (
                            <small className='p-invalid color-danger'>Nombre requerido</small>
                        )}
                    </div>

                    <div className='p-field'>

                        <label htmlFor='description'>Descripción</label>

                        <InputTextarea
                            id='description' name='description'
                            value={ values?.description }
                            onChange={ handleInputChange }
                            required rows={6}
                            className={classNames({ 'p-invalid': submitted && !values?.description })} />

                        {submitted && !values?.description && 
                            <small className='p-invalid color-danger'>Descripción requerido</small>
                        }
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ProcessFormDialog;
