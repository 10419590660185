import React, { useState, useEffect, useCallback, useRef } from "react";

import { TabMenu } from "primereact/tabmenu";

import SubsystemsComponents from "./SubsystemsComponents";
import { Button } from "primereact/button";

import Structure from "./Structure";
import Mechanisms from "./Mechanisms";
import Limits from "./Limits";
import Environment from "./Environment";
import Properties from "./Properties";
import Meaning from "./Meaning";
import { useDispatch } from "react-redux";
import { inDesignComplexSystemActions } from "../../../../redux/actions/complex-system";

import {
  PickNodesGraph,
  InteractionsGraphGenerator,
} from "../../../components/Graphs";

import { Dialog } from 'primereact/dialog';

const wizardItems = [
  { id: 0, label: "Composición", icon: "pi pi-table" },
  { id: 1, label: "Estructura", icon: "pi pi-sitemap" },
  { id: 2, label: "Mecanismos", icon: "pi pi-spinner" },
  { id: 3, label: "Límites", icon: "pi pi-minus-circle" },
  { id: 4, label: "Entorno", icon: "pi pi-globe" },
  { id: 5, label: "Propiedades", icon: "pi pi-list" },
  { id: 6, label: "Significado", icon: "pi pi-sun" },
];

const ComplexSystem = (props) => {
  const [tabSelected, setTabSelected] = useState(wizardItems[0]);
  const [visibleFullScreen, setVisibleFullScreen] = useState(false);

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [error, setError] = useState(null);

  const generator = new InteractionsGraphGenerator(nodes, edges);
  const graph = generator?.graph;
  const options = generator?.options;
  const toast = useRef(null);

  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    if (error) {
      showMessage("error", "Error", error.message);
    }
  }, [error]);

  const loadGraphComponents = useCallback(async () => {
    try {
      // Retrieving components
      const fetchedModelComponents = await dispatch(
        inDesignComplexSystemActions.getComponentsAddedToModel()
      );

      const fetchedModelInteractions = await dispatch(
        inDesignComplexSystemActions.getInteractions()
      );

      setNodes(fetchedModelComponents);
      setEdges(fetchedModelInteractions);
    } catch (error) {
      setError(error);
      return error;
    }
  }, []);

  useEffect(() => {
    loadGraphComponents().then((e) => {
      setReload(false);
    });
  }, [reload]);

  return (
    <>
      <div className="card">
        <TabMenu
          model={wizardItems}
          onTabChange={(e) => {
            tabChangeHandler(e.value);
          }}
          activeIndex={tabSelected.id}
        />

        <SubsystemsComponents
          isVisible={tabSelected.id === 0}
          setNodes={setNodes}
          reloadGraph={setReload}
        />
        {/* Hacking react */}
        {tabSelected.id === 0 ? (
          <></>
        ) : tabSelected.id === 1 ? (
          <Structure setEdges={setEdges} reloadGraph={setReload} />
        ) : tabSelected.id === 2 ? (
          <Mechanisms />
        ) : tabSelected.id === 3 ? (
          <Limits />
        ) : tabSelected.id === 4 ? (
          <Environment />
        ) : tabSelected.id === 5 ? (
          <Properties />
        ) : tabSelected.id === 6 ? (
          <Meaning />
        ) : (
          <></>
        )}

        <Button
          label={visibleFullScreen ? "Cerrar" : "MIT"}
          type="button"
          icon={
            visibleFullScreen
              ? "pi pi-angle-double-down"
              : "pi pi-angle-double-up"
          }
          className="p-button-warning"
          onClick={() => {
            setVisibleFullScreen(!visibleFullScreen);
          }}
          style={{
            position: "fixed",
            bottom: "2%",
            right: "1%",
            width: "80px",
            height: "45px",
            zIndex: visibleFullScreen ? 10001 : 10,
          }}
        />
      </div>

      <Dialog visible={visibleFullScreen} 
        onHide={() => setVisibleFullScreen(false)} 
        modal 
        maximized>
        <PickNodesGraph graph={graph} options={options} />
      </Dialog>
    </>
  );
};

export default ComplexSystem;
