import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getCountries, getEcuCantonsByGID_1, getEcuProvinces } from '../../../../pages/utils/constants/ecu_json';
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';

const LocationForm = ({ values, handleInputChange }) => {

    const [ countries, setCountries ] = useState([]);
    const [ countriesFiltered, setCountriesFiltered ] = useState([]);
    
    const [ provinces, setProvinces ] = useState([]);
    const [ selectedProvince, setSelectedProvince ] = useState(null);
    const [ provincesFiltered, setProvincesFiltered ] = useState([]);

    const [ cantons, setCantons ] = useState([]);
    const [ selectedCanton, setSelectedCanton ] = useState(null);
    const [ cantonsFiltered, setCantonsFiltered ] = useState([]);

    useEffect(() => {
        setCountries(getCountries())
        setProvinces(getEcuProvinces());
    }, [])

    useEffect(() => {
        if (selectedProvince?.properties?.GID_1) {
            setSelectedCanton(null);
            setCantons(getEcuCantonsByGID_1(selectedProvince.properties.GID_1));
        }
    }, [ selectedProvince ])

    const searchCountry = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCountriesFiltered([...countries]);
            }
            else {
                setCountriesFiltered(countries.filter((country) => {
                    return country?.properties?.COUNTRY.toLowerCase().includes(event.query.toLowerCase());
                }));
            }
        }, 100);
    };

    const searchProvince = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setProvincesFiltered([...provinces]);
            }
            else {
                setProvincesFiltered(provinces.filter((province) => {
                    return province?.properties?.NAME_1.toLowerCase().includes(event.query.toLowerCase());
                }));
            }
        }, 100);
    };

    const searchCanton = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setCantonsFiltered([...cantons]);
            }
            else {
                setCantonsFiltered(cantons.filter((canton) => {
                    return canton?.properties?.NAME_2.toLowerCase().includes(event.query.toLowerCase());
                }));
            }
        }, 100);
    };

    return (
        <div>
            <form className='p-fluid' autoComplete='off' >

                <div className="p-field">
                    <label className='required'>País</label>
                    <AutoComplete 
                        dropdown
                        placeholder='Seleccione un país'
                        value={ values?.model_location?.country_name } 
                        onChange={(e) => handleInputChange({ 
                            target: { 
                                name: 'model_location', 
                                value: { ...values?.model_location, country_name: 'Ecuador' } 
                            } 
                        })}
                        suggestions={ countriesFiltered } 
                        completeMethod={ searchCountry } field="properties.COUNTRY" />

                </div>

                <div className="p-field">
                    <label className='required'>Provincia</label>
                    <AutoComplete
                        dropdown
                        placeholder='Seleccione una Provincia'
                        disabled={(!values?.model_location?.country_name) ? true:false }
                        value={ selectedProvince } 
                        onChange={(e) => setSelectedProvince(e.value)} 
                        suggestions={ provincesFiltered } 
                        completeMethod={ searchProvince } field="properties.NAME_1" />
                </div>

                <div className="p-field">
                    <label className='required'>Cantón</label>
                    <AutoComplete 
                        dropdown
                        placeholder='Seleccione un Cantón'
                        disabled={(!selectedProvince) ? true:false }
                        value={ selectedCanton } 
                        onChange={(e) => setSelectedCanton(e.value)} 
                        suggestions={ cantonsFiltered } 
                        completeMethod={ searchCanton } field="properties.NAME_2" />
                </div>

                <div className="p-field">
                    <label className='required'>Barrio</label>
                    <InputText
                        // disabled={(!selectedCanton) ? true:false }
                        value={ values?.model_location?.neighborhood_name }
                        placeholder='Ingrese el nombre del barrio'
                        onChange={(e) => handleInputChange({ 
                            target: { 
                                name: 'model_location', 
                                value: { ...values?.model_location, neighborhood_name: e.target.value } 
                            } 
                        })} />
                </div>

            </form>
        </div>
    )
}

LocationForm.propTypes = {}

export default LocationForm;