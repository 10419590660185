import axios from "axios";
import * as urls from  '../../../api/urls/acquireds';

export const createActivityAssignment = async (data, token) => {

    const body = {
        a_process_id: data?.process_id,
        a_activity_id: data?.activity_id,
        a_model_id: data?.model_id,
        user_id: data?.user_id,
    }

    const response = await axios.post(urls.urlCreateActivityAssignment, body, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const listAssigmentByActivity = async (activity_id, token) => {

    const response = await axios.get(urls.urlListAssignmentsByActivity + activity_id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deleteActivityAssignment = async (id, token) => {

    const response = await axios.delete(urls.urlDeleteActivityAssignment + id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

// User are distinct
export const listUserAssigmentByModel = async (a_model_id, token) => {

    const response = await axios.get(urls.urlListUsersAssignmentsByModel + a_model_id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}