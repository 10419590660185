import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useSelector } from 'react-redux';
import { updateAllTool } from '../../../../../../redux/actions/toolbox.action';
import { TextEditor } from '../../../../../components/UI';

export const ToolOrigins = ({ values, handleInputChange, showMessage }) => {

    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const handleSubmit = (e) => {

        e.preventDefault();

        const body = {
            url: values?.url,
            keywords: values?.keywords,
            origins: values?.origins,
        }

        updateAllTool(body, values?.id, user?.access_token, role).then(data => {
            showMessage('success', 'Origen Actualizado', '');
        });
    }

    const customhandleInputChange = (e, name) => {
        handleInputChange({ target: { name: name, value: e?.htmlValue } });
    }

    return (
        <div>
            <div className="p-fluid card">
                <form onSubmit={ handleSubmit }>
                    <div className="p-grid">
                        <div className="p-col-6">
                            <div className="p-field">

                                <label>Palabras clave</label>

                                <InputText
                                    autoComplete='off'
                                    name="keywords" required
                                    placeholder="Ingrese separadas por coma (,)"
                                    value={ values?.keywords }
                                    onChange={ handleInputChange } />
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-field">

                                <label>Enlace</label>

                                <InputText
                                    name="url" autoComplete='off'
                                    placeholder="Ingrese la URL"
                                    value={ values?.url } required
                                    onChange={ handleInputChange } />
                            </div>
                        </div>
                    </div>

                    <div className="p-field">
                        <TextEditor
                            onInputChange={ customhandleInputChange }
                            name='origins'
                            value={ values?.origins }
                            title="Origenes y Evolución"
                            subtitle="Describa los origenes y evolución"
                            submitted={ false }
                            messageError="Origenes requeridos"
                            onSave={ () => { return; } }
                            actionButtonDisabled={ false }
                            helpInformation=""
                            btnSaveProps={{ type: 'submit' }} />
                    </div>
                </form>
            </div> 
        </div>
    )
}