import axios from "axios";
import { createAction } from "redux-actions";
import { URL_BASE_SOCIAL_NETWORK, urlAppUsers } from "../../api/urls";

export const setAppUsers = createAction("SET_APP_USERS");
export const reset = createAction("RESET");


export const setAppUsersErrorAction = createAction(
  "SET_APP_USERS_ERROR_ACTION"
);

export const resetAppUsers = () => async (dispatch, getState) => {
  await dispatch(reset())
}

export const getAppUsers = (modelId, token) => async (dispatch, getState) => {
  try {
    const { data, status } = await axios.get(
      `${URL_BASE_SOCIAL_NETWORK}${urlAppUsers}?model_id=${modelId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (status === 200) {
      dispatch(setAppUsers(data));
      dispatch(
        setAppUsersErrorAction({
          message: "Success",
        })
      );
      return data;

    } else {
      console.log(data);
    }
  } catch (error) {
    dispatch(
        setAppUsersErrorAction({
        message: "Error retrieving channels",
      })
    );
  }
};