import React from 'react';
import PropTypes from 'prop-types';

const PaypalText = ({ style={}, className='' }) => {
    return (
        <span style={{ fontSize: '1.1rem', ...style }} className={ className }>
            <i style={{ color: '#222D65 ' }}>
                Pay
            </i>
            <i style={{ color: '#169BD7' }}>
                Pal
            </i>
        </span>
    )
}

PaypalText.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
}

export default PaypalText
