export const toCapitalize = (string) => {

    if (string !== undefined && string !== null) {
        const capitalized = string.trim()
            .toLowerCase()
            .replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));

        return capitalized;
    }

    return null;
}