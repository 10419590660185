import React, { useEffect, useRef, useState, useCallback } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as PLTActions from "../../../redux/actions/principles-theories-laws.actions";
import { useDispatch } from "react-redux";
import { GenericDialog } from "../UI";
import { SelectButton } from "primereact/selectbutton";
import TheoryLayPrincipleItems from "../UI/TheoryLawPrincipleItems";

const emptyTheoryLawPrincipleItem = {
  origin_meaning: "",
  local_meaning: "",
  purpose: "",
  results: "",
  aplication_processes: "",
};

const TheoriesLawsAndPrinciples = () => {
  const dispatch = useDispatch();
  const toast = useRef();
  const [theoriesLawsPrinciples, setTheoriesLawsPrinciples] = useState();
  const [theoryLawPrinciple, setTheoryLawPrinciple] = useState();
  const [theoriesLawsPrincipleItem, setTheoriesLawsPrincipleItem] = useState(
    emptyTheoryLawPrincipleItem
  );

  const [label, setLabel] = useState("");

  const [globalFilter, setGlobalFilter] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [error, setError] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [_previousVersion, _setPreviousVersion] = useState(
    emptyTheoryLawPrincipleItem
  );

  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const options = [
    { label: "Principio", id: 1 },
    { label: "Ley", id: 2 },
    { label: "Teoría", id: 3 },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const onSelectOption = (e) => {
    setSelectedOption(e);
  };

  useEffect(() => {
    if (error) showMessage("error", "Error", error.message);
  }, [error]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const header = (
    <div className="table-header">
      <div style={{ display: "inline-block" }}>
        <h5 className="p-m-0 ">Listado de principios, leyes, y teorías</h5>
      </div>

      <div style={{ display: "inline-block", float: "right" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar..."
          />
        </span>
      </div>
    </div>
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">{rowData.label}</span>
      </>
    );
  };

  const typeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">
          {options.find((o) => o.id == rowData.ptl_type).label}
        </span>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning p-mr-2"
          onClick={() => onLoadTheoryLawPrinciple(rowData.id)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger p-mr-2"
          onClick={() => onDeleteTheoryPrincipleLaw(rowData.id)}
        />
      </div>
    );
  };

  const loadTheoriesLawsPrinciples = useCallback(async () => {
    try {
      const _theoriesLawsPrinciples = await dispatch(PLTActions.getPLTs());
      setTheoriesLawsPrinciples(_theoriesLawsPrinciples);
    } catch (error) {
      setError(error);
    }
  }, [theoriesLawsPrinciples]);

  useEffect(() => {
    setLoading(true);
    loadTheoriesLawsPrinciples().then(() => {
      setLoading(false);
    });
  }, []);

  const loadTheoryLawPrinciple = async (keyConceptId) => {
    try {
      const _theoryLawPriciple = await dispatch(
        PLTActions.getPLTComponents(keyConceptId)
      );
      setTheoryLawPrinciple(
        theoriesLawsPrinciples.find((c) => c.id === keyConceptId)
      );
      setTheoriesLawsPrincipleItem(_theoryLawPriciple);
      _setPreviousVersion(_theoryLawPriciple);
    } catch (error) {
      setError(error);
    }
  };

  const onLoadTheoryLawPrinciple = (keyConceptId) => {
    setLoading(true);
    loadTheoryLawPrinciple(keyConceptId).then(() => {
      setLoading(false);
      setOpenEditor(true);
    });
  };

  const deleteTheoryLawPrinciple = async () => {
    setLoading(true);
    try {
      await dispatch(PLTActions.deletePLT(theoryLawPrinciple.id));
      setTheoriesLawsPrinciples([
        ...theoriesLawsPrinciples.filter(
          (c) => c.label !== theoryLawPrinciple.label
        ),
      ]);
    } catch (error) {
      setError(error);
    }
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const onDeleteTheoryPrincipleLaw = (id) => {
    const _ptl = theoriesLawsPrinciples.find((c) => c.id === id);
    setTheoryLawPrinciple(_ptl);
    setDeleteDialogOpen(true);
  };

  const onCreateTheoryPrincipleLaw = async (e) => {
    if (label.trim() === "") return;

    setLoading(true);

    const _theoryPrincipleLaw = {
      ptl_type: selectedOption?.id,
      label: label,
    };
    try {
      const _newTheoryPrincipleLaw = await dispatch(
        PLTActions.createPLT(_theoryPrincipleLaw)
      );
      setTheoriesLawsPrinciples([
        ...theoriesLawsPrinciples,
        _newTheoryPrincipleLaw,
      ]);
      setSelectedOption(options[0]);
      setLabel("");
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const onHideEditor = (e) => {
    setOpenEditor(false);
    setTheoryLawPrinciple();
    setTheoriesLawsPrincipleItem(emptyTheoryLawPrincipleItem);
  };

  const hideDeleteDialog = (e) => {
    setDeleteDialogOpen(false);
    setTheoryLawPrinciple();
  };

  const onInputChange = (e, name) => {
    const val = e.htmlValue || "";
    let _theoryPrincipleLaw = { ...theoriesLawsPrincipleItem };
    _theoryPrincipleLaw[name] = val;
    setTheoriesLawsPrincipleItem(_theoryPrincipleLaw);

    if (_previousVersion[name] !== _theoryPrincipleLaw[name]) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  const onUpdateTheoryPrincipleLaw = async (attribute) => {
    setSubmitted(true);
    setLoading(true);
    try {
      if (hasChanged) {
        await dispatch(
          PLTActions.updatePLTComponents(
            theoryLawPrinciple.id,
            attribute,
            theoriesLawsPrincipleItem
          )
        );
        setHasChanged(false);
        _setPreviousVersion(theoriesLawsPrincipleItem);
        showMessage("success", "Éxito", "Guardado.");
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="card-w-title">
        <Toast ref={toast} />
        <h3>Principios, Leyes, y Teorías</h3>
      </div>

      <GenericDialog
        visible={deleteDialogOpen}
        header={"Confirmar"}
        onCancelAction={() => {
          setDeleteDialogOpen(false);
        }}
        onConfirmationAction={deleteTheoryLawPrinciple}
        onHide={hideDeleteDialog}
        message={<span>¿Desea eliminar {theoryLawPrinciple?.label}?</span>}
        condition={theoryLawPrinciple}
      />

      <TheoryLayPrincipleItems
        open={openEditor}
        setOpen={onHideEditor}
        concept={theoryLawPrinciple}
        ptl={theoriesLawsPrincipleItem}
        onSaveAttribute={onUpdateTheoryPrincipleLaw}
        onInputChange={onInputChange}
        submitted={setSubmitted}
        hasChanged={hasChanged}
        title={
          <span>
            <b>{theoryLawPrinciple?.label}</b>
          </span>
        }
      />

      <div className="p-grid">
        <div className="p-col-12 p-lg-6 ">
          <div style={{ marginBottom: 0 }} className="card p-fluid">
            <h5>Construcción de principios, leyes, y teorías</h5>
            <SelectButton
              value={selectedOption}
              onChange={(e) => onSelectOption(e.value)}
              options={options}
              optionLabel="label"
            />
          </div>
          <div className="card p-mb-2 p-fluid">
            <InputText
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              type="text"
              placeholder={`Ingrese el nombre de${
                selectedOption?.id === 1
                  ? "l principio."
                  : selectedOption?.id === 2
                  ? " la ley."
                  : " la teoría."
              }`}
            ></InputText>
          </div>

          <div className="p-grid">
            <div className="p-col-12 p-lg-8 "></div>

            <div className="p-col-12 p-lg-4 ">
              <div
                className="card p-mb-2 p-fluid"
                style={{ justifyContent: "flex-end" }}
              >
                <Button
                  type="button"
                  label="Crear"
                  onClick={(e) => {
                    onCreateTheoryPrincipleLaw(e);
                  }}
                  className="p-button-success"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12 p-lg-6 ">
          <div className="card">
            <DataTable
              value={theoriesLawsPrinciples}
              dataKey="id"
              paginator
              rows={10}
              rowHover
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              globalFilter={globalFilter}
              emptyMessage="Todavía no se han creado principios, leyes, y teorías."
              header={header}
            >
              <Column
                field="label"
                header="Nombre"
                className="p-col-12 p-lg-6 "
                sortable
                body={nameBodyTemplate}
              />

              <Column
                field="ptl_type"
                header="Tipo"
                className="p-col-12 p-lg-3 "
                sortable
                body={typeBodyTemplate}
              />

              <Column header="Construír" body={actionBodyTemplate} />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheoriesLawsAndPrinciples;
