import React, { useState, useEffect, useRef, useCallback } from 'react';
import Joyride, { EVENTS } from 'react-joyride';
import useForm from '../../../hooks/useForm';
import photoDefault from '../../assets/img/no-photo-profile.png';
import TeamFormDialog from './TeamFormDialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import {
  getMembersById,
  getTeamById,
} from '../../../redux/actions/team.actions';
import {
  deleteUser,
  updateUserTutorial,
} from '../../../redux/actions/user.action';
import { steps } from './utils/data';
import { useSelector, useDispatch } from 'react-redux';
import { GenericDialog } from '../../components/UI';
import { Dropdown } from 'primereact/dropdown';
import * as personsActions from '../../../redux/actions/persons.actions';
import * as tableTemplates from '../utils/templates/tableTemplates';

const emptyInvitacion = {
  expiration: 10,
  rol: {},
  email: '',
};

export const ListMembers = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.LoginState?.data);
  const op = useRef(null);
  const toast = useRef(null);

  const [members, setMembers] = useState([]);
  const [member, setMember] = useState({});
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [globalFilter1, setGlobalFilter1] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openTeamFormDialog, setOpenTeamFormDialog] = useState(false);
  const { values, setValues, handleInputChange } = useForm(emptyInvitacion);
  const [team, setTeam] = useState({});
  const [teamSummary, setTeamSummary] = useState({
    nro_members: 0,
    nro_admins: 0,
  });

  // Tutorial
  const [run, setRun] = useState(false);
  const [run1, setRun1] = useState(false);
  const [run2, setRun2] = useState(false);

  const [processingInvitation, setProcessingInvitation] = useState(false);

  useEffect(() => {
    if (user?.team?.id) {
      getTeamById(user?.team?.id, user?.access_token).then((data) => {
        setTeam(data);
      });
    }
  }, [user]);

  useEffect(() => {
    if (members) {
      let admins = 0;

      members.forEach((member) => {
        if (
          member?.role?.name.toLocaleUpperCase() === 'ADMIN'.toLocaleUpperCase()
        ) {
          admins++;
        }
      });

      setTeamSummary({
        nro_members: members.length,
        nro_admins: admins,
      });
    }
  }, [members]);

  const loadData = useCallback(() => {
    getMembersById(user?.team?.id, user?.access_token).then((data) => {
      setMembers(data);
    });
    personsActions.getInvitedPeople(user?.access_token).then((response) => {
      setPendingInvitations(response);
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const sendInvitation = async (e) => {
    e.preventDefault();

    const data = {
      username: values?.email,
      team_id: user?.team?.id,
    };
    setProcessingInvitation(true);
    let status = await dispatch(
      personsActions.sendInvitation(data, user?.access_token)
    );
    setProcessingInvitation(false);

    if (status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Invicatión Enviada',
        detail: '',
        life: 3000,
      });
      loadData();
      setValues(emptyInvitacion);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error sending invitation',
        detail: 'Error!',
        life: 3000,
      });
    }
  };

  const toggle = (event) => {
    op.current.toggle(event);
    if (run1 && !user?.user_complete_tutorial) {
      setRun2(true);
    }
  };

  const handleJoyrideCallback = async (data) => {
    const { type, action } = data;

    if (action === 'close' && run) {
      setRun(false);
      let d = await dispatch(
        updateUserTutorial(user?.id, user?.access_token, user)
      );
    }

    if (action === 'next' && type === EVENTS.TOUR_END && run) {
      setRun(false);
      setRun1(true);
    }
  };

  const handleJoyrideCallback1 = async (data) => {
    const { type, action } = data;

    if (action === 'close' && run) {
      setRun1(false);
      let d = await dispatch(
        updateUserTutorial(user?.id, user?.access_token, user)
      );
    }

    if (type === EVENTS.TOUR_END && run1) {
      setRun1(false);
    }
  };

  const handleDeleteMember = () => {
    if (member?.id) {
      deleteUser(member?.id, user?.access_token)
        .then((response) => {
          toast.current.show({
            severity: 'success',
            summary: 'Miembro eliminado',
            detail: member?.username,
            life: 3000,
          });
          setOpenDeleteDialog(false);
          setMember({});
          loadData();
        })
        .catch((err) => {
          toast.current.show({
            severity: 'error',
            summary: 'Miembro no eliminado',
            detail: 'Error inesperado',
            life: 3000,
          });
        });
    }
  };

  const newMember = () => {
    return (
      <div>
        <Joyride
          continuous
          scrollToFirstStep
          showSkipButton
          run={run1}
          steps={steps?.newM}
          textButton={'Next'}
          styles={{
            options: {
              zIndex: 10000,
              backgroundColor: '#545B67',
              textColor: '#fff',
              primaryColor: '#6A6FD1',
            },
          }}
        />

        <div>
          <Button
            label="Añadir Miembro"
            icon="pi pi-user-plus"
            className="p-button-info"
            onClick={toggle}
          />
        </div>

        <OverlayPanel
          ref={op}
          appendTo={document.body}
          showCloseIcon
          style={{ maxWidth: '550px' }}
        >
          <Joyride
            continuous
            scrollToFirstStep
            showSkipButton
            run={run2}
            steps={steps?.newMem}
            textButton={'Next'}
            styles={{
              options: {
                zIndex: 10000,
                backgroundColor: '#545B67',
                textColor: '#fff',
                primaryColor: '#6A6FD1',
              },
            }}
            callback={handleJoyrideCallback1}
          />

          <br />

          <form onSubmit={sendInvitation}>
            <div className="p-fluid">
              <div className="p-field">
                <label className="required">Correo</label>&nbsp;
                <div className="p-inputgroup" style={{}}>
                  <InputText
                    placeholder="Ingrese el correo"
                    name="email"
                    value={values?.email}
                    required
                    type="email"
                    onChange={handleInputChange}
                  />
                  {/* <Dropdown
                    value={values?.rol}
                    onChange={() => {
                      return;
                    }}
                    options={[]}
                    optionLabel="name"
                    placeholder="Seleccione un Rol"
                  /> */}
                </div>
              </div>

              <div className="p-field p-text-center">
                <Button
                  loading={processingInvitation}
                  loadingIcon="pi pi-spinner pi-spin"
                  label={
                    processingInvitation ? 'Procesando...' : 'Enviar Invitación'
                  }
                  className="p-button-success p-button-outlined"
                  type="submit"
                />
              </div>
            </div>
          </form>

          <br />

          <div className="crud-demo">
            <DataTable
              value={pendingInvitations}
              paginator
              className="datatable-responsive p-datatable-customers"
              rows={5}
              dataKey="id"
              emptyMessage="Sin invitaciones pendientes."
            >
              <Column field="username" header="Email" sortable />

              <Column
                style={{ width: '10rem' }}
                field="username"
                header="Estado"
                sortable
                body={(rowData) => (
                  <span className="status-violet">
                    {rowData?.is_accepted ? 'Aceptado' : 'Pendiente'}
                  </span>
                )}
              />
              <Column
                style={{ width: '7rem' }}
                body={(e) => (
                  <Button
                    icon="pi pi-send"
                    className="p-button-rounded"
                    tooltip="Seleccionar Usuario"
                    onClick={() => setValues({ ...values, email: e?.username })}
                  />
                )}
              />
            </DataTable>
          </div>
        </OverlayPanel>
      </div>
    );
  };

  const membersHeaderTemplate = (
    <div className="table-header">
      <h5>Miembros</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilter1}
          onChange={(e) => setGlobalFilter1(e.target.value)}
          placeholder="Buscar"
        />
      </span>
    </div>
  );

  const teamName = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="p-d-inline">
          <h5 className="p-d-inline-block p-mb-0">{team?.name}</h5>
        </div>
        <div className="p-d-inline p-ml-2">
          <Button
            className="p-button-warning p-button-rounded"
            icon="pi pi-pencil"
            onClick={() => setOpenTeamFormDialog(true)}
          />
        </div>
      </div>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          disabled={
            rowData?.role?.name.toLocaleUpperCase() ===
            'admin'.toLocaleUpperCase()
          }
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => {
            setOpenDeleteDialog(true);
            setMember(rowData);
          }}
        />
      </div>
    );
  };
  return (
    <>
      <div className="card table-demo">
        <Toast ref={toast} />

        <Joyride
          scrollToFirstStep
          showSkipButton
          continuous
          run={run}
          steps={steps?.body}
          styles={{
            options: {
              zIndex: 10000,
              backgroundColor: '#545B67',
              textColor: '#fff',
              primaryColor: '#6A6FD1',
            },
          }}
          callback={handleJoyrideCallback}
        />

        <div className="card crud-demo">
          <Toolbar
            className="p-mb-4 p-toolbar"
            left={teamName}
            right={newMember}
          />

          <div className="p-grid">
            <div className="p-col-12 p-md-2 p-lg-2 p-xl-2 p-text-center">
              <input
                type="image"
                src={team?.logo || photoDefault}
                className="img-profile bg-dark-intense p-shadow-10"
                alt="photo-profile"
              />
            </div>
            <div
              className="p-col-12 p-md-10 p-lg-10 p-xl-10"
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
            >
              <div style={{ maxWidth: '550px', textAlign: 'justify' }}>
                <p>{team?.description}</p>
              </div>
            </div>
          </div>

          <div className="card schedule p-mb-0">
            <p>Resumen</p>
            <ul className="p-grid">
              <li className="p-col p-m-1">
                <div className="schedule-header">
                  <h6>Número de miembros</h6>
                  <span>
                    <span style={{ fontSize: '.9rem' }}>
                      {teamSummary?.nro_members}
                    </span>
                  </span>
                </div>
                <span>{teamSummary?.nro_members} miembros</span>
              </li>
              <li className="p-col p-m-1">
                <div className="schedule-header">
                  <h6>Número de administradores</h6>
                  <span>
                    <span style={{ fontSize: '.9rem' }}>
                      {teamSummary?.nro_admins}
                    </span>
                  </span>
                </div>
                <span>{teamSummary?.nro_admins} administradores</span>
              </li>
            </ul>
          </div>

          <DataTable
            value={members}
            paginator
            rowHover
            className="datatable-responsive"
            rows={10}
            dataKey="id"
            globalFilter={globalFilter1}
            emptyMessage="No members found."
            header={membersHeaderTemplate}
          >
            <Column field="username" header="Usuario" sortable />
            <Column
              field="person"
              header="Nombre"
              sortable
              body={tableTemplates.fullNamePersonBodyTemplate}
            />
            <Column
              field="role.name"
              header="Rol"
              sortable
              body={(rowData) => (
                <div>{rowData?.role?.name.toLocaleUpperCase()}</div>
              )}
            />
            <Column
              field="status"
              header="Estado"
              sortable
              body={tableTemplates.statusBodyTemplate}
            />
            <Column style={{ width: '8rem' }} body={actionTemplate} />
          </DataTable>
        </div>
      </div>

      <GenericDialog
        visible={openDeleteDialog}
        header={'Confirmar'}
        onCancelAction={() => setOpenDeleteDialog(false)}
        onConfirmationAction={() => handleDeleteMember()}
        onHide={() => setOpenDeleteDialog(false)}
        message={
          <span>
            ¿Desea eliminar <b>{member?.username}</b>?
          </span>
        }
        condition={member?.id}
      />

      <TeamFormDialog
        open={openTeamFormDialog}
        setOpen={setOpenTeamFormDialog}
        team={team}
        setTeam={setTeam}
      />
    </>
  );
};

export default ListMembers;
