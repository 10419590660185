import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Survey from './templates/Survey';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import CreateSurvey from '../../components/Surveys/CreateSurvey';
import { Toolbar } from 'primereact/toolbar';
import InfoApp from '../infoApp';
import AssignedSurveys from '../infoApp/templates/AssignedSurveys';

import { RolesEnum } from '../../enums/roles';

const Polls = () => {
  const location = useLocation();
  const model = useSelector((state) => state.ModelState?.model);
  const roleNav = useSelector((state) => state.RolNavigationState?.state);

  const tip = location.state?.tipo;
  const process = location.state?.process; //Process of the process
  const activity = location.state?.activity; //Process of the activity

  const [open, setOpen] = useState(false);
  const [actualizar, setActualizar] = useState(false);

  const dataList = {
    tipo: tip === 'activity' ? 'activity' : 'model',
    id: tip === 'activity' ? activity?.id : model?.id,
  };

  const wizardItemsCreator = [
    { id: 0, label: 'Encuestas', icon: 'pi pi-mobile' },
  ];
  const wizardItemsExecutor = [
    { id: 0, label: 'Encuestas', icon: 'pi pi-pencil' },
    { id: 1, label: 'Encuestas Publicadas', icon: ' pi pi-mobile' },
    { id: 2, label: 'Usuarios de la App', icon: 'pi pi-users' },
    { id: 3, label: 'Encuestas Asignadas', icon: 'pi pi-eye' },
  ];

  const wizardItems =
    roleNav === RolesEnum.Designer ? wizardItemsCreator : wizardItemsExecutor;
  const [tabSelected, setTabSelected] = useState(wizardItems[0]);

  const toast = useRef(null);

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  const leftToolbarTemplate = () => {
    return (
      <div
        style={{
          display: 'flex',
          verticalAlign: 'middle',
          justifyContent: 'center',
        }}
      >
        <Button
          label="Crear Encuesta"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setOpen(true);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="p-col-12 p-md-12">
        <br />
        <div className="card card-w-title">
          <h3>Consola de encuestas del MIT {model?.name}</h3>
          <div hidden={dataList.tipo === 'model' ? true : false}>
            <Toolbar
              className="p-mb-4 p-toolbar"
              left={leftToolbarTemplate}
            ></Toolbar>
          </div>
          <TabMenu
            className="p-mt-3"
            model={wizardItems}
            onTabChange={(e) => {
              tabChangeHandler(e.value);
            }}
            activeItem={tabSelected}
          />

          {tabSelected?.id === 0 ? (
            <div className="list-demo">
              <Survey
                tipoId={dataList}
                actualizar={actualizar}
                roleNav={roleNav}
              />
            </div>
          ) : tabSelected?.id === 1 ? (
            <div className="list-demo">
              <Survey
                tipoId={dataList}
                actualizar={actualizar}
                roleNav={roleNav}
              />
            </div>
          ) : tabSelected?.id === 2 ? (
            <InfoApp />
          ) : tabSelected?.id === 3 ? (
            <AssignedSurveys />
          ) : null}
        </div>
      </div>

      <CreateSurvey
        process={process}
        activity={activity}
        setActualizar={setActualizar}
        toast={toast}
        model={model}
        setOpen={setOpen}
        open={open}
      />
    </>
  );
};

export default Polls;
