import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useSelector } from 'react-redux';
import { createComplementaryToolbox, deleteComplementaryToolbox, listComplementaryByToolbox } from '../../../../../../redux/actions/toolbox.action';
import { descriptionBodyTemplate, imageBodyTemplate } from '../../../../utils/templates/tableTemplates';
import ToolboxsOverlayPanel from './ToolboxsOverlayPanel';
import { RolesEnum } from '../../../../../enums/roles';

const ToolComplementaries = ({ values, handleInputChange, showMessage }) => {

    const user = useSelector(state => state.LoginState.data)
    const op = useRef(null);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ complementaries, setComplementaries ] = useState([]);

    useEffect(() => {
        if (values?.id) {
            listComplementaryByToolbox(values?.id, user?.access_token, role).then((data) => {
                setComplementaries(data);
            });
        }
    }, [ user, values, role ])

    const handleDeleteComplementary = (data) => {
        if (data?.id) {
            deleteComplementaryToolbox(data?.id, user.access_token, role).then(response => {
                showMessage('success', 'Herramienta eliminada', data?.related_toolbox?.element?.name);
                listComplementaryByToolbox(values?.id, user?.access_token, role).then((data) => {
                    setComplementaries(data);
                });
            }).catch(err => {
                showMessage('error', 'No se pudo eliminar la herramienta', '');
            })
        }
    }

    const handleToolboxSelect = ({ value }) => {
        if (value && values?.id) {

            const body = {
                toolbox_id: values?.id,
                related_toolbox_id: value?.id,
            }

            createComplementaryToolbox(body, user?.access_token, role).then((data) => {
                showMessage('success', 'Herramienta añadida', value?.element?.name);
                listComplementaryByToolbox(values?.id, user?.access_token, role).then((data) => {
                    setComplementaries(data);
                });
            }).catch(err => {
                showMessage('error', 'No se pudo asociar la herramienta', 'Herramienta ya registrada');
            });
        }
    }

    const dataTableHeader = () => {
        return (
            <div className='p-grid'>
                <div className="p-col-6">
                    <h5>
                        Herramientas Complemementarias
                    </h5>
                </div>
                <div className="p-col-6 p-text-right">
                    <Button
                        icon='pi pi-plus'
                        className="p-button-outlined p-button-info p-mr-1"
                        label="Agregar herramienta" 
                        onClick={ (e) => op.current.toggle(e)} />
                </div>
            </div>
        );
    }

    const actionTemplate = (data) => {
        return (
            <div>
                <Button
                    icon="pi pi-trash" 
                    className="p-button-rounded p-button-danger" 
                    title="Eliminar" 
                    onClick={() => handleDeleteComplementary(data)} />
            </div>
        );
    }

    const nameField = (role === RolesEnum.Designer) ? 'related_toolbox.element.name':'a_related_toolbox.a_element.name';
    const descriptionField = (role === RolesEnum.Designer) ? 'related_toolbox.element.description':'a_related_toolbox.a_element.description';

    return (
        <div>
            <div className="card crud-demo">
                <DataTable
                    className="datatable-responsive"
                    value={ complementaries } 
                    rowHover paginator rows={ 5 }
                    emptyMessage='Herramientas complementarias no ingresadas'
                    header={ dataTableHeader() } >

                    <Column 
                        field="image" header="Imagen" 
                        body={ (data, props) => imageBodyTemplate(
                            (role === RolesEnum.Designer) ? data?.related_toolbox?.element : data?.a_related_toolbox?.a_element,
                            props,
                        )} />
                    <Column 
                        field={ nameField }
                        header="Nombre" sortable />
                    <Column 
                        field={ descriptionField }
                        header="Descripción" sortable 
                        body={ (data, props) => descriptionBodyTemplate(
                            (role === RolesEnum.Designer) ? data?.related_toolbox?.element : data?.a_related_toolbox?.a_element,
                            props,
                        )} />
                    <Column 
                        className='p-text-center' 
                        style={{ width: '7rem' }}
                        bodyStyle={{ textAling: 'center' }} 
                        body={ actionTemplate } />
                        
                </DataTable>
            </div>

            <ToolboxsOverlayPanel
                handleSelect={ handleToolboxSelect }
                op={ op } />
        </div>
    );
};

export default ToolComplementaries;