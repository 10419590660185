import React, { useRef, useEffect, useState } from 'react';
import StatusMessages, { useMessages } from './StatusMessages';
import PropTypes from 'prop-types';
import SavedPaymentMethods from './SavedPaymentMethod';
import { Button } from 'primereact/button';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Toast } from 'primereact/toast';
import { createPagoStripe, getPaymentMethodsByCustomer } from '../../../redux/actions/payment-method.actions';
import { urlCreatePaymentIntent, URL_BASE_PASARELA_PAGOS } from '../../../api/urls';

const CURRENCY = 'usd';
const PAYMENT_METHOD_TYPE = 'card';
const SETUP_FUTURE_USAGE = 'off_session';

// Estructure objects
// Customer bbject require: names, last_names, email, code
const StripePayment = ({ customer, product, submited, setSubmited, callback }) => {
    
    const URL = `${URL_BASE_PASARELA_PAGOS}${urlCreatePaymentIntent}`
    const elements = useElements();
    const stripe = useStripe();
    const toast  = useRef(null);
    const fullNameCustomer = customer.names + ' ' + customer.last_names;
    const [ messages, addMessage ] = useMessages();
    const [ stripePaymentMethods, setStripePaymentMethods ] = useState([]);
    const [ stripePaymentMethod, setStripePaymentMethod ] = useState(null);

    useEffect(() => {
        getPaymentMethodsByCustomer(customer?.code).then(response => {
            if (response?.payment_methods) {
                setStripePaymentMethods(response?.payment_methods);
            }
        });
    }, [ customer ])

    const handleSubmit = async (e) => {

        e.preventDefault()
        if (!stripe || !elements) return;
        setSubmited(true);

        // Create payment intent on the server
        const { clientSecret, error: backendError } = await fetch(URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                amount: parseFloat(product?.price) * 100,
                currency: CURRENCY,
                paymentMethodType: PAYMENT_METHOD_TYPE,
                receipt_email: customer?.email,
                customer_id: (stripePaymentMethod) ? stripePaymentMethod.customer : null,
            }),
        }).then(r => r.json())

        if (backendError) {
            toast.current.show({ severity: 'error', summary: backendError.message, life: 3000 });
            addMessage(backendError.message);
            setSubmited(true);
            return;
        } else {
            addMessage('Processing ...');
        }

        const bodyPaymentMethod = {
            card: elements.getElement(CardElement),
            billing_details: { name: fullNameCustomer, email: customer.email },
        }

        // Confirm the payment on the client
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
            clientSecret,
            {
                payment_method: (stripePaymentMethod) ? stripePaymentMethod?.code : bodyPaymentMethod,
                receipt_email: customer?.email,
                setup_future_usage: SETUP_FUTURE_USAGE,
            }
        );

        if (stripeError) {
            toast.current.show({ severity: 'error', summary: stripeError?.message, life: 3000 });
            addMessage(stripeError?.message);
            setSubmited(false);
            return;
        }

        // Finally, save pay data in data base
        if (paymentIntent?.status === 'succeeded') {

            addMessage(`Payment ${paymentIntent?.status}`);

            createPagoStripe(paymentIntent.id, customer, product).then(response => {

                toast.current.show({ severity: 'success', summary: response.message, life: 3000, });
                addMessage('*****************************');
                addMessage(`Thank you, ${customer.names}!`);
                addMessage('*****************************');

                // Ejecute the function if all was successfull
                callback();
            });
        }
    }

    return (
        <div>
            <Toast ref={ toast } />

            { (stripePaymentMethods.length > 0) &&
                stripePaymentMethods.map(paymentMethod => (
                    <SavedPaymentMethods 
                        key={ paymentMethod?.code }
                        paymentMethod={ paymentMethod }
                        value={ stripePaymentMethod }
                        setValue={ setStripePaymentMethod } />
                ))
            }

            { (!stripePaymentMethod) ?
                <form
                    id='payment-form'
                    className='bg-dark-intense p-p-3' 
                    style={{ maxWidth: '400px', margin: 'auto', borderRadius: '5px' }}
                    onSubmit={ handleSubmit } >

                    <label htmlFor='card-element'>
                        <a href='_blank'>Card Checkout</a>
                        <hr />
                    </label>

                    <CardElement
                        id='card-element'
                        iconStyle="solid"
                        options={{
                            iconStyle: 'solid',
                            style: {
                                base: {
                                    iconColor: '#c4f0ff',
                                    color: '#fff',
                                    fontSize: '16px',
                                },
                                invalid: {
                                    iconColor: '#FFC7EE',
                                    color: '#FFC7EE',
                                },
                            },
                        }} />

                    <Button
                        className='p-mt-5 p-button-text p-shadow-5'
                        style={{ width: '100%' }}
                        disabled={!stripe || !elements || submited}
                        icon={ (submited) && 'pi pi-spin pi-spinner' }
                        type='submit'
                        label='Pay' />
                </form>
                :
                <div style={{ maxWidth: '240px', margin: 'auto' }} >
                    <Button
                        className='p-button-text p-shadow-5'
                        style={{ width: '240px' }}
                        icon={ (submited) && 'pi pi-spin pi-spinner' }
                        disabled={ submited }
                        type='submit'
                        label='Pay'
                        onClick={ handleSubmit } />
                </div>
            }

            <StatusMessages messages={ messages } />
        </div>
    )
}

StripePayment.propTypes = {
    customer: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    submited: PropTypes.bool.isRequired,
    setSubmited: PropTypes.func.isRequired,
}

export default StripePayment