import axios from 'axios';
import { createAction } from 'redux-actions';
import api from "../../api";
import * as urls from  '../../api/urls';

export const setLoadingPlanAction = createAction('SET_LOADING_PLAN_ACTION');
export const setPlanDataAction = createAction('SET_PLAN_DATA_ACTION');
export const setPlanErrorAction = createAction('SET_PLAN_ERROR_ACTION');
export const setResetPlanAction = createAction('SET_RESET_PLAN_ACTION');


export const getPlans = (history) => async (dispatch,getState) =>{


    dispatch(setResetPlanAction());
    dispatch(setLoadingPlanAction(true));

    try{
        const token = getState()?.LoginState?.data?.access_token;
        const {data, status} = await api.get(urls.urlListPlans, {
            headers: {
              Authorization: 'Bearer ' + token  
            }
           });


        if(status === 200){
            dispatch(setPlanDataAction(data));
            dispatch(
                setPlanErrorAction({
                    message:"Exito"
                })
            );
        }
        else if(status === 403){
            console.log("FORBIDEEEN");
            history.push('/access');
        }


    }
    catch(error){
        console.log(error);
        dispatch(
            setPlanErrorAction({
                message:"Error al recuperar información"
            })
        );

    }

    dispatch(setLoadingPlanAction(false));
};


export const createPlan = async (data, token) => {

    const dataCreate ={
      name: data.name,
      description: data.description,
      team_members_nos: data.team_members_nos,
      model_nos: data.model_nos,
      team_app_nos: data.team_app_nos,
      web_storage: data.web_storage,
      social_app_storage: data.social_app_storage,
      neo4j_nodes_no: data.neo4j_nodes_no,
      neo4_relationships_no: data.neo4_relationships_no,
      firebase_storage_time: data.firebase_storage_time,
      conversation_nos: data.conversation_nos,
      price: data.price,
      featured: data.featured,
      discount: data.discount
    }
    
    const response = await api.post(urls.urlCreatePlan, dataCreate,{
        headers:{
          Authorization:'Bearer '+ token
        }
     });

    return response.status;
}

export const updatePlan = async(data, token) =>{

    const planID = data?.id;

    const dataEdit ={
        name: data.name,
        description: data.description,
        team_members_nos: data.team_members_nos,
        model_nos: data.model_nos,
        team_app_nos: data.team_app_nos,
        web_storage: data.web_storage,
        social_app_storage: data.social_app_storage,
        neo4j_nodes_no: data.neo4j_nodes_no,
        neo4_relationships_no: data.neo4_relationships_no,
        firebase_storage_time: data.firebase_storage_time,
        conversation_nos: data.conversation_nos,
        price: data.price,
        featured: data.featured,
        discount: data.discount
    }

    const response = await axios.put(`${urls.URL_BASE}${urls.urlUpdatePlan}${planID}`, dataEdit,{
        headers:{
            Authorization:'Bearer '+ token
          }
    })

    return response.status;
}


//Esta creado la funcion pero aun no existe un servicio para poder consumirla
export const deletePlan = async(planId, token) =>{
    const response = await axios.delete(`${urls.URL_BASE}${urls.urlDeletePlan}${planId}`, {
        headers:{
            Authorization:'Bearer '+ token
          },
    });

    return response.data;
}
