import axios from 'axios';
import { createAction } from 'redux-actions';
import {
  urlDesignObservationUnits,
  urlDesignConcepts,
  urlDesignDimensions,
} from '../../api/urls';

export const setObservationUnits = createAction('SET_OBSERVATION_UNITS');
export const setConcepts = createAction('SET_CONCEPTS');
export const setDimensions = createAction('SET_DIMENSIONS');
export const setIndicators = createAction('SET_INDICATORS');
export const setIndices = createAction('SET_INDICES');

export const setErrorAction = createAction('SET_ERROR_ACTION');
export const reset = createAction('RESET');

/* ******** OBSERVATION UNITS ******** */

export const getObservationUnits = () => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { data, status } = await axios.get(
      `${urlDesignObservationUnits}?role=${role}&model_id=${modelId}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );

    if (status === 200) {
      dispatch(setObservationUnits(data));
      return data;
    } else {
      throw new Error("Couldn't bring stored observation units");
    }
  } catch (error) {
    throw error;
  }
};

export const updateObservationUnits =
  (observationUnits) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const roleNav = getState().RolNavigationState?.state;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { data, status } = await axios.post(
        `${urlDesignObservationUnits}?role=${role}`,
        {
          units: observationUnits,
          model_id: modelId,
          role_nav: roleNav,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        dispatch(setObservationUnits(data));
      } else {
        throw new Error('Error updating observation units');
      }
    } catch (error) {
      throw error;
    }
  };

/* ******** CONCEPTS ******** */

export const getConcepts = () => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { data, status } = await axios.get(
      `${urlDesignConcepts}?role=${role}&model_id=${modelId}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      dispatch(setConcepts(data));
      return data;
    } else {
      throw new Error("Couldn't bring stored concepts");
    }
  } catch (error) {
    throw error;
  }
};

export const createConcept =
  (concept, observationUnitId) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { status, data } = await axios.post(
        `${urlDesignConcepts}?role=${role}`,
        {
          id: concept.id,
          model_id: modelId,
          parent_id: observationUnitId,
          name: concept.name,
          definition: concept.definition,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        const _concepts = getState().OperativationState?.concepts;
        dispatch(setConcepts([data, ..._concepts]));
        return data;
      } else {
        throw new Error('Error creating concept.');
      }
    } catch (error) {
      throw error;
    }
  };

export const updateConcept = (concept) => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.put(
      `${urlDesignConcepts}?role=${role}`,
      {
        id: concept.id,
        model_id: modelId,
        parent_id: concept.parent_id,
        name: concept.name,
        definition: concept.definition,
      },
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      let _concepts = [...getState().OperativationState?.concepts];
      const index = _concepts.findIndex((c) => c.id === concept.id);
      _concepts[index] = data;
      dispatch(setConcepts(_concepts));
      return data;
    } else {
      throw new Error('Error updating concept.');
    }
  } catch (error) {
    throw error;
  }
};

export const deleteConcept = (conceptId) => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status } = await axios.delete(
      `${urlDesignConcepts}?role=${role}&model_id=${modelId}&concept_id=${conceptId}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      const _concepts = [...getState().OperativationState?.concepts];
      dispatch(setConcepts(_concepts.filter((c) => c.id !== conceptId)));
    } else {
      throw new Error('Error deleting ');
    }
  } catch (error) {
    throw error;
  }
};

/* ******** DIMENSIONS ******** */

export const getDimensions = () => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { data, status } = await axios.get(
      `${urlDesignDimensions}?role=${role}&model_id=${modelId}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      dispatch(setDimensions(data));
      return data;
    } else {
      throw new Error('Error fethching dimensions.');
    }
  } catch (error) {
    throw error;
  }
};

export const createDimension =
  (dimension, parentId) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { status, data } = await axios.post(
        `${urlDesignDimensions}?role=${role}`,
        {
          id: dimension.id,
          model_id: modelId,
          parent_id: parentId,
          name: dimension.name,
          definition: dimension.definition,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        const _dimensions = getState().OperativationState?.dimensions;
        dispatch(setDimensions([data, ..._dimensions]));
        return data;
      } else {
        throw new Error('Error creating dimension.');
      }
    } catch (error) {
      throw error;
    }
  };

export const updateDimension = (dimension) => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.put(
      `${urlDesignDimensions}?role=${role}`,
      {
        id: dimension.id,
        model_id: modelId,
        parent_id: dimension.parent_id,
        name: dimension.name,
        definition: dimension.definition,
      },
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      let _dimensions = [...getState().OperativationState?.dimensions];
      const index = _dimensions.findIndex((c) => c.id === dimension.id);
      _dimensions[index] = data;
      dispatch(setDimensions(_dimensions));
      return data;
    } else {
      throw new Error('Error updating dimension.');
    }
  } catch (error) {
    throw error;
  }
};

export const deleteDimension = (dimensionId) => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const role = getState().RolNavigationState?.state;

  try {
    const { status } = await axios.delete(
      `${urlDesignDimensions}?role=${role}&dimension_id=${dimensionId}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      const _dimensions = [...getState().OperativationState?.dimensions];
      dispatch(setDimensions(_dimensions.filter((c) => c.id !== dimensionId)));
    } else {
      throw new Error('Error deleting dimension.');
    }
  } catch (error) {
    throw error;
  }
};
