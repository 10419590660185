import React, { useState, useRef, useEffect, useCallback } from 'react';
import { TabMenu } from 'primereact/tabmenu';

import { TextEditor } from '../../../components/UI';

import { useDispatch } from 'react-redux';

import * as csActions from '../../../../redux/actions/complex-system/in-design-complex-system.actions';

const MEANING = 'meaning';
const PURPOSE = 'purpose';
const VALUES = 'values';
const IDENTITY = 'identity';
const MEANING_STEP = 'meaning';

const wizardItems = [
  { id: 0, label: 'Significado', icon: 'pi pi-sun' },
  { id: 1, label: 'Propósito', icon: 'pi pi-directions' },
  { id: 2, label: 'Valores', icon: 'pi pi-compass' },
  { id: 3, label: 'Identidad', icon: 'pi pi-clone' },
];

const emptyComponent = {
  id: '',
  processes: '',
  phenomenons: '',
  organization_patterns: '',
  function: '',
};

const Meaning = (props) => {
  const dispatch = useDispatch();
  const toast = useRef();

  const [tabSelected, setTabSelected] = useState(wizardItems[0]);
  const [component, setComponent] = useState(emptyComponent);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const [_previousVersion, _setPreviousVersion] = useState(emptyComponent);

  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) showMessage('error', 'Error', error.message);
  }, [error]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  const loadComponent = useCallback(async () => {
    try {
      const _retrieved_component = await dispatch(
        csActions.getModelizationComponents(MEANING_STEP)
      );
      setComponent(_retrieved_component);
      _setPreviousVersion(_retrieved_component);
    } catch (error) {
      setError(error);
    }
  }, [component]);

  useEffect(() => {
    setLoading(true);
    loadComponent().then(() => {
      setLoading(false);
    });
  }, []);

  const onInputChange = (e, name) => {
    const val = e.htmlValue || '';
    let _modelizationComponents = { ...component };
    _modelizationComponents[name] = val;
    setComponent(_modelizationComponents);
    if (_previousVersion[name] !== _modelizationComponents[name]) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  const onSaveAttribute = async (attribute) => {
    setSubmitted(true);
    setLoading(true);
    try {
      if (hasChanged) {
        await dispatch(
          csActions.updateModelizationComponents(
            component.id,
            attribute,
            component,
            MEANING_STEP
          )
        );
        setHasChanged(false);
        _setPreviousVersion(component);
        showMessage('success', 'Éxito', 'Guardado.');
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="card">
        <TabMenu
          model={wizardItems}
          onTabChange={(e) => {
            tabChangeHandler(e.value);
          }}
          activeItem={tabSelected}
        />

        {tabSelected.id === 0 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={MEANING}
            value={component[MEANING]}
            title={'Significado'}
            subtitle={'Describa...'}
            submitted={submitted}
            messageError={'Se requiere agregar significado'}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={'Información de ayuda de este componente'}
          />
        ) : tabSelected.id === 1 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={PURPOSE}
            value={component[PURPOSE]}
            title={'Propósito'}
            subtitle={'Describa...'}
            submitted={submitted}
            messageError={'Se requiere agregar propósito'}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={'Información de ayuda de este componente'}
          />
        ) : tabSelected.id === 2 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={VALUES}
            value={component[VALUES]}
            title={'Valores'}
            subtitle={'Describa...'}
            submitted={submitted}
            messageError={'Se requiere agregar valores'}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={'Información de ayuda de este componente'}
          />
        ) : tabSelected.id === 3 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={IDENTITY}
            value={component[IDENTITY]}
            title={'Identidad'}
            subtitle={'Describa...'}
            submitted={submitted}
            messageError={'Se requiere agregar identidad'}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={'Información de ayuda de este componente'}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Meaning;
