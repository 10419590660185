import React, { useEffect, useState } from 'react';
import Modeler from "bpmn-js/lib/Viewer";
import ProcessViewerOptions from './ProcessViewerOptions';
import ActivityDialog from './ActivityDialog';
import { useSelector } from 'react-redux';
import { importXML } from '../../utils/helpers/bpmnHelpers';
import { eventsViewerBpmn } from '../../utils/constants/bpmnConstants';
import { retrieveActivityByTask } from '../../../../redux/actions/activity.actions';

const ProcessViewer = props => {

    // const process = useSelector((state) => state.ProcessState.process);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ activity, setActivity ] = useState({});
    const [ process, setProcess ] = useState({});
    const [ diagramXML, setDiagramXML ] = useState('');
    const [ diagramSVG, setDiagramSVG ] = useState('');
    const [ activityDialog, setActivityDialog ] = useState(false);

    useEffect(() => {

        if (process?.id) {

            const container = document.getElementById("container");
            container.innerHTML = '';

            const modelerValues = {
                container,
                keyboard: {
                    bindTo: document,
                },
            }
        
            const modeler = new Modeler({ ...modelerValues });
            const eventBus = modeler.get('eventBus');
            const overlays = modeler.get('overlays');
            const canvas = modeler.get('canvas');

            importXML(setDiagramXML, setDiagramSVG, modeler, process?.xml_body); //Import blank xml if activity haven't xml created

            eventsViewerBpmn.forEach(function(event) {
                eventBus.on(event, function(e) {

                    const element = e?.element;

                    if (event === 'element.click') {
                        if (element?.type === 'bpmn:Task') {
                            retrieveActivityByTask(element?.id, process?.id, user?.access_token, role).then(response => {
                                setActivity(response);
                                setActivityDialog(true);
                            });
                        }
                        return false;
                    }
                    
                    if (event === 'shape.added') {

                        const element = e?.element;

                        if (element?.type === 'bpmn:Task') {

                            canvas.addMarker(element?.id, 'task-completed');
                            overlays.add(element?.id, {
                                position: { top:0, right:0 },
                                html: `<button class='p-button p-button-rounded'>${ 1 }</button>`,
                            });
                        }
                    }
                })
            })

            return () => {
                eventsViewerBpmn.forEach(function(event) {
                    eventBus.off(event); // Clean all events of bpmn modeler of events list
                });
            }
        }

    }, [ user, process ]);

    return (
        <div className='animate__animated animate__zoomIn'>
            <div className="card p-shadow-10">

                <h5 className='p-text-center'>Visualizador de Actividades</h5>

                <ActivityDialog
                    activity={ activity }
                    setActivity={ setActivity }
                    dialog={ activityDialog }
                    setDialog={ setActivityDialog }
                    process={ process } />

                <ProcessViewerOptions
                    diagramSVG={ diagramSVG }
                    diagramXML={ diagramXML }
                    process={ process }
                    setProcess={ setProcess } />

                { (process?.xml_body) ?
                    <div
                        className="p-shadow-10 p-p-2"
                        id="container"
                        style={{
                            height: "75vh",
                            background: "#eee",
                            borderRadius: '10px',
                            color: '#000',
                        }} ></div>
                    :
                    <div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h2 className='p-text-center'>
                            Selecciona un proceso
                        </h2>
                    </div>
                }
            </div>
        </div>
    );
}

ProcessViewer.propTypes = {}

export default ProcessViewer;