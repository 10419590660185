import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUnitTimeTypes } from '../../../redux/actions/unit-time-type.actions';
import { toCapitalize } from '../../pages/utils/helpers/functions';

const UnitTimeTypesDropdown = ({ unitTimeType, setUnitTimeType, disabled=false, defaultValue=true, placeholder='Unidad' }) => {

    const user = useSelector((state) => state.LoginState.data);
    const [ unitTimeTypes, setUnitTimeTypes ] = useState([]);
    const { t: tGlobal } = useTranslation(['global']);

    useEffect(() => {
        getUnitTimeTypes(user.access_token).then(data => {

            const response = []

            // Establacer a letra capital el label de todos los tipos
            data.forEach((type) => {
                response.push({
                    ...type,
                    label: toCapitalize(type?.label),
                });
            });

            setUnitTimeTypes(response);

            // Establece una unidad de tiempo (díás) por defecto
            if (defaultValue && !unitTimeType?.id) {
                setUnitTimeType(response[0]);
            }
        });
    }, [ user ])

    const handleChange = ({ value }) => {
        setUnitTimeType(value);
    }

    return (
        <Dropdown
            style={{ width: 'auto' }}
            value={ unitTimeType }
            onChange={ handleChange } 
            options={ unitTimeTypes } 
            optionLabel="label"
            disabled={ disabled }
            placeholder={ placeholder }
            itemTemplate={ (value) => tGlobal('times.' + value?.label.toLowerCase()) }
            valueTemplate={ (value) => (value) ? tGlobal('times.' + value?.label.toLowerCase()) : placeholder } />
    )
}

export default UnitTimeTypesDropdown;