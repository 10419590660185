import React, { useState,useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import {Messages} from "primereact/messages";
import { FileUpload } from 'primereact/fileupload';
import { URL_BASE } from '../../../../../../api/urls';
import { useRepository } from '../../../../../../redux/hooks/useRepository';
import { Editor } from 'primereact/editor';
import { useKnowledge } from '../../../../../../redux/hooks/useKnowledge';
import { Toast } from 'primereact/toast';
import { createKnowledgeAditionalData, setCurrentKnowledgeDataAction } from '../../../../../../redux/actions/knowledge.action';


export const ItemMethod = ({action,dispatch,user}) => {


    const {insertKnowledgeData,dataCurrent}= useKnowledge();
    const  toast  = useRef(null);

    const message = useRef();


    const [dataItem,setDataItem] = useState({
        method: action==="edit"?dataCurrent?.method:"",
    });



    const addInfoMessageSaved = (status, content) => {
        message.current.show({severity: status, content: content});
    };

    const [dropdownItem, setDropdownItem] = useState(null);
    const [dropdownItem2, setDropdownItem2] = useState(null);
    const dropdownItems = [
        { name: 'Socio Cultural', code: 1 },
        { name: 'Medio Construido', code: 2 },
        { name: 'Político Institucional', code: 3 },
        { name: 'Político Institucional', code: 3 },
    ];
    const dropdownItems2 = [
        { name: 'Demografía', code: 1 },
        { name: 'Medio Construido', code: 2 },
        { name: 'Político Institucional', code: 3 },
        { name: 'Político Institucional', code: 3 },
    ];
    const dropdownItems3 = [
        { name: 'Etnia', code: 1 },
        { name: 'Medio Construido', code: 2 },
        { name: 'Político Institucional', code: 3 },
        { name: 'Político Institucional', code: 3 },
    ];

    const fileUploadHandler = ({files}) => {
        const [file] = files;
        console.log(file);
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            uploadFile(file);
        };
        fileReader.readAsDataURL(file);
    };

    const save = (e) =>{
e.preventDefault()
if(method){
            

                createKnowledgeAditionalData({opcion: "method",
                id: dataCurrent?.id,
                method: method
                },user.access_token).then(response => {
                    dispatch(setCurrentKnowledgeDataAction(response)) 
                    toast.current.show({ severity: 'success', summary: 'Item Actualizado', detail: "Se ha actualizado exitosamente el método del item", life: 3000 });
                    
                });
    }

    }
    const uploadFile = async (invoiceFile) => {
        let formData = new FormData();
        formData.append('file_obj', invoiceFile);
        console.log(dropdownItem);
        

    
    
        /*
        const response = await fetch(`orders/${orderId}/uploadInvoiceFile`,
            {
                method: 'POST',
                body: formData
            },
        );
        */
    };
    
    const {method} = dataItem;

    return (
        <div className="p-grid">
             <Toast ref={ toast } />  

            

            <div className="p-col-12">
                <div className="card">
                    <h3>Método</h3>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="long_description">Ingrese el texto referente al método del ítem</label>
                            <Editor style={{height:'320px'}} value={method} onTextChange={(e) => setDataItem({ method: e.htmlValue })}/>
                        </div>
                      
                <Button label= "Guardar" className="p-button-rounded p-mr-2 p-mb-2" onClick={save}/>
                </div> 
                <div style={{ display: "inline-block", width: 250, position: 'fixed', bottom: '0%', left: '140px'}}>
                        <Messages  ref={message}/>
                    </div>
            </div>
            </div>
        </div>
    )
}