import React from "react";
import PropTypes from 'prop-types';
import { confirmPopup } from "primereact/confirmpopup";
import { Button } from "primereact/button";

const styleDefault = { 
    padding: '0px',
    maxWidth: '350px',
}

const HelpButton = ({ size='medium', outlined=false, message='', popupStyle={}, buttonStyle={}, containerStyle={} }) => {

    /**
     * @returns button size in rem unit
     */
    const getSize = () => {
        if (size === 'small') return 1.5;
        else if (size === 'medium') return 2;
        else if (size === 'large') return 2.75;
    }

    const btnDefaultClass = `p-button-rounded p-button-${(!outlined) ? 'text':'outlined'} p-button-info p-shadow-5`;
    const btnDefaultStyle = { width: getSize() + 'rem', height: getSize() + 'rem' }
    const containerDefaultStyle = { marginRight: '10px', marginLeft: '10px', display: "inline", verticalAlign: 'super' }

    const showPopup = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: message,
            footer: <></>,
            style: { ...styleDefault, ...popupStyle },
        });
    };

    return (
        <div style={{ ...containerDefaultStyle, ...containerStyle }} >
            <Button
                type="button"
                style={{ ...btnDefaultStyle, ...buttonStyle }}
                className={ btnDefaultClass }
                onClick={ showPopup }
                icon='pi pi-question' />
        </div>
    );
};

HelpButton.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    outlined: PropTypes.bool,
    message: PropTypes.string,
}

export default HelpButton;