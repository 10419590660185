import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProcessInfo from './ProcessInfo';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const ProcessCard = ({ process }) => {

    const [ openedDialog, setOpenedDialog ] = useState(false);

    return (
        <div className='card p-pb-0 p-mt-3 p-mb-1' style={{ border: '1px solid gray', borderRadius: '10px' }}>
            <div className='p-grid'>

                <div className='p-col'>
                    <div className='p-grid'>
                        <div className="p-col">
                            <b>{ process?.name }</b> 
                        </div>
                        <div className="p-col">
                            <i>{ process?.description }</i> 
                        </div>
                        <div className="p-col">
                            <span className='status-info'>
                                { process?.type }
                            </span>
                        </div>
                    </div>
                </div>

                <div className='p-col p-text-right'>
                    <Button
                        className='p-button-text'
                        label='Ver resumen'
                        onClick={() =>  setOpenedDialog(true) } />
                </div>
            </div>

            <ProcessInfo
                process={ process }
                open={ openedDialog }
                setOpen={ setOpenedDialog } />
        </div>
    )
}

ProcessCard.propTypes = {
    process: PropTypes.object.isRequired,
}

export default ProcessCard