import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline'
import { useSelector } from 'react-redux';
import { subscriptionHistory } from '../../../redux/actions/payment-method.actions';

const SubscriptionTimeline = props => {

    const [ events, setEvents ] = useState([]);
    const user = useSelector((state) => state.LoginState.data);

    const customEvents = [
        { 
            status: 'Monthly Payment', 
            date: '2022-03-03', 
            icon: 'pi pi-check', 
            color: '#4caf50' ,
            desc: 'Subscription created successfully',
        },
        { 
            status: 'Monthly Payment', 
            date: '2022-04-03', 
            icon: 'pi pi-check', 
            color: '#4caf50' ,
            desc: 'Subscription updated successfully',
        },
        { 
            status: 'Monthly Payment', 
            date: '2022-05-03', 
            icon: 'pi pi-check', 
            color: '#4caf50' ,
            desc: 'Subscription updated successfully',
        },
        { 
            status: 'Monthly Payment', 
            date: '2022-06-03', 
            icon: 'pi pi-check', 
            color: '#4caf50' ,
            desc: 'Subscription updated successfully',
        }    
    ];

    useEffect(() => {

        // Get subscription history
        subscriptionHistory(user?.id).then(response => {

            console.log(response);

            // if (response?.subscriptions) {
            //     response?.subscriptions.map((sub) => {

            //         // Push custom events of subscriptions
            //         setEvents([
            //             { 
            //                 status: 'Monthly Payment', 
            //                 date: '2022-03-03', 
            //                 icon: 'pi pi-check', 
            //                 color: '#4caf50' ,
            //                 desc: 'Subscription created successfully',
            //             }
            //         ]);
            //     });
            // }

            setEvents(customEvents)
        });

    }, [ user ])
    
    const customizedContent = (item) => {
        return (
            <Card className='p-shadow-5' title={ item.status } subTitle={ item.date }>
                <p>{ item.desc }</p>
            </Card>
        );
    };

    const customizedMarker = (item) => {
        return (
            <span className="custom-marker p-shadow-2 p-p-1" style={{ backgroundColor: item.color, borderRadius: '50%' }}>
                <i className={classNames('marker-icon', item.icon)}></i>
            </span>
        );
    };

    return (
        <>
            <div>
                <Timeline 
                    value={ events } 
                    align="alternate" 
                    className="customized-timeline" 
                    marker={ customizedMarker } 
                    content={ customizedContent } />
            </div>
        </>
    )
}

SubscriptionTimeline.propTypes = {}

export default SubscriptionTimeline;