import axios from 'axios';
import * as urls from '../../api/urls';
import { createAction } from 'redux-actions';

export const setSurveyState = createAction('SET_SURVEY_STATE');
export const setCreateSurveyErrorAction = createAction(
  'SET_CREATE_SURVEY_ERROR_ACTION'
);
export const setDeleteSurveyErrorAction = createAction(
  'SET_DELETE_SURVEY_ERROR_ACTION'
);
export const setSurveysByProcessState = createAction(
  'SET_SURVEYSBYPROCESS_STATE'
);

export const createSurvey =
  (survey, processId, activityId, modelId, token) =>
  async (dispatch, getState) => {
    const role = getState().RolNavigationState?.state;

    const new_survey = {
      name: survey.name,
      description: survey.description,
      process_id: processId,
      activity_id: activityId,
      model_id: modelId,
      survey_respondent: {},
      questions: [],
      status: 1,
    };
    try {
      const { data, status } = await axios.post(
        urls.urlCreateSurvey + role,
        new_survey,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      if (status === 200 || status === 201) {
        dispatch(setSurveyState(data));
        dispatch(
          setCreateSurveyErrorAction({
            message: 'Success',
          })
        );
        return 201;
      } else {
        return 404;
      }
    } catch (error) {
      dispatch(
        setCreateSurveyErrorAction({
          message: 'Error creating new survey',
        })
      );
    }
  };

export const editSurvey = async (survey, token, role) => {
  const stage_role = '/' + role;

  const edit_survey = {
    name: survey.name,
    description: survey.name,
    activity_id: survey.activity_id,
    process_id: survey.process_id,
    questions: survey.questions,
    survey_respondent: survey.surveyRespondent,
    status: survey.status,
  };
  try {
    const response = await axios.put(
      urls.urlUpdateSurvey + survey.id + stage_role,
      edit_survey,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.status;
  } catch (error) {
    return 400;
  }
};

export const getSurveyByProcess = async (processId, token, role) => {
  const stage_role = '/' + role;

  const { data } = await axios.get(
    urls.urlListSurveysByProcess + processId + stage_role,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return data.data;
};

export const getSurveyByActivity = async (activityId, token, role) => {
  const stage_role = '/' + role;

  const { data } = await axios.get(
    urls.urlListSurveysByActivity + activityId + stage_role,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return data.data;
};

export const getSurveyByTemplate = async (templateId, token) => {
  const response = await axios.get(
    `${urls.URL_BASE}${urls.urlGetSurveyByTemplateId}${templateId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response;
};

export const getSurveyByModel = async (modelId, token, role) => {
  const stage_role = '/' + role;

  const { data } = await axios.get(
    urls.urlListSurveysByModel + modelId + stage_role,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return data.data;
};

export const getSurveyById = async (surveyId, token, role) => {
  const stage_role = '/' + role;

  const { data } = await axios.get(
    urls.urlGetSurveyById + surveyId + stage_role,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return data.data;
};

export const deleteSurvey = async (surveyId, token, role) => {
  const stage_role = '/' + role;

  const response = await axios.delete(
    urls.urlDeleteSurvey + surveyId + stage_role,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response;
};

export const deleteSurveyMain = async (surveyId, token) => {
  const response = await axios.delete(
    `${urls.URL_BASE}${urls.urlDeleteSurveyMain}${surveyId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response;
};

export const getVariables = async (modelId, token) => {
  const response = await axios.get(
    `${urls.URL_BASE}${urls.urlListVariables}${modelId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response.data;
};

export const publishSurvey = async (surveyId, token, role) => {
  const stage_role = '/' + role;

  const response = await axios.put(
    urls.urlPublishSurvey + surveyId + stage_role,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response.data;
};

export const assignedSurveysList = async (token) => {
  const response = await axios.get(
    `${urls.URL_BASE}${urls.urlAssignedSurveysList}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response.data;
};

export const listSurveyPublished = async (modelId, token) => {
  const response = await axios.get(
    `${urls.URL_BASE}${urls.urlListSurveysPublishedByModel}/${modelId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response.data;
};

export const listAsignmentByUser = async (userId, token) => {
  const response = await axios.get(
    `${urls.URL_BASE}${urls.urlListAssignmentByUser}/${userId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response.data;
};

export const createSurveyAssignment = async (data, token) => {
  const NEW_ASSIGNMENT = {
    assignments: data?.assignments,
    user_id: data?.user_id,
    survey_template_id: data?.survey_id,
    model_id: data?.model_id,
  };

  const response = await axios.post(
    `${urls.URL_BASE}${urls.urlCreateSurveyAssignment}`,
    NEW_ASSIGNMENT,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response.data;
};

export const updateSurveyAssignment = async (
  assignmentId,
  assignmentNum,
  token
) => {
  const response = await axios.put(
    `${urls.URL_BASE}${urls.urlUpdateSurveyAssignment}/${assignmentId}/${assignmentNum}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response.data;
};

export const publishedSurvey = async (data, token) => {
  const new_data = {
    survey_template_id: data?.survey_template_id,
    model_id: data?.model_id,
  };
  const response = await axios.post(
    `${urls.URL_BASE}${urls.urlCreatePublishSurvey}`,
    new_data,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response;
};
