import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { getRoles } from '../../../redux/actions/rol.actions';
import { useSelector } from 'react-redux';
import * as tableTemplates from '../utils/templates/tableTemplates';
import RoleFormDialog from './templates/RoleFormDialog';

import { deleteRol } from '../../../redux/actions/rol.actions';

const Roles = () => {

    const [ roles, setRoles ] = useState(null);
    const [ role, setRole ] = useState(null);
    const [ selectedRoles, setSelectedRoles ] = useState(null);
    const [ roleDialog, setRoleDialog ] = useState(false);
    const [ deleteRoleDialog, setDeleteRoleDialog ] = useState(false);
    const [ globalFilter, setGlobalFilter ] = useState([]);
    const user = useSelector((state) => state.LoginState.data);
    const toast = useRef(null);
    const dt = useRef(null);

    const emptyRole = { name: '', description: '', status: 1 };

    const loadData = async () => {
        setRoles(await getRoles(user?.access_token))
    }

    useEffect(() => {
        loadData();
    },[user]);

    const openNew = () => {
        setRole(emptyRole);
        setRoleDialog(true);
    }

    const hideDeleteRoleDialog = () => {
        setDeleteRoleDialog(false);
    }

    const editRole = ( row ) => {
        setRole({ ...row });
        setRoleDialog(true);
    }

    const confirmDeleteRole = ( r ) => {
        setRole(r);
        setDeleteRoleDialog(true);
    }

    const deleteRole = () => {

        deleteRol(role?.id, user?.access_token).then((response)=>{
            console.log(response);
            loadData();
            setDeleteRoleDialog(false);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Role Deleted', life: 3000 });
        }).catch(()=>{
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error al eliminar el servicio', life: 3000 });
            setDeleteRoleDialog(false);  
        })

    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const leftToolbarTemplate = () => {
        return (
            <>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={ openNew } />
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={ exportCSV } />
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
       
        return (
            <>
                <span className="p-column-title">Actions</span>
                <div className="actions">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editRole(rowData)} />
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteRole(rowData)} />
                </div>
            </>
        );
    }

    const header = (
        <div className="table-header">
            <h2 className="p-m-0">
                <p>Roles</p>
            </h2>
            <i>{ (selectedRoles && selectedRoles.length > 0) && `Selected (${selectedRoles.length})` }</i>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteRoleDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={ hideDeleteRoleDialog } />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={ deleteRole } />
        </>
    );

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={ toast } />
                        <Toolbar className="p-mb-4 p-toolbar" left={ leftToolbarTemplate } right={ rightToolbarTemplate }></Toolbar>

                        <DataTable ref={ dt } value={ roles } selection={ selectedRoles } onSelectionChange={(e) => setSelectedRoles(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                            globalFilter={ globalFilter } emptyMessage="No roles found." header={ header } selectionMode='single'>

                            <Column style={{ width: '10rem' }} field="id" header="ID" sortable body={ tableTemplates.idBodyTemplate }></Column>
                            <Column field="name" header="Name" sortable body= { tableTemplates.nameBodyTemplate }></Column>
                            <Column field="description" header="Description" sortable body= { tableTemplates.descriptionBodyTemplate }></Column>
                            <Column style={{ width: '10rem' }} field="status" header="Status" sortable body= { tableTemplates.statusBodyTemplate }></Column>
                            <Column style={{ width: '10rem' }} header="Actions" body={ actionBodyTemplate }></Column>

                        </DataTable>

                        <RoleFormDialog
                            open={ roleDialog }
                            setOpen={ setRoleDialog }
                            role={ role }
                            setRole={ setRole }
                            callback={ loadData } />

                        <Dialog visible={ deleteRoleDialog } style={{ width: '350px' }} header="Confirm" modal footer={ deleteRoleDialogFooter } onHide={ hideDeleteRoleDialog }>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {role && <span>Are you sure you want to delete <b>{role?.name}</b>?</span>}
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Roles;
