import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { tableAcquiredModelStatus, tableModelStatus } from "../../../pages/utils/constants/uiConstants";
import { RolesEnum } from "../../../enums/roles";
import * as tableTemplates from "../../../pages/utils/templates/tableTemplates";
import { IN_EXECUTION_STATUS } from "../../../pages/utils/constants/modelConstans";

const ModelsListTable = (props) => {

  const [ globalFilter, setGlobalFilter ] = useState([]);

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning p-mr-2"
          onClick={() => props.onEditBasicInfoModel(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger p-mr-2"
          onClick={() => {
            props.onDeleteModel(rowData);
          }}
        />
        <Button
          icon="pi pi-briefcase"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => {
            props.onModelClick(rowData);
          }}
        />
        <Button
          icon="pi pi-send"
          className="p-button-rounded p-button-info"
          onClick={() => props.onPublishModel(rowData)}
        />
      </div>
    );
  };

  const actionExecutorTemplate = (rowData) => (
    <div className="actions">
      {(rowData?.status === IN_EXECUTION_STATUS) &&
        // Show editor if model is in execution
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning p-mr-2"
          onClick={() => props.onEditBasicInfoModel(rowData)}
        />
      }

      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger p-mr-2"
        onClick={() => {
          props.onDeleteModel(rowData);
        }}
      />
      
      {(rowData?.status === IN_EXECUTION_STATUS) ?
        // Show button to keep working in the model
        <Button
          icon="pi pi-briefcase"
          className="p-button-rounded p-button-success p-mr-2"
          title="Seguir trabajando"
          onClick={() => {
            props.onModelClick(rowData);
          }} />
        :
        // Show button to execute the model
        <Button
          icon="pi pi-step-forward-alt"
          className="p-button-rounded p-button-info p-mr-2"
          title="Ejecutar"
          onClick={() => {
            props.onExecuteModel(rowData);
          }} />
      }
    </div>
  );

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">
        {props.roleNav === RolesEnum.Designer
          ? "MITs en Diseño"
          : "MITs Adquiridos"}
      </h5>

      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const logoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        <img
          src={rowData.image}
          alt={`image_${rowData.name}`}
          className="product-image"
        />
      </>
    );
  };

  const getTableStatus = (data, table) => {
    if (props.roleNav === RolesEnum.Executor) {
      return tableTemplates.statusBodyTemplate(data, table, tableAcquiredModelStatus);
    } else {
      return tableTemplates.statusBodyTemplate(data, table, tableModelStatus);
    }
  };

  return (
    <>
      <DataTable
        ref={props.dt}
        value={props.modelsList}
        dataKey="id"
        paginator
        rows={10}
        rowHover
        rowsPerPageOptions={[5, 10, 25]}
        className="datatable-responsive"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        globalFilter={globalFilter}
        emptyMessage="No models found."
        header={header}
        loading={ props.isLoading }
      >
        <Column
          style={{ width: "130px" }}
          field="logo"
          header="Logo"
          body={logoBodyTemplate}
        />

        <Column field="name" header="Nombre" sortable body={ tableTemplates.nameBodyTemplate } />

        <Column
          field="description"
          header="Descripción"
          sortable
          body={ tableTemplates.descriptionBodyTemplate }
        />

        <Column
          field="status"
          header="Estado"
          sortable
          body={(data, table) => getTableStatus(data, table)}
        />

        <Column
          header="Acciones"
          body={
            props.roleNav === RolesEnum.Executor
              ? actionExecutorTemplate
              : actionBodyTemplate
          }
        />
      </DataTable>
    </>
  );
};

export default ModelsListTable;