import { useSelector} from 'react-redux';

export const useToolbox = () =>{

    const state = useSelector((state) => state.ToolboxState);

    const listTools = () => {}

    const insertTool = (data) => {}

    const getCurrentTool = (tool_id) => {}

    const getDistinctTool = (tool_id) => {}

    const insertToolData = (data) => {}

    const removeTool = () => {}

    return {
        ...state,
        listTools,
        insertTool,
        getCurrentTool,
        getDistinctTool,
        insertToolData,
        removeTool,
        toolboxData: state?.data,
        currentTool:state?.dataCurrent,
        distinctTool:state?.dataDistinct,
        complementaryTool:state?.dataComplementary,
        loading:state?.loading
    }
}