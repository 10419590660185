import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useSelector } from 'react-redux';

import { ImageUpload } from '../../../../components/UI';
import { uploadImage } from '../../../../../redux/actions/generics.action';
import { S3_ELEMENT_TYPE } from '../../../utils/types/s3_types';
import {
  createElement,
  listElementsByModel,
  updateElement,
} from '../../../../../redux/actions/toolbox.action';

const CreateTool = ({
  values,
  setValues,
  handleInputChange,
  emptyElement,
  setElements,
  showMessage,
}) => {
  const user = useSelector((state) => state?.LoginState?.data);
  const model = useSelector((state) => state.ModelState.model);
  const role = useSelector((state) => state.RolNavigationState?.state);

  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({
    name: { status: false, message: '' },
    image: { status: false, message: '' },
    description: { status: false, message: '' },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!values?.name.trim()) {
      setErrors({
        ...errors,
        name: {
          status: true,
          message: 'Nombre requerido',
        },
      });
      return;
    }

    if (!values?.description.trim()) {
      setErrors({
        ...errors,
        description: {
          status: true,
          message: 'Descripción requerido',
        },
      });
      return;
    }

    if (values?.id) {
      updateElement(values, values?.id, user?.access_token, role)
        .then((element) => {
          showMessage('success', 'Herramienta actualizada', element?.name);
          updateImageElement(element?.id);

          listElementsByModel(model?.id, user?.access_token, role).then(
            (data) => {
              setElements(data);
            }
          );
        })
        .catch((err) => {
          showMessage('error', 'Error', 'No se pudo actualizar la herramienta');
        });
    } else {
      createElement(values, user?.access_token, role)
        .then((element) => {
          handleReset();
          showMessage('success', 'Herramienta creada', element?.name);
          updateImageElement(element?.id);

          listElementsByModel(model?.id, user?.access_token, role).then(
            (data) => {
              setElements(data);
            }
          );
        })
        .catch((err) => {
          showMessage('error', 'Error', 'No se pudo crear la herramienta');
        });
    }
  };

  const updateImageElement = (element_id) => {
    if (image && model?.id) {
      const body = {
        team_id: model?.team_id,
        type: S3_ELEMENT_TYPE,
        type_id: element_id,
        dataImage: image,
      };

      uploadImage(body, user?.access_token, role)
        .then((data) => {
          setImage(null);
          listElementsByModel(model?.id, user?.access_token, role).then(
            (data) => {
              setElements(data);
            }
          );
          console.log(data);
        })
        .catch((err) => {
          showMessage('error', 'Error', 'No se pudo guardar la imagen');
        });
    }
  };

  const onImageChange = (e, name) => {
    setImage(e);
  };

  const handleReset = () => {
    setValues(emptyElement);
  };

  return (
    <div>
      <div>
        <div className="p-fluid">
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="p-field">
              <label>Imagen</label>

              <ImageUpload
                maxFileSize={1000000}
                image={image}
                setImage={setImage}
                onInputChange={onImageChange}
                chooseOptions={{ style: { width: '100%' } }}
              />
            </div>

            <div className="p-field">
              <label className="required">Nombre</label>

              <InputText
                name="name"
                required
                autoComplete="off"
                className={errors.name.status && 'p-invalid'}
                value={values?.name}
                placeholder="Ingrese el nombre de la herramienta"
                onChange={handleInputChange}
              />

              {errors?.name?.status && (
                <small className="p-invalid color-danger">
                  {errors?.name?.message}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="required">Descripción</label>

              <InputTextarea
                name="description"
                required
                autoResize={false}
                className={errors.description.status && 'p-invalid'}
                rows={3}
                value={values?.description}
                placeholder="Ingrese la descripción de la herramienta"
                onChange={handleInputChange}
              />

              {errors?.description?.status && (
                <small className="p-invalid color-danger">
                  {errors?.description?.message}
                </small>
              )}
            </div>

            <div className="p-field p-text-center">
              <Button
                type="submit"
                className={`p-button-${values?.id ? 'warning' : 'success'}`}
                label={values?.id ? 'Editar' : 'Crear'}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

CreateTool.propTypes = {};

export default CreateTool;
