import * as actions from '../actions/complex-systems.action';

const {handleActions} = require('redux-actions');

const initialState ={
    loading:false,
    data:null,
    error:null,
    loadingCreate:false,
    dataCreate:null,
    errorCreate:null,
    loadingByLevel:false,
    dataByLevel:null,
    errorByLevel:null,
    loadingDistinct:false,
    dataDistinct:null,
    errorDistinct:null
};

export const SystemsReducer = handleActions(
    {
        [actions.setLoadingSystemsAction]: (state,{payload}) =>({
            ...state,
            loading:payload
        }),
        [actions.setSystemsDataAction]: (state,{payload}) =>({
            ...state,
            data:payload
        }),
        [actions.setSystemsErrorAction]: (state,{payload}) =>({
            ...state,
            error:payload
        }),
        [actions.setLoadingSubsystemsLevelAction]: (state,{payload}) =>({
            ...state,
            loadingByLevel:payload
        }),
        [actions.setSubsystemsLevelDataAction]: (state,{payload}) =>({
            ...state,
            dataByLevel:payload
        }),
        [actions.setSubsystemsLevelErrorAction]: (state,{payload}) =>({
            ...state,
            errorByLevel:payload
        }),
        [actions.setLoadingCreateSystemAction]: (state,{payload}) =>({
            ...state,
            loadingCreate:payload
        }),
        [actions.setSystemCreateDataAction]: (state,{payload}) =>({
            ...state,
            dataCreate:payload
        }),
        [actions.setSystemCreateErrorAction]: (state,{payload}) =>({
            ...state,
            errorCreate:payload
        }),

/*
        [actions.setLoadingCreateToolAction]: (state,{payload}) =>({
            ...state,
            loadingCreate:payload
        }),
        [actions.setToolCreateDataAction]: (state,{payload}) =>({
            ...state,
            dataCreate:payload
        }),
        [actions.setToolCreateErrorAction]: (state,{payload}) =>({
            ...state,
            errorCreate:payload
        }),


        [actions.setLoadingCurrentToolAction]: (state,{payload}) =>({
            ...state,
            loadingCurrent:payload
        }),
        [actions.setCurrentToolDataAction]: (state,{payload}) =>({
            ...state,
            dataCurrent:payload
        }),
        [actions.setCurrentToolErrorAction]: (state,{payload}) =>({
            ...state,
            errorCurrent:payload
        }),


        [actions.setLoadingDistinctToolAction]: (state,{payload}) =>({
            ...state,
            loadingDistinct:payload
        }),
        [actions.setDistinctToolDataAction]: (state,{payload}) =>({
            ...state,
            dataDistinct:payload
        }),
        [actions.setDistinctToolErrorAction]: (state,{payload}) =>({
            ...state,
            errorDistinct:payload
        }),


        [actions.setLoadingComplementaryToolAction]: (state,{payload}) =>({
            ...state,
            loadingComplementary:payload
        }),
        [actions.setComplementaryToolDataAction]: (state,{payload}) =>({
            ...state,
            dataComplementary:payload
        }),
        [actions.setComplementaryToolErrorAction]: (state,{payload}) =>({
            ...state,
            errorComplementary:payload
        }),
*/
    },
    initialState,
);
