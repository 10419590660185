import React from "react";
// import Editor from "equation-editor-react";
import { InputText } from "primereact/inputtext";

const EquationEditor = (props) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          color: "black",
          height: 105,
          fontSize: 18,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          margin: "auto",
          width: "94%",
        }}
      >
        <InputText
          placeholder="Ecuación..."
          value={props.expression}
          style={{
            color: "black",
            background: "white",
            borderWidth: 1,
            textAlign: "center",
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 10,
            paddingRight: 10,
            borderColor: "gray",
            width: "99%",
          }}
          onInput={(e) => props.onInputChange(e, props.name)}
        />

        {/* <Editor
          value={equation}
          onChange={(e) => {
            onChangeHandler(e);
          }}
          autoCommands="pi theta sqrt sum prod alpha beta gamma rho"
          autoOperatorNames="sin cos tan"
        /> */}
      </div>
    </>
  );
};

export default EquationEditor;
