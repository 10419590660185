import React from 'react';
import PropTypes from 'prop-types';
import inputNumberRender from '../../../../../hooks/renders/inputNumberRender';

const BudgetForm = ({ values, handleInputChange }) => {
    return (
        <div>
            <div className="schedule">
                <ul>
                    <li>
                        <div className="schedule-header">
                            <h6>Presupuesto recomendado</h6>
                            <span>
                                <span style={{ fontSize: '.9rem' }}>
                                    $5000
                                </span>
                            </span>
                        </div>
                        <span>
                            5000 dólares
                        </span>
                    </li>
                </ul>
            </div>

            <form className='p-fluid' autoComplete='off' >
                <div className='p-field'>
                    <label className='required'>Presupuesto</label>
                    { 
                        inputNumberRender({ values, handleInputChange, component: {
                            attributes: {
                                id: 'budget',
                                name: 'budget',
                                required: true,
                                placeholder: "Ingresa el presupuesto del modelo",
                                showbuttons: true,
                                min: 0,
                                step: 100,
                                prefix: '$',
                            }
                        }})
                    }
                </div>
            </form>
        </div>
    )
}

BudgetForm.propTypes = {
    values: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired,
}

export default BudgetForm