import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import EditFieldDialog from '../../../components/Profile/EditFieldDialog';
import ProfileField from '../../../components/Profile/ProfileFile';
import SaveChangesDialog from '../../../components/Profile/SaveChangesDialog';
import { isEqual } from 'lodash';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

const ProfilePerson = (props) => {

    const [ dialog, setDialog ] = useState(false);
    const [ values, setValues ] = useState({ ...props?.person });
    const [ btnHidden, setBtnHidden ] = useState(true);
    const labels = [ 'Names', 'Last Names', 'Identificatión', 'Gender', 'Email' ]

    useEffect(() => {

        if (isEqual(props?.person, values)) {
            setBtnHidden(true);
        } else {
            setBtnHidden(false);
        }
    }, [ values ])

    return (
        <>
            <Toolbar
                className="p-mb-4 p-toolbar"
                left={
                    <ProfileField field={ values?.names } label="Names" />
                }
                right={
                    <EditFieldDialog
                        values={ values } setValues={ setValues }
                        label='Name' fieldName="names" />
                }>
            </Toolbar>

            <Toolbar
                className="p-mb-4 p-toolbar"
                left={
                    <ProfileField field={ values?.last_names } label="Last Names" />
                }
                right={
                    <EditFieldDialog
                        values={ values } setValues={ setValues }
                        label='Last Name' fieldName="last_names" />
                }>
            </Toolbar>

            <Toolbar
                className="p-mb-4 p-toolbar"
                left={
                    <ProfileField field={ props?.user } label="Email" icon="envelope" />
                }
                /*right={
                    <EditFieldDialog
                        values={ values } setValues={ setValues }
                        label='Email' fieldName='email' />
                }*/>
            </Toolbar>

            <Toolbar
                className="p-mb-4 p-toolbar"
                left={
                    <ProfileField field={ values?.gender } label="Gender" gender={values?.gender} />
                }
                right={
                    <EditFieldDialog
                        values={ values } setValues={ setValues }
                        label='Gender' fieldName="gender" />
                }>
            </Toolbar>

            <Toolbar
                className="p-mb-4 p-toolbar"
                left={
                    <ProfileField field={ values?.identification } label="Identification" icon="id-card" />
                }
                right={
                    <EditFieldDialog
                        values={ values } setValues={ setValues }
                        label='Identification' fieldName="identification" />
                }>
            </Toolbar>

            <div>

                {
                    (!btnHidden) &&
                    <Button
                        className="p-button-info"
                        style={{ width: '100%' }}
                        label="Save all person changes"
                        onClick={ () => setDialog(true) } />
                }

                <SaveChangesDialog
                    open={ dialog }
                    setOpen={ setDialog }
                    values={ values }
                    setValues={ setValues }
                    oldValues={ props?.person }
                    labels={ labels } />

            </div>
        </>
    );
}

ProfilePerson.propTypes = {
    person: PropTypes.object.isRequired
};

export default ProfilePerson;
