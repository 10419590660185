import React, { useEffect, useRef, useState, useCallback } from "react";
import { Toast } from "primereact/toast";
import { Chips } from "primereact/chips";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import KnowledgeItems from "../UI/KnowledgeItems";
import * as knowledgeBaseActions from "../../../redux/actions/knowledge-base.actions";
import { useDispatch } from "react-redux";
import { GenericDialog } from "../UI";

const emptyKnowledge = {
  originMeaning: "",
  localMeaning: "",
  purpose: "",
  theory: "",
  method: "",
  tools: "",
  duality: "",
  keyInteractions: "",
};

const KnowledgeBase = () => {
  const dispatch = useDispatch();
  const toast = useRef();
  const [conceptsLabels, setConceptsLabels] = useState();
  const [concepts, setConcepts] = useState();
  const [concept, setConcept] = useState();
  const [knowledge, setKnowledge] = useState(emptyKnowledge);

  const [globalFilter, setGlobalFilter] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [error, setError] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [_knowledgePreviousVersion, _setKnowledgePreviousVersion] =
    useState(emptyKnowledge);

  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (error) showMessage("error", "Error", error.message);
  }, [error]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const header = (
    <div className="table-header">
      <div style={{ display: "inline-block" }}>
        <h5 className="p-m-0 ">Listado</h5>
      </div>

      <div style={{ display: "inline-block", float: "right" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar..."
          />
        </span>
      </div>
    </div>
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">{rowData.label}</span>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning p-mr-2"
          onClick={() => onLoadKnowledge(rowData.id)}
        />
      </div>
    );
  };

  const loadConcepts = useCallback(async () => {
    try {
      const _concepts = await dispatch(knowledgeBaseActions.getConcepts());
      setConcepts(_concepts);
      setConceptsLabels(_concepts.map((c) => c.label));
    } catch (error) {
      setError(error);
    }
  }, [concepts]);

  useEffect(() => {
    setLoading(true);
    loadConcepts().then(() => {
      setLoading(false);
    });
  }, []);

  const loadKnowledge = 
    async (conceptId) => {
      try {
        const _knowledge = await dispatch(
          knowledgeBaseActions.getKnowledge(conceptId)
        );
        setConcept(concepts.find(c => c.id === conceptId))
        setKnowledge(_knowledge);
        _setKnowledgePreviousVersion(_knowledge);
      } catch (error) {
        setError(error);
      }
    };

  const onLoadKnowledge = (conceptId) => {
    setLoading(true);
    loadKnowledge(conceptId).then(() => {
      setLoading(false);
      setOpenEditor(true);
    });
  };

  const deleteConcept = async () => {
    setLoading(true);
    try {
      await dispatch(knowledgeBaseActions.deleteConcept(concept.id));
      setConcepts([...concepts.filter((c) => c.label !== concept.label)]);
      setConceptsLabels([...conceptsLabels.filter((c) => c !== concept.label)]);
    } catch (error) {
      setError(error);
    }
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const onDeleteConcept = (e) => {
    const _del_label = e.value[0];
    const _concept = concepts.find((c) => c.label === _del_label);
    setConcept(_concept);
    setDeleteDialogOpen(true);
  };

  const onCreateConcept = async (e) => {
    setLoading(true);
    const conceptLabel = e.value.trim();
    if (conceptLabel === "") return;
    try {
      const _newConcept = await dispatch(
        knowledgeBaseActions.createConcept(conceptLabel)
      );
      setConceptsLabels([...conceptsLabels, _newConcept.label]);
      setConcepts([...concepts, _newConcept]);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const onHideEditor = (e) => {
    setOpenEditor(false);
    setConcept();
    setKnowledge(emptyKnowledge);
  };

  const hideDeleteDialog = (e) => {
    setDeleteDialogOpen(false);
    setConcept();
  };

  const onInputChange = (e, name) => {
    const val = e.htmlValue || "";
    let _knowledge = { ...knowledge };
    _knowledge[name] = val;
    setKnowledge(_knowledge);

    if (_knowledgePreviousVersion[name] !== _knowledge[name]) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  const onUpdateKnowledge = async (attribute) => {
    setSubmitted(true);
    setLoading(true);
    try {
      if (hasChanged) {
        await dispatch(
          knowledgeBaseActions.updateKnowledge(concept.id, attribute, knowledge)
        );
        setHasChanged(false);
        _setKnowledgePreviousVersion(knowledge);
        showMessage("success", "Éxito", "Guardado.");
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="card-w-title">
        <Toast ref={toast} />
        <h3>Base del conocimiento</h3>
      </div>

      <GenericDialog
        visible={deleteDialogOpen}
        header={"Confirmar"}
        onCancelAction={() => {
          setDeleteDialogOpen(false);
        }}
        onConfirmationAction={deleteConcept}
        onHide={hideDeleteDialog}
        message={<span>¿Desea eliminar {concept?.label}?</span>}
        condition={concept}
      />

      <KnowledgeItems
        open={openEditor}
        setOpen={onHideEditor}
        concept={concept}
        knowledge={knowledge}
        onSaveAttribute={onUpdateKnowledge}
        onInputChange={onInputChange}
        submitted={setSubmitted}
        hasChanged={hasChanged}
        title={
          <span>
          <b>{concept?.label}</b>
        </span>
        }
        // subtitle={"Componentes de "}
      />

      <div className="p-grid">
        <div className="p-col-12 p-lg-6 ">
          <div className="card p-fluid">
            <h5>Categorías/Conceptos</h5>
            <Chips
              value={conceptsLabels}
              style={{ padding: 10, width: "100%" }}
              allowDuplicate={false}
              onRemove={(e) => {
                onDeleteConcept(e);
              }}
              onAdd={(e) => {
                onCreateConcept(e);
              }}
              placeholder={"Ingrese una categoría/concepto y presione enter."}
              separator={"-"}
            />
          </div>
        </div>

        <div className="p-col-12 p-lg-6 ">
          <div className="card">
            <DataTable
              // ref={props.dt}
              value={concepts}
              dataKey="id"
              paginator
              rows={10}
              rowHover
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              globalFilter={globalFilter}
              emptyMessage="Todavía no se han creado categorías/conceptos."
              header={header}
            >
              <Column
                field="label"
                header="Nombre"
                className="p-col-12 p-lg-9 "
                sortable
                body={nameBodyTemplate}
              />

              <Column header="Construír" body={actionBodyTemplate} />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnowledgeBase;
