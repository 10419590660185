export const getComponentByVariable = (variable) => {

    const type = variable?.variable_type?.label.toUpperCase();
    
    if (type === 'ENTERO') {

        return getIntegerComponent(variable);

    } else if (type === 'DECIMAL') {

        return getDecimalComponent(variable);

    } else if (type === 'TEXTO') {

        return getStringComponent(variable);

    } else if (type === 'FECHA') {

        return getDateComponent(variable);        

    } else if (type === 'BOOLEANA') {

        return getBooleanComponent(variable);
    }
}

const getIntegerComponent = (variable) => {

    const measure_type = variable?.measure_type?.label.toUpperCase();

    if (measure_type === 'ESCALAR') {

        let min = ''
        let max = ''
        let key = "number" + variable?.id;

        if (variable?.measure_type_characteristics_values) {

            variable?.measure_type_characteristics_values.forEach((characteristic_value) => {

                const { characteristics, value } = characteristic_value;

                if (characteristics?.label === 'Límite inferior') {
                    min = value;
                } else if (characteristics?.label === 'Límite superior') {
                    max = value;
                }
            });
        }

        return {
            "label": "Number",
            "mask": false,
            "tableView": false,
            "delimiter": false,
            "requireDecimal": false,
            "inputFormat": "plain",
            "truncateMultipleSpaces": false,
            "key": key,
            "type": "number",
            "input": true,
            "validate": {
                "min": min,
                "max": max,
            },
        };


    } else if (measure_type === 'NOMINAL' || measure_type === 'ORDINAL') {
        
        return ({
            "label": "Data Map",
            "tableView": false,
            "key": "dataMap" + variable.id,
            "type": "datamap",
            "input": true,
            "valueComponent": {
                "label": "Number",
                "mask": false,
                "tableView": false,
                "delimiter": false,
                "requireDecimal": false,
                "inputFormat": "plain",
                "truncateMultipleSpaces": false,
                "key": "number",
                "type": "number",
                "input": true,
                "hideLabel": true
            }
        });
    }
}

const getDecimalComponent = (variable) => {

    const measure_type = variable?.measure_type?.label.toUpperCase();

    if (measure_type === 'ESCALAR') {

        let min = '';
        let max = '';
        let key = "number#" + variable?.id;
        let decimalLimit = '';

        if (variable?.measure_type_characteristics_values) {

            variable?.measure_type_characteristics_values.forEach((characteristic_value) => {

                const { characteristics, value } = characteristic_value;

                if (characteristics?.label === 'Límite inferior') {
                    min = value;
                } else if (characteristics?.label === 'Límite superior') {
                    max = value;
                } else if (characteristics?.label === 'Decimales') {
                    decimalLimit = value;
                }
            });
        }

        return {
            "label": "Number",
            "mask": false,
            "tableView": false,
            "delimiter": false,
            "decimalLimit": decimalLimit,
            "requireDecimal": true,
            "inputFormat": "plain",
            "truncateMultipleSpaces": false,
            "key": key,
            "type": "number",
            "input": true,
            "validate": {
                "min": min,
                "max": max,
            },
        };
    }
}

const getStringComponent = (variable) => {
    return {
        "label": "Text Field",
        "tableView": true,
        "key": "textField#" + variable?.id,
        "type": "textfield",
        "input": true,
    };
}

const getDateComponent = (variable) => {

    const dayFirst = (format) => {
        if (format.toUpperCase() === 'dd/mm/yy') return true;
        else return false;
    }

    return {
        "label": "Day",
        "hideInputLabels": false,
        "inputsLabelPosition": "top",
        "useLocaleSettings": false,
        "tableView": false,
        "fields": {
            "day": {
                "hide": false
            },
            "month": {
                "hide": false
            },
            "year": {
                "hide": false
            }
        },
        "dayFirst": dayFirst(variable?.variable_type_format?.label),
        "key": "day#" + variable?.id,
        "type": "day",
        "input": true,
        "defaultValue": "00/00/0000"
    };
}

const getBooleanComponent = (variable) => {
    return {
        "label": "Checkbox",
        "tableView": false,
        "key": "checkbox#" + variable?.id,
        "type": "checkbox",
        "input": true
    };
}