import React, { useState, useRef, useEffect, useCallback } from "react";
import { TabMenu } from "primereact/tabmenu";

import { TextEditor } from "../../../components/UI";

import { useDispatch } from "react-redux";

import * as csActions from "../../../../redux/actions/complex-system/in-design-complex-system.actions";

const DINAMIC_EQUILIBRIUM = "dinamic_equilibrium";
const EVOLUTIONARY_STABILITY = "evolutionary_stability";
const VULNERABILITY = "vulnerability";
const FLEXIBILITY = "flexibility";
const COMPLEXITY = "complexity";
const EMERGENCY = "emergency";

const PROPERTIES_STEP = "properties";

const wizardItems = [
  { id: 0, label: "Equilibrio dinámico", icon: "pi pi-table" },
  { id: 1, label: "Estabilidad evolutiva", icon: "pi pi-minus" },
  { id: 2, label: "Vulnerabilidad", icon: "pi pi-spinner" },
  { id: 3, label: "Flexibilidad", icon: "pi pi-pause" },
  { id: 4, label: "Complejidad", icon: "pi pi-th-large" },
  { id: 5, label: "Emergencia", icon: "pi pi-exclamation-triangle" },
];

const emptyComponent = {
  id: "",
  processes: "",
  phenomenons: "",
  organization_patterns: "",
  function: "",
};

const Properties = (props) => {
  const dispatch = useDispatch();
  const toast = useRef();

  const [tabSelected, setTabSelected] = useState(wizardItems[0]);
  const [component, setComponent] = useState(emptyComponent);

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const [_previousVersion, _setPreviousVersion] = useState(emptyComponent);

  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) showMessage("error", "Error", error.message);
  }, [error]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  const loadComponent = useCallback(async () => {
    try {
      const _retrieved_component = await dispatch(
        csActions.getModelizationComponents(PROPERTIES_STEP)
      );
      setComponent(_retrieved_component);
      _setPreviousVersion(_retrieved_component);
    } catch (error) {
      setError(error);
    }
  }, [component]);

  useEffect(() => {
    setLoading(true);
    loadComponent().then(() => {
      setLoading(false);
    });
  }, []);

  const onInputChange = (e, name) => {
    const val = e.htmlValue || "";
    let _modelizationComponents = { ...component };
    _modelizationComponents[name] = val;
    setComponent(_modelizationComponents);
    if (_previousVersion[name] !== _modelizationComponents[name]) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  const onSaveAttribute = async (attribute) => {
    setSubmitted(true);
    setLoading(true);
    try {
      if (hasChanged) {
        await dispatch(
          csActions.updateModelizationComponents(
            component.id,
            attribute,
            component,
            PROPERTIES_STEP
          )
        );
        setHasChanged(false);
        _setPreviousVersion(component);
        showMessage("success", "Éxito", "Guardado.");
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="card">
        <TabMenu
          model={wizardItems}
          onTabChange={(e) => {
            tabChangeHandler(e.value);
          }}
          activeItem={tabSelected}
        />

        {tabSelected.id === 0 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={DINAMIC_EQUILIBRIUM}
            value={component[DINAMIC_EQUILIBRIUM]}
            title={"Equilibrio dinámico"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar equilibrio dinámico"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected.id === 1 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={EVOLUTIONARY_STABILITY}
            value={component[EVOLUTIONARY_STABILITY]}
            title={"Estabilidad evolutiva"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar estabilidad evolutiva"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected.id === 2 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={VULNERABILITY}
            value={component[VULNERABILITY]}
            title={"Vulnerabilidad"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar vulnerabilidad"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected.id === 3 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={FLEXIBILITY}
            value={component[FLEXIBILITY]}
            title={"Flexibilidad"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar flexibilidad"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected.id === 4 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={COMPLEXITY}
            value={component[COMPLEXITY]}
            title={"Complejidad"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar complejidad"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected.id === 5 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={EMERGENCY}
            value={component[EMERGENCY]}
            title={"Emergencia"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar emergencia"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Properties;
