import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { inDesignComplexSystemActions } from "../../../redux/actions/complex-system";
import { RadioButton } from "primereact/radiobutton";

const ComponentsRelationshipsTable = (props) => {
  const [interactions, setInteractions] = useState(props.data);
  const [radioValue, setRadioValue] = useState(null);

  useEffect(() => {
    setInteractions(props.data);
  }, [props.data]);

  const sourceBodyTemplate = (data) => {
    return (
      <div className="p-col-12 p-lg-12">
        {data?.source?.selected_component?.name}
      </div>
    );
  };

  const targetBodyTemplate = (data) => {
    return (
      <div className="p-col-12 p-lg-12">
        {data?.target?.selected_component?.name}
      </div>
    );
  };

  const attributesIntensityTemplate = (data) => {
    return (
      <>
        <div className="p-grid">
          <div
            className="p-col-12 p-lg-4"
            style={{
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
            }}
          >
            <div className="p-field-radiobutton">
              <RadioButton
                inputId={`w_${data.id}`}
                name="weak"
                value={data}
                checked={data.intensity_id === 1}
                onChange={(e) => props.onUpdateAttributes(e)}
              />
              <label htmlFor="option1">Débil</label>
            </div>
          </div>
          <div className="p-col-12 p-lg-4">
            <div className="p-field-radiobutton">
              <RadioButton
                inputId={`m_${data.id}`}
                name="medium"
                value={data}
                checked={data.intensity_id === 2}
                onChange={(e) => props.onUpdateAttributes(e)}
              />
              <label htmlFor="option2">Media</label>
            </div>
          </div>
          <div className="p-col-12 p-lg-4">
            <div className="p-field-radiobutton">
              <RadioButton
                inputId={`s_${data.id}`}
                name="strong"
                value={data}
                checked={data.intensity_id === 3}
                onChange={(e) => props.onUpdateAttributes(e)}
              />
              <label htmlFor="option3">Fuerte</label>
            </div>
          </div>
        </div>
      </>
    );
  };

  const directionAttributesTemplate = (data) => {
    return (
      <>
        <div className="p-grid">
          <div className="p-col-12 p-md-6">
            <div className="p-field-radiobutton">
              <RadioButton
                inputId={`plus_${data.id}`}
                name="positive"
                value={data}
                checked={data.direction_id === 1}
                onChange={(e) => props.onUpdateAttributes(e)}
              />
              <label htmlFor="option1">+</label>
            </div>
          </div>
          <div className="p-col-12 p-md-6">
            <div className="p-field-radiobutton">
              <RadioButton
                inputId={`minus_${data.id}`}
                name="negative"
                value={data}
                checked={data.direction_id === 2}
                onChange={(e) => props.onUpdateAttributes(e)}
              />
              <label htmlFor="option2">-</label>
            </div>
          </div>
        </div>
      </>
    );
  };

  const keyAttributesTemplate = (data) => {
    return (
      <>
        <div className="p-grid">
          <div className="p-col-12 p-md-6">
            <div className="p-field-radiobutton">
              <Checkbox
                id={`${data.id}`}
                inputId={`${data.id}`}
                name="is_key"
                value={data}
                checked={data.is_key}
                onChange={(e) => {
                  props.onUpdateAttributes(e);
                }}
              />
              <label htmlFor="option1"></label>
            </div>
          </div>
        </div>
      </>
    );
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <div className="actions">
          <Button
            key={data.component_id}
            icon="pi pi-pencil"
            className="p-button-rounded p-button-warning p-mr-2"
            onClick={(e) => {
              props.onUpdateLabels(data);
            }}
          />
          <Button
            key={data.component_id}
            icon="pi pi-trash"
            className="p-button-rounded p-button-danger p-mr-2"
            onClick={(e) => {
              props.removeComponent(data);
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div>
      <DataTable
        value={interactions}
        dataKey="id"
        rowHover
        emptyMessage="No se han seleccionado interacciones."
      >
        <Column
          style={{ width: "15%" }}
          field="source"
          header="Origen"
          body={sourceBodyTemplate}
        />
        <Column
          style={{ width: "15%" }}
          field="target"
          header="Destino"
          body={targetBodyTemplate}
        />
        <Column
          style={{ width: "30%" }}
          field="key"
          header="Intensidad"
          body={attributesIntensityTemplate}
        />
        <Column
          style={{ width: "25%" }}
          field="key"
          header="Dirección"
          body={directionAttributesTemplate}
        />
        <Column
          style={{ width: "10%" }}
          field="key"
          header="Clave"
          body={keyAttributesTemplate}
        />
        <Column
          style={{ width: "15%" }}
          field="actions"
          header="Acciones"
          body={actionBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

export default ComponentsRelationshipsTable;
