import React, { useState, useEffect, useRef } from 'react';
import ToolComplementaries from "./ToolComplementaries";
import useForm from '../../../../../hooks/useForm';
import ToolResults from "./ToolResults";
import ToolTheory from "./ToolTheory";
import ToolMethod from "./ToolMethod";
import ToolUses from "./ToolUses";
import { TabMenu } from "primereact/tabmenu";
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { getToolByElement } from '../../../../../redux/actions/toolbox.action';
import { Toast } from 'primereact/toast';
import { ToolOrigins } from './ToolOrigins';

const UpdateTool = ({ open, setOpen, element }) => {

    const user = useSelector((state) => state.LoginState.data);
    const toast  = useRef(null);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const wizardItems = [
        {id: 0, label: 'Orígenes y Evolución', icon: 'pi pi-th-large'},
        {id: 1, label: 'Teoría', icon: 'pi pi-users'},
        {id: 2, label: 'Método', icon: 'pi pi-eye'},
        {id: 3, label: 'Método de Empleo/Usos', icon: 'pi pi-eye'},
        {id: 4, label: 'Cambios/Resultados', icon: 'pi pi-eye'},
        {id: 5, label: 'Herramientas complementarias', icon: 'pi pi-eye'},
    ];

    const [ tabSelected, setTabSelected ] = useState(wizardItems[0]);
    const { values, setValues, handleInputChange } = useForm({});

    useEffect(() => {
        if (element?.id) {
            getToolByElement(element?.id, user?.access_token, role).then((data) => {
                setValues(data);
            });
        }
    }, [ user, element, setValues, role ])

    const showMessage = (severity, summary, detail) => {
        toast?.current?.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: 3000,
        });
    };

    const handleTabChange = (e) => {
        setTabSelected(e);
    }

    const dialogHeader = () => {
        return (
            <div>
                <h5>Herramienta: { element?.name }</h5>
            </div>
        );
    }
    
    const renderSwitch = (key) => {
        switch (key) {
            case 0:
                return <ToolOrigins handleInputChange={ handleInputChange } values={ values } showMessage={ showMessage } />
            case 1:
                return <ToolTheory handleInputChange={ handleInputChange } values={ values } showMessage={ showMessage } />
            case 2:
                return <ToolMethod handleInputChange={ handleInputChange } values={ values } showMessage={ showMessage } />
            case 3:
                return <ToolUses handleInputChange={ handleInputChange } values={ values } showMessage={ showMessage } />
            case 4:
                return <ToolResults handleInputChange={ handleInputChange } values={ values } showMessage={ showMessage } />
            case 5:
                return <ToolComplementaries handleInputChange={ handleInputChange } values={ values } showMessage={ showMessage } action={ '' } />
            default:
                return <ToolOrigins handleInputChange={ handleInputChange } values={ values } showMessage={ showMessage } />
        };
    }

    return (
        <div>
            <Dialog 
                visible={ open } header={ dialogHeader }
                contentStyle={{ height: '100%' }}
                style={{ width: "95%", maxWidth: '1100px', height: '90%' }} modal
                onHide={ () => setOpen(false) } >

                <Toast ref={ toast } />

                <div>
                    <TabMenu 
                        className='p-mt-1'
                        model={ wizardItems }
                        onTabChange={(e) => handleTabChange(e.value)} 
                        activeItem={ tabSelected } />

                    { renderSwitch(tabSelected.id) }
                </div>
            </Dialog>
        </div>
    );
};

export default UpdateTool;