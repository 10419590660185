import React, { useEffect, useRef, useState } from 'react';
import ButtonBuilderOption from '../../../../components/BPMN/ButtonBuilderOption';
import inputNumberRender from '../../../../../hooks/renders/inputNumberRender';
import socialImg from '../../../../assets/img/social-media.svg';
import surveyImg from '../../../../assets/img/survey.svg';
import formImg from '../../../../assets/img/form.svg';
import useForm from '../../../../../hooks/useForm';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import UnitTimeTypesDropdown from '../../../../components/UI/UnitTimeTypesDropdown';
import { updateActivityByTask } from '../../../../../redux/actions/activity.actions';
import { InputTextarea } from 'primereact/inputtextarea';
import { isFormValid } from '../utils/validations';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { indicadoresObject } from '../../../../../api/data';
import { toCapitalize } from '../../../utils/helpers/functions';
import { useTranslation } from 'react-i18next';

const DialogActivity = ({ dialog, setDialog, process, activity, setActivity }) => {

    const history = useHistory();
    const toast  = useRef(null);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ showButtons, setShowButtons ] = useState(true);
    const [ unitTimeType, setUnitTimeType ] = useState({});
    const { t: tGlobal } = useTranslation(['global']);
    const { values, setValues, handleInputChange } = useForm({});
    /**
     * status: true if have error
     * status: false if haven'n error
     * message: Message of error
     */
    const [ errors, setErrors ] = useState({
        desc: { status: false, message: '' },
        duration: { status: false, message: '' },
    })

    const activityTypes = ['form', 'survey', 'social'];

    const builderOptions = [
        {
            id: 1,
            label: 'Formulario',
            img: formImg,
            type: 'form', //Type of builder
            btnType: 'success',
            callback: () => history.push({
                pathname: '/form/builder',
                state: {
                    process: process,
                    activity: activity,
                    indicators: indicadoresObject,
                },
            }),
        },
        {
            id: 2,
            label: 'Encuesta',
            img: surveyImg,
            type: 'survey',  //Type of builder
            callback: () => history.push({
                pathname: '/admin/surveys',
                state: {
                    tipo : 'activity',
                    process: process,
                    activity: activity,
                    indicators: indicadoresObject,
                },
            }),
        },
        {
            id: 3,
            label: 'Social',
            img: socialImg,
            type: 'social', //Type of builder
            btnType: 'info',
            callback: () => {
                alert('Coming Soon');
            },
        },
    ];

    useEffect(() => {

        setValues({
            name: activity?.name,
            desc: activity?.description,
            duration: activity?.duration,
            budget: activity?.budget,
        });

        // Verificar si tiene una unidad de tiempo
        if (activity?.unit_time_type && activity?.unit_time_type?.label) {
            setUnitTimeType({
                ...activity?.unit_time_type,
                label: toCapitalize(activity?.unit_time_type?.label),
            });
        } else {
            setUnitTimeType({});
        }

        // Reset errors
        setErrors({
            desc: { status: false, message: '' },
            duration: { status: false, message: '' },
            budget: { status: false, message: '' },
        })

        // Show or hide buttons
        if (activity?.name && activity?.description && activity?.long_description && activity?.duration) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }

    }, [ activity, setValues ]);

    const headerDialogHeader = () => {
        return (
            <>
                <span style={{ fontSize: '1.1rem' }}>
                    {activity?.name}
                </span>
                <span className={`status-${(activity?.status === 1) ? 'success':'danger'} p-ml-2`}>
                    {(activity?.status === 1) ? 'Activo':'Inactivo'}
                </span>
            </>
        );
    }

    const handleSubmit = () => {

        if (isFormValid(values, unitTimeType, setErrors)) {

            const body = {
                name: values?.name,
                description: values?.desc,
                long_description: values?.desc,
                duration: values?.duration,
                unit_time_type_id: unitTimeType?.id,
                budget: values?.budget,
            }

            updateActivityByTask(activity?.task_id, process?.id, body, user?.access_token, role).then(response => {
                toast.current.show({ severity: 'success', summary: 'Edited Activity', detail: `${activity?.name}`, life: 3000 });
                setActivity(response);
            });
        }
    }

    return (
        <Dialog
            header={ headerDialogHeader } visible={ dialog }
            onHide={() => setDialog(false)}
            style={{ width: '350px' }} modal >

            <Toast ref={ toast } />

            <div className="p-fluid">

                <div className="p-field">
                    <label className='required'>Descripción</label>
                    <InputTextarea
                        id="desc" name="desc"
                        value={ (values?.desc) ? values?.desc: '' }
                        placeholder="Escribe la descripción de la actividad ..."
                        autoComplete="off" required
                        onChange={ handleInputChange }
                        autoResize={ true }
                        maxLength={ 200 }
                        rows={ 3 }
                        className={classNames({ 'p-invalid': errors?.desc?.status === true })} />
                    { (errors?.desc?.status === true) && <small className="color-danger">{ errors?.desc?.message }</small> }
                </div>

                <div className="p-field">
                    <label className='required'>Presupuesto</label>
                    { 
                        inputNumberRender({ values, handleInputChange, component: {
                            attributes: {
                                id: 'budget',
                                name: 'budget',
                                required: true,
                                placeholder: "Ingresa el presupuesto de la actividad",
                                class: classNames({ 'p-invalid': errors?.budget?.status === true }),
                                showbuttons: true,
                                min: 0,
                                autocomplete: 'off',
                                step: 100,
                                prefix: '$',
                            }
                        }})
                    }
                    { (errors?.budget?.status === true) && <small className="p-invalid color-danger">{ errors?.budget?.message }</small> }
                </div>

                <div className="p-field">
                    <label className='required'>Duración (Nro. { tGlobal('times.' + unitTimeType?.label?.toLowerCase()) })</label>
                    <div className="p-inputgroup">
                        { 
                            inputNumberRender({ values, handleInputChange, component: {
                                attributes: {
                                    id: 'duration',
                                    name: 'duration',
                                    required: true,
                                    placeholder: "Escribe la duración de la actividad",
                                    class: classNames({ 'p-invalid': errors?.duration?.status === true }),
                                    showbuttons: false,
                                    min: 0,
                                    autocomplete: 'off',
                                    style: {
                                        borderTopRightRadius: '0px',
                                        borderEndEndRadius: '0px',
                                    },
                                }
                            }})
                        }
                        <UnitTimeTypesDropdown
                            unitTimeType={ unitTimeType }
                            setUnitTimeType={ setUnitTimeType } />

                    </div>
                    { (errors?.duration?.status === true) && <small className="p-invalid color-danger">{ errors?.duration?.message }</small> }
                </div>

                <Button
                    className="p-button p-button-info"
                    label="Guardar"
                    onClick={ handleSubmit } />
            </div>

            <span
                style={{ fontSize: '.9rem', }}
                className="p-d-block p-my-3 p-text-center" >
                    Herramienta:
            </span>

            <div className="p-text-center">
                {
                    ( showButtons ) ?
                    builderOptions.map((builder) => {

                        // Return one button, return builder created in this activity
                        if (activityTypes.includes( activity?.type && activity?.type)) { 
                            if (activity?.type.toUpperCase() === builder?.type.toUpperCase()) {
                                return <ButtonBuilderOption
                                    key={ builder?.id } btnType={ builder?.btnType }
                                    img={ builder?.img } label={ builder?.label }
                                    callback={ builder?.callback } />
                            }

                        // Return all buttons
                        } else { 
                            return <ButtonBuilderOption
                                key={ builder?.id } btnType={ builder?.btnType }
                                img={ builder?.img } label={ builder?.label }
                                callback={ builder?.callback } />
                        }
                    })
                    :
                    <small className='color-warning'>
                        {/* Show if field are emptys */}
                        {(!activity?.description || !activity?.long_description || !activity?.duration)
                            ? 'Necesitas completar todos los campos'
                            : 'La actividad necesita un nombre'
                        }
                    </small>
                }
            </div>
        </Dialog>
    )
}

DialogActivity.propTypes = {
    dialog: PropTypes.bool.isRequired,
    setDialog: PropTypes.func.isRequired,
    activity: PropTypes.object.isRequired,
    process: PropTypes.object,
}

export default DialogActivity;