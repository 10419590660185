import React, { useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { TextEditor } from ".";

const ORIGIN_MEANING = "origin_meaning"
const LOCAL_MEANING = "local_meaning"
const PURPOSE = "purpose"
const RESULTS = "results"
const APLICATION_PROCESSES = "aplication_processes"

const components = [
  { id: 0, label: "Significado de origen", icon: "pi pi-sun" },
  { id: 1, label: "Significado local", icon: "pi pi-sun" },
  { id: 2, label: "Propósito", icon: "pi pi-eye" },
  { id: 3, label: "Resultados", icon: "pi pi-th-large" },
  { id: 4, label: "Procesos de aplicación", icon: "pi pi-spinner" },
];

const TheoryLayPrincipleItems = (props) => {
  const [tabSelected, setTabSelected] = useState(components[0]);

  const dialogFooter = (
    <>
      <Button
        label="Cerrar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          props.setOpen();
          props.submitted(false);
          setTabSelected(components[0]);
        }}
      />
    </>
  );

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  return (
    <>
      <Dialog
        visible={props.open}
        style={{ width: "90%", zindex: 20 }}
        header={props.title}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={() => {
          props.setOpen();
        }}
      >
        <h6>{props.subtitle}</h6>
        <div className="p-grid list-demo">
          <div className="p-col-12 p-md-12">
            <div className="card card-w-title">
              <h3>Principios, Leyes, y Teorías</h3>
              <TabMenu
                model={components}
                onTabChange={(e) => {
                  tabChangeHandler(e.value);
                }}
                activeItem={tabSelected}
              />

              {tabSelected.id === 0 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={ORIGIN_MEANING}
                  value={props.ptl[ORIGIN_MEANING]}
                  title={"Significado de origen"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar significado origen"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 1 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={LOCAL_MEANING}
                  value={props.ptl[LOCAL_MEANING]}
                  title={"Significado local"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar un significado local"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 2 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={PURPOSE}
                  value={props.ptl[PURPOSE]}
                  title={"Propósito"}
                  subtitle={"Describa ..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar propósito"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 3 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={RESULTS}
                  value={props.ptl[RESULTS]}
                  title={"Resultados"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar resultados"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 4 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={APLICATION_PROCESSES}
                  value={props.ptl[APLICATION_PROCESSES]}
                  title={"Procesos de aplicación"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar procesos de aplicación"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default TheoryLayPrincipleItems;
