import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import {
  IndicatorPreviewItem,
  EquationEditor,
  IndicatorVariablesDefinition,
  IndicatorTags,
} from ".";

const CreateIndicatorDialog = (props) => {

  const dialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          props.setCreateDialog();
          props.setSubmitted(false);
        }}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={props.createTargetObject}
      />
    </>
  );

  return (
    <>
      <Dialog
        visible={props.createDialog}
        style={{ width: "90%", zindex: 20 }}
        header={props.title}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={() => {
          props.setCreateDialog();
        }}
      >
        <h6>{props.subtitle}</h6>
        <div className="p-grid list-demo">
          <div className="p-col-12 p-lg-5">
            <div className="card">
              <h4>Generalidades</h4>
              <IndicatorPreviewItem
                title={"Nombre"}
                description={props.targetObject.name}
                onInputChange={props.onInputChange}
                name={"name"}
              />
              <IndicatorPreviewItem
                title={"Definición"}
                description={props.targetObject.definition}
                onInputChange={props.onInputChange}
                name={"definition"}
                helpInfo={"Información de ayuda para la definición."}
              />
              <IndicatorPreviewItem
                title={"Contexto"}
                description={props.targetObject.context}
                onInputChange={props.onInputChange}
                name={"context"}
                helpInfo={"Información de ayuda para el contexto."}
              />
              <IndicatorPreviewItem
                title={"Instrumentos"}
                description={props.targetObject.instruments}
                onInputChange={props.onInputChange}
                name={"instruments"}
                helpInfo={"Información de ayuda para los instrumentos."}
              />

              <IndicatorPreviewItem
                title={"Interpretación"}
                description={props.targetObject.interpretation}
                onInputChange={props.onInputChange}
                name={"interpretation"}
                helpInfo={"Información de ayuda para la interpretación."}
              />
            </div>

            <div className="card">
              <h4>Información Adicional</h4>
              <IndicatorPreviewItem
                title={"Periodicidad"}
                description={props.targetObject.periodicity}
                onInputChange={props.onInputChange}
                name={"periodicity"}
                helpInfo={"Información de ayuda para la periodicidad."}
              />
              <IndicatorPreviewItem
                title={"Unidad Territorial"}
                description={props.targetObject.territorial_unit}
                onInputChange={props.onInputChange}
                name={"territorial_unit"}
                helpInfo={"Información de ayuda para la unidad territorial."}
              />
              <IndicatorPreviewItem
                title={"Grupo social"}
                description={props.targetObject.social_group}
                onInputChange={props.onInputChange}
                name={"social_group"}
                helpInfo={"Información de ayuda para el grupo social."}
              />
            </div>
          </div>

          <div className="p-col-12 p-lg-7">
            <div className="card p-formgrid ">
              <h4>Operativización</h4>
              <h5 style={{ marginLeft: 15 }}>Ecuación</h5>

              <EquationEditor
                title={"Ecuación"}
                expression={props.targetObject.infix_expression}
                onInputChange={props.onInputChange}
                name={"infix_expression"}
              />

              <div className="card">
                <h5>Definición de Variables</h5>
                <IndicatorVariablesDefinition
                  name={"variables"}
                  data={props.targetObject?.variables}
                  onInputChange={props.onInputChange}
                  onElementChange={props.onElementChange}
                  variablesTypes={props.variablesTypes}
                />
              </div>
            </div>

            <div className="card">
              <IndicatorTags
                name={"tags"}
                data={props.targetObject?.tags}
                onInputChange={props.onInputChange}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CreateIndicatorDialog;
