import React from 'react';
import { SlideMenu } from 'primereact/slidemenu';
import { Menubar } from 'primereact/menubar';
import { useHistory } from 'react-router-dom';

const MenuME = ({ windowSize, setKey }) => {

    const history = useHistory();

    const items = [
        {
            label:'Modelo',
            icon:'pi pi-fw pi-home',
            style: { fontSize: '1.5rem' },
            command: () => { handleKey('model-dashboard') },
        },
        {   
            disabled: true,
            style: { borderTop: '1px solid gray' },
        },
        {
            label:'Procesos',
            icon:'pi pi-fw pi-sitemap',
            items:[
                {
                    level: 0,
                    label:'Dashboard',
                    icon:'pi pi-fw pi-home',
                    activeItem: true,
                    command: () => { handleKey('processes-dashboard') },
                },
                {
                    level: 0,
                    label:'Lista',
                    icon:'pi pi-fw pi-list',
                    activeItem: true,
                    command: () => { handleKey('processes-list') },
                },
                {
                    label:'Visualizador',
                    icon:'pi pi-fw pi-eye',
                    command: () => { handleKey('processes-viewer') },
                },
                {
                    label:'Editor',
                    icon:'pi pi-fw pi-pencil',
                    disabled: true,
                    command: () => { handleKey('processes-editer') },
                },
            ]
        },
        {
            label:'Actividades',
            icon:'pi pi-fw pi-check-circle',
            items:[
                {
                    label:'Dashboard',
                    icon:'pi pi-fw pi-home',
                    command: () => { handleKey('activities-dashboard') },
                },
                {
                    label:'Lista',
                    icon:'pi pi-fw pi-list',
                    command: () => { handleKey('activities-list') },
                },
            ]
        },
        {
            label:'Configuraciones',
            icon:'pi pi-fw pi-cog',
            command: () => { handleKey('settings') },
        },
        {   
            disabled: true,
            style: { borderTop: '1px solid gray' },
        },
        {
            label:'Regresar',
            icon:'pi pi-fw pi-arrow-left',
            command: () => { history.goBack() },
        },
    ];

    const handleKey = (key) => {
        setKey(key);
        history.push('?tab=' + key);
    }

    // Return template
    if (windowSize > 900) {
        return (
            <div className='p-col-2' style={{ maxWidth: '200px' }} >
                <SlideMenu 
                    style={{ border: '0px solid #fff'}} 
                    model={ items }
                    easing="ease-in"
                    effectDuration={ 700 }
                    viewportHeight={ 250 } 
                    menuWidth={ 175 } />
            </div>
        )
    } else {
        return (
            <div className='p-col-12'>
                <Menubar model={ items } />
            </div>
        )
    }
}

export default MenuME;