import React, { useEffect, useRef, useState, useCallback } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as keyTopicsAndTrendsActions from "../../../redux/actions/key-topics-and-trends.actions";
import { useDispatch } from "react-redux";
import { GenericDialog, TopicItems } from "../UI";
import { SelectButton } from "primereact/selectbutton";

const emptyTopic = {
  origin: "",
  evolution: "",
  theory: "",
  tools: "",
  implementation: "",
};

const KeyTopicsAndTrends = () => {
  const dispatch = useDispatch();
  const toast = useRef();
  const [keyTopicTrends, setKeyTopicTrends] = useState();
  const [keyTopicTrend, setKeyTopicTrend] = useState();
  const [topic, setTopic] = useState(emptyTopic);

  const [label, setLabel] = useState("");

  const [globalFilter, setGlobalFilter] = useState([]);
  const [openEditor, setOpenEditor] = useState(false);
  const [error, setError] = useState(null);

  const [submitted, setSubmitted] = useState(false);
  const [_topicPreviousVersion, _setTopicPreviousVersion] =
    useState(emptyTopic);

  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const options = [
    { label: "Tema clave", id: 1 },
    { label: "Tendencia", id: 2 },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0]);

  const onSelectOption = (e) => {
    setSelectedOption(e);
  };

  useEffect(() => {
    if (error) showMessage("error", "Error", error.message);
  }, [error]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const header = (
    <div className="table-header">
      <div style={{ display: "inline-block" }}>
        <h5 className="p-m-0 ">Listado de temas</h5>
      </div>

      <div style={{ display: "inline-block", float: "right" }}>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar..."
          />
        </span>
      </div>
    </div>
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">{rowData.label}</span>
      </>
    );
  };

  const typeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">
          {options.find((o) => o.id == rowData.topic_type).label}
        </span>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning p-mr-2"
          onClick={() => onLoadTopic(rowData.id)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger p-mr-2"
          onClick={() => onDeleteKeyTopicTrend(rowData.id)}
        />
      </div>
    );
  };

  const loadKeyTopicTrends = useCallback(async () => {
    try {
      const _topics_trends = await dispatch(
        keyTopicsAndTrendsActions.getConcepts()
      );
      setKeyTopicTrends(_topics_trends);
    } catch (error) {
      setError(error);
    }
  }, [keyTopicTrends]);

  useEffect(() => {
    setLoading(true);
    loadKeyTopicTrends().then(() => {
      setLoading(false);
    });
  }, []);

  const loadTopic = async (topicTrendId) => {
    try {
      const _topic = await dispatch(
        keyTopicsAndTrendsActions.getTopic(topicTrendId)
      );
      setKeyTopicTrend(keyTopicTrends.find((c) => c.id === topicTrendId));
      setTopic(_topic);
      _setTopicPreviousVersion(_topic);
    } catch (error) {
      setError(error);
    }
  };

  const onLoadTopic = (topicTrendId) => {
    setLoading(true);
    loadTopic(topicTrendId).then(() => {
      setLoading(false);
      setOpenEditor(true);
    });
  };

  const deleteKeyTopicTrend = async () => {
    setLoading(true);
    try {
      await dispatch(keyTopicsAndTrendsActions.deleteConcept(keyTopicTrend.id));
      setKeyTopicTrends([
        ...keyTopicTrends.filter((c) => c.label !== keyTopicTrend.label),
      ]);
    } catch (error) {
      setError(error);
    }
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const onDeleteKeyTopicTrend = (id) => {
    const _topicTrend = keyTopicTrends.find((c) => c.id === id);
    setKeyTopicTrend(_topicTrend);
    setDeleteDialogOpen(true);
  };

  const onCreateKeyTopicTrend = async (e) => {
    if (label.trim() === "") return;

    setLoading(true);

    const _keyTopicTrend = {
      topic_type: selectedOption?.id,
      label: label,
    };
    try {
      const _newKeyTopicTrend = await dispatch(
        keyTopicsAndTrendsActions.createConcept(_keyTopicTrend)
      );
      setKeyTopicTrends([...keyTopicTrends, _newKeyTopicTrend]);
      setSelectedOption(options[0]);
      setLabel("");
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const onHideEditor = (e) => {
    setOpenEditor(false);
    setKeyTopicTrend();
    setTopic(emptyTopic);
  };

  const hideDeleteDialog = (e) => {
    setDeleteDialogOpen(false);
    setKeyTopicTrend();
  };

  const onInputChange = (e, name) => {
    const val = e.htmlValue || "";
    let _keyTopicAndTrend = { ...topic };
    _keyTopicAndTrend[name] = val;
    setTopic(_keyTopicAndTrend);

    if (_topicPreviousVersion[name] !== _keyTopicAndTrend[name]) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  const onUpdateTopic = async (attribute) => {
    setSubmitted(true);
    setLoading(true);
    try {
      if (hasChanged) {
        await dispatch(
          keyTopicsAndTrendsActions.updateTopic(
            keyTopicTrend.id,
            attribute,
            topic
          )
        );
        setHasChanged(false);
        _setTopicPreviousVersion(topic);
        showMessage("success", "Éxito", "Guardado.");
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
    setSubmitted(false);
  };

  return (
    <>
      <div className="card-w-title">
        <Toast ref={toast} />
        <h3>Temas clave y tendencias</h3>
      </div>

      <GenericDialog
        visible={deleteDialogOpen}
        header={"Confirmar"}
        onCancelAction={() => {
          setDeleteDialogOpen(false);
        }}
        onConfirmationAction={deleteKeyTopicTrend}
        onHide={hideDeleteDialog}
        message={<span>¿Desea eliminar {keyTopicTrend?.label}?</span>}
        condition={keyTopicTrend}
      />

      <TopicItems
        open={openEditor}
        setOpen={onHideEditor}
        concept={keyTopicTrend}
        topic={topic}
        onSaveAttribute={onUpdateTopic}
        onInputChange={onInputChange}
        submitted={setSubmitted}
        hasChanged={hasChanged}
        title={
          <span>
            <b>{keyTopicTrend?.label}</b>
          </span>
        }
      />

      <div className="p-grid">
        <div className="p-col-12 p-lg-6 ">
          <div style={{ marginBottom: 0 }} className="card p-fluid">
            <h5>Construcción de temas</h5>
            <SelectButton
              value={selectedOption}
              onChange={(e) => onSelectOption(e.value)}
              options={options}
              optionLabel="label"
            />
          </div>
          <div className="card p-mb-2 p-fluid">
            <InputText
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              type="text"
              placeholder={`Ingrese el nombre de${
                selectedOption?.id === 1 ? "l tema clave." : " la tendencia."
              }`}
            ></InputText>
          </div>

          <div className="p-grid">
            <div className="p-col-12 p-lg-8 "></div>

            <div className="p-col-12 p-lg-4 ">
              <div
                className="card p-mb-2 p-fluid"
                style={{ justifyContent: "flex-end" }}
              >
                <Button
                  type="button"
                  label="Crear"
                  onClick={(e) => {
                    onCreateKeyTopicTrend(e);
                  }}
                  className="p-button-success"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12 p-lg-6 ">
          <div className="card">
            <DataTable
              // ref={props.dt}
              value={keyTopicTrends}
              dataKey="id"
              paginator
              rows={10}
              rowHover
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              globalFilter={globalFilter}
              emptyMessage="Todavía no se han creado temas clave y tendencias."
              header={header}
            >
              <Column
                field="label"
                header="Nombre"
                className="p-col-12 p-lg-6 "
                sortable
                body={nameBodyTemplate}
              />

              <Column
                field="topic_type"
                header="Tipo"
                className="p-col-12 p-lg-3 "
                sortable
                body={typeBodyTemplate}
              />

              <Column header="Construír" body={actionBodyTemplate} />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeyTopicsAndTrends;
