import React, { useState, useEffect, useRef } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { DataTable } from 'primereact/datatable';
import classNames from 'classnames';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { findAllSubsystems } from '../../../../api/data';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { useComplexSystems } from '../../../../redux/hooks/useComplexSystems';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Editor } from 'primereact/editor';
import Spinner from '../../../components/UI/spinner/Spinner';
import { createSystem, setSystemsDataAction, updateSystem } from '../../../../redux/actions/complex-systems.action';


const ComplexSystem = () => {

    const [ models, setModels ] = useState(null); //Data od table
    const [ modelsFilters, setModelsFilters ] = useState(null) //Data of table search filter
    const [ expandedRows, setExpandedRows ] = useState([]);
    const [ expandedSubRows, setExpandedSubRows ] = useState([]);
    const [ dropdownValue, setDropdownValue ] = useState(null); //Value of serach filter
    const [ levelValue, setLevelValue ] = useState(null); //Value of serach filter
    const [ dropdownValues, setDropdownValues ] = useState(null); //Value of serach filter
    const [ selectedAutoValue, setSelectedAutoValue ] = useState(null);
    const [ autoFilteredValue, setAutoFilteredValue ] = useState([]);
    const [ autoValue, setAutoValue ] = useState(null);
    const [personDialog, setPersonDialog] = useState(false);
    const [switchValue, setSwitchValue] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [system, setSystem] = useState(emptySystem);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.LoginState.data);
    
    const [persons, setPersons] = useState(null);
    const  toast  = useRef(null);
    const history = useHistory();
    const {listSystems,
        SystemsData,
        getSubsystemsByLevelAction,
        SystemsDataByLevel,
        insertSystem,
        modifySystem,loading} = useComplexSystems();
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        setSystem({
            ...system,
            [name] : val
        })
    };
    useEffect(() => {
        listSystems()
         // setModels(findAllSubsystems());
          //setModelsFilters(findAllSubsystems());
    }, []);

    useEffect(() => {

        if (dropdownValue) {

            console.log(modelsFilters);

            if (dropdownValue === 'm') {

                let data = []

                for (const model of modelsFilters) {
                    data.push({
                        name: model.name,
                        id: model.id,
                    });
                }
                setAutoValue( data );

            } else if (dropdownValue === 'p') {

                let data = []

                for (const model of modelsFilters) {
                    if (model?.processes) {
                        for (const process of model?.processes) {
                            data.push({
                                name: process.name,
                                id: process.id,
                            });
                        }
                    }
                }
                setAutoValue( data );

            } else if (dropdownValue === 'a') {

                let data = []

                for (const model of modelsFilters) {
                    if (model?.processes) {
                        for (const process of model?.processes) {
                            if (process?.activities) {
                                for (const activity of process?.activities) {
                                    data.push({
                                        name: activity.name,
                                        id: activity.id,
                                    });
                                }
                            }
                        }
                    }
                }
                setAutoValue( data );
            }
        } else {
            if (modelsFilters) {
                let data = []
                
                for (const model of modelsFilters) {
                    data.push({
                        name: model.name,
                        id: model.id,
                    });
                }
                setAutoValue( data );
            }
        }

    }, [ dropdownValue, modelsFilters ])

    useEffect(() => {
        if (selectedAutoValue) {
            setTableByFilters();
        }
    }, [ selectedAutoValue ])

    const savePerson = () => {
        setSubmitted(true);

        if (system.name.trim()) {

            if (isNew) {
                //update
                createSystem(system, user?.access_token).then(response => {
                    toast.current.show({ severity: 'success', summary: 'Subsistema Creado', detail: "Se ha insertado exitosamente el registro", life: 3000 });
                    dispatch(setSystemsDataAction(response))
                });
                

                
            } else {

                //_person.id = createId();
                //_persons.push(_person);
                console.log("UPDATE");
                updateSystem(system, user?.access_token).then(response => {
                    toast.current.show({ severity: 'success', summary: 'Subsistema Actualizado', detail: "Se ha actualizado exitosamente el registro", life: 3000 });
                    dispatch(setSystemsDataAction(response))
                });
                
            }
            //setPersons(_persons);
            setPersonDialog(false);
            setSystem(emptySystem);
        }
    };

    const reloadData = (event) => {
        listSystems()
        toast.current.show({ severity: 'info', summary: 'Información actualizada exitosamente', life: 3000 });
    }

    const searchCountry = (event) => {
        setTimeout(() => {
            if (!event.query.trim().length) {
                setAutoFilteredValue([...autoValue]);
            }
            else {
                setAutoFilteredValue(autoValue.filter((country) => {
                    return country.name.toLowerCase().includes(event.query.toLowerCase());
                }));
            }
        }, 100);
    };

    const onRowExpand = (event) => {
        toast.current.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
    };

    const onRowCollapse = (event) => {
        toast.current.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    };

    const expandAll = () => {
        let _expandedRows = {}
        models.forEach(m => _expandedRows[`${m.id}`] = true);

        setExpandedRows(_expandedRows);
        toast.current.show({ severity: 'success', summary: 'All Rows Expanded', life: 3000 });
    };

    const collapseAll = () => {
        setExpandedRows(null);
        toast.current.show({ severity: 'success', summary: 'All Rows Collapsed', life: 3000 });
    };
    const emptySystem = {
        id: 0,
        description: '',
        name: '',
        level: null,
        position:0,
        subsystem_parent_id: null,
    };
    const openNew = () => {
        setSystem(emptySystem);
        setIsNew(true);
        setLevelValue(null);
        setDropdownValue(null);
        setSubmitted(false);
        setPersonDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setPersonDialog(false);
    };
    const dialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={savePerson}/>
        </>
    );

    const setTableByFilters = () => {

        let data = []
        let rowData = {}
        let rowSubData = {}
        
        for (const model of models) {
            if (model?.processes) {
                for (const process of model?.processes) {
                    if (process?.activities) {
                        for (const activity of process?.activities) {
                            if (selectedAutoValue) {
                                for (const value of selectedAutoValue) {

                                    if (model?.name === value?.name) {
                                        rowData[model?.id] = true;
                                        if (!data.includes(model)) data.push(model);
                                    }
                                    
                                    if (process?.name === value?.name || activity?.name === value?.name) {
                                        rowData[model?.id] = true;
                                        rowSubData[process?.id] = true;
                                        if (!data.includes(model)) data.push(model);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        setExpandedSubRows(rowSubData);
        setExpandedRows(rowData);
        if (data.length > 0) setModels(data);
        else setModels(findAllSubsystems());
    }

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const statusBodyTemplate = (data) => {

        const status = (data.status || data.status === 1) ? 'instock' : 'outofstock';
        const statusValue = (data.status) ? 'Active' : 'Inactive';

        return (
            <>
                <span className="p-column-title">Status</span>
                <span className={`product-badge status-${status}`}>{statusValue}</span>
            </>
        )
    };



    const levelValues = [
        { name: 'Nivel 1', value: 1, },
        { name: 'Nivel 2', value: 2, },
        { name: 'Nivel 3', value: 3, },
    ];

    const modelsTableHeader = (
        <>
            <Toolbar
                className="p-p-0 p-m-0"
                style={{
                    border: '0px'
                }}
                left={
                    <div className="table-header-container p-d-block">
                        <Button className="p-mr-1 p-button-info" icon="pi pi-plus" label="Expandir Todo" onClick={ expandAll } />
                        <Button className="p-mr-1 p-button-warning" icon="pi pi-minus" label="Colapsar Todo" onClick={ collapseAll } />
                    </div>
                }
                right={
                    <div className="table-header-container p-d-block">
                        <Dropdown 
                            className="p-mr-1" style={{ width: '115px' }} value={ dropdownValue } 
                            onChange={(e) => setDropdownValue(e.value)} options={ dropdownValues } 
                            optionLabel="name" placeholder="Buscar por:" />

                        <AutoComplete 
                            className="p-mr-1" multiple placeholder="Buscar" id="dd" 
                            dropdown value={ selectedAutoValue } 
                            onChange={(e) => setSelectedAutoValue(e.value)}
                            suggestions={ autoFilteredValue } completeMethod={ searchCountry } field="name" />

                        <Button className="p-button-rounded" icon="pi pi-refresh" label="Refrescar" onClick={ reloadData } />
                    </div>
                } />
        </>
    );

    const imageBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${data.img}`} alt={data.img} className="product-image" />
                <div>
                    <Rating value={data.qualification} readonly cancel={false} />
                </div>
            </>
        );
    };

    const nameBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                <h4>{data.name}</h4>
                {/*<span style={{ color: '#00acee' }}>#{data?.hashtag}</span>*/}
            </>
        );
    };


    const modelButtonsTemplate = (data) => {

        const editSubsystem = (p) =>{
            console.log(p);
            setIsNew(false);
            setSystem({...p})
            //setPlan({...p});
            setPersonDialog(true);
          };

        return (
            <>
                <Button onClick={ ()=>editSubsystem(data) } icon="pi pi-plus" className="p-button-info p-button-rounded p-mx-1" label="" placeholder="Nuevo Subsistema" />
                <Button onClick={ ()=>editSubsystem(data) } icon="pi pi-pencil" className="p-button-info p-button-rounded p-mx-1" label="" placeholder="Editar" />
            </>
        );
    }

    const processButtonsTemplate = (data) => {

        const editSubsystem = (p) =>{
            console.log(p.subsystem_parent_id);
            setIsNew(false);
            setSystem({...p})
            setLevelValue(p.level)
            getSubsystemsByLevelAction(p.level)
            setDropdownValue(p.subsystem_parent_id)
            
            //setPlan({...p});
            setPersonDialog(true);
          };

        return (
            <>
                <Button onClick={ ()=>editSubsystem(data) } icon="pi pi-plus" className="p-button-info p-button-rounded p-mx-1" label="" placeholder="Nuevo Subsistema" />
                <Button onClick={ ()=>editSubsystem(data) } icon="pi pi-pencil" className="p-button-info p-button-rounded p-mx-1" label="" placeholder="Editar" />
            </>
        );
    }

    const activityButtonsTemplate = (data) => {

        const handleButton = () => {
            history.push({
                pathname: '/admin/build-form',
                state: {
                    process: data,
                },
            });
        }

        const handleViewButton = () => {
            history.push({
                pathname: '/admin/render-form-schema',
                state: {
                    activity: data,
                },
            });
        }

        return (
            <div style={{ textAlign: 'center' }}>
                {
                    (data?.schema) &&
                        <Button 
                            onClick={ handleViewButton } icon="pi pi-eye" 
                            className="p-button-info p-button-rounded p-mx-1" 
                            title="View Form" />
                }
                <Button 
                    onClick={ handleButton } icon={ `pi pi-${ (data?.schema) ? 'pencil' : 'plus' }` } 
                    className={ `p-button-rounded p-button-${ (data?.schema) ? 'warning' : 'success' }` }
                    title={ (data?.activities?.length > 0) ? 'Edit Form' : 'New Form' } />
            </div>
        );
    }

    const rowSubExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Subsistemas nivel 3 de: <a>{data?.name}</a></h5>
                <DataTable value={data?.activities} >
                    <Column field="name" header="Nombre" sortable body={bodyTemplate}></Column>
                    <Column field="description" header="Descripción" sortable body={bodyTemplate}></Column>
                    <Column style={{ width: '10rem' }} field="status" header="Estado" sortable body={ statusBodyTemplate }></Column>
                    <Column style={{ width: '15rem' }} body={ activityButtonsTemplate }></Column>
                </DataTable>
            </div>
        );
    };

    const rowExpansionTemplate = (data) => {
        if(data?.subsystems_level2.length>0){
            return (

               <div className="orders-subtable" >
                   <h5>Subsistemas nivel 2 de: <a>{ data?.name }</a></h5>
                   <DataTable value={ data?.subsystems_level2 } expandedRows={ expandedSubRows } className="p-datatable-customers" dataKey="id" onRowToggle={(e) => setExpandedSubRows(e.data)} rowExpansionTemplate={ rowSubExpansionTemplate }>
                       <Column expander headerStyle={{ width: '3rem' }} />
                       <Column field="name" header="Nombre" sortable body={bodyTemplate}></Column>
                       <Column field="description" header="Descripción" sortable body={bodyTemplate}></Column>
                       <Column style={{ width: '10rem' }} field="status" header="Estado" sortable body={statusBodyTemplate}></Column>
                       <Column style={{ width: '15rem' }} body={ processButtonsTemplate }></Column>
                   </DataTable>
               </div>
           );
        }
        else{
            return (

                <div className="orders-subtable" >
                    <h5>No existen Subsistemas nivel 2 de: <a>{ data?.name }</a></h5>

                </div>
            );
        }
        
    };

    const onChangeLevel = (e) =>{
        console.log("LEVEL CHANGED");
         setLevelValue(e.value)
         setSystem(
             {...system,
             level:e.value}
         )
         if (parseInt(e.value)>1)
            getSubsystemsByLevelAction(e.value)
            if (!isNew)
                setDropdownValue(system.subsystem_parent_id)

        
    }

    const onChangeSubsystem=(e) => {
        console.log(e)
        setDropdownValue(e.value)
        setSystem({
            ...system,
            subsystem_parent_id:e.value
        })
    }
    const leftToolbarTemplate = () => {
        return (
         
          <>
          <Button label="Nuevo" icon="pi pi-check" className="p-button-alert" onClick={openNew} />
      </>
           
        )
    }
    
    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help"  />
            </>
        )
    }
    if (loading) {
        return (
            <Spinner/>
        )
    }

    return (
        <>
        <div className="p-col-12">
        <div className="card">
            <div style={{ textAlign: 'left' }}>
            


              
                <Toolbar className="p-mb-4 p-toolbar" left={ leftToolbarTemplate } right={ rightToolbarTemplate }></Toolbar>

            </div>
        </div>
    </div>
        <div className="p-grid table-demo">

            <div className="p-col-12">
                <div className="card">
                    <a><h2 style={{ textAlign: 'center' }}>Sistemas complejos</h2></a>
                    <Toast ref={ toast } />
                    <DataTable value={ SystemsData } expandedRows={ expandedRows } className="p-datatable-customers" dataKey="id" onRowToggle={(e) => setExpandedRows(e.data)} onRowExpand={ onRowExpand } onRowCollapse={ onRowCollapse }
                        header={ modelsTableHeader } rowExpansionTemplate={ rowExpansionTemplate }>
                        <Column expander headerStyle={{ width: '3rem' }} />
               
                        <Column field="name" header="Nombre" body={ nameBodyTemplate }></Column>
                        <Column field="desc_small" header="Descripción" body={ bodyTemplate }></Column>
                        <Column style={{ width: '10rem' }} field="status" header="Estado" sortable body={ statusBodyTemplate }></Column>
                        <Column style={{ width: '15rem' }} body={ modelButtonsTemplate }></Column>
                    </DataTable>
                </div>
            </div>

        </div>
        <Dialog visible={personDialog} style={{width: '500px'}} header="Ingrese los datos del Subsistema" modal
                                className="p-fluid" footer={dialogFooter} onHide={hideDialog}>
                            <div className="p-field">
                                <label htmlFor="name">Nombre</label>
                                <InputText id="name" value={system?.name} onChange={(e) => onInputChange(e, 'name')}
                                           required autoFocus
                                           className={classNames({'p-invalid': submitted && !system?.name})}/>
                                {submitted && !system?.name && <small className="p-invalid">Name is required.</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="description">Descripción</label>
                                <Editor id="description" name="description" style={{height:'320px'}} value={system?.description} onTextChange={(e) => setSystem({...system, description: e.htmlValue })}/>

                                {submitted && !system?.description &&
                                <small className="p-invalid">Last Name is required.</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="position">Poisición</label>
                                <InputText id="position" value={system?.position} onChange={(e) => onInputChange(e, 'position')}
                                           required autoFocus type="number" placeholder="Ej: 1,2,3"
                                           className={classNames({'p-invalid': submitted && !system?.name})}/>
                                {submitted && !system?.position && <small className="p-invalid">Name is required.</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="email">Nivel</label>
                                
                            <Dropdown 
                            className={classNames({'p-invalid': submitted && !system?.level})}  value={ levelValue } 
                            onChange={onChangeLevel} options={ levelValues } 
                            optionLabel="name" placeholder="Elija un nivel:" />
                                {submitted && !system?.email && <small className="p-invalid">Email is required.</small>}
                            </div>
                            
                            
                            {(levelValue === 2 || levelValue === 3) && <div className="p-field">
                                <label htmlFor="gender">Subsistema Padre</label>
                                <Dropdown 
                            className={classNames({'p-invalid': submitted && !system?.subsystem_parent_id})}  value={ dropdownValue } 
                            onChange={onChangeSubsystem} options={ SystemsDataByLevel } 
                            optionLabel="name" placeholder="Elija un subsistema:" />
                                {submitted && !system?.gender &&
                                <small className="p-invalid">Gender is required.</small>}
                            </div>}
                            <div className="p-field">
                                <label htmlFor="status" className="p-mr-2">Estado</label>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <InputSwitch id="status" className="p-mr-2" checked={switchValue}
                                                 onChange={(e) => setSwitchValue(e.value)} autoFocus/>
                                    <i>{(switchValue) ? 'Activo' : 'Inactivo'}</i>
                                </div>
                            </div>
                        </Dialog>
        </>
    )
}

export default ComplexSystem
