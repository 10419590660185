import axios from 'axios';
import { createAction } from 'redux-actions';
import * as urls from '../../api/urls';

export const setUnitTimeAction = createAction('SET_UNIT_TIME_ACTION');
export const setUIAction = createAction('SET_UI_ACTION');
export const setAttributeUIAction =  createAction('SET_ATTRIBUTE_UI_ACTION');
export const resetUserPreferencesAction = createAction('RESET_USER_PREFERENCES_ACTION');

export const retrieveUserPreferencesByUser = async (user_id, token) => {

    const response = await axios.get(urls.urlRetrieveUserPreferencesByUser + user_id, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const createUserPreferences = async (data, token) => {

    const response = await axios.post(urls.urlCreateUserPreferences, data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}

export const updateUserPreferences = async (id, data, token) => {

    const response = await axios.put(urls.urlUpdateUserPreferences + id, data, {
        headers: {
            Authorization: 'Bearer ' + token,
        }
    });

    return response.data;
}