import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'primereact/progressbar';
import { Chip } from 'primereact/chip';
import { getStatusCls } from '../../utils/helpers/monitoringHelpers';
import { useSelector } from 'react-redux';
import { listAssigmentByActivity } from '../../../../redux/actions/acquireds/activity.assignment.actions';
import img from '../../../assets/img/avatar-6.png';

const ActivityCard = ({ activity }) => {

    const user = useSelector((state) => state.LoginState.data);
    const [ assigneds, setAssigneds ] = useState([])

    useEffect(() => {
        if (activity?.id) {
            listAssigmentByActivity(activity?.id, user?.access_token).then(assigments => {
                setAssigneds(assigments);
            })
        }
    }, [ user, activity ])
    
    const getStatusMsg = (status) => {

        if (status === 0) {
            return 'No asignado'
        } else if (status === 1) {
            return 'No iniciado';
        } else if (status === 2) {
            return 'En ejecucuión';
        } else if (status === 3) {
            return 'FInalizado';
        }
    }

    return (
        <div className='p-col-12 p-sm-6 p-lg-6 p-xl-4'>
            <div className='card' style={{ borderRadius: '10px', border: '1px solid gray', height: '100%' }} >
                
                <h6 className='p-text-center'>
                    { activity?.name } 
                    <span className={'p-ml-1 status-' + getStatusCls(activity?.assignment_status) }>{ getStatusMsg(activity?.assignment_status) }</span>
                </h6>

                <div>
                    <div style={{ paddingBottom: '3px' }} >Progreso</div>
                    <ProgressBar value={ activity?.progress } showValue={ true } />
                </div>

                <div className='p-mt-3'>
                    <div style={{ paddingBottom: '5px' }} >Integrantes</div>
                    <div className="p-d-flex p-ai-center p-flex-wrap" style={{ overflow: 'auto', maxHeight: '100px' }}>
                        {
                            assigneds.map((assigned, i) => {
                                return (
                                    <Chip
                                        key={ i }
                                        label={`${ assigned?.user?.username }`}
                                        image={ img }
                                        className="p-mr-2 p-mb-2"  />
                                );
                            })
                        }
                    </div>
                </div>

                <div className="schedule p-mt-2">
                    <ul className='p-grid'>
                        <li className='p-col p-mr-1'>
                            <div className="schedule-header">
                                <div>Duración</div>
                            </div>
                            <span>{ activity?.duration } { activity?.unit_time_type?.label }</span>
                        </li>
                        <li className='p-col p-mr-1'>
                            <div className="schedule-header">
                                <div>Presupuesto</div>
                            </div>
                            <span>{ activity?.budget } dólares</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

ActivityCard.propTypes = {
    activity: PropTypes.object.isRequired,
}

export default ActivityCard