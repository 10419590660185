import * as actions from '../actions/category.actions';

const {handleActions} = require('redux-actions');

const initialState ={
    loading:false,
    data:null,
    error:null,
    loadingDropdown:false,
    dataDropdown:null,
    errorDropdown:null
};

export const CategoriesReducer = handleActions(
    {
        [actions.setLoadingCategoriesAction]: (state,{payload}) =>({
            ...state,
            loading:payload
        }),
        [actions.setCategoriesDataAction]: (state,{payload}) =>({
            ...state,
            data:payload
        }),
        [actions.setCategoriesErrorAction]: (state,{payload}) =>({
            ...state,
            error:payload
        }),

        [actions.setLoadingCategoriesDropdownAction]: (state,{payload}) =>({
            ...state,
            loadingDropdown:payload
        }),
        [actions.setCategoriesDropdownDataAction]: (state,{payload}) =>({
            ...state,
            dataDropdown:payload
        }),
        [actions.setCategoriesDropdownErrorAction]: (state,{payload}) =>({
            ...state,
            errorDropdown:payload
        }),
    },
    initialState,
);
