import { SlideMenu } from "primereact/slidemenu";
import { WizardItem } from "../../types/UI";

type SideMenuProps = {
  tabSelected: (arg: number) => void;
};

const SideMenu = ({ tabSelected }: SideMenuProps) => {
  // const history = useHistory();

  const tabChangeHandler = (tab: number) => {
    tabSelected(tab);
  };

  const wizardItems: WizardItem[] = [
    {
      id: 0,
      label: "Historia Sistémica",
      icon: "pi pi-th-large",
      command: function (e) {
        tabChangeHandler(e.item?.id);
      },
    },
    {
      id: 1,
      label: "Variables e Indicadores",
      icon: "pi pi-share-alt",
      command: function (e) {
        tabChangeHandler(e.item?.id);
      },
    },
    {
      id: 2,
      label: "Mapa Sistémico",
      icon: "pi pi-sitemap",
      command: function (e) {
        tabChangeHandler(e.item?.id);
      },
    },
    {
      id: 3,
      label: "Interacciones con el Sistema Territorial",
      icon: "pi pi-list",
      command: function (e) {
        tabChangeHandler(e.item?.id);
      },
    },
    {
      id: 4,
      label: "Sistema de Problemas",
      icon: "pi pi-comments",
      command: function (e) {
        tabChangeHandler(e.item?.id);
      },
    },
    {
      id: 5,
      label: "Solución Sistémica",
      icon: "pi pi-tablet",
      command: function (e) {
        tabChangeHandler(e.item?.id);
      },
    },
    {
      id: 6,
      label: "Procesos de Solución",
      icon: "pi pi-folder-open",
      command: function (e) {
        tabChangeHandler(e.item?.id);
      },
    },
    {
      label: "Salir",
      icon: "pi pi-times",
      command: function () {
        // history.goBack();
      },
    },
  ];

  return (
    <div style={{ height: "100%", width: "15%" }}>
      <SlideMenu
        model={wizardItems}
        viewportHeight={700}
        menuWidth={200}
        style={{ width: "13%", position: "fixed" }}
      />
    </div>
  );
};

export default SideMenu;
