import React, { useEffect, useState } from 'react';
import ReactJoyride, { EVENTS } from 'react-joyride';
import logo from '../assets/img/gearth-logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { getMembersById } from '../../redux/actions/team.actions';
import { getModelsByTeam } from '../../redux/actions/model.actions';
import { updateUserTutorial } from '../../redux/actions/user.action';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { getActivityAUD } from '../../redux/actions/aud.actions';

export const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.LoginState?.data);

  const { t } = useTranslation(['global']);
  const [run, setRun] = useState(false);
  const [team, setTeam] = useState([]);
  const [models, setModels] = useState([]);
  const [activityEvents, setActivityEvents] = useState([]);
  const [over, setOver] = useState({
    card1: false,
    card2: false,
    card3: false,
  });

  const loadData = () => {
    // getActivityAUD(user?.access_token).then(data => {
    //     if (data.length > 0) setActivityEvents(data);
    // });
  };

  useEffect(() => {
    loadData();
  }, [user]);

  const steps = [
    {
      content: (
        <>
          <h5
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Bienvenido a{' '}
            <img
              src={logo}
              style={{ width: '7rem', height: 'auto' }}
              alt="gearth-logo"
            />
          </h5>
          <br />
          <p>Antes de iniciar completa los siguientes pasos</p>
        </>
      ),
      locale: { skip: <p aria-label="skip">Dissmis</p> },
      placement: 'center',
      target: 'body',
    },
    {
      content: (
        <div>
          Por favor complete su perfil, asi lo podremos conocer mejor
          <br />
          ¡Haz click en Ir a Perfil!
        </div>
      ),
      disableBeacon: true,
      locale: { skip: <p aria-label="skip">Dissmis</p> },
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: 'auto',
      spotlightClicks: true,
      target: '.notification span',
      title: 'Perfil',
    },
  ];

  let d = null; // Variable a utilizar para guardar el estado del tutorial del usuario.

  const membersOfTeam = async () => {
    getMembersById(user?.team?.id, user?.access_token).then((response) => {
      setTeam(response);
    });
  };

  const modelsOfTeam = async () => {
    getModelsByTeam(user?.team?.id, user?.access_token).then((response) => {
      setModels(response);
    });
  };

  const updateTutorial = (d) => {
    if (d === 200) {
      console.log('Cambio en el Redux actualizado');
    } else {
      console.log('Error al actualizar el estado del tutorial');
    }
  };

  const chargeData = async () => {
    membersOfTeam();
    modelsOfTeam();

    if (!user?.user_complete_tutorial && user?.role?.name === 'Test') {
      d = await dispatch(
        updateUserTutorial(user?.id, user?.access_token, user)
      );
    } else if (!user?.user_complete_tutorial && user?.role?.name !== 'Test') {
      setRun(true);
    }
  };

  useEffect(() => {
    chargeData();
  }, [user]);

  const handleJoyrideCallback = async (data) => {
    const { type, action } = data;

    if (action === 'close' && run) {
      setRun(false);
      d = await dispatch(
        updateUserTutorial(user?.id, user?.access_token, user)
      );
      updateTutorial(d);
    }

    if (type === EVENTS.TOUR_END && run) {
      setRun(false);
      d = await dispatch(
        updateUserTutorial(user?.id, user?.access_token, user)
      );
      updateTutorial(d);
    }
  };

  const handleOver = (cardNumber, status) => {
    setOver({
      ...over,
      [cardNumber]: status,
    });
  };

  const getColorEvent = (event) => {
    if (event === 'insert') return 'green';
    else if (event === 'update') return 'orange';
    else if (event === 'delete') return 'red';
    else return 'blue';
  };

  const getNameEvent = (event) => {
    if (event === 'insert') return 'Created';
    else if (event === 'update') return 'Updated';
    else if (event === 'delete') return 'Deleted';
    else return '';
  };

  const getDescEvent = (event, new_value = '', old_value = '') => {
    if (event === 'insert') return new_value;
    else if (event === 'update') return old_value + ' -> ' + new_value;
    else if (event === 'delete') return old_value;
    else return '';
  };

  const getTimePassed = (time) => {
    const created = new Date(Date.parse(time));
    const date = new Date();

    const diference = date.getTime() - created.getTime();
    const minutes = Math.round(diference / 1000 / 60);
    const hours = (minutes * 1) / 60;
    const days = (hours * 1) / 24;

    if (minutes < 1) {
      return '1 min ago';
    } else if (minutes < 60) {
      return minutes + ' mins ago';
    } else if (minutes < 1440) {
      return Math.round(hours) + ' hours ago';
    } else if (minutes < 14400) {
      return Math.round(days) + ' days ago';
    } else {
      return time.split('T')[0];
    }
  };

  return (
    <div className="layout-dashboard">
      {/* <ReactJoyride
                continuous
                scrollToFirstStep
                //showProgress
                showSkipButton
                run={run}
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                        backgroundColor:'#545B67',
                        textColor:'#fff',
                        primaryColor: '#6A6FD1',
                    },
                }}
                callback={handleJoyrideCallback} /> */}

      <div className="p-grid">
        <div className="p-col-12 desktop-teams">
          <div className="card team">
            <div className="card-header">
              <div className="card-title">
                <h3 className="p-d-inline-block p-mr-3">{user?.team?.name}</h3>
                <span className="status-info">
                  <span className="p-mr-1">{team.length}</span>
                  <span>
                    {team.length === 1
                      ? t('vocabulary.singular.member')
                      : t('vocabulary.plural.members')}
                  </span>
                </span>
              </div>
            </div>
            <div className="peoples people-container">
              {team.map((member, i) => (
                <div key={i}>
                  <img
                    className="p-d-block p-ml-1"
                    src="assets/demo/images/dashboard/avatar/avatar-4.png"
                    alt="member"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-col-12 p-p-6">
          <div className="p-grid" style={{ margin: '-1rem' }}>
            <div className="p-col">
              <div
                onMouseOver={() => handleOver('card1', true)}
                onMouseLeave={() => handleOver('card1', false)}
                className="card overview-box white cursor-pointer"
              >
                <div className="overview-info">
                  <h6>Mis Modelos</h6>
                  <h1>{models.length}</h1>
                </div>
                <i className="pi pi-share-alt"></i>
              </div>
            </div>

            <div className="p-col">
              <div
                onClick={() => history.push('/admin/members')}
                onMouseOver={() => handleOver('card2', true)}
                onMouseLeave={() => handleOver('card2', false)}
                className="card overview-box blue cursor-pointer"
              >
                <div className="overview-info">
                  <h6>Mi Equipo ({user.team.name})</h6>
                  <h1>{team.length}</h1>
                </div>
                <i className={`pi pi-users`}></i>
              </div>
            </div>

            <div className="p-col">
              <div
                onMouseOver={() => handleOver('card3', true)}
                onMouseLeave={() => handleOver('card3', false)}
                className="card overview-box darkgray cursor-pointer"
              >
                <div className="overview-info">
                  <h6>Datos levantados</h6>
                  <h1>96%</h1>
                </div>
                <i className={`pi pi-th-large`}></i>
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12 p-md-6 p-xl-3">
          <div className="card timeline">
            <div className="card-header">
              <div className="card-title">
                <h6>Actividad reciente</h6>
                <p
                  className="subtitle p-d-flex"
                  style={{ alignItems: 'center' }}
                >
                  Actualizado al 21/09/2021
                  <Button
                    className="p-button-text p-button-rounded p-ml-1"
                    icon="pi pi-refresh"
                    tooltip="Refresh"
                    onClick={loadData}
                  />
                </p>
              </div>
            </div>
            <ul>
              {activityEvents.map((activity) => (
                <li
                  key={activity?.id}
                  className={getColorEvent(activity?.action)}
                >
                  <i className="pi pi-circle-on"></i>
                  <div className="event-content">
                    <span className="event-title">
                      Activity {getNameEvent(activity?.action)}
                    </span>
                    <span>
                      {getDescEvent(
                        activity?.action,
                        activity?.new_value,
                        activity?.old_value
                      )}
                    </span>
                    <span className="time">
                      {getTimePassed(activity?.created_at)}
                    </span>
                  </div>
                </li>
              ))}
              <li className="blue">
                <i className="pi pi-circle-on"></i>
                <div className="event-content">
                  <span className="event-title">Nueva Actividad</span>
                  <span>Pedro Zeas ha creado una nueva actividad</span>
                  <span className="time">Hace 3 mins</span>
                </div>
              </li>
              <li className="blue">
                <i className="pi pi-circle-on"></i>
                <div className="event-content">
                  <span className="event-title">Log</span>
                  <span>Correo enviado a admin@gearth.science</span>
                  <span className="time">Hace 12 mins</span>
                </div>
              </li>
              <li className="green">
                <i className="pi pi-circle-on"></i>
                <div className="event-content">
                  <span className="event-title">Nuevo MIT</span>
                  <span>
                    Reached 80% CPU capacity in Ireland. Automatic capacity
                    increase initiated.
                  </span>
                  <span className="time">1:30PM</span>
                </div>
              </li>
              <li className="orange">
                <i className="pi pi-circle-on"></i>
                <div className="event-content">
                  <span className="event-title">Capacity</span>
                  <span>Reached 60% CPU capacity in Ireland.</span>
                  <span className="time">9:40AM</span>
                </div>
              </li>
              <li className="blue">
                <i className="pi pi-circle-on"></i>
                <div className="event-content">
                  <span className="event-title">Billing</span>
                  <span>Upgraded plan, 10users yearly to 20users yearly</span>
                  <span className="time">7:42AM</span>
                </div>
              </li>
              <li className="blue">
                <i className="pi pi-circle-on"></i>
                <div className="event-content">
                  <span className="event-title">New Sale</span>
                  <span>
                    Richard Jones has purchased a blue t-shirt for $79.
                  </span>
                  <span className="time">3 mins ago</span>
                </div>
              </li>
            </ul>
            <button className="p-link">See all</button>
          </div>
        </div>
      </div>
    </div>
  );
};
