import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditFieldDialog from '../../../components/Profile/EditFieldDialog';
import ProfileField from '../../../components/Profile/ProfileFile';
import { useHistory } from 'react-router-dom';
import { Toolbar } from 'primereact/toolbar';
import Joyride, { EVENTS ,STATUS } from 'react-joyride';

const RedirectField = (route, msg, icon = 'arrow-right') => {

    const history = useHistory();

    const handleRedirectBtn = () => {
        history.push(route);
    }

    return (
        <>
            <button onClick={ handleRedirectBtn } className="p-button p-component p-button-info p-button-text p-mr-2 p-mb-2">
                <span className={ `p-button-icon p-c pi pi-${icon} p-button-icon-right` }></span>
                <span className="p-button-label p-c">{ msg }</span>
            </button>
        </>
    )
}

const ProfileUser = (props) => {

    const [ values, setValues ] = useState(props.user);
    const [ verify, setVerify ] = useState(false);

    React.useEffect(()=>{
      if (props.v) {
        setVerify(true);
      }
    },[props.v])

    const steps = [
      {
        content: (
          <div>
            Ahora crea tu equipo de trabajo, invita a miembros a trabajar en tu grupo
            <br />
            ¡Haz click aqui!
            </div>
          ),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: 'auto',
        spotlightClicks: true,
        target: '.listmembers span',
        title: 'Equipo de trabajo',
    },
  ];
    const handleJoyrideCallback = (data) => {
      const { type } = data;

      if (type === EVENTS.TOUR_END && verify) {
        setVerify(false);
      }
  };

    return (
        <>
          <Joyride
          continuous
          scrollToFirstStep
          //showProgress
          showSkipButton
          run={verify}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
              backgroundColor:'#545B67',
              textColor:'#fff',
              primaryColor: '#6A6FD1',
            },
          }}
          callback={handleJoyrideCallback}
          />
            <Toolbar
                className="p-mb-4 p-toolbar"
                left={ <ProfileField field={ props?.user?.username } label="Username" /> }
                right={
                    <EditFieldDialog
                        values={ values } setValues={ setValues }
                        label='Username' fieldName="username" />
                }>
            </Toolbar>

            <Toolbar
                className="p-mb-4 p-toolbar"
                left={ <ProfileField field="***********" label="Password" icon="lock" /> }
                right={
                    <EditFieldDialog
                        values={ values } setValues={ setValues }
                        label='Password' fieldName="password" />
                }>
            </Toolbar>

            <Toolbar
                className="p-mb-4 p-toolbar"
                left={ <ProfileField field={ props?.user?.role?.name } label="Role" icon="briefcase" /> }
                right={
                    <EditFieldDialog
                        values={ values } setValues={ setValues }
                        label='Role' fieldName="role" />
                }>
            </Toolbar>

            <Toolbar
                className="p-mb-4 p-toolbar listmembers"
                left={ <ProfileField field={ props?.user?.team?.name } label="Team" icon="users" />}
                right={ RedirectField('/admin/members', 'View')} >
            </Toolbar>

            {
                (props?.user?.role?.id === 1 || props?.user?.role?.id === 2) &&
                    <Toolbar
                        className="p-mb-4 p-toolbar"
                        left={ <ProfileField field={ props?.user?.team?.plan?.name } label="Subscription Plan" icon="dollar" /> }
                        right={ <span>{RedirectField('/pages/changeplan', 'Update')}</span> } >
                    </Toolbar>
            }

            {
                (props?.user?.role?.id === 1 || props?.user?.role?.id === 2) &&
                    <Toolbar
                        className="p-mb-4 p-toolbar"
                        left={ <ProfileField field="PayPal" label="Pay Method" icon="id-card" /> }
                        right={ RedirectField('#', 'View') } >
                    </Toolbar>
            }
        </>
    );
}

ProfileUser.propTypes = {
    user: PropTypes.object.isRequired
}

export default ProfileUser;
