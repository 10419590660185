import React from 'react';
import PropTypes from 'prop-types';

const PlanInfo = ({ plan }) => {

    return (
        <div className='p-card p-px-3 p-px-md-5 p-px-lg-6 p-px-xl-6 p-py-3' >
            <div>
                <h3 className='p-text-center color-primary'>{plan?.name}</h3>
            </div>
            <div className='p-grid'>
                <div className='p-col-1'>
                    <i className={`pi pi-calendar status-${(!plan?.monthly_updates) ? 'danger':'success' }`}></i>
                </div>
                <div className='p-col-6 p-text-bold' style={{ fontSize: '1.3rem' }}>
                    <p className={`${(!plan?.monthly_updates) && 'text-line-through'}`} >
                        Monthly Updates
                    </p>
                </div>
                <div className='p-col-5'>
                    <p>
                        {
                            (plan?.monthly_updates) ?
                            <i className="pi pi-check"></i>
                            :
                            <i className="pi pi-times unavailable"></i>
                        }
                    </p>
                </div>
            </div>
            <div className='p-grid'>
                <div className='p-col-1'>
                    <i className='pi pi-inbox status-success'></i>
                </div>
                <div className='p-col-6 p-text-bold' style={{ fontSize: '1.3rem' }}>
                    <p>Disk Space</p>
                </div>
                <div className='p-col-5'>
                    <p>
                        <span>{ plan?.disk_space } GB</span>
                    </p>
                </div>
            </div>
            <div className='p-grid'>
                <div className='p-col-1'>
                    <i className='pi pi-envelope status-success'></i>
                </div>
                <div className='p-col-6 p-text-bold' style={{ fontSize: '1.3rem' }}>
                    <p className='p-text-underline'>Email Accounts</p>
                </div>
                <div className='p-col-5'>
                    <p>
                        <span>{ plan?.email_accounts }</span>
                    </p>
                </div>
            </div>
            <div className='p-grid'>
                <div className='p-col-1'>
                    <i className='pi pi-bars status-success'></i>
                </div>
                <div className='p-col-6 p-text-bold' style={{ fontSize: '1.3rem' }}>
                    <p>Domains</p>
                </div>
                <div className='p-col-5'>
                    <p>
                        <span>{ plan?.domains }</span>
                    </p>
                </div>
            </div>
            <div className='p-grid'>
                <div className='p-col-1'>
                    <i className='pi pi-align-left status-success'></i>
                </div>
                <div className='p-col-6 p-text-bold' style={{ fontSize: '1.3rem' }}>
                    <p>Sub Domains</p>
                </div>
                <div className='p-col-5'>
                    <p>
                        <span>{ plan?.sub_domains }</span>
                    </p>
                </div>
            </div>
            <div className='p-grid'>
                <div className='p-col-1'>
                    <i className={`pi pi-pencil status-${(plan?.custom_domains) ? 'success':'danger' }`}></i>
                </div>
                <div className='p-col-6 p-text-bold' style={{ fontSize: '1.3rem' }}>
                    <p className={`${(!plan?.custom_domains) && 'text-line-through'}`} >
                        Custom Domains
                    </p>
                </div>
                <div className='p-col-5'>
                    <p>
                        {
                            (plan?.custom_domains) ?
                            <i className="pi pi-check"></i>
                            :
                            <i className="pi pi-times unavailable"></i>
                        }
                    </p>
                </div>
            </div>
            <div className='p-grid'>
                <div className='p-col-1'>
                    <i className={`pi pi-cog status-${(plan?.technical_support) ? 'success':'danger' }`}></i>
                </div>
                <div className='p-col-6 p-text-bold' style={{ fontSize: '1.3rem' }}>
                    <p className={`${(!plan?.technical_support) && 'text-line-through'}`} >
                        Technical Support
                    </p>
                </div>
                <div className='p-col-5'>
                    <p>
                        {
                            (plan?.technical_support) ?
                            <i className="pi pi-check"></i>
                            :
                            <i className="pi pi-times unavailable"></i>
                        }
                    </p>
                </div>
            </div>
            <div className='p-grid'>
                <div className='p-col-1'>
                    <i className='pi pi-cloud-upload status-success' ></i>
                </div>
                <div className='p-col-6 p-text-bold' style={{ fontSize: '1.3rem' }}>
                    <p>Monthly Bandwidth</p>
                </div>
                <div className='p-col-5'>
                    <p>
                        <span>{ plan?.monthly_bandwidth } GB</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

PlanInfo.propTypes = {
    plan: PropTypes.object.isRequired,
}

export default PlanInfo;