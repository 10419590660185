import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from 'primereact/toolbar';
import { leftTemplate, rightTemplate } from './utils/templates';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import InputGender from '../../../components/Register/InputGender';

const UserTemplate = ({
  setIndex,
  values,
  handleInputChange,
  type,
  validities,
}) => {
  const { t: tGlobal } = useTranslation(['global']);
  const { t: tAuth } = useTranslation(['auth']);

  const inputPassword = {
    attributes: {
      name: 'password',
      placeholder: tGlobal('placeholder.write-password'),
      desc: tGlobal('password'),
      maxlength: 50,
      autocomplete: 'new-password',
      type: 'password',
    },
  };

  const inputConfirmation = {
    attributes: {
      name: 'confirmation',
      placeholder: 'Confirma tu contraseña',
      desc: tGlobal('confirm-password'),
      maxlength: 50,
      autocomplete: 'new-password',
      type: 'password',
    },
  };

  const inputGender = {
    attributes: {
      name: 'gender',
      desc: tGlobal('gender'),
      required: true,
      type: 'number',
    },
  };

  const inputNames = {
    attributes: {
      name: 'names',
      placeholder: tGlobal('placeholder.write-names'),
      desc: tGlobal('names'),
      maxlength: 50,
      minlength: 3,
    },
  };

  const inputLastNames = {
    attributes: {
      name: 'lnames',
      placeholder: tGlobal('placeholder.write-last-names'),
      desc: tGlobal('last-names'),
      maxlength: 50,
      minlength: 3,
    },
  };

  return (
    <>
      <div className="p-grid center p-mb-1">
        <div className="p-col-12 p-lg-5 p-md-6">
          <div>
            <Fieldset legend={tAuth('user-info')} toggleable>
              <Toolbar
                className="p-mb-3"
                left={leftTemplate(
                  values,
                  handleInputChange,
                  inputNames,
                  validities?.names
                )}
                right={rightTemplate('user', inputNames, validities?.names)}
              />
              <Toolbar
                className="p-mb-3"
                left={leftTemplate(
                  values,
                  handleInputChange,
                  inputLastNames,
                  validities?.lnames
                )}
                right={rightTemplate(
                  'user',
                  inputLastNames,
                  validities?.lnames
                )}
              />
              <Toolbar
                className="p-mb-3"
                // left={ genderTemplate(values, handleInputChange, errors?.gender) }
                left={
                  <InputGender
                    values={values}
                    handleInputChange={handleInputChange}
                  />
                }
                right={rightTemplate(null, inputGender, validities?.gender)}
              />

              <Toolbar
                className="p-mb-3"
                left={leftTemplate(
                  values,
                  handleInputChange,
                  inputPassword,
                  validities?.password
                )}
                right={rightTemplate(
                  'lock',
                  inputPassword,
                  validities?.password
                )}
              />
              <Toolbar
                className="p-mb-3"
                left={leftTemplate(
                  values,
                  handleInputChange,
                  inputConfirmation,
                  validities?.confirmation
                )}
                right={rightTemplate(
                  'lock',
                  inputConfirmation,
                  validities?.confirmation
                )}
              />
            </Fieldset>
          </div>
        </div>
      </div>

      {/* <HandleSteps
        handleBack={() => {
          setIndex((i) => i - 1);
        }}
        handleContinue={handleContinue}
        finish={type}
      /> */}
    </>
  );
};

UserTemplate.propTypes = {
  setIndex: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default UserTemplate;
