import { FINALISEDS_STATUS, RUNNING_STATUS, UNASSIGNEDS_STATUS, UNINITIATEDS_STATUS } from "../constants/monitoringConstants";

/**
 * @param {int} status -> id of status
 * @returns string status class
 */
export const getStatusCls = (status) => {
    if (status === UNASSIGNEDS_STATUS) {
        return 'danger';
    } else if (status === UNINITIATEDS_STATUS) {
        return 'warning';
    } else if (status === RUNNING_STATUS) {
        return 'info';
    } else if (status === FINALISEDS_STATUS) {
        return 'success';
    } else {
        return'primary';
    }
}

export const getTimeByDays = (days, unitTime) =>{
    if (unitTime?.label.toLowerCase() === 'days') {
        return days;
    } else if (unitTime?.label.toLowerCase() === 'weeks') {
        return Math.round(days / 7);
    } else if (unitTime?.label.toLowerCase() === 'months') {
        return Math.round(days / 30);
    } else {
        return days;
    }
}