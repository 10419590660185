import axios from "axios";
import { createAction } from 'redux-actions';
import api from "../../api";
import * as urls from  '../../api/urls';


export const setLoadingCategoriesAction = createAction('SET_LOADING_CATEGORIES_ACTION');
export const setCategoriesDataAction = createAction('SET_CATEGORIES_DATA_ACTION');
export const setCategoriesErrorAction = createAction('SET_CATEGORIES_ERROR_ACTION');
export const setResetCategoriesAction = createAction('SET_RESET_CATEGORIES_ACTION');


export const setLoadingCategoriesDropdownAction = createAction('SET_LOADING_CATEGORIES_DROPDOWN_ACTION');
export const setCategoriesDropdownDataAction = createAction('SET_CATEGORIES_DROPDOWN_DATA_ACTION');
export const setCategoriesDropdownErrorAction = createAction('SET_CATEGORIES_DROPDOWN_ERROR_ACTION');
export const setResetCategoriesDropdownAction = createAction('SET_RESET_CATEGORIES_DROPDOWN_ACTION');

export const getCategoryById = async (id, token) => {
    const response = await axios.get(`${urls.URL_BASE}${urls.urlGetRoleById}${id}`,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

  return response.data;
}

export const getCategories =  (history) => async (dispatch,getState) =>{

    dispatch(setResetCategoriesAction());
    dispatch(setLoadingCategoriesAction(true));
    try{
        const token = getState()?.LoginState?.data?.access_token;
        const {data, status} = await api.get(urls.urlListCategories, {
            headers: {
              Authorization: 'Bearer ' + token  
            }
           });


        if(status === 200){
            dispatch(setCategoriesDataAction(data));
            dispatch(
                setCategoriesErrorAction({
                    message:null
                })
            );
        }
        else if(status === 403){
            console.log("FORBIDEEEN");
            history.push('/access');
        }
       

    }
    catch(error){
        console.log(error);
        dispatch(
            setCategoriesErrorAction({
                message:"Error al recuperar información"
            })
        );

    }

    dispatch(setLoadingCategoriesAction(false));
};


export const getCategoriesDropdown =  (history) => async (dispatch,getState) =>{

    dispatch(setResetCategoriesDropdownAction());
    dispatch(setLoadingCategoriesDropdownAction(true));
    try{
        const token = getState()?.LoginState?.data?.access_token;
        const {data, status} = await api.get(urls.urlListCategoriesDropdown, {
            headers: {
              Authorization: 'Bearer ' + token  
            }
           });


        if(status === 200){
            dispatch(setCategoriesDropdownDataAction(data));
            dispatch(
                setCategoriesDropdownErrorAction({
                    message:null
                })
            );
        }
        else if(status === 403){
            console.log("FORBIDEEEN");
            history.push('/access');
        }
       

    }
    catch(error){
        console.log(error);
        dispatch(
            setCategoriesDropdownErrorAction({
                message:"Error al recuperar información"
            })
        );

    }

    dispatch(setLoadingCategoriesDropdownAction(false));
};



export const createCategory = async (data, token) => {

    const role = {
        name: data?.name,
        description: data?.description,
    };

    const response = await axios.post(`${urls.URL_BASE}${urls.urlCreateRole}`, role, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}