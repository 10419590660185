import React, { useState, useEffect } from "react";
import { Chips } from "primereact/chips";

const IndicatorTags = (props) => {
  const [tags, setTags] = useState(props.data.map(c => c?.label));

  useEffect(() => {
    setTags(props.data.map(c => c.label))
    
  }, [props.data])

  return (
    <>
      <h4>Tags</h4>
      <Chips
        value={tags}
        style={{ padding: 10 }}
        allowDuplicate={false}
        onChange={(e) => props.onInputChange(e, props.name)}
        placeholder={"Ingrese una etiqueta y presione enter."}
        separator={'-'}
      />
    </>
  );
};

export default IndicatorTags;
