import { URL_BASE as MAIN_URL } from '../urls'

export const URL_BASE = MAIN_URL

/* Model URLs */
export const urlListModel = URL_BASE + '/acquired-models';
export const urlAcquireModel = URL_BASE + '/acquired-models/acquire/';
export const urlListModelByTeam = URL_BASE + '/acquired-models/list/by-team/';
export const urlRetrieveModelById = URL_BASE + '/acquired-models/retrieve/';

/* Process URLs */
export const urlListProcessByModel = URL_BASE + '/a-process/list/by-model/';
export const urlRetrieveProcess = URL_BASE + '/a-process/retrieve/';

/* Assignment URLs */
export const urlListAssignmentsByActivity = URL_BASE + '/activity-assignment/list/by-activity/';
export const urlListUsersAssignmentsByModel = URL_BASE + '/activity-assignment/list-users/by-model/';
export const urlCreateActivityAssignment = URL_BASE + '/activity-assignment/create';
export const urlDeleteActivityAssignment = URL_BASE + '/activity-assignment/delete/';