import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import * as processActions from "../../../../redux/actions/process.actions";
import * as tableTemplates from '../../utils/templates/tableTemplates'

const emptyResource = {
    resource_id: null,
    process_id: 0,
    name: "",
    description: "",
};

const ResourcesList = (props) => {

    const dispatch = useDispatch();
    const toast = useRef(null);
    const dt = useRef(null);
    const userState = useSelector((state) => state.LoginState);
    const process = useSelector((state) => state.ProcessState.process);
    const resource_process = useSelector((state) => state.ProcessState?.resource_process);

    const [random, setRandom] = useState();
    const [globalFilter, setGlobalFilter] = useState("");
    const [selectedResource, setSelectedResource] = useState(null);
    const [resource, setResource] = useState(emptyResource);
    const [resources, setResources] = useState(null);
    const [deleteChannelDialog, setDeleteChannelDialog] = useState(false);
    const [channelDialog, setChannelDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        dispatch(processActions.getResourcesByProcessId(process?.id,userState.data.access_token));
    }, [ ]);

    const openNew = () => {
        setResource(emptyResource);
        setSubmitted(false);
        setChannelDialog(true);
    };

    const saveResource = async (e) => {

        e.preventDefault();
        setSubmitted(true);

        let resp;
        let _status;
        
        if (!resource.process_id) {

            resource.process_id = process?.id;

            await dispatch(
                processActions.createResourceProcess(resource, userState.data.access_token));
                toast?.current?.show({
                    severity: "success",
                    summary: "Successful",
                    detail:  "Recurso creado",
                    life: 3000,
            });
        } else {

          resp = await dispatch(
            //socialNetworkActions.updateChannel(resource, userState.data.access_token, rolNavState)
          );

          if (resp) {
              _status = resp[0];
              toast?.current?.show({
                  severity: _status === 200 ? "success" : "error",
                  summary: _status === 200 ? "Successful" : "Error",
                  detail: _status === 200 ? "Recurso actualizado" : "Recurso no actualizado",
                  life: 3000,
              });
          }
        }

        setChannelDialog(false);
        setResource(emptyResource);
    };

    const deleteChannel = async () => {
        const resp = await dispatch(
            processActions.deleteResourceProcess(process?.id,resource?.id,userState.data.access_token)
        );
        
        setResources(resp);
        setDeleteChannelDialog(false);
        setResource(emptyResource);
        toast.current.show({
            severity:  "success",
            summary: "Recurso Eliminado",
            detail: "",
            life: 3000,
        });
    };

    const deleteDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => setDeleteChannelDialog(false)} />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={ deleteChannel } />
        </>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setChannelDialog(false);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _resource = { ...resource };
        _resource[`${name}`] = val;
        setResource(_resource);
    };

    const confirmDeleteChannel = (c) => {
        setResource(c);
        setDeleteChannelDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                  icon="pi pi-trash"
                  className="p-button-rounded p-button-danger p-mr-1"
                  onClick={() => confirmDeleteChannel(rowData)} />
            </div>
        );
    };

    const header = (
        <div
          className="table-header">

            <div className='p-grid'>
                <div className="p-col" style={{ display: 'flex', alignItems: 'center' }}>
                    <h5 className='p-m-0'>
                        Recursos
                    </h5>
                </div>
                <div className="p-col">
                    <Button
                        label="Nuevo"
                        icon="pi pi-plus"
                        className="p-button-success p-mr-2 p-mb-2"
                        onClick={openNew} />
                </div>
            </div>

            <div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search"
                        onInput={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Search..." />
                </span>
            </div>
        </div>
    );

    return (
        <>
            <Toast ref={toast} />

            <div className="crud-demo">
                <DataTable
                    id={ random } ref={dt}
                    value={resource_process}
                    selection={selectedResource}
                    onSelectionChange={(e) => setSelectedResource(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    globalFilter={globalFilter}
                    emptyMessage="No tiene recursos asignados"
                    header={header} >
                      
                    <Column
                        field="name"
                        header="Nombre"
                        sortable
                        body={ tableTemplates.nameBodyTemplate } />
                    <Column
                        field="description"
                        header="Descripción"
                        body={ tableTemplates.descriptionBodyTemplate } />
                    <Column 
                        style={{ width: '5rem' }}
                        body={ actionBodyTemplate } />
                </DataTable>
            </div>

            <Dialog
                visible={channelDialog}
                style={{ width: "400px" }}
                header="Recurso"
                modal
                className="p-fluid"
                onHide={hideDialog} >
                  
                <form onSubmit={ saveResource }>
                    <div className="p-field">
                        <label className="required">Nombre</label>
                        <InputText
                            id="name" value={resource?.name} autoComplete='off'
                            onChange={(e) => onInputChange(e, "name")}
                            required autoFocus placeholder="Escriba el nombre del recurso"
                            className={classNames({ "p-invalid": submitted && !resource?.name })} />

                        {submitted && !resource?.name && (
                        <small className="p-invalid">Nombre es requerido.</small>
                        )}
                    </div>

                    <div className="p-field">
                        <label className="required">Descripción</label>
                        <InputTextarea
                            id="description" name="description" value={resource?.description}
                            rows={8} autoResize placeholder="Máximo 50 palabras ..."
                            autoCapitalize="on" required
                            className={classNames({"p-invalid": submitted && !resource?.description})}
                            onChange={(e) => onInputChange(e, "description")} />
                        {submitted && !resource?.description && (
                            <small className="p-invalid">La descripción es requerida.</small>
                        )}
                    </div>

                    <div className="p-field">
                        <Button
                            label="Crear"
                            className="p-button-success"
                            type="submit" />
                    </div>
                </form>

            </Dialog>

            <Dialog
                visible={deleteChannelDialog}
                style={{ width: "450px" }}
                header="Confirmar" modal
                footer={deleteDialogFooter}
                onHide={() => setDeleteChannelDialog(false) } >

                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    {resource && (
                      <span>
                        Está seguro que desea eliminar el recurso <b>{resource?.name}</b>
                      </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};

export default ResourcesList;