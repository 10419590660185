import axios from "axios";
import * as urls from  '../../../api/urls/publisheds';

export const listPublishedModels = async (token) => {
    const response = await axios.get(urls.urlListPublishedModels, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const retrievePublishedModelById = async (model_id, token) => {

    const response = await axios.get(urls.urlRetrieveModelById + model_id, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    return response.data;
}