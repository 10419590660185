import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import Spinner from '../../../../components/UI/spinner/Spinner';
import {
  PickNodesGraph,
  PickNodesGraphGenerator,
} from '../../../../components/Graphs';
import { buildDataStructure, buildGraphStructure } from '../utils';
import { indicatorsActions } from '../../../../../redux/actions/operativization';

import {
  DeleteObjectDialog,
  IndicatorsList,
  CreateIndicatorDialog,
  GenericDialog,
} from '../../../../components/UI';

import {
  getVariables,
  getTags,
} from '../../../../components/utils/equationParser';

const emptyIndicator = {
  id: null,
  parent_id: null,
  model_id: null,
  name: '',
  definition: '',
  context: '',
  instruments: '',
  interpretation: '',
  periodicity: '',
  territorial_unit: '',
  social_group: '',
  infix_expression: '',
  variables: [],
  tags: [],
};

const Indicators = (props) => {
  const observationUnitsState = useSelector(
    (state) => state?.OperativationState?.observationUnits
  );
  const conceptsState = useSelector(
    (state) => state?.OperativationState?.concepts
  );
  const dimensionsState = useSelector(
    (state) => state?.OperativationState?.dimensions
  );
  const indicatorsState = useSelector(
    (state) => state?.OperativationState?.indicators
  );
  const modelName = useSelector((state) => state?.ModelState?.model?.name);

  const [dimensionsAndIndicators, setDimensionsAndIndicators] = useState([]);
  const [indicator, setIndicator] = useState(emptyIndicator);
  const [parentId, setParentId] = useState(null);

  const [graphData, setGraphData] = useState({});
  const generator = new PickNodesGraphGenerator(graphData);
  const graph = generator?.graph;
  const options = generator?.options;

  const [visibleFullScreen, setVisibleFullScreen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const toast = useRef(null);

  const [variablesTypes, setVariablesTypes] = useState();

  const [alert, setAlert] = useState();

  const [parent, setParent] = useState();

  const dispatch = useDispatch();

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _indicator = { ...indicator };

    if (name === 'infix_expression') {
      _indicator[`${name}`] = val;
      const variables = getVariables(val);
      _indicator['variables'] = variables;
    } else if (name === 'tags') {
      const tags = getTags(val, _indicator['tags']);
      _indicator['tags'] = tags;
    } else {
      _indicator[`${name}`] = val;
    }
    setIndicator(_indicator);
  };

  const onElementChange = (value, name) => {
    let _indicator = { ...indicator };
    _indicator[`${name}`] = value;
    setIndicator(_indicator);
  };

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    // Adding groupId for visualization
    let _observatioUnits = [...observationUnitsState];
    for (let i = 0; i < observationUnitsState?.length; i++) {
      _observatioUnits[i]['groupId'] = '1';
      // observationUnitsState[i]["definition"] = "Unidad de observación";
    }
    let _concepts = [...conceptsState];
    for (let i = 0; i < conceptsState?.length; i++) {
      _concepts[i]['groupId'] = '2';
      // conceptsState[i]["definition"] = "Variable";
    }
    let _dimensions = [...dimensionsState];
    for (let i = 0; i < dimensionsState?.length; i++) {
      _dimensions[i]['groupId'] = '3';
      // dimensionsState[i]["definition"] = "Dimensión";
    }
    let _indicators = [...indicatorsState];
    for (let i = 0; i < indicatorsState?.length; i++) {
      _indicators[i]['groupId'] = '4';
      // indicatorsState[i]["definition"] = "Indicador";
    }

    const dataForGraphData = buildDataStructure([
      [..._observatioUnits],
      [..._concepts],
      [..._dimensions],
      [..._indicators],
    ]);

    const graphData = buildGraphStructure(
      [...dataForGraphData],
      modelName,
      '0',
      3
    );

    setGraphData(graphData);
    props.canContinue(indicatorsState.length > 0 ? true : false);
  }, [dimensionsAndIndicators]);

  useEffect(() => {
    if (error) {
      showMessage('error', 'Error', error.message);
    }
  }, [error]);

  useEffect(() => {
    document.body.style.overflow = createDialog ? 'hidden' : 'unset';
  }, [createDialog]);

  const loadVariablesTypes = useCallback(async () => {
    try {
      const variablesTypes = await dispatch(
        indicatorsActions.getVariablesTypes()
      );
      setVariablesTypes(variablesTypes);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    loadVariablesTypes();
  }, []);

  const loadIndicators = useCallback(async () => {
    try {
      // Retrieving Indicators
      const _indicators = await dispatch(indicatorsActions.getIndicators());
      const dataForUI = buildDataStructure([
        [...dimensionsState],
        [..._indicators],
      ]);
      setDimensionsAndIndicators(dataForUI);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    loadIndicators().then(() => {
      setLoading(false);
    });
  }, []);

  const deleteIndicator = async () => {
    setLoading(true);
    await dispatch(indicatorsActions.deleteIndicator(indicator.id));
    const index = dimensionsAndIndicators.findIndex(
      (c) => c.id == indicator.parent_id
    );
    let _aux = [...dimensionsAndIndicators];
    _aux[index].indicators = _aux[index].indicators.filter(
      (c) => c.id !== indicator.id
    );

    setDimensionsAndIndicators(_aux);
    setDeleteDialog(false);
    setIndicator(emptyIndicator);
    setLoading(false);
    showMessage('success', 'Éxito', 'Indicador Eliminado!');
  };

  const createIndicator = async () => {
    setSubmitted(true);
    setError(null);

    if (indicator?.name.trim() && indicator?.definition.trim()) {
      try {
        setLoading(true);

        if (!indicator?.id) {
          // Creating new indicator

          const _newIndicator = await dispatch(
            indicatorsActions.createIndicator(indicator, parentId)
          );

          const index = dimensionsAndIndicators.findIndex(
            (c) => c.id === parentId
          );

          let _aux = [...dimensionsAndIndicators];
          _aux[index].components = [_newIndicator, ..._aux[index].components];
          setDimensionsAndIndicators(_aux);
          showMessage('success', 'Éxito', 'Indicador Creado!');
          setParentId(null);
        } else {
          // Updating existing indicator
          const _upIndicator = await dispatch(
            indicatorsActions.updateIndicator(indicator)
          );
          let _aux = [...dimensionsAndIndicators];
          const index = _aux.findIndex((c) => c.id === indicator.parent_id);
          const indexIndicator = _aux[index].components.findIndex(
            (c) => c.id === indicator.id
          );
          _aux[index].components[indexIndicator] = _upIndicator;
          setDimensionsAndIndicators(_aux);
          showMessage('success', 'Éxito', 'Indicador Actualizado!');
        }
        setIndicator(emptyIndicator);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
      setCreateDialog(false);
      setSubmitted(false);
    }
  };

  // On Buttons Cliks
  const onDeleteIndicator = (c) => {
    setIndicator(c);
    setDeleteDialog(true);
  };

  const onCreateIndicator = (id) => {
    setParentId(id);
    setParent(dimensionsState.find((c) => c.id === id));
    setIndicator(emptyIndicator);
    setCreateDialog(true);
  };

  const onEditIndicator = (data) => {
    setIndicator(data);
    setCreateDialog(true);
  };

  const _isDataEntered = () => {
    for (let key of Object.keys(indicator)) {
      if (
        key !== 'variables' &&
        key !== 'tags' &&
        key !== 'id' &&
        key !== 'parent_id' &&
        key !== 'model_id' &&
        indicator[key] !== ''
      ) {
        return true;
      }
    }
    return false;
  };

  const onHideCreateIndicatorDialog = () => {
    if (_isDataEntered()) {
      setAlert(true);
    } else {
      setCreateDialog(false);
    }
  };

  const hideCreateIndicatorDialog = () => {
    setAlert(false);
    setCreateDialog(false);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h5>Construcción de Indicadores</h5>
      <Toast ref={toast} />

      {dimensionsAndIndicators.map((c) => (
        <IndicatorsList
          data={c}
          key={c.id}
          onCreateIndicator={onCreateIndicator}
          onDeleteIndicator={onDeleteIndicator}
          onEditIndicator={onEditIndicator}
        />
      ))}

      <GenericDialog
        visible={alert}
        header={'Confirmar'}
        onCancelAction={() => {
          setAlert(false);
        }}
        onConfirmationAction={hideCreateIndicatorDialog}
        onHide={() => {
          setAlert(false);
        }}
        message={
          <span>Si continúa se perderán los cambios ¿Desea continuar?</span>
        }
        condition={true}
      />

      <DeleteObjectDialog
        concept={indicator}
        setDeleteDialog={setDeleteDialog}
        deleteDialog={deleteDialog}
        deleteConcept={deleteIndicator}
      />

      <CreateIndicatorDialog
        createDialog={createDialog}
        setCreateDialog={onHideCreateIndicatorDialog}
        targetObject={indicator}
        submitted={submitted}
        createTargetObject={createIndicator}
        onInputChange={onInputChange}
        onElementChange={onElementChange}
        setSubmitted={setSubmitted}
        variablesTypes={variablesTypes}
        title={
          <span>
            Dimensión <b>{parent?.name}</b>
          </span>
        }
        subtitle={'Información del Indicador'}
      />

      <Button
        label={visibleFullScreen ? 'Cerrar' : 'Ver'}
        type="button"
        icon={
          visibleFullScreen
            ? 'pi pi-angle-double-down'
            : 'pi pi-angle-double-up'
        }
        className="p-button-warning"
        onClick={() => {
          setVisibleFullScreen(!visibleFullScreen);
        }}
        style={{
          position: 'fixed',
          bottom: '2%',
          right: '1%',
          width: '80px',
          height: '45px',
          zIndex: visibleFullScreen ? 10001 : 10,
        }}
      />

      <Sidebar
        visible={visibleFullScreen}
        onHide={() => setVisibleFullScreen(false)}
        baseZIndex={1000}
        fullScreen
      >
        <PickNodesGraph graph={graph} options={options} />
      </Sidebar>
    </>
  );
};

export default Indicators;
