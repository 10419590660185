import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import queryString from "query-string";
import { Redirect, Route } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { retriveLastestSubscription } from '../../redux/actions/payment-method.actions';
import { CUSTOMER_404_CODE } from '../pages/utils/constants/payment-gateways';

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {

    const history = useHistory();
    const user = useSelector((state) => state.LoginState.data);
    // const location = useLocation();
    const location = rest.location;
    const pathnames = location.pathname.split('/');
    const { status = null } = queryString.parse( location.search );
    const { t: tGlobal } = useTranslation(['global']);

    if (pathnames[1] === 'executor') {
    }

    useEffect(() => {
        if (isAuthenticated && user) {
            // retriveLastestSubscription(user?.id).then(response => {
            //     if (!response?.subscription) {
            //     history.push({
            //         pathname: '/register/payment-method',
            //         state: {
            //             message: tGlobal('messages.not-payment-method'),
            //         }
            //     })
            // }
            // }).catch(err => {

            //     const error = err.response?.data?.error;
                
            //     if (error && error?.error_code === CUSTOMER_404_CODE) {
            //         history.push({
            //             pathname: '/register/payment-method',
            //             state: {
            //                 message: tGlobal('messages.not-payment-method'),
            //             }
            //         })
            //     }
            // });
        }
    }, [ user, tGlobal, history, isAuthenticated ]);

    return (
        <Route
            { ...rest }
            component={(props) => 
                (isAuthenticated)
                    ? <Component { ...props } />
                    : <Redirect to="/login" />
            } />
    )
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired,
}

export default PrivateRoute;