import React, { useEffect, useRef } from 'react';
import ToolboxsOverlayPanel from '../../Core/Toolbox/Update/ToolComplementaries/ToolboxsOverlayPanel';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useSelector,useDispatch } from 'react-redux';
import { createToolProcess, deleteToolProcess, getToolsByProcessId } from '../../../../redux/actions/process.actions';
import { descriptionBodyTemplate, imageBodyTemplate } from '../../utils/templates/tableTemplates';
import { RolesEnum } from '../../../enums/roles';
import '../../../assets/scss/modelos.scss';

const ToolsProccess = () =>{

    const op2 = useRef(null);
    const toast = useRef(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.LoginState.data);
    const process = useSelector((state) => state.ProcessState?.process);
    const tools_process = useSelector((state) => state.ProcessState?.tool_process);
    const role = useSelector((state) => state.RolNavigationState?.state);

    useEffect(() =>{
        dispatch(getToolsByProcessId(process.id, user?.access_token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ process, user ]);

    const onDeleteProcessIndicator = (data) => {
        dispatch(deleteToolProcess(process?.id, data?.id, user?.access_token));
    }

    const toggleDataTable = (event) => {
        op2.current.toggle(event);
    };

    const handleSelect = async ({ value }) => {
        if (value && value?.id) {

            const body = { process_id: process.id, toolbox_id: value?.id }
        
            createToolProcess(body, user?.access_token, role).then(data => {
                showMessage("success", "Herramienta Seleccionada", value?.element?.name);
                dispatch(getToolsByProcessId(process.id, user?.access_token));
            }).catch((err) => {
                showMessage("error", "No se pudo agregar la herramienta", "Herramienta ya registrada");
            });
        }
        op2.current.hide();
    };

    const showMessage = (severity, summary, detail) => {
        toast?.current?.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: 3000,
        });
    };

    const actionTemplateTool = (data) => (
        <Button 
            icon="pi pi-trash" 
            className="p-button-rounded p-button-danger" 
            title="Eliminar" 
            onClick={() => onDeleteProcessIndicator(data)} />
    );

    const toolsHeader = (
        <div className='p-grid'>
            <div className="p-col-6">
                <h5>
                    Herramientas
                </h5>
            </div>
            <div className="p-col-6 p-text-right">
                <Button
                    icon='pi pi-plus'
                    className="p-button-outlined p-button-info p-mr-1"
                    label="Agregar herramienta" 
                    onClick={ toggleDataTable } />
            </div>
        </div>
    );

    return ( 
        <>
            <Toast ref={toast} />
                    
            <div className="crud-demo">
                <DataTable
                    className="datatable-responsive"
                    value={ tools_process } 
                    rowHover paginator rows={5}
                    header={ toolsHeader } >

                    <Column 
                        field="image" header="Imagen" 
                        body={ (data, props) => imageBodyTemplate(
                            (role === RolesEnum.Designer) ? data?.toolbox?.element : data?.a_toolbox?.a_element, props
                        )} />
                    <Column
                        field={(role === RolesEnum.Designer) ? "toolbox.element.name":"a_toolbox.a_element.name"}
                        header="Nombre" sortable />
                    <Column
                        field={(role === RolesEnum.Designer) ? "toolbox.element.description":"a_toolbox.a_element.description"}
                        header="Descripción" sortable
                        body={(data, props) => descriptionBodyTemplate(
                            (role === RolesEnum.Designer) ? data?.toolbox?.element : data?.a_toolbox?.a_element, props
                        )} />
                    <Column 
                        className='p-text-center' 
                        style={{ width: '7rem' }}
                        bodyStyle={{ textAling: 'center' }} 
                        body={ actionTemplateTool } />
                </DataTable>
            </div>

            <ToolboxsOverlayPanel
                op={ op2 }
                handleSelect={ handleSelect } />
        </>
    );
};

export default ToolsProccess;