import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import {
  PickNodesGraph,
  PickNodesGraphGenerator,
} from "../../../components/Graphs";
import Spinner from "../../../components/UI/spinner/Spinner";
import { ScrollTop } from "primereact/scrolltop";

import { inDesignComplexSystemActions } from "../../../../redux/actions/complex-system";
import { ScrollPanel } from "primereact/scrollpanel";

import {
  CreateObjectDialog,
  DeleteObjectDialog,
  ActionsMenuDialog,
} from "../../../components/UI";
import ModelComponentsTable from "../../../components/UI/ModelComponentsTable";
import { Button } from 'primereact/button';
import FilePickerDialog from '../../../components/UI/FilePickerDialog';
import { syncCompositions } from '../../../../redux/actions/sync.actions';

const emptyComponent = {
  id: null,
  name: "",
  definition: "",
  groupId: "",
  ref_id: "",
};

const SubsystemsComponents = (props) => {
  const [components, setComponents] = useState({});
  const [component, setComponent] = useState(emptyComponent);
  const [rootNode, setRootNode] = useState(-1);
  const [parentComponent, setParentComponent] = useState();
  const [createOrEditLabel, setCreateOrEditLabel] = useState("");
  const generator = new PickNodesGraphGenerator(components);
  const graph = generator?.graph;
  const options = generator?.options;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [componentHasBeenDeleted, setComponentHasBeenDeleted] = useState(false);

  const [error, setError] = useState(null);
  const [createDialog, setCreateDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [actionsMenuDialog, setActionsMenuDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);

  const [modelComponents, setModelComponents] = useState();

  const [openSyncComponentsDialog, setSyncComponentsDialog] = useState(false);

  const pickedComponentHandler = {
    select: ({ nodes }) => {
      let nodeId = nodes[0];
      if (nodeId && nodeId !== rootNode) {
        // Prevent for selecting the root node
        const component = components[nodeId];
        const selectedNode = {
          id: nodeId,
          name: component?.name,
          definition: component?.definition,
          groupId: component?.groupId,
          ref_id: component?.ref_id,
        };
        setComponent(emptyComponent);
        setParentComponent(selectedNode);
        setActionsMenuDialog(true);
      }
    },
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _component = { ...component };
    _component[`${name}`] = val;
    setComponent(_component);
  };

  useEffect(() => {
    if (error) {
      showMessage("error", "Error", error.message);
    }
  }, [error]);

  const loadModelComponents = useCallback(async () => {
    try {
      // Retrieving components
      const fetchedModelComponents = await dispatch(
        inDesignComplexSystemActions.getComponentsAddedToModel()
      );

      setModelComponents(fetchedModelComponents);
    } catch (error) {
      setError(error);
      return error;
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    loadModelComponents().then((e) => {
      setLoading(false);
    });
  }, []);

  const loadComponents = useCallback(async () => {
    try {
      // Retrieving components
      const fetchedComponents = await dispatch(
        inDesignComplexSystemActions.getComponentsGraphRepresentation()
      );
      // Determining the root node
      for (var key in fetchedComponents) {
        if (`${fetchedComponents[key].groupId}` === "0") {
          setRootNode(fetchedComponents[key].id);
          break;
        }
      }
      setComponents(fetchedComponents);
    } catch (error) {
      setError(error);
      return error;
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    loadModelComponents();
    loadComponents().then((e) => {
      setHasLoaded(!(e instanceof Error));
      setLoading(false);
      setComponentHasBeenDeleted(false);
    });
  }, [componentHasBeenDeleted]);

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  // TODO TESTING UPDATE METHOD
  const createComponent = async () => {
    setSubmitted(true);
    setError(null);

    if (component?.name.trim() && component?.definition.trim() && component?.ref_id.trim()) {
      try {
        // setLoading(true);
        if (!component?.id) {
          // Creating new component
          const _newComponent = await dispatch(
            inDesignComplexSystemActions.createComponent(
              component,
              parentComponent
            )
          );
          // Next two lines for fixing format
          _newComponent["groupId"] = _newComponent["group_id"].toString();
          _newComponent["components"] = [];
          // Adding the new component to the list of components already retrieved
          let _components = { ...components };
          _components[_newComponent.id.toString()] = { ..._newComponent };
          // Adding new component to components of parent component
          _components[_newComponent.parent_id.toString()].components.push(
            _newComponent.id.toString()
          );
          setComponents(_components);
          showMessage("success", "Éxito", "Sub Sistema Creado!");
        } else {
          // Updating existing component
          const _upComponent = await dispatch(
            inDesignComplexSystemActions.updateComponent(component)
          );
          let _components = { ...components };
          _components[_upComponent.id].name = _upComponent?.name;
          _components[_upComponent.id].definition = _upComponent?.definition;
          setComponents(_components);

          showMessage("success", "Éxito", "Sub Sistema Actualizado!");
        }
        setComponent(emptyComponent);
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        setSubmitted(false);
      }
      setCreateDialog(false);
      setSubmitted(false);
    }
  };

  const onAddToModel = async () => {
    setError(null);
    try {
      const _componentAdded = await dispatch(
        inDesignComplexSystemActions.addComponentToModel(parentComponent)
      );

      setModelComponents([_componentAdded, ...modelComponents]);
      showMessage("success", "Éxito", "Sub Sistema Agreagado al Modelo!");
      setActionsMenuDialog(false);
      props.reloadGraph(true);
    } catch (error) {
      setError(error);
    }
  };

  // TODO: add model_id for better security. In the db filter by model id and component id
  const deleteComponent = async () => {
    // setLoading(true);
    setError(null);
    try {
      await dispatch(
        inDesignComplexSystemActions.deleteComponent(component.id)
      );
      setComponentHasBeenDeleted(true);
      showMessage("success", "Éxito", "Sub Sistema Eliminado!");
      props.reloadGraph(true);
    } catch (error) {
      setError(error);
    }
    setDeleteDialog(false);
    setComponent(emptyComponent);
    // setLoading(false);
  };

  // On Buttons Cliks
  const onDeleteComponent = () => {
    setComponent(parentComponent);
    setDeleteDialog(true);
    setActionsMenuDialog(false);
  };

  const onCreateComponent = () => {
    setComponent(emptyComponent);
    setCreateDialog(true);
    setActionsMenuDialog(false);
    setCreateOrEditLabel(`Crear sub sistema para ${parentComponent?.name}`);
  };

  const onEditComponent = () => {
    setComponent(parentComponent);
    setCreateDialog(true);
    setCreateOrEditLabel(`Editar sub sistema: ${parentComponent?.name}`);
    setActionsMenuDialog(false);
  };

  const openSyncComponents = () => {
    setSyncComponentsDialog(true);
  };

  const hideSyncComponentsDialog = () => {
    setSyncComponentsDialog(false);
  };

  const syncComponents = (files) => {
    const file = files['0'];
    if (!file) {
      showMessage('info', 'Archivo no seleccionado');
      return;
    }

    const extension = file.name.split('.').at(-1);
    if (!extension) {
      showMessage('error', 'Archivo no tiene extensión: ' + file.name);
      return;
    }
    let filename = `ds-compositions.${extension}`;

    setSyncComponentsDialog(false);

    showMessage('info', 'Procesando...', 'Espere al mensaje de confirmación');
    dispatch(syncCompositions(file, filename)).then((error) => {
      if (error) {
        showMessage('error', 'Error al sincronizar', error);
      } else {
        showMessage('success', 'Sincronización completada');
        loadModelComponents();
      }
    });
  };

  if (loading) {
    return <Spinner />;
  }
  if (!hasLoaded) {
    return <div>Oops we are having some troubles, try later.</div>;
  }

  return (
    <div style={{ display: props.isVisible ? "inline" : "none" }}>
      <div className="p-grid list-demo">
        <div className="p-col-12 p-lg-9">
          <div className="card ">
            <h5 className="p-m-0">Subsistemas</h5>

            <Toast ref={toast} />

            <PickNodesGraph
              graph={graph}
              options={options}
              events={pickedComponentHandler}
            />

            <ActionsMenuDialog
              actionsMenuDialog={actionsMenuDialog}
              setActionsMenuDialog={setActionsMenuDialog}
              onCreateComponent={onCreateComponent}
              onEditComponent={onEditComponent}
              onDeleteComponent={onDeleteComponent}
              onAddToModel={onAddToModel}
              component={parentComponent}
            />

            <DeleteObjectDialog
              concept={parentComponent}
              setDeleteDialog={setDeleteDialog}
              deleteDialog={deleteDialog}
              deleteConcept={deleteComponent}
            />

            <CreateObjectDialog
              createDialog={createDialog}
              setCreateDialog={setCreateDialog}
              targetObject={component}
              submitted={submitted}
              createTargetObject={createComponent}
              onInputChange={onInputChange}
              setSubmitted={setSubmitted}
              title={createOrEditLabel}
            />

            <FilePickerDialog
              visible={openSyncComponentsDialog}
              onHideDialog={hideSyncComponentsDialog}
              onContinue={syncComponents}
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            />
          </div>
        </div>

        <div className="p-col-12 p-lg-3">
          <div className="card p-formgrid ">
            <h5>Elementos clave y variables</h5>

            <ScrollPanel style={{ width: "100%", height: "700px" }}>
              <ModelComponentsTable
                data={modelComponents}
                removeComponent={onDeleteComponent}
                setError={setError}
                reloadGraph={props.reloadGraph}
              />

              <ScrollTop
                target="parent"
                className="custom-scrolltop"
                threshold={100}
                icon="pi pi-arrow-up"
              />
            </ScrollPanel>

            <Button
              label="Sincronizar"
              icon="pi pi-refresh"
              className="p-button-success p-my-2"
              onClick={openSyncComponents}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubsystemsComponents;
