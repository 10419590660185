import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import Spinner from '../../../../components/UI/spinner/Spinner';
import {
  PickNodesGraph,
  PickNodesGraphGenerator,
} from '../../../../components/Graphs';
import { buildDataStructure, buildGraphStructure } from '../utils';
import * as operativizationActions from '../../../../../redux/actions/operativization.actions';
import {
  CreateObjectDialog,
  DeleteObjectDialog,
  DimensionsList,
} from '../../../../components/UI';

const emptyDimension = {
  id: null,
  parent_id: null,
  name: '',
  definition: '',
};

const ConceptSpecification = (props) => {
  const observationUnitsState = useSelector(
    (state) => state?.OperativationState?.observationUnits
  );
  const conceptsState = useSelector(
    (state) => state?.OperativationState?.concepts
  );
  const dimensionsState = useSelector(
    (state) => state?.OperativationState?.dimensions
  );
  const modelName = useSelector((state) => state?.ModelState?.model?.name);

  const [conceptsAndDimensions, setConceptsAndDimensions] = useState([]);
  const [dimension, setDimension] = useState(emptyDimension);
  const [parentId, setParentId] = useState(null);
  const [parent, setParent] = useState();

  const [graphData, setGraphData] = useState({});
  const generator = new PickNodesGraphGenerator(graphData);
  const graph = generator?.graph;
  const options = generator?.options;

  const [visibleFullScreen, setVisibleFullScreen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const toast = useRef(null);

  const dispatch = useDispatch();

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _concept = { ...dimension };
    _concept[`${name}`] = val;
    setDimension(_concept);
  };

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    // Adding groupId for visualization
    let _observatioUnits = [...observationUnitsState];
    for (let i = 0; i < observationUnitsState?.length; i++) {
      _observatioUnits[i]['groupId'] = '1';
      // observationUnitsState[i]["definition"] = "Unidad de observación";
    }
    let _concepts = [...conceptsState];
    for (let i = 0; i < conceptsState?.length; i++) {
      _concepts[i]['groupId'] = '2';
      // conceptsState[i]["definition"] = "Variable";
    }
    let _dimensions = [...dimensionsState];
    for (let i = 0; i < dimensionsState?.length; i++) {
      _dimensions[i]['groupId'] = '3';
      // dimensionsState[i]["definition"] = "Dimensión";
    }

    const dataForGraphData = buildDataStructure([
      [..._observatioUnits],
      [..._concepts],
      [..._dimensions],
    ]);

    const graphData = buildGraphStructure(
      [...dataForGraphData],
      modelName,
      '0',
      2
    );
    setGraphData(graphData);
    props.canContinue(dimensionsState.length > 0 ? true : false);
  }, [conceptsAndDimensions]);

  useEffect(() => {
    if (error) {
      showMessage('error', 'Error', error.message);
    }
  }, [error]);

  const loadDimensions = useCallback(async () => {
    try {
      // Retrieving Dimensions
      const _dimensions = await dispatch(
        operativizationActions.getDimensions()
      );
      const dataForUI = buildDataStructure([
        [...conceptsState],
        [..._dimensions],
      ]);
      setConceptsAndDimensions(dataForUI);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    loadDimensions().then(() => {
      setLoading(false);
    });
  }, []);

  const deleteDimension = async () => {
    setLoading(true);
    await dispatch(operativizationActions.deleteDimension(dimension.id));

    const index = conceptsAndDimensions.findIndex(
      (c) => c.id == dimension.parent_id
    );

    let _aux = [...conceptsAndDimensions];
    _aux[index].components = _aux[index].components.filter(
      (c) => c.id !== dimension.id
    );

    setConceptsAndDimensions(_aux);
    setDimension(emptyDimension);
    showMessage('success', 'Éxito', 'Dimensión Eliminada!');
    setDeleteDialog(false);
    setLoading(false);
  };

  const createDimension = async () => {
    setSubmitted(true);
    setError(null);

    if (dimension?.name.trim() && dimension?.definition.trim()) {
      try {
        setLoading(true);

        if (!dimension?.id) {
          // Creating new dimension
          const _newDimension = await dispatch(
            operativizationActions.createDimension(dimension, parentId)
          );

          const index = conceptsAndDimensions.findIndex(
            (c) => c.id === parentId
          );

          let _aux = [...conceptsAndDimensions];
          _aux[index].components = [_newDimension, ..._aux[index].components];
          setConceptsAndDimensions(_aux);
          showMessage('success', 'Éxito', 'Dimensión Creada!');
          setParentId(null);
        } else {
          // Updating existing concept
          const _upConcept = await dispatch(
            operativizationActions.updateDimension(dimension)
          );
          let _aux = [...conceptsAndDimensions];
          const index = _aux.findIndex((c) => c.id === dimension.parent_id);
          const indexConcept = _aux[index].components.findIndex(
            (c) => c.id === dimension.id
          );
          _aux[index].components[indexConcept] = _upConcept;
          setConceptsAndDimensions(_aux);
          showMessage('success', 'Éxito', 'Dimensión Actualizada!');
        }
        setDimension(emptyDimension);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
      setCreateDialog(false);
      setSubmitted(false);
    }
  };

  // On Buttons Cliks
  const onDeleteDimension = (c) => {
    setDimension(c);
    setDeleteDialog(true);
  };

  const onCreateDimension = (id) => {
    setParentId(id);
    setParent(conceptsState.find((c) => c.id === id));
    setDimension(emptyDimension);
    setCreateDialog(true);
  };

  const onEditDimension = (data) => {
    setDimension(data);
    setCreateDialog(true);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h5>Representación de Dimensiones</h5>
      <Toast ref={toast} />

      {conceptsAndDimensions.map((c) => (
        <DimensionsList
          data={c}
          key={c.id}
          onCreateDimension={onCreateDimension}
          onDeleteDimension={onDeleteDimension}
          onEditDimension={onEditDimension}
        />
      ))}

      <DeleteObjectDialog
        concept={dimension}
        setDeleteDialog={setDeleteDialog}
        deleteDialog={deleteDialog}
        deleteConcept={deleteDimension}
      />

      <CreateObjectDialog
        createDialog={createDialog}
        setCreateDialog={setCreateDialog}
        targetObject={dimension}
        submitted={submitted}
        createTargetObject={createDimension}
        onInputChange={onInputChange}
        setSubmitted={setSubmitted}
        title={`Variable ${parent?.name}`}
        subtitle={'Información de la dimensión'}
      />

      <Button
        label={visibleFullScreen ? 'Cerrar' : 'Ver'}
        type="button"
        icon={
          visibleFullScreen
            ? 'pi pi-angle-double-down'
            : 'pi pi-angle-double-up'
        }
        className="p-button-warning"
        onClick={() => {
          setVisibleFullScreen(!visibleFullScreen);
        }}
        style={{
          position: 'fixed',
          bottom: '2%',
          right: '1%',
          width: '80px',
          height: '45px',
          zIndex: 10001,
        }}
      />

      <Sidebar
        visible={visibleFullScreen}
        onHide={() => setVisibleFullScreen(false)}
        baseZIndex={1000}
        fullScreen
      >
        <PickNodesGraph graph={graph} options={options} />
      </Sidebar>
    </>
  );
};

export default ConceptSpecification;
