import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'primereact/radiobutton';
import { useTranslation } from 'react-i18next';

const defaultValues = {
  // Label is used as key for translation
  male: {
    label: 'male',
    value: 'M',
  },
  female: {
    label: 'female',
    value: 'F',
  },
};

const InputGender = ({
  values,
  handleInputChange,
  name = 'gender',
  custom = defaultValues,
  info = false,
}) => {
  const { t: tglobal } = useTranslation(['global']);

  return (
    <>
      <div>
        <div className="p-grid">
          {Object.keys(custom).map((key, i) => {
            return (
              <div key={i + key} className="p-col p-pb-0">
                <div className="p-field-radiobutton">
                  <RadioButton
                    inputId="gender"
                    name="gender"
                    value={custom[key].value}
                    checked={values[name] === custom[key].value}
                    onChange={handleInputChange}
                  />
                  <label htmlFor="gender">{tglobal(custom[key].label)}</label>
                </div>
              </div>
            );
          })}
        </div>
        {/* Show or hide label at this input depeding info param*/}
        {info ? (
          ''
        ) : (
          <i className="p-d-block p-mt-1">
            {tglobal('gender')}
            <span className="p-ml-1 required-input">*</span>
          </i>
        )}
      </div>
    </>
  );
};

InputGender.propTypes = {
  values: PropTypes.object,
  handleInputChange: PropTypes.func,
};

export default InputGender;
