import {
  EMAIL_REGEX,
  NAMES_REGEX,
  DESC_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
  USERNAME_REGEX,
  ALPHANUMERIC_REGEX,
} from '../../../utils/constants/regex';
import i18next from 'i18next';

export const personValidation = (values) => {
  const data = {};

  const { response: idResponse, errors: idErrors } = idValidation(values.id);
  const { response: namesResponse, errors: namesErrors } = namesValidation(
    values.names
  );
  const { response: emailResponse, errors: emailErrors } = emailValidation(
    values.email
  );
  const { response: phoneResponse, errors: phoneErrors } = phoneValidation(
    values.phone
  );
  const { response: lnamesResponse, errors: lnamesErrors } = lnamesValidation(
    values.lnames
  );
  const { response: genderResponse, errors: genderErrors } = genderValidation(
    values.gender
  );

  data.id = {
    error: idResponse,
    errors: idErrors,
  };

  data.names = {
    error: namesResponse,
    errors: namesErrors,
  };

  data.lnames = {
    error: lnamesResponse,
    errors: lnamesErrors,
  };

  data.email = {
    error: emailResponse,
    errors: emailErrors,
  };

  data.phone = {
    error: phoneResponse,
    errors: phoneErrors,
  };

  data.gender = {
    error: genderResponse,
    errors: genderErrors,
  };

  // return false if have errors
  if (
    idResponse ||
    namesResponse ||
    lnamesResponse ||
    emailResponse ||
    genderResponse ||
    phoneResponse
  ) {
    return { response: false, data: data };
  } else {
    return { response: true, data: data };
  }
};

export const userValidation = (values) => {
  const data = {};

  const { response: passwordResponse, errors: passwordErrors } =
    passwordValidation(values.password);
  const { response: confirmationResponse, errors: confirmationErrors } =
    confirmationValidation(values.confirmation, values.password);
  const { response: namesResponse, errors: namesErrors } = namesValidation(
    values.names
  );
  const { response: lnamesResponse, errors: lnamesErrors } = lnamesValidation(
    values.lnames
  );
  const { response: genderResponse, errors: genderErrors } = genderValidation(
    values.gender
  );

  data.password = {
    error: passwordResponse,
    errors: passwordErrors,
  };

  data.confirmation = {
    error: confirmationResponse,
    errors: confirmationErrors,
  };

  data.names = {
    error: namesResponse,
    errors: namesErrors,
  };

  data.lnames = {
    error: lnamesResponse,
    errors: lnamesErrors,
  };

  data.gender = {
    error: genderResponse,
    errors: genderErrors,
  };

  // return false if have errors
  if (
    namesResponse ||
    lnamesResponse ||
    genderResponse ||
    passwordResponse ||
    confirmationResponse
  ) {
    return { response: false, data: data };
  } else {
    return { response: true, data: data };
  }
};

export const adminValidation = (values) => {
  const data = {};

  const { response: passwordResponse, errors: passwordErrors } =
    passwordValidation(values.password);
  const { response: confirmationResponse, errors: confirmationErrors } =
    confirmationValidation(values.confirmation, values.password);
  const { response: namesResponse, errors: namesErrors } = namesValidation(
    values.names
  );
  const { response: lnamesResponse, errors: lnamesErrors } = lnamesValidation(
    values.lnames
  );
  const { response: genderResponse, errors: genderErrors } = genderValidation(
    values.gender
  );

  const { response: emailResponse, errors: emailErrors } = emailValidation(
    values.email
  );

  data.password = {
    error: passwordResponse,
    errors: passwordErrors,
  };

  data.confirmation = {
    error: confirmationResponse,
    errors: confirmationErrors,
  };

  data.names = {
    error: namesResponse,
    errors: namesErrors,
  };

  data.lnames = {
    error: lnamesResponse,
    errors: lnamesErrors,
  };

  data.gender = {
    error: genderResponse,
    errors: genderErrors,
  };

  data.email = {
    error: emailResponse,
    errors: emailErrors,
  };

  // return false if have errors
  if (
    namesResponse ||
    lnamesResponse ||
    genderResponse ||
    passwordResponse ||
    confirmationResponse ||
    emailResponse
  ) {
    return { response: false, data: data };
  } else {
    return { response: true, data: data };
  }
};

export const teamValidation = (values) => {
  const data = {};

  const { response: teamResponse, errors: teamErrors } = teamNameValidation(
    values.team
  );
  const { response: descResponse, errors: descErrors } = teamDescValidation(
    values.desc
  );

  data.team = {
    error: teamResponse,
    errors: teamErrors,
  };

  data.desc = {
    error: descResponse,
    errors: descErrors,
  };

  // return false if have errors
  if (teamResponse || descResponse) {
    return { response: false, data: data };
  } else {
    return { response: true, data: data };
  }
};

//FIELDS VALIDATIONS

const idValidation = (id) => {
  const errors = [];
  let response = false; //True if have errors

  if (id) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  return {
    errors,
    response,
  };
};

const namesValidation = (names) => {
  const alphanumeric = i18next.t('global:vocabulary.singular.alphanumeric');

  const errors = [];
  let response = false; //True if have errors

  if (names) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  if (names.length > 1 && names.length < 50) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.length', { min: 3, max: 50 }),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.length', { min: 3, max: 50 }),
    });
  }

  if (names.match(NAMES_REGEX)) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.correct-format'),
      format: [alphanumeric],
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.correct-format'),
      format: [alphanumeric],
    });
  }

  return {
    errors,
    response,
  };
};

const lnamesValidation = (lnames) => {
  const alphanumeric = i18next.t('global:vocabulary.singular.alphanumeric');

  const errors = [];
  let response = false; //If have errors

  if (lnames) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  if (lnames.length > 1 && lnames.length < 50) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.length', { min: 3, max: 50 }),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.length', { min: 3, max: 50 }),
    });
  }

  if (lnames.match(NAMES_REGEX)) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.correct-format'),
      format: [alphanumeric],
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.correct-format'),
      format: [alphanumeric],
    });
  }

  return {
    errors,
    response,
  };
};

const emailValidation = (email) => {
  const errors = [];
  let response = false; //If have errors

  if (email) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  if (email.length > 3 && email.length < 320) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.length', { min: 3, max: 320 }),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.length', { min: 3, max: 320 }),
    });
  }

  if (email.match(EMAIL_REGEX)) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.correct-format'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.correct-format'),
    });
  }

  return {
    errors,
    response,
  };
};

const phoneValidation = (phone) => {
  const errors = [];
  let response = false; //If have errors

  if (phone.length > 7 && phone.length < 20) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.length', { min: 7, max: 20 }),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.length', { min: 7, max: 20 }),
    });
  }

  if (phone.match(PHONE_REGEX)) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.correct-format'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.correct-format'),
    });
  }

  return {
    errors,
    response,
  };
};

const genderValidation = (gender) => {
  const errors = [];
  let response = false; //If have errors

  if (gender) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  return {
    errors,
    response,
  };
};

const usernameValidation = (username) => {
  const errors = [];
  let response = false; //If have errors

  if (username) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  if (username.length > 3 && username.length < 20) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.length', { min: 3, max: 20 }),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.length', { min: 3, max: 20 }),
    });
  }

  if (username.match(USERNAME_REGEX)) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.correct-format'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.correct-format'),
    });
  }

  return {
    errors,
    response,
  };
};

const passwordValidation = (password) => {
  const numeric = i18next.t('global:vocabulary.plural.numeric');
  const alphabetical = i18next.t('global:vocabulary.plural.alphabetical');
  const uppercase = i18next.t('global:vocabulary.plural.uppercase');

  const passwordFormats = [
    `1 ${alphabetical}`,
    `1 ${uppercase} ${alphabetical}`,
    i18next.t('global:vocabulary.no-space'),
  ];

  const errors = [];
  let response = false; //If have errors

  if (password) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  if (password.length > 4) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.length-min', { min: 4 }),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.length-min', { min: 4 }),
    });
  }

  if (password.match('.')) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.correct-format'),
      format: [...passwordFormats],
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.correct-format'),
      format: [...passwordFormats],
    });
  }

  return {
    errors,
    response,
  };
};

const confirmationValidation = (confirmation, password) => {
  const errors = [];
  let response = false; //If have errors

  if (confirmation) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  if (confirmation && confirmation === password) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.equals-password'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.equals-password'),
    });
  }

  return {
    errors,
    response,
  };
};

const teamNameValidation = (name) => {
  const alphanumeric = i18next.t('global:vocabulary.singular.alphanumeric');

  const errors = [];
  let response = false; //If have errors

  if (name) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  if (name.length > 3 && name.length < 100) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.length', { min: 3, max: 100 }),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.length', { min: 3, max: 100 }),
    });
  }

  if (name.match(ALPHANUMERIC_REGEX)) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.correct-format'),
      format: [alphanumeric],
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.correct-format'),
      format: [alphanumeric],
    });
  }

  return {
    errors,
    response,
  };
};

const teamDescValidation = (desc) => {
  const alphanumeric = i18next.t('global:vocabulary.singular.alphanumeric');

  const errors = [];
  let response = false; //If have errors

  if (desc) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.required'),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.required'),
    });
  }

  if (desc.length > 5 && desc.length < 500) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.length', { min: 5, max: 500 }),
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.length', { min: 5, max: 500 }),
    });
  }

  if (desc.match(DESC_REGEX)) {
    errors.push({
      status: true,
      msg: i18next.t('auth:validation.correct-format'),
      format: [alphanumeric],
    });
  } else {
    response = true;
    errors.push({
      status: false,
      msg: i18next.t('auth:validation.correct-format'),
      format: [alphanumeric],
    });
  }

  return {
    errors,
    response,
  };
};
