import React, { useState } from "react";
import { TabMenu } from "primereact/tabmenu";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { TextEditor } from ".";

const ORIGIN = "origin";
const EVOLUTION = "evolution";
const THEORY = "theory";
const TOOLS = "tools";
const IMPLEMENTATION = "implementation";

const components = [
  { id: 0, label: "Origen", icon: "pi pi-sun" },
  { id: 1, label: "Evolución", icon: "pi pi-eye" },
  { id: 2, label: "Teoría", icon: "pi pi-book" },
  { id: 3, label: "Herramientas", icon: "pi pi-briefcase" },
  { id: 4, label: "Implementación", icon: "pi pi-link" },
];

const TopicItems = (props) => {
  const [tabSelected, setTabSelected] = useState(components[0]);

  const dialogFooter = (
    <>
      <Button
        label="Cerrar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          props.setOpen();
          props.submitted(false);
          setTabSelected(components[0]);
        }}
      />
    </>
  );

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  return (
    <>
      <Dialog
        visible={props.open}
        style={{ width: "90%", zindex: 20 }}
        header={props.title}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={() => {
          props.setOpen();
        }}
      >
        <h6>{props.subtitle}</h6>
        <div className="p-grid list-demo">
          <div className="p-col-12 p-md-12">
            <div className="card card-w-title">
              <h3>Temas claves y tendencias</h3>
              <TabMenu
                model={components}
                onTabChange={(e) => {
                  tabChangeHandler(e.value);
                }}
                activeItem={tabSelected}
              />

              {tabSelected.id === 0 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={ORIGIN}
                  value={props.topic[ORIGIN]}
                  title={"Origen"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar origen"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 1 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={EVOLUTION}
                  value={props.topic[EVOLUTION]}
                  title={"Evolución"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar evolución"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 2 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={THEORY}
                  value={props.topic[THEORY]}
                  title={"Teoría"}
                  subtitle={"Describa ..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar teoría"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 3 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={TOOLS}
                  value={props.topic[TOOLS]}
                  title={"Herramientas"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar herramientas"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 4 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={IMPLEMENTATION}
                  value={props.topic[IMPLEMENTATION]}
                  title={"Implementación"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar implementación"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default TopicItems;
