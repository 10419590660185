import axios from "axios";
import * as urls from  '../../api/urls';

export const getRoleById = async (id, token) => {
    const response = await axios.get(`${urls.URL_BASE}${urls.urlGetRoleById}${id}`,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

  return response.data;
}

export const getRoles = async (token) => {
    const response = await axios.get(`${urls.URL_BASE}${urls.urlListRoles}`,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const createRole = async (data, token) => {

    const role = {
        name: data?.name,
        description: data?.description,
    };

    const response = await axios.post(`${urls.URL_BASE}${urls.urlCreateRole}`, role, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deleteRol = async (rolId, token) =>{

    const response = await axios.put(`${urls.URL_BASE}${urls.urlDeleteRole}${rolId}`,{},{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    console.log(response);
    return response;
}

export const updateRol = async (data, token) =>{

    const roleId = data?.id;

    const role ={
        id: roleId,
        name: data?.name,
        description: data?.description
    };

    const response = await axios.put(`${urls.URL_BASE}${urls.urlUpdateRole}${roleId}`, role, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    console.log(response);
    return response;
}