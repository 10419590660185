// Types from s3 folders
export const S3_ELEMENT_TYPE = 2;
export const S3_KNOWLEDGE_TYPE = 3;
export const S3_MODEL_TYPE = 4;
export const S3_TEAM_TYPE = 5;

// Folders name from s3
export const S3_ELEMENT_FOLDER = 'elements';
export const S3_KNOWLEDGE_FOLDER = '';
export const S3_MODEL_FOLDER = 'models';
export const S3_TEAM_FOLDER = 'teams';