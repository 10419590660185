export const UPDATE_RELATIONSHIPS = "UPDATE_RELATIONSHIPS";
export const UPDATE_RELATIONSHIPS_FROM_SUBSYSTEM = "UPDATE_RELATIONSHIPS_FROM_SUBSYSTEM";

export const SET_MODEL = "SET_MODEL";
export const DELETE_MODEL = "DELETE_MODEL";

export const ADD_SUBSYSTEM = "ADD_SUBSYSTEM";
export const DELETE_SUBSYSTEM = "DELETE_SUBSYSTEM";
export const ADD_COMPONENT = "ADD_COMPONENT";
export const REMOVE_COMPONENT = "REMOVE_COMPONENT";
export const ADD_RELATIONSHIP = "ADD_RELATIONSHIP";
export const REMOVE_RELATIONSHIP = "REMOVE_RELATIONSHIP";
export const UPDATE_RELATIONSHIP_FEATURES = "UPDATE_RELATIONSHIP_FEATURES";
export const CHANGE_COMPONENT_ATTRIBUTE = "CHANGE_COMPONENT_ATTRIBUTE";
export const RESET_MODEL_STORE = "RESET_MODEL_STORE";

export const SET_USER = "SET_USER";
export const SET_MODEL_ID = "SET_MODEL_ID";







