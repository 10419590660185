import CloseQuestion from '../../../components/Surveys/CloseQuestion';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSurveyById } from '../../../../redux/actions/survey.actions';
import { Button as ButtonF } from 'primereact/button';
import { Grid, Paper, Typography } from '@material-ui/core';
import '../../../assets/scss/surveys.scss';

const SurveyView = (props) => {
  let history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.LoginState.data);
  const formId = location.state?.formId;
  const role = useSelector((state) => state.RolNavigationState?.state);

  const [survey, setSurvey] = useState();
  const [questions, setQuestions] = useState([]);

  console.log('Vista previa');

  const loadData = async () => {
    getSurveyById(formId, user.access_token, role).then((response) => {
      setSurvey(response);
      setQuestions(response?.questions);
    });
  };

  useEffect(() => {
    loadData();
  }, [formId]);

  const header = () => {
    const leftTemplate = (
      <>
        <h5>Vista Previa de la Encuesta</h5>
      </>
    );

    const rightTemplate = (
      <>
        <ButtonF
          label="Regresar"
          icon="pi pi-times"
          className="p-button-help"
          onClick={() => {
            history.goBack();
          }}
        />
      </>
    );

    return (
      <div className="card">
        <div className="p-grid">
          <div className="p-col-6">{leftTemplate}</div>
          <div className="p-col-6 p-mb-1" style={{ textAlign: 'right' }}>
            {rightTemplate}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {header()}
      <br />
      <Grid
        container
        direction="column"
        style={{ justifyContent: 'center' }}
        alignItems="center"
      >
        <Grid item xs={12} sm={7} style={{ width: '100%' }}>
          <Grid style={{ borderTop: '10px solid teal', borderRadius: 10 }}>
            <div>
              <div>
                <Paper elevation={2} style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginLeft: '15px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontFamily: 'sans-serif Roboto',
                        marginBottom: '15px',
                      }}
                    >
                      {survey?.name}
                    </Typography>
                    <Typography variant="subtitle1">
                      {survey?.description}
                    </Typography>
                    <div
                      className="p-mt-2 p-mb-2 p-d-block"
                      style={{ textAlign: 'center', display: 'flex' }}
                    >
                      {questions.length === 0 ? (
                        <Typography
                          variant="subtitle1"
                          style={{ color: 'red' }}
                        >
                          Esta encuesta no contiene preguntas creadas
                        </Typography>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </Paper>
              </div>
            </div>
          </Grid>

          <div>
            <Grid>
              {questions.map((ques, i) => (
                <div key={i}>
                  {' '}
                  <br></br>
                  <Paper>
                    <CloseQuestion questions={ques} index={i} />
                  </Paper>
                </div>
              ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SurveyView;
