import { Checkbox } from '@material-ui/core';

const HeaderSurvey = ({ formData, setFormData, saveQuestions }) => {
  const changeSurveyRespondent = (validator, key) => {
    let surveyRes = { ...formData };
    if (validator) {
      surveyRes.survey_respondent[key] = '';
      setFormData(surveyRes);
      saveQuestions();
    } else {
      surveyRes.survey_respondent[key] = null;
      setFormData(surveyRes);
      saveQuestions();
    }
  };

  return (
    <>
      <h6 className="p-ml-3">Escoja los items del encabezado</h6>
      <div className="p-ml-6 p-mb-2 p-grid">
        <div className="p-col">
          <div style={{ display: 'flex', verticalAlign: 'middle' }}>
            <Checkbox
              checked={
                formData?.survey_respondent?.identification === '' || false
              }
              onChange={(e) =>
                changeSurveyRespondent(e.target.checked, 'identification')
              }
            />{' '}
            <p className="p-mt-2">Identificacion:</p>
          </div>

          <div style={{ display: 'flex', verticalAlign: 'middle' }}>
            <Checkbox
              checked={formData?.survey_respondent?.names === '' || false}
              onChange={(e) =>
                changeSurveyRespondent(e.target.checked, 'names')
              }
            />
            <p className="p-mt-2">Nombres:</p>
          </div>

          <div style={{ display: 'flex', verticalAlign: 'middle' }}>
            <Checkbox
              checked={
                formData?.survey_respondent?.phone_number === '' || false
              }
              onChange={(e) =>
                changeSurveyRespondent(e.target.checked, 'phone_number')
              }
            />
            <p className="p-mt-2">Telefono:</p>
          </div>
        </div>

        <div className="p-col">
          <div style={{ display: 'flex', verticalAlign: 'middle' }}>
            <Checkbox
              checked={formData?.survey_respondent?.email === '' || false}
              onChange={(e) =>
                changeSurveyRespondent(e.target.checked, 'email')
              }
            />{' '}
            <p className="p-mt-2">Correo:</p>
          </div>

          <div style={{ display: 'flex', verticalAlign: 'middle' }}>
            <Checkbox
              checked={formData?.survey_respondent?.addres === '' || false}
              onChange={(e) =>
                changeSurveyRespondent(e.target.checked, 'address')
              }
            />
            <p className="p-mt-2">Direccion:</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderSurvey;
