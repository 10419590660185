import React, { useEffect, useState } from 'react';
import UnitTimeTypesDropdown from '../../../components/UI/UnitTimeTypesDropdown';
import { Fieldset } from 'primereact/fieldset';
import { setUnitTimeAction } from '../../../../redux/actions/user-preferences.actions';
import { useDispatch, useSelector } from 'react-redux';

const Settings = props => {

    const dispatch = useDispatch();
    const { unitTime } = useSelector((state) => state.UserPreferencesState);
    const [ unitTimeType, setUnitTimeType ] = useState(unitTime);
    
    useEffect(() => {
        dispatch(setUnitTimeAction(unitTimeType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ unitTimeType ]);

    return (
        <div className='animate__animated animate__zoomIn'>
            <div className="card p-shadow-10">

                <h5 className='p-text-center'>Settings</h5>

                <div className="p-grid">
                    <div className="p-col">
                        <Fieldset legend='Unidad de tiempo' toggleable >
                            <p style={{ color: 'GrayText' }}>
                                Seleccione la unidad de medida en la que desea que se le proporcione la información de tiempos para el monitoreo y evaluación.
                            </p>

                            <div className='p-fluid'>
                                <div className='p-field'>
                                    <label style={{ fontSize: '.9rem' }}>Unidad de Tiempo</label>
                                    <UnitTimeTypesDropdown
                                        unitTimeType={ unitTimeType }
                                        setUnitTimeType={ setUnitTimeType } />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
            </div>
        </div>
    );
}

Settings.propTypes = {}

export default Settings;