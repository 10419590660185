import React, { useEffect, useRef, useState } from 'react';
import PersonTemplate from './templates/PersonTemplate';
import TeamTemplate from './templates/TeamTemplate';
import useForm from '../../../hooks/useForm';
import { Steps } from 'primereact/steps';
import { Divider } from 'primereact/divider';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { confirmPopup } from 'primereact/confirmpopup';
import HandleSteps from '../../components/Register/HandleSteps';
import { teamValidation } from './templates/utils/validations';
import { checkTeamAvailability } from '../../../redux/actions/team.actions';
import { Toast } from 'primereact/toast';
import { registrarUsuario } from '../../../redux/actions/user.action';

import {
  changeLayoutCSS,
  changeThemeCSS,
} from '../utils/helpers/changeStyleSheetsColor';
import '../../assets/scss/register.scss';
import AdminUserTemplate from './templates/AdminUserTemplate';
import { adminValidation } from './templates/utils/validations';

import { useLogin } from '../../../redux/hooks/useUser';

const Register = () => {
  '';
  const toast = useRef(null);

  const history = useHistory();
  const { t: tGlobal } = useTranslation(['global']);
  const { t: tAuth } = useTranslation(['auth']);

  const alphanumeric = tGlobal('vocabulary.singular.alphanumeric');
  const alphabetical = tGlobal('vocabulary.plural.alphabetical');
  const uppercase = tGlobal('vocabulary.plural.uppercase');

  const [error, setError] = useState();

  const { login, iniciaSesion } = useLogin();

  const [index, setIndex] = useState(0); // Index of Steps component
  const { values, setValues, handleInputChange } = useForm({
    plan: {},
    id: '',
    names: '',
    lnames: '',
    email: '',
    gender: '',
    username: '',
    password: '',
    confirmation: '',
    team: '',
    desc: '',
  });

  const passwordFormats = [
    `1 ${alphabetical}`,
    `1 ${uppercase} ${alphabetical}`,
    tGlobal('vocabulary.no-space'),
  ];

  const [teamValidities, setTeamValidities] = useState({
    team: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.unique-name') },
        { status: null, msg: tAuth('validation.length', { min: 3, max: 100 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [alphanumeric],
        },
      ],
    },
    desc: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.length', { min: 5, max: 300 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [alphanumeric],
        },
      ],
    },
  });

  const [admindValidities, setAdminValidities] = useState({
    password: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.length-min', { min: 4 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [...passwordFormats],
        },
      ],
    },
    confirmation: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.equals-password') },
      ],
    },

    names: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.length', { min: 1, max: 50 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [alphanumeric],
        },
      ],
    },
    lnames: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.length', { min: 1, max: 50 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [alphanumeric],
        },
      ],
    },
    gender: {
      error: false,
      errors: [{ status: null, msg: tAuth('validation.required') }],
    },
    email: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.length', { min: 3, max: 320 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [tGlobal('email')],
        },
      ],
    },
  });

  // Change style tags href attribute in index.html
  useEffect(() => {
    changeLayoutCSS('dark');
    changeThemeCSS('dark');
  }, []);

  useEffect(() => {
    if (login) {
      if (values.plan.id === 1) {
        setTimeout(() => {
          history.replace('/');
        }, 3000);
      } else {
        setTimeout(() => {
          history.replace('/register/payment-method');
        }, 3000);
      }
    }
  }, [login]);

  const wizardItems = [
    { label: tAuth('user-info') },
    { label: tAuth('team-info') },
  ];

  const handleCheckTeam = async () => {
    // Get validation errors
    let { data } = teamValidation(values);
    let { team } = data;
    let { error, errors: teamErrors } = team;

    //Check if was available
    let { available } = await checkTeamAvailability(values?.team);

    if (available && !error) {
      toast.current.show({
        severity: 'success',
        summary: tGlobal('status.available'),
        detail: tAuth('messages.available-team'),
        life: 3000,
      });
    } else if (!available && !error) {
      toast.current.show({
        severity: 'error',
        summary: tGlobal('status.not-available'),
        detail: tAuth('messages.registered-team'),
        life: 3000,
      });
    }

    if (!error && available) error = false;
    else error = true; //True if have errors

    data = {
      desc: teamValidities?.desc,
      team: {
        error: error,
        errors: [...teamErrors, { status: available, msg: 'Unique name' }],
      },
    };

    setTeamValidities(data);
  };

  const registerUserAndTem = async () => {
    // setSubmited(true);

    // Get validation errors
    let { response, data } = teamValidation(values);
    let { team, desc } = data;

    if (!response) {
      setTeamValidities(data);

      return;
    }

    // Check if team name was available
    let { available } = await checkTeamAvailability(values?.team);

    if (available === false) {
      // Put errors (true or false) to team input
      data = {
        desc,
        team: {
          error: true,
          errors: [...team.errors, { status: available, msg: 'Unique name' }],
        },
      };

      toast.current.show({
        severity: 'error',
        summary: tGlobal('status.not-available'),
        detail: tAuth('messages.registered-team'),
        life: 3000,
      });

      setError(data);
      return;
    }

    registrarUsuario(values)
      .then((response) => {
        if (values.plan.id === 1) {
          toast.current.show({
            severity: 'success',
            summary: tGlobal('status.success'),
            detail: tAuth('messages.redirect-login'),
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: 'success',
            summary: tGlobal('status.success'),
            detail: tAuth('messages.redirect-payment'),
            life: 3000,
          });
        }

        // Auto login after register
        iniciaSesion({ username: values.email, password: values.password });
      })
      .catch((error) => {
        const detail = error?.response?.data?.detail;

        if (detail) {
          toast.current.show({
            severity: 'error',
            summary: tGlobal('status.error'),
            detail: detail,
            life: 5000,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: tGlobal('status.error'),
            detail: tAuth('messages.register-error'),
            life: 5000,
          });
        }

        setError(data);
      });
  };

  const handleContinue = () => {
    const { response, data } = adminValidation(values);

    if (response) {
      if (index === wizardItems.length - 1) {
        // its on the final step
        registerUserAndTem();
      }

      if (index < wizardItems.length - 1) setIndex((i) => i + 1);
    } else {
      setTeamValidities(data);
    }
  };

  // Render template depending Step index
  const templateSwitch = () => {
    switch (index) {
      case 0:
        return (
          <AdminUserTemplate
            setIndex={setIndex}
            values={values}
            handleInputChange={handleInputChange}
            type={true}
            validities={admindValidities}
          />
        );
      case 1:
        return (
          <TeamTemplate
            setIndex={setIndex}
            values={values}
            handleInputChange={handleInputChange}
            validities={teamValidities}
            handleCheckTeam={handleCheckTeam}
          />
        );
      default:
        return <></>;
    }
  };

  const handleSignIn = (event) => {
    confirmPopup({
      accept: () => history.push('/login'),
      target: event.currentTarget,
      icon: 'pi pi-exclamation-triangle',
      message: (
        <div>
          <h5>{tGlobal('questions.sure-you-want-proceed')}</h5>
          <i>{tGlobal('messages.lose-all-entered-data')}.</i>
        </div>
      ),
    });
  };

  return (
    <div className="p-grid p-dir-col" style={{ padding: '0px', margin: '0px' }}>
      <Toast ref={toast} />

      {/* Logo and title of register page */}
      <div
        className="p-col p-d-flex"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h3 className="p-d-inline-block p-text-center p-ml-3">
          {tGlobal('titles.sign-up')}

          <Divider className="p-my-2" />

          <div style={{ fontSize: '0.9rem', textAlign: 'center' }}>
            {tAuth('have-account')}
            <button
              style={{ fontSize: '.9rem' }}
              className="p-link p-ml-1"
              onClick={handleSignIn}
            >
              {tGlobal('titles.sign-in')}
            </button>
          </div>
        </h3>
      </div>

      <div
        className="p-col-12 p-col-md-10 p-col-lg-8"
        style={{ margin: 'auto' }}
      >
        <Steps activeIndex={index} model={wizardItems} readOnly />
      </div>

      <div className="p-col" style={{ padding: '0px', margin: '0px' }}>
        <div className="login-body">
          <div
            className="login-wrapper"
            style={{ backgroundColor: '#3E4754', height: 'auto' }}
          >
            <div style={{ width: '90%' }}>{templateSwitch()}</div>
          </div>
        </div>
      </div>
      <HandleSteps
        handleBack={() => {
          setIndex((i) => i - 1);
        }}
        handleContinue={handleContinue}
        finish={index === wizardItems.length - 1}
        type={index > 0 && index < wizardItems.length}
      />
    </div>
  );
};

export default Register;
