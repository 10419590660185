import React, { useEffect, useState } from 'react';
import ProcessCard from './ProcessCard';
import { listProcessByModel } from '../../../../../redux/actions/acquireds/process.actions';
import { useSelector } from 'react-redux';

const ProcessList = props => {

    const user = useSelector((state) => state.LoginState.data);
    const { model } = useSelector(state => state.ModelState);
    const [ processes, setProcesses ] = useState([]);

    useEffect(() => {
        if (model?.id) {
            listProcessByModel(model?.id, user?.access_token).then(listResponse => {
                if (listResponse) setProcesses(listResponse);
            });
        }
    }, [ user, model ]);

    return (
        <div className='animate__animated animate__zoomIn'>
            <div className="card p-shadow-10">

                <h5 className='p-text-center'>Lista de Procesos</h5>

                <div className="card schedule p-mb-0">
                    <p>Resultados</p>
                    <ul className='p-grid'>
                        <li className='p-col p-m-1'>
                            <div className="schedule-header">
                                <h6>Número de resutados</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { processes.length }
                                    </span>
                                </span>
                            </div>
                            <span>{ processes.length } procesos</span>
                        </li>
                        <li className='p-col p-m-1'>
                            <div className="schedule-header">
                                <h6>Title</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        Result
                                    </span>
                                </span>                                                
                            </div>
                            <span>
                                Description
                            </span>
                        </li>
                    </ul>
                </div>

                <div>
                {
                    processes.map((process) => (
                        <ProcessCard
                            key={ process?.id }
                            process={ process } />
                    ))
                }
                </div>
            </div>
        </div>
    );
}

ProcessList.propTypes = {}

export default ProcessList