import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useForm from '../../../../hooks/useForm';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { createRole, updateRol } from '../../../../redux/actions/rol.actions';
import { useSelector } from 'react-redux';

const emptyFunc = () => { return };
const emptyRole = { name: '', description: ''};

const RoleFormDialog = React.memo(({ open, setOpen, role=emptyRole, setRole=emptyFunc, callback=emptyFunc, }) => {

    const [ submitted, setSubmitted ] = useState(false);
    const { values, setValues, handleInputChange } = useForm((role != null) ? role:emptyRole);
    const user = useSelector((state) => state.LoginState.data);
    const toast = useRef(null);

    useEffect(() => {
        setValues((role != null) ? role:emptyRole);
    }, [ role ])

    const saveRole = () => {

        setSubmitted(true);
        
        if (values?.name.trim() && values?.description.trim()) {
            
            if (!values?.id) {
                createRole(values, user?.access_token).then(response => {
                    toast.current.show({ severity: 'success', summary: 'Role Created', detail: response?.name, life: 3000 });
                    callback();
                });
            } else {
                updateRol(values, user?.access_token).then(()=>{
                    toast.current.show({ severity: 'success', summary: 'Role Updated', detail: 'Exito', life: 3000 });
                    callback();
                })
            }
            
            hideDialog();
        }
    }

    const hideDialog = () => {
        setSubmitted(false);
        setValues(emptyRole);
        setRole(null);
        setOpen(false);
    }

    const roleDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={ hideDialog } />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={ saveRole } />
        </>
    );

    return (
        <>
            <Toast ref={ toast } />

            <Dialog visible={ open } style={{ width: '350px' }} header="Role Details" modal className="p-fluid" footer={ roleDialogFooter } onHide={ hideDialog } >

                <div className="p-field">
                    <label htmlFor="name">Name</label>
                    <InputText 
                        id="name" name="name" 
                        value={ values?.name } 
                        placeholder="Write role name"
                        onChange={ handleInputChange } 
                        required autoFocus autoComplete="off"
                        className={ (submitted && !values?.name) ? 'p-invalid':'' } />

                    { submitted && !values?.name && <small className="p-invalid color-danger">Name is required.</small> }
                </div>

                <div className="p-field">
                    <label htmlFor="description" style={{ fontSize: '.9rem' }}>Description</label>
                    <InputTextarea 
                        id="description" name="description"
                        value={ values?.description } 
                        placeholder="Write role description"
                        autoComplete="off" required
                        onChange={ handleInputChange }
                        maxLength={ 200 }
                        rows={ 2 } autoResize
                        className={classNames({ 'p-invalid': submitted && !values?.description })} />
                    { submitted && !values?.description && <small className="p-invalid color-danger">Description is required.</small> }
                </div>

            </Dialog>
        </>
    )
})

RoleFormDialog.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default RoleFormDialog