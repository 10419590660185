import React from 'react';

const Indices = (props) => {

    return (
        <>
            <h5>Índices</h5>
        </>
    )
}

export default Indices;