import * as actions from '../actions/operativization.actions';

const {handleActions} = require('redux-actions');

const initialState ={
    observationUnits:[],
    concepts: [],
    dimensions: [],
    indicators: [],
    indices: [],
    error:null,
};

export const OperativizationReducer = handleActions(
    {
        [actions.setObservationUnits]: (state, {payload}) =>({
            ...state,
            observationUnits: payload
        }),
        [actions.setConcepts]: (state, {payload}) =>({
            ...state,
            concepts: payload
        }),
        [actions.setDimensions]: (state, {payload}) =>({
            ...state,
            dimensions: payload
        }),
        [actions.setIndicators]: (state, {payload}) =>({
            ...state,
            indicators: payload
        }),
        [actions.setIndices]: (state, {payload}) =>({
            ...state,
            indices: payload
        }),
        [actions.setErrorAction]: (state,{payload}) =>({
            ...state,
            error:payload
        }),
    },
    initialState,
);
