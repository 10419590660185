import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { size } from 'lodash';
import * as surveyActions from '../../../../redux/actions/survey.actions';
import '../../../assets/scss/modelos.scss';
import surveyImg from '../../../assets/img/survey.svg';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

const Survey = (props) => {
  const history = useHistory();
  const user = useSelector((state) => state.LoginState.data);
  const role = useSelector((state) => state.RolNavigationState?.state);

  const toast = useRef(null);
  const actualiza = props?.actualizar;
  const list = props?.tipoId;

  const [polls, setPolls] = useState([]);
  const [layout, setLayout] = useState('grid');
  const [globalFilter, setGlobalFilter] = useState([]);
  const [pollId, setPollId] = useState('');
  const [dialog, setDialog] = useState(false);

  const loadSurveys = () => {
    if (list.tipo === 'activity') {
      surveyActions
        .getSurveyByActivity(list?.id, user?.access_token, role)
        .then((responseSurvey) => {
          setPolls(responseSurvey);
          setGlobalFilter(responseSurvey);
        })
        .catch(() => {
          toast.current.show({
            severity: 'warn',
            summary: 'Error de conexión',
            detail: 'A ocurrido un error, por favor intentalo de nuevo!',
            life: 3000,
          });
        });
    } else {
      surveyActions
        .getSurveyByModel(list?.id, user?.access_token, role)
        .then((responseSurvey) => {
          setPolls(responseSurvey);
          setGlobalFilter(responseSurvey);
        })
        .catch(() => {
          toast.current.show({
            severity: 'warn',
            summary: 'Error de conexión',
            detail: 'A ocurrido un error, por favor intentalo de nuevo!',
            life: 3000,
          });
        });
    }
  };

  const deletes = (pollId) => {
    surveyActions
      .deleteSurvey(pollId, user?.access_token, role)
      .then((responseDelete) => {
        if (responseDelete?.status === 200) {
          loadSurveys();
          toast.current.show({
            severity: 'success',
            summary: 'Exito',
            detail: 'Encuesta eliminada!',
            life: 3000,
          });
          setDialog(false);
          setPollId('');
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Error!',
            life: 3000,
          });
          setDialog(false);
          setPollId('');
        }
        setDialog(false);
        setPollId('');
      });
  };

  const deleteSurvey = () => {
    surveyActions
      .getSurveyByTemplate(pollId, user?.access_token)
      .then((response) => {
        if (response?.data !== null) {
          surveyActions
            .deleteSurveyMain(response?.data?.id, user?.access_token)
            .then(() => {
              deletes(pollId);
            })
            .catch(() => {
              toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'A ocurrido un error, por favor intentalo de nuevo!',
                life: 3000,
              });
            });
        } else {
          deletes(pollId);
        }
      })
      .catch(() => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'A ocurrido un error, por favor intentalo de nuevo!',
          life: 3000,
        });
      });
  };

  useEffect(() => {
    loadSurveys();
  }, [actualiza, list]);

  const handleConfigDialog = (pollId) => {
    setPollId(pollId);
    setDialog(true);
  };

  const searchSurvey = (text) => {
    if (text) {
      const newData = polls.filter(function (item) {
        const itemData = item?.name
          ? item?.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setGlobalFilter(newData);
    } else {
      setGlobalFilter(polls);
    }
  };

  const headerDialog = () => {
    return (
      <>
        <span className="p-d-block p-text-center">Alerta!</span>
      </>
    );
  };

  const confirmationDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-primary"
        type="button"
        label="Eliminar"
        onClick={() => deleteSurvey()}
      />

      <Button
        className="p-button-text p-button-danger"
        type="button"
        label="Cancelar"
        onClick={() => close()}
      />
    </>
  );

  const close = () => {
    setDialog(false);
    setPollId('');
  };

  const edit = async (surveyId) => {
    let data = await surveyActions.getSurveyById(
      surveyId,
      user?.access_token,
      role
    );
    history.push({
      pathname: '/admin/survey/edit',
      state: {
        poll: data,
      },
    });
  };

  const dataviewHeader = (
    <div className="p-grid p-nogutter">
      <div className="p-col-12 p-md-6">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            disabled={polls ? false : true}
            type="search"
            placeholder="Buscar Enc..."
            onInput={(e) => searchSurvey(e.target.value)}
          />
        </span>
      </div>
      <div className="p-col-6" style={{ textAlign: 'right' }}>
        <DataViewLayoutOptions
          layout={layout}
          onChange={(e) => setLayout(e.value)}
        />
      </div>
    </div>
  );

  const dataviewModelos = (data) => {
    return (
      <div
        className=" cardif p-col-12"
        onClick={() => {
          handleConfigDialog(data?.id);
        }}
      >
        <div className="product-list-item">
          <img src={surveyImg} alt={data.name} style={{ width: '100px' }} />
          <div className="p-grid">
            <div className="product-list-detail p-col">
              <div className="product-name">{data.name}</div>
              <div className="product-description">{data.description}</div>
              <div
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {data?.status === 3 || list?.tipo === 'model' ? (
                  <Button
                    className="p-button-warning  p-pl-1 p-pr-1 p-mr-1"
                    iconPos="bottom"
                    label="Ver encuesta"
                    onClick={() =>
                      history.push({
                        pathname: '/view/Survey',
                        state: { formId: data?.id },
                      })
                    }
                  />
                ) : (
                  <Button
                    className="p-button-primary p-mr-1"
                    iconPos="bottom"
                    label="Editar"
                    onClick={() => edit()}
                  />
                )}
                <Button
                  className="p-button-danger"
                  label="Eliminar"
                  onClick={() => deleteSurvey(data?.id)}
                />
              </div>
            </div>
            {data?.status === 3 ? (
              <div className="p-col">
                <i className="pi pi-check-circle"></i>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  };

  const dataviewGridItem = (data) => {
    return (
      <div className=" p-col-12 p-md-3">
        <div className=" cardif product-grid-item card">
          <div
            className="p-dataview-header"
            style={{
              display: 'flex',
              verticalAlign: 'middle',
              justifyContent: 'center',
            }}
          >
            <img src={surveyImg} alt={data?.name} style={{ width: '100px' }} />
          </div>
          <div className="p-grid p-mt-2">
            <div className="product-grid-item-content p-col-10">
              <div className="product-name">{data?.name}</div>
              <div className="product-description">{data?.description}</div>
              <div
                style={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {data?.status === 3 || list?.tipo === 'model' ? (
                  <Button
                    className="p-button-warning  p-pl-1 p-pr-1 p-mr-1 p-mb-1"
                    iconPos="bottom"
                    label="Ver encuesta"
                    onClick={() =>
                      history.push({
                        pathname: '/view/Survey',
                        state: { formId: data?.id },
                      })
                    }
                  />
                ) : (
                  <Button
                    className="p-button-primary p-mr-1 p-mb-1"
                    iconPos="bottom"
                    label="Editar"
                    onClick={() => edit(data?.id)}
                  />
                )}
                <Button
                  className="p-button-danger p-mb-1"
                  label="Eliminar"
                  onClick={() => {
                    setDialog(true);
                    setPollId(data?.id);
                  }}
                />
              </div>
            </div>
            {data?.status === 3 ? (
              <div className="p-col-1">
                <i className="pi pi-check-circle"></i>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (data, layout) => {
    if (!data) {
      return;
    }
    if (layout === 'list') {
      return dataviewModelos(data);
    } else if (layout === 'grid') {
      return dataviewGridItem(data);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div>
        {size(globalFilter) >= 0 ? (
          <>
            <DataView
              className="p-ml-3 p-mr-3"
              value={globalFilter}
              layout={layout}
              paginator
              rows={4}
              itemTemplate={itemTemplate}
              header={dataviewHeader}
            />
            <br />
          </>
        ) : (
          <h4>The requested survey does not exist</h4>
        )}
      </div>
      <div>
        {/* Alerta de estar seguro de eliminar la encuesta */}
        <Dialog
          header={headerDialog}
          visible={dialog}
          onHide={() => close()}
          style={{ width: '20%' }}
          modal
          footer={confirmationDialogFooter}
        >
          <div className="p-text-center">
            <p>Esta seguro de eliminar la encuesta</p>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default Survey;
