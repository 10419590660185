import React, { useEffect, useRef, useState } from 'react';
import NroInfoCard from '../../../components/UI/NroInfoCard';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { retrieveActivitySummaryByModel } from '../../../../redux/actions/activity.actions';
import { getTimeByDays } from '../../utils/helpers/monitoringHelpers';
import { OverlayPanel } from 'primereact/overlaypanel';
import Settings from '../Settings';
import { useTranslation } from 'react-i18next';

const Model = props => {

    const op = useRef(null);
    const user = useSelector((state) => state.LoginState.data);
    const { model } = useSelector(state => state.ModelState);
    const budget = 5000;
    const { t: tGlobal } = useTranslation(['global']);
    const { unitTime } = useSelector((state) => state.UserPreferencesState);
    const [ activitySummary, setActivitySummary ] = useState({
        total: 0,
        finaliseds: 0,
        running: 0,
        uninitiateds: 0,
        unassigneds: 0,
        duration: 0,
        duration_elapsed: 0,
        budget: 0,
        budget_finaliseds: 0,
        budget_running: 0,
        budget_uninitiateds: 0,
    });
    
    const timeLabel = tGlobal('times.' + unitTime?.label.toLowerCase());

    const toggle = (event) => {
        op.current.toggle(event);
    };

    useEffect(() => {
        if  (model?.id) {
            retrieveActivitySummaryByModel(model?.id, user?.access_token).then((summary) => {
                setActivitySummary(summary);
            });
        }
    }, [ user, model ]);

    return (
        <div className='animate__animated animate__zoomIn'>
            <div className="card p-shadow-10">

                <h5 className='p-text-center'>Resumen del Modelo { model?.name }</h5>

                <div className='p-grid'>

                    <NroInfoCard
                        label='Procesos' icon='pi pi-sitemap' color='blue'
                        value={ 8 } />

                    <NroInfoCard
                        label='Actividades' icon='pi pi-check-circle' color='gray'
                        value={ activitySummary?.total }  />

                    <NroInfoCard
                        label='Presupuesto' icon='pi pi-dollar' color='blue'
                        value={ budget } valueSuffix='dólares' />

                    <NroInfoCard
                        label='Duración' icon='pi pi-calendar-times' color='gray'
                        value={ getTimeByDays(activitySummary?.duration, unitTime) } valueSuffix={ timeLabel } />
                </div>
                
                <OverlayPanel ref={ op } appendTo={document.body} showCloseIcon style={{ maxWidth: '350px' }}>
                    <Settings />
                </OverlayPanel>

                <div className="card schedule" style={{ border: '1px solid gray' }}>
                    <div className="card-header">
                        <div className="card-title">
                            <h6>Resumen del modelo</h6>
                            {/* <p className="subtitle">
                                Nombre del modelo
                            </p> */}
                        </div>
                        <p className="subtitle">
                            <Button
                                className='p-button-outlined p-button-rounded'
                                icon='pi pi-cog'
                                onClick={ toggle } />
                        </p>
                    </div>

                    <p>Duración</p>

                    <ul>
                        <li>
                            <div className="schedule-header">
                                <h6>Duración total</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { getTimeByDays(activitySummary?.duration, unitTime) }
                                    </span>
                                    <span className='p-ml-1'>
                                        { timeLabel }
                                    </span>
                                </span>
                            </div>
                            <span>Suma de la duración de las actividades</span>
                        </li>
                        <li>
                            <div className="schedule-header">
                                <h6>Tiempos transcurridos</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { getTimeByDays(activitySummary?.duration_elapsed, unitTime) }
                                    </span>
                                    <span className='p-ml-1'>
                                        { timeLabel }
                                    </span>
                                </span>                                                
                            </div>
                            <span>Suma de la duración de las actividades finalizadas</span>
                        </li>
                    </ul>

                    <p>Presupuesto</p>

                    <ul>
                        <li>
                            <div className="schedule-header">
                                <h6>Presupuesto total</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { budget }
                                    </span>
                                    <span className='p-ml-1'>
                                        dólares
                                    </span>
                                </span>
                            </div>
                            <span>Presupuesto inicial asignado al modelo</span>
                        </li>
                        <li>
                            <div className="schedule-header">
                                <h6>Presupuesto sobrante</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { budget - activitySummary?.budget }
                                    </span>
                                    <span className='p-ml-1'>
                                        dólares
                                    </span>
                                </span>
                            </div>
                            <span>Resto del presupuesto inicial entre el presupuesto sin asignaciones, gastato, asignado y en ejecución</span>
                        </li>
                        <li>
                            <div className="schedule-header">
                                <h6>Presupuesto sin Asignaciones</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { activitySummary?.budget_unassigneds }
                                    </span>
                                    <span className='p-ml-1'>
                                        dólares
                                    </span>
                                </span>                                                
                            </div>
                            <span>Suma del presupuesto de las actividades sin asignaciones</span>
                        </li>
                        <li>
                            <div className="schedule-header">
                                <h6>Presupuesto Asignado</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { activitySummary?.budget_uninitiateds }
                                    </span>
                                    <span className='p-ml-1'>
                                        dólares
                                    </span>
                                </span>                                                
                            </div>
                            <span>Suma del presupuesto de las actividades aún no iniciadas</span>
                        </li>
                        <li>
                            <div className="schedule-header">
                                <h6>Presupuesto en Ejecución</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { activitySummary?.budget_running }
                                    </span>
                                    <span className='p-ml-1'>
                                        dólares
                                    </span>
                                </span>                                                
                            </div>
                            <span>Suma del presupuesto de las actividades en ejecución</span>
                        </li>
                        <li>
                            <div className="schedule-header">
                                <h6>Presupuesto Gastado</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { activitySummary?.budget_finaliseds }
                                    </span>
                                    <span className='p-ml-1'>
                                        dólares
                                    </span>
                                </span>                                                
                            </div>
                            <span>Suma del presupuesto de las actividades finalizadas</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

Model.propTypes = {}

export default Model