import React from 'react'
import PropTypes from 'prop-types';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';

const ListItem = ({ data, handleItemClick }) => {

    return (
        <div className="cardif p-col-12">
            <div className="product-list-item">

                <div>
                    <img src={ data?.image } alt={ data?.name } style={{ width: '100px' }}/>
                </div>

                <div className="product-list-detail">                    
                    <div className="product-name">
                        { data?.name }
                    </div>
                    <div className="product-description">
                        { data?.description }
                    </div>

                    <Rating
                        value={ 4 } 
                        readonly cancel={ false } />
                        
                    <div>
                        <i className="pi pi-tag product-category-icon"></i>
                        <span className="product-category">{data?.category.name}</span>
                    </div>
                </div>

                <div className="product-list-action">
                    <span className="product-price">$10</span>
                    <Button
                        icon='pi pi-eye'
                        label='Detalles'
                        className='p-button p-button-info'
                        onClick={ () => handleItemClick(data) } />
                    <span className='status-success p-text-center'>Disponible</span>
                </div>
            </div>
        </div>
      );
}

ListItem.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ListItem