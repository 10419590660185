import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CreateInputIndicator from './CreateInputIndicator';
import RelateIndicator from './RelateIndicator';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

const VariablesRelationship = ({ relationships, setRelationships, indicators, setFormSchema, formSchema }) => {

    const opCreateRef = useRef(null);
    const opAddRef = useRef(null);
    const [ input, setInput ] = useState({});

    const indicatorTemplate = (rel) => {

        if (rel?.variable) {
            
            return (
                <span className='input-variable-info'>
                    { rel?.variable?.label }
                </span>
            );

        } else {

            return (
                <>
                    <Button 
                        icon='pi pi-plus'
                        onClick={ (event) => {
                            opAddRef.current.toggle(event);
                            setInput(rel.input);
                        }} />
                </>
            );
        }
    }

    const inputTemplate = (rel) => (
        <span className='input-variable-info'>
            { rel?.input?.label }
        </span>
    );

    return (
        <>
            <div className='p-shadow-5 p-p-3 p-m-2 card' style={{ borderRadius: '5px' }} >

                <h5 className='p-text-center'>Variables and fields relationships</h5>

                <Toolbar 
                    left={<b>Field</b>}
                    right={<b>Variable</b>}
                    className='p-mb-2' 
                    style={{ border: '0px' }} />

                <div className='p-mb-3 relationships-container'>
                    {
                        relationships.map((rel, i) => {
                            return (
                                <div key={ i }>
                                   <Toolbar left={ inputTemplate(rel) } right={ indicatorTemplate(rel) } className='p-mb-1' /> 
                                </div>
                            );
                        })
                    }
                </div>

                <Button 
                    className='p-button-outlined'
                    icon='pi pi-plus' 
                    style={{ width: '100%' }}
                    onClick={ (event) => opCreateRef.current.toggle(event) } />
            </div>

            <CreateInputIndicator 
                opRef={ opCreateRef }
                indicators={ indicators }
                setRelationships={ setRelationships }
                setFormSchema={ setFormSchema }
                formSchema={ formSchema }
                relationships={ relationships } />

            <RelateIndicator 
                opRef={ opAddRef }
                indicators={ indicators }
                setRelationships={ setRelationships }
                input={ input }
                setInput={ setInput } />
        </>
    )
}

VariablesRelationship.propTypes = {
    relationships: PropTypes.array.isRequired,
}

export default VariablesRelationship;