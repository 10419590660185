import React from "react";
import ResourcesList from "./ResourcesList";

const ResourcesProcess = () => {

    return (
        <div>
            <div className="card">
                <ResourcesList />
            </div>
        </div>
    );
};

export default ResourcesProcess;