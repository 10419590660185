import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { assignmentStatusOptions, getAssignmentStatusById } from '../../../utils/constants/monitoringConstants';
import { getStatusCls } from '../../../utils/helpers/monitoringHelpers';
import { updateActivity } from '../../../../../redux/actions/activity.actions';
import Assignments from './Assignments';
import Settings from './Settings';

const ActivityDialog = ({ dialog, setDialog, activity, setActivity, process }) => {

    const toast  = useRef(null);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ status, setStatus ] = useState({});

    useEffect(() => {
        if (activity?.id) setStatus(getAssignmentStatusById(activity?.assignment_status));
    }, [ activity ])

    const  handleStatusChange = ({ value }) => {
        if (value?.id !== 0) {

            const body = { assignment_status: value?.id }

            if (value?.id === 1 && activity?.progress !== 0) body['progress'] = 0;
            if (value?.id === 2 && (activity?.progress === 0 || activity?.progress === 100)) body['progress'] = 10;
            if (value?.id === 3 && activity?.progress !== 100) body['progress'] = 100;

            updateActivity(activity?.id, body, user?.access_token, role).then((activityUpdated) => {
                setActivity(activityUpdated);
            });
        } else {
            toast.current.show({ severity: 'error', summary: 'Estado no cambiado', detail: 'Asignaciones existentes', life: 2000 });
        }
    }

    const dialogHeader = () => {
        return (
            <>
                <span className="p-text-center" style={{ fontSize: '1.1rem' }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a style={{ fontSize: '1rem' }}>
                        <i>{activity?.name}</i>
                    </a>
                </span>
            </>
        );
    }

    const selectedStatusItemTemplate = (value) => {
        if (value) {
            return <span className={'status-' + getStatusCls(value?.id)}>{ value?.label }</span>
        } else {
            return <span>Estado</span>
        }
    }

    const dialogFooter = () => {
        return (
            <div className='p-mt-3'>
                <Dropdown
                    placeholder='Estado'
                    options={ assignmentStatusOptions }
                    optionLabel='label'
                    value={ status }
                    onChange={ handleStatusChange }
                    disabled={(activity?.assignment_status === 0) ? true:false}
                    valueTemplate={ selectedStatusItemTemplate } />
            </div>
        );
    }

    return (
        <Dialog
            footer={ dialogFooter }
            header={ dialogHeader } visible={ dialog }
            onHide={() => setDialog(false)}
            style={{ maxWidth: '400px', minWidth: '280px' }} modal >

            <Toast ref={ toast } />

            <Assignments
                process={ process }
                activity={ activity }
                toast={ toast }
                setActivity={ setActivity } />

            <br />

            <Settings
                process={ process }
                activity={ activity }
                setActivity={ setActivity }
                toast={ toast } />

        </Dialog>
    )
}

ActivityDialog.propTypes = {
    dialog: PropTypes.bool.isRequired,
    setDialog: PropTypes.func.isRequired,
    activity: PropTypes.object.isRequired,
    process: PropTypes.object,
}

export default ActivityDialog;