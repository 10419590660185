import axios from "axios";
import { urlKeyTopicsAndTrends, urlTopic } from "../../api/urls";

//////////////////////
///// CONCEPTS
//////////////////////
export const getConcepts = () => async (dispatch, getState) => {
  
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { data, status } = await axios.get(`${urlKeyTopicsAndTrends}/${modelId}/${role}`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });

    if (status === 200) {
      return data;
    } else {
      throw new Error("Error fethching key concept and trend.");
    }
  } catch (error) {
    throw error;
  }
};

export const createConcept = (concept) => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.post(`${urlKeyTopicsAndTrends}/${modelId}/${role}`,
      {
        model_id: modelId,
        label: concept.label,
        topic_type: concept.topic_type,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (status === 200) {
      return data;
    } else {
      throw new Error("Error creating key concept and trend");
    }
  } catch (error) {
    throw error;
  }
};

export const updateConcept = (concept) => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.put(
      `${urlKeyTopicsAndTrends}/${role}`,
      {
        id: concept.id,
        model_id: modelId,
        label: concept.label,
        topic_type: concept.topic_type,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (status === 200) {
      return data;
    } else {
      throw new Error("Error updating key concept and trend");
    }
  } catch (error) {
    throw error;
  }
};

export const deleteConcept = (conceptId) => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const role = getState().RolNavigationState?.state;

  try {
    const { status } = await axios.delete(`${urlKeyTopicsAndTrends}/${conceptId}/${role}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (status !== 200) {
      throw new Error("Error deleting key concept and trend");
    }
  } catch (error) {
    throw error;
  }
};

//////////////////////
///// TOPIC
//////////////////////

export const getTopic = (conceptId) => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const role = getState().RolNavigationState?.state;

  try { 
    const { status, data } = await axios.get(`${urlTopic}/${conceptId}/${role}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (status === 200) return data;
    else throw new Error("Error fetching topic");
  } catch (error) {
    throw error;
  }
};

export const updateTopic = (conceptId, attribute, topic) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const role = getState().RolNavigationState?.state;

    try {
      const { status } = await axios.put(`${urlTopic}/${conceptId}/${role}`,
        {
          id: topic.id,
          label: attribute,
          value: topic[attribute],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200)
        throw new Error(
          `Error updating topic attribute: ${attribute}`
        );
    } catch (error) {
      throw error;
    }
  };
