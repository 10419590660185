import { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const FilePickerDialog = (props) => {
    const maxFileSize = 100000000;

    const [files, setFiles] = useState(undefined);
    const [error, setError] = useState(undefined);
    const toast = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (!error) {
            return;
        }
        console.error(error);
        showMessage("error", "Error", error);
    }, [error]);

    const showMessage = (severity, summary, detail) => {
        toast?.current?.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: 3000,
        });
    };

    const onSelectHandler = ({ files }) => {
        for (const file of files) {
            if ((file?.size ?? 0) > maxFileSize) {
                setError(file.name + " Tamaño de imagen super al límite permitido (1MB)");
                return;
            }
        }
        setFiles(files);
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        onSelectHandler({ files: files });
    };

    const onContinue = () => {
        if (!files?.length) return;

        const _files = [];
        for (const file of files) {
            _files.push(file);
        }
        handleReset();
        props.onContinue?.call(this, _files);
    }

    const onHideDialog = () => {
        handleReset();
        props.onHideDialog?.call(this);
    }

    const handleReset = () => {
        setFiles(undefined);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const dialogFooter = (
        <>
            <Button
                label='Cancel'
                icon='pi pi-times'
                className='p-button-text'
                onClick={onHideDialog} />

            <Button
                label='Sincronizar'
                icon='pi pi-check'
                className='p-button-text'
                onClick={onContinue} />
        </>
    );

    const dialogHeader = (
        <h5>
            {props.header || 'Selección de Archivos'}
        </h5>
    );

    return (
        <Dialog
            modal
            className='p-fluid'
            style={{ width: '650px' }}
            contentStyle={{ minHeight: '100px' }}
            visible={props.visible}
            footer={dialogFooter}
            header={dialogHeader}
            onHide={onHideDialog}
        >
            <div className='p-field'>
                <label htmlFor='sync-components-file'>Seleccionar {props.multiple ? 'Archivos' : 'Archivo'}</label>

                <input
                    type="file"
                    id='sync-components-file'
                    onChange={handleFileChange}
                    style={{ width: '100%' }}
                    ref={fileInputRef}
                    multiple={props.multiple}
                    accept={props.accept}
                />
            </div>
        </Dialog>
    );
}

export default FilePickerDialog;