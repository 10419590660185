/**
 * Main backend
 */
export const URL_BASE = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_MAIN_API_TAG}`;

/**
 * OP backend
 */
export const URL_BASE_OPERATIVIZATION_SYSTEM = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_OP_API_TAG}`;

/**
 * CS backend
 */
export const URL_BASE_COMPLEX_SYSTEM = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_CS_API_TAG}`;

/**
 * SN backend
 */
export const URL_BASE_SOCIAL_NETWORK = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_SN_API_TAG}`;

/**
 * FRM backend
 */
export const URL_SURVEYS = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_FRM_API_TAG}`;

export const URL_BASE_PASARELA_PAGOS =
  'https://payment-gateways-v4.herokuapp.com/';
// export const URL_BASE_PASARELA_PAGOS = 'http://127.0.0.1:8005/';
export const URL_BASE_NEO4J =
  'http://ec2-54-198-149-53.compute-1.amazonaws.com:8001/api/v1';

/**
 * URLs ****************************************************************************************************************
 */

/* Genercis URLs */
export const urlChannels = '/channels';
export const urlAppUsers = '/app-users';

/* User URLs */
export const urlLogin = '/user/login';
export const urlSendInvitation = '/user/invitation';
export const urlPeopleInvited = '/user/list-pending-invitations';
export const urlGetUsers = '/user/list-users';
export const urlRegister = '/user/register';
export const urlListUserInfoAppByTeam = '/user/list-info-users/by-team/';
export const urlRegisterGuest = '/user/activate-team-user';
export const urlRegisterUserInfoApp = '/user/register-app';
export const urlUpdateInvitation = '/user/toggle-tutorial';
export const urlUpdateUSerInfoApp = '/user/update/with-password';
export const verifyTextCodeUrl = '/user/verify-text-code';
export const urlDeleteUser = '/user/delete/';

export const urlRetrieveUserPreferences =
  URL_BASE + '/user-preferences/retrieve/';
export const urlRetrieveUserPreferencesByUser =
  URL_BASE + '/user-preferences/retrieve/by-user/';
export const urlListUserPreferences = URL_BASE + '/user-preferences/list';
export const urlCreateUserPreferences = URL_BASE + '/user-preferences/create';
export const urlUpdateUserPreferences = URL_BASE + '/user-preferences/update/';
export const urlDeleteUserPreferences = URL_BASE + '/user-preferences/delete/';

/* Plans URLs */
export const urlListPlans = '/plans/list';
export const urlCreatePlan = '/plans/create';
export const urlUpdatePlan = '/plans/update';
export const urlDeletePlan = '/plans/delete';

/* Repository URLs */
export const urlListRepositories = '/repositories/list';
export const urlCreateRepositories = '/repositories/create';

export const urlCreateModelNeo4j = '/models-api/create-model';
export const urlDeleteModelNeo4j = '/models-api/delete-model';
export const urlGetModelByFilterNeo4j = '/models-api/get-model-by-filter';

/* Model URLs */
export const urlRetrieveModel = '/models/retrieve/';
export const urlCreateModel = '/models/create';
export const urlUpdateModel = '/models/update/';
export const urlDeleteModel = '/models/delete/';
export const urlListModelsByTeam = '/models/list/by-team/';
export const urlListVariables = '/models/list-variables/';
export const urlExecuteModel = URL_BASE + '/models/execute/'; // ONLY ACQUIRED SERVICE

export const urlRetrieveModelGeneralities =
  URL_BASE + '/model-generalities/retrieve/';
export const urlRetrieveModelGeneralitiesByModel =
  URL_BASE + '/model-generalities/retrieve/by-model/';
export const urlCreateModelGeneralities =
  URL_BASE + '/model-generalities/create';
export const urlUpdateModelGeneralities =
  URL_BASE + '/model-generalities/update/';
export const urlDeleteModelGeneralities =
  URL_BASE + '/model-generalities/delete/';
export const urlUpdateModelLocation =
  URL_BASE + '/model-generalities/update/location/';

/* Teams URLs */
export const urlRetrieveTeam = '/teams/retrieve/';
export const urlListTeam = '/teams/list';
export const urlListMembersByTeamId = '/teams/list-all-users/';
export const urlCheckTeamAvailability = '/teams/team-available';
export const urlTeamsUsersNoApp = '/teams/list-users-no-app';
export const urlUpdateTeam = '/teams/update/';

/* Models adquired URLs */
export const urlModelsAcquiredByTeam = '/models-acquired/list-by-team';
export const urlGetModelNeo4j = '/models-api/get-model';
export const urlGetModelAdquired = '/models-acquired/get';

/* Roles URLs */
export const urlListRoles = '/roles/list';
export const urlGetRoleById = '/roles/list/';
export const urlCreateRole = '/roles/create';
export const urlDeleteRole = '/roles/delete/';
export const urlUpdateRole = '/roles/update/';

/* Payment Methods URLs */
export const urlGetPaymentMethods = '/payment-methods/list';
export const urlCreatePaymentMethod = '/payment-methods/create';
export const urlUpdatePaymentMethod = '/payment-methods/update/';
export const urlDeletePaymentMethod = '/payment-methods/delete/';

/* Toolbox URLs */
export const urlListElementByModel = '/toolbox/list-element/by-model/';
export const urlCreateElement = '/toolbox/create-element/';
export const urlUpdateElement = '/toolbox/update-element/';
export const urlDeleteElement = '/toolbox/delete-element/';

export const urlRetrieveTool = '/toolbox/retrieve/';
export const urlRetrieveToolByElement = '/toolbox/retrieve/by-element/';
export const urlListToolByModel = '/toolbox/list/by-model/';
export const urlUpdateAllTool = '/toolbox/update/';
export const urlListTools = '/toolbox/list';
export const urlListDistinctTools = '/toolbox/list-distinct';
export const urlUpdateTool = '/toolbox/update';

export const urlListComplemantaryByToolbox =
  '/toolbox/list-complementary/by-toolbox/';
export const urlCreateComplementaryToolbox = '/toolbox/create-complementary/';
export const urlDeleteComplementaryToolbox = '/toolbox/delete-complementary/';

/* Surveys */
export const urlGetSurveyById = URL_SURVEYS + '/surveys/';
export const urlListSurveysByProcess = URL_SURVEYS + '/surveys/process/';
export const urlListSurveysByActivity = URL_SURVEYS + '/surveys/activity/';
export const urlListSurveysByModel = URL_SURVEYS + '/surveys/model/';
export const urlCreateSurvey = URL_SURVEYS + '/surveys/';
export const urlUpdateSurvey = URL_SURVEYS + '/surveys/';
export const urlPublishSurvey = URL_SURVEYS + '/surveys/publish/';
export const urlDeleteSurvey = URL_SURVEYS + '/surveys/';

export const urlCreatePublishSurvey = '/survey/create';
export const urlDeleteSurveyMain = '/survey/delete/';
export const urlGetSurveyByTemplateId = '/survey/list/by-survey-template/';
export const urlListSurveysPublishedByModel =
  '/survey/list-survers-templates/by-model';

export const urlAssignedSurveysList = '/user-assignment-survey/list/all';
export const urlListAssignmentByUser = '/user-assignment-survey/list/by-user';
export const urlCreateSurveyAssignment =
  '/user-assignment-survey/create/by-survey-template';
export const urlUpdateSurveyAssignment =
  '/user-assignment-survey/update-assignments';

/* Moments-Models URLs */
export const urlListMomentsModelByMoment = '/moments-model/list-all-info/';

export const urlListMoments = '/moments/list';

/* Processes URLs */
export const urlGetProcessById = '/process/retrieve/';
export const urlListProcessByMomentModel = '/process/list/by-model-moment/';
export const urlUpdateProcessXML = '/process/update-xml-body/';
export const urlUpdateProcess = '/process/update/';
export const urlCreateProcess = '/process/create/';
export const urlCreateIndicatorProcess = '/process/associate-indicator';
export const urlGetIndicatorProcess = '/process/indicators-by-process';
export const urlDeleteIndicatorProcess = '/process/delete-indicator';
export const urlDeleteProcess = '/process/delete/';

export const urlListToolProcess = '/process/list-tools/by-process/';
export const urlCreateToolProcess = '/process/associate-tool/';
export const urlDeleteToolProcess = '/process/delete-tool/';

export const urlListResourceProcess = '/process/list-resources/by-process/';
export const urlCreateResourceProcess = '/process/associate-resource/';
export const urlDeleteResourceProcess = '/process/delete-resource/';

export const urlListResultProcess = '/process/list-results/by-process/';
export const urlCreateResultProcess = '/process/associate-result/';
export const urlDeleteResultProcess = '/process/delete-result/';
export const urlUpdateResultProcess = '/process/update-result/';

/* Unit time type URLs */
export const urlGetUnitTimeTypes = '/unit-time-type/list';

/* Activities URLs */
export const urlRetrieveActivity = URL_BASE + '/activity/retrieve/';
export const urlRetrieveActivityByTask =
  URL_BASE + '/activity/retrieve/by-task-id/';
export const urlRetrieveActivitySummaryByModel =
  URL_BASE + '/activity/retrieve/summary/by-model/';
export const urlRetrieveActivitySummaryByProcess =
  URL_BASE + '/activity/retrieve/summary/by-process/';
export const urlListActivityByModel = URL_BASE + '/activity/list/by-model/';
export const urlListActivityByFilters = URL_BASE + '/activity/list/by-filters';
export const urlCreateActivity = URL_BASE + '/activity/create/';
export const urlUpdateActivity = URL_BASE + '/activity/update/';
export const urlUpdateActivityByTask =
  URL_BASE + '/activity/update/by-task-id/';
export const urlUpdateActivityType = URL_BASE + '/activity/update-type/'; // DON'T USED
export const urlUpdateActivityTypeByTask =
  URL_BASE + '/activity/update-type/by-task-id/';
export const urlDeleteActivity = URL_BASE + '/activity/delete/'; // DON'T USED
export const urlDeleteActivityByTask =
  URL_BASE + '/activity/delete/by-task-id/';

/* Gateways URLs */
export const urlRetrieveGatewayByGatewayId =
  URL_BASE + '/gateway/retrieve/by-gateway-id/';
export const urlCreateGateway = URL_BASE + '/gateway/create/';
export const urlDeleteGatewayByGatewayId =
  URL_BASE + '/gateway/delete/by-gateway-id/';

/* Start Event URLs */
export const urlRetrieveStartEventByStartEventId =
  URL_BASE + '/start-event/retrieve/by-start-event-id/';
export const urlCreateStartEvent = URL_BASE + '/start-event/create/';
export const urlDeleteStartEventByStartEventId =
  URL_BASE + '/start-event/delete/by-start-event-id/';

/* End Event URLs */
export const urlRetrieveEndEventByEndEventId =
  URL_BASE + '/end-event/retrieve/by-end-event-id/';
export const urlCreateEndEvent = URL_BASE + '/end-event/create/';
export const urlDeleteEndEventByEndEventId =
  URL_BASE + '/end-event/delete/by-end-event-id/';

/* Connections Shapes URLs */
export const urlCreateConnectionShape = URL_BASE + '/connection-shape/create/';
export const urlDeletePreviousConnection =
  URL_BASE + '/connection-shape/delete-previous-connection/';
export const urlDeleteNextConnection =
  URL_BASE + '/connection-shape/delete-next-connection/';

/*Knowledge Base*/
export const urlListItems = '/knowledge/list';
export const urlCreateKnowledgePurpose = '/knowledge/create-purpose';
export const urlCreateKnowledgeTheory = '/knowledge/create-theory';
export const urlCreateKnowledgeMethod = '/knowledge/create-method';
export const urlCreateKnowledgeTool = '/knowledge/create-tool';
export const urlCreateKnowledgeDuality = '/knowledge/create-duality';
export const urlCreateKnowledgeInteractions = '/knowledge/create-interactions';
export const urlCreateKnowledge = '/knowledge/create';
export const urlUpdateKnowledge = '/knowledge/update';

/*Complex Systems*/

/*Complex Systems PREVIOUS VERSION*/
export const urlListSystems = '/subsystems/list';
export const urlListSystemsByLevel = '/subsystems/list-by-level';
export const urlCreateSystem = '/subsystems/create';
export const urlUpdateSystem = '/subsystems/update';

/* Roles URLs */
export const urlListCategories = '/categories/list';
export const urlListCategoriesDropdown = '/categories/list-dropdown';
export const urlGetCategoryById = '/categories/list/';
export const urlCreateCategory = '/categories/create';

/* Forms URLs */
export const urlGetFormByActivity = URL_SURVEYS + '/forms/activity/';
export const urlCreateForm = URL_SURVEYS + '/forms/';
export const urlUpdateForm = URL_SURVEYS + '/forms/update/';
export const urlDeleteForm = URL_SURVEYS + '/forms/';

/* Payment Gateway URLs */
export const urlGetPaypalButton = 'paypal';
export const urlCreatePagoPaypal = 'paypal/save-billing-data/';
export const urlGet2CheckoutForm = 'twocheckout';
export const urlCreatePaymentIntent = 'stripepayments/create-payment-intent/';
export const urlCreatePago2Checkout = 'twocheckout/pago/';
export const urlCreateStripeSubscription =
  'stripepayments/create-subscription/';
export const urlSavePaypalSubscriptionData = 'paypal/save-subscription-data/';
export const urlCreatePagoStripe = 'stripepayments/save-billing-data/';
export const urlGetOrCreateStripeCustomer =
  'stripepayments/get-or-create-customer/';
export const urlIsPurchased = 'purchase/is-purchased/';
export const urlGetPaymentMethodsByCustomer =
  'purchase/customer-payment-methods/';
export const urlGetSubscriptionHistory = 'purchase/subscription-history/';
export const urlGetLastestSubscription = 'purchase/subscription-lastest/';

/* Operativization Variables URLs */
export const urlDesignObservationUnits = `${URL_BASE_OPERATIVIZATION_SYSTEM}/design/observation-units`;
export const urlDesignConcepts = `${URL_BASE_OPERATIVIZATION_SYSTEM}/design/concepts`;
export const urlDesignDimensions = `${URL_BASE_OPERATIVIZATION_SYSTEM}/design/dimensions`;
export const urlDesignIndicators = `${URL_BASE_OPERATIVIZATION_SYSTEM}/design/indicators`;
export const urlDesignIndicatorsByIds = `${URL_BASE_OPERATIVIZATION_SYSTEM}/design/indicators/ids`;
export const urlDesignIndices = `${URL_BASE_OPERATIVIZATION_SYSTEM}/design/indices`;
export const urlPublishedOperativization = `${URL_BASE_OPERATIVIZATION_SYSTEM}/published`;
export const urlPublishedOperativizationPublish = `${URL_BASE_OPERATIVIZATION_SYSTEM}/published/publish`;

export const urlVariablesTypes = `${URL_BASE_OPERATIVIZATION_SYSTEM}/design/variables-types`;

/*Person URls*/
export const urlListPersons = '/person/list-person';
export const urlCreatePersons = '/person/persons/';

/*In design Complex System URL*/
export const urlDesignCSCInit = `${URL_BASE_COMPLEX_SYSTEM}/design/init`;
export const urlDesignCSCModelization = `${URL_BASE_COMPLEX_SYSTEM}/design/modelization-components`;
export const urlDesignCSC = `${URL_BASE_COMPLEX_SYSTEM}/design`;
export const urlDesignCSCComposition = `${URL_BASE_COMPLEX_SYSTEM}/design/composition`;
export const urlDesignCSCInteractions = `${URL_BASE_COMPLEX_SYSTEM}/design/interactions`;
export const urlDesignCSCMechanisms = `${URL_BASE_COMPLEX_SYSTEM}/design/modelization/mechanisms`;
export const urlDesignCSLimits = `${URL_BASE_COMPLEX_SYSTEM}/design/modelization/limits`;
export const urlDesignCSCEnvironment = `${URL_BASE_COMPLEX_SYSTEM}/design/modelization/environment`;
export const urlDesignCSCProperties = `${URL_BASE_COMPLEX_SYSTEM}/design/modelization/properties`;
export const urlDesignCSCMeaning = `${URL_BASE_COMPLEX_SYSTEM}/design/modelization/meaning`;

export const urlDesignCSCGraph = `${URL_BASE_COMPLEX_SYSTEM}/design/graph-representation`;
export const urlPublishedCSCPublish = `${URL_BASE_COMPLEX_SYSTEM}/published/publish`;
export const urlPublishedCSC = `${URL_BASE_COMPLEX_SYSTEM}/published`;

/*Models Categories*/
export const urlModelCategories = `${URL_BASE}/categories/list-dropdown`;

/* Models Basic Info: Principles, Purpose, Scope */
export const urlBasicInfo = `${URL_BASE}/models/basic-info/`;
export const urlUpdateBasicInfo = `${URL_BASE}/models/basic-info/`;

/* Knowledge Baese */
export const urlConcept = `${URL_BASE}/knowledge-concepts/concepts`;
export const urlKnowledge = `${URL_BASE}/knowledge-concepts/knowledge`;

/* Key topics and trends */
export const urlKeyTopicsAndTrends = `${URL_BASE}/key-topics-and-trends/concepts`;
export const urlTopic = `${URL_BASE}/key-topics-and-trends/topics`;

/* Principles, Theories, and Laws */
export const urlPrinciplesTheoriesAndLaws = `${URL_BASE}/principles-theories-laws`;
export const urlPrincipleTheoryLawComponent = `${URL_BASE}/principles-theories-laws/components`;

/* AUD */
export const urlGetActivityAUD = '/aud/list/activity';

/* PUBLISH MODEL */
export const urlPublishModel = `${URL_BASE}/published-models/publish`;

/* Uploads URLs */
export const urlUploadImage = URL_BASE + '/upload/image/';
export const urlUploadPDF = URL_BASE + '/upload/pdf';

/* Sync URLs */
export const urlSyncMainComponents = `${URL_BASE_COMPLEX_SYSTEM}/sync/main-components`;

export const urlSyncCompositions = `${URL_BASE_COMPLEX_SYSTEM}/sync/compositions`;

export const urlSyncInteractions = `${URL_BASE_COMPLEX_SYSTEM}/sync/interactions`;