import axios from "axios";
import * as urls from  '../../api/urls';

export const retrieveActivity = async (activity_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.urlRetrieveActivity + activity_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const retrieveActivityByTask = async (task_id, process_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.urlRetrieveActivityByTask + task_id + '/' + process_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const retrieveActivitySummaryByModel = async (model_id, token) => {
    const response = await axios.get(urls.urlRetrieveActivitySummaryByModel + model_id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const retrieveActivitySummaryByProcess = async (process_id, token) => {
    const response = await axios.get(urls.urlRetrieveActivitySummaryByProcess + process_id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const listActivityByModel = async (model_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.urlListActivityByModel + model_id + stage_role, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const listByFilters = async (filters, token) => {

    const response = await axios.post(urls.urlListActivityByFilters, filters, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const createActivity = async (data, token, role) => {

    const activity = {
        task_id: data?.taskId,
        name: data?.name,
        description: '',
        process_id: data?.processId,
    };

    const response = await axios.post(urls.urlCreateActivity + role, activity, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const updateActivity = async (activity_id, data, token, role) => {

    const stage_role = '/' + role;
    const body = { ...data }

    const response = await axios.put(urls.urlUpdateActivity + activity_id + stage_role, body, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const updateActivityByTask = async (taskId, process_id, data, token, role) => {
    
    const stage_role = '/' + role;
    const activity = { ...data }

    const response = await axios.put(urls.urlUpdateActivityByTask + taskId + '/' + process_id + stage_role, activity, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const updateActivityTypeByTask = async (taskId, process_id, type, token, role) => {

    const stage_role = '/' + role;
    const typeData = { type: type }

    const response = await axios.put(urls.urlUpdateActivityTypeByTask + taskId + '/' + process_id + stage_role, typeData, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deleteActivityByTask = async (taskId, process_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.delete(urls.urlDeleteActivityByTask + taskId + '/' + process_id + stage_role, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}