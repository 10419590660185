import React from 'react';
import PropTypes from 'prop-types';

const ProcessEditor = props => {
  return (
    <div>ProcessEditor</div>
  )
}

ProcessEditor.propTypes = {}

export default ProcessEditor