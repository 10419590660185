import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { OverlayPanel } from 'primereact/overlaypanel'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { descriptionBodyTemplate, statusBodyTemplate } from '../../../../utils/templates/tableTemplates'
import { useSelector } from 'react-redux'
import { getToolsByModel } from '../../../../../../redux/actions/toolbox.action'
import { RolesEnum } from '../../../../../enums/roles'

const ToolboxsOverlayPanel = ({ op, handleSelect }) => {

    const user = useSelector(state => state.LoginState.data)
    const model = useSelector((state) => state.ModelState?.model);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ toolboxs, setToolboxs ] = useState([]);

    useEffect(() => {
        getToolsByModel(model?.id, user?.access_token, role).then((data) => {
            setToolboxs(data);
        });
    }, [ user, model, role ]);

    return (
        <div>
            <OverlayPanel ref={ op } appendTo={ document.body } showCloseIcon id="overlay_panel" style={{ maxWidth: '700px' }} >
                <div className='crud-demo'>
                    <DataTable
                        className="datatable-responsive"
                        value={ toolboxs } rowHover 
                        selectionMode="single" 
                        paginator rows={ 4 }
                        onSelectionChange={ handleSelect } >

                        <Column selectionMode="single" headerStyle={{ width: '3em' }}/>
                        
                        <Column 
                            field={(role === RolesEnum.Designer) ? 'element.name':'a_element.name'}
                            header="Nombre" sortable />
                        <Column
                            field={(role === RolesEnum.Designer) ? 'element.description':'a_element.description'}
                            header="Descripción" sortable
                            body={ (data, props) => descriptionBodyTemplate( (role === RolesEnum.Designer) ? data?.element:data?.a_element, props) } />
                        <Column 
                            style={{ width: '8rem' }} 
                            field="status" header="Estado" 
                            sortable body={ statusBodyTemplate } />
                    </DataTable>
                </div>
            </OverlayPanel>
        </div>
    )
}

ToolboxsOverlayPanel.propTypes = {
    handleSelect: PropTypes.func.isRequired,
}

export default ToolboxsOverlayPanel