import * as actions from '../actions/team.actions';

const {handleActions} = require('redux-actions');

const initialState={
  loading:false,
  data:null,
  error:null,
};

export const TeamReducer = handleActions(
  {
    [actions.setLoadingTeamAction]: (state,{payload}) =>({
      ...state,
      loading:payload
    }),
    [actions.setTeamDataAction]: (state,{payload}) =>({
      ...state,
      data:payload
    }),
    [actions.setTeamErrorAction]: (state,{payload}) =>({
      ...state,
      error:payload
    }),
  },
  initialState,
);
