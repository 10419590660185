import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Fieldset } from 'primereact/fieldset';
import { Rating } from 'primereact/rating';
import useForm from '../../../hooks/useForm';
import { Button } from 'primereact/button';

const Filters = props => {

    const { values, handleInputChange, setValues } = useForm({
        rating: 0,
    });

    return (
        <div>
            <Fieldset toggleable legend='Filtros'>
                <div className="p-grid">
                    <div className="p-col">
                        <div className="p-field">
                            <label style={{ fontSize: '.9rem' }}>
                                Calificación
                                <span className='status-info p-ml-1'>
                                    {(values?.rating === null || values?.rating === 0)
                                        ? 'Todas'
                                        : values?.rating + ' estrellas'
                                    }
                                </span>
                            </label>
                            <Rating 
                                name='rating'
                                value={ values?.rating }
                                onChange={ handleInputChange } />
                        </div>
                    </div>

                    <div className="p-col">
                        
                    </div>
                </div>
                <div className='p-text-center'>
                    <Button
                        style={{ width: '100%', maxWidth: '200px' }}
                        className='p-button-outlined p-button-info'
                        label='Aplicar' />
                </div>
            </Fieldset>
        </div>
    );
}

Filters.propTypes = {
    
}

export default Filters