import React, { useState } from 'react'
import ProfileUser from './User';
import ProfilePerson from './Person';
import photoDefault from '../../assets/img/no-photo-profile.png';
import Joyride, { EVENTS, STATUS } from 'react-joyride';
import { Fieldset } from 'primereact/fieldset';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { updateUserTutorial } from '../../../redux/actions/user.action';
import '../../assets/scss/profile.scss';

const getFilteredObject = (object) => {

    const filteredObject = {};
    const notUsing = [ 'status', 'updated_at', 'created_at']; //Se quito el id del filtro aun no se sabe con seguridad que es lo que hace

    for (const key in object) {
        if (!notUsing.includes(key)) {
            filteredObject[key] = object[key];
        }
    }

    return filteredObject;
}

const Profile = props => {

    const dispatch = useDispatch();

    const data = useSelector((state) => state.LoginState);
    const dataUser = useSelector((state) => state.LoginState?.data);
    const [ user ] = useState( getFilteredObject(data?.data) );
    const [ person ] = useState( getFilteredObject(data?.data?.person) );
    const [ run , setRun ] = useState(false);
    const [ btn, setBtn ] = useState(false);
    const [ verify, setVerify ] = useState(false);

    React.useEffect(()=>{
        if (!user?.user_complete_tutorial) {
            setRun(true);
            setBtn(true);
        }
    },[user]);

    const steps=[
        {
            content: (<><h5>Revisa tu perfil!</h5>
              <br />
              <p>Por favor revisa los datos de tu perfil, y comprueba que sean correctos</p>
              </>
            ),
            locale: { skip: <p aria-label="skip">Dissmis</p> },
            placement: 'center',
            target: 'body',
        },
        {
            content: (<><h5>Verifica tus datos!</h5>
              <br />
              <p>Al momento de que compruebes tus datos da click aqui</p>
              </>
            ),
            locale: { skip: <p aria-label="skip">Dissmis</p> },
            placement: 'auto',
            target: '.title-user button',
        },
    ]

    const handleJoyrideCallback = async(data) => {
        const { type, action } = data;

        if (action === 'close' && run) {
            setRun(false);
            setBtn(false);
            let d = await dispatch ( updateUserTutorial(user?.id, user?.access_token, dataUser));
        }

        if (type === EVENTS.TOUR_END && run) {
            setRun(false);
            console.log('hola');
            //let d = await dispatch ( updateUserTutorial(user?.id, user?.access_token));
        }
    };

    return (
        <>
            <Joyride
                continuous
                scrollToFirstStep
                //showProgress
                showSkipButton
                run={run}
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                        backgroundColor:'#545B67',
                        textColor:'#fff',
                        primaryColor: '#6A6FD1',
                    },
                }}
                callback={handleJoyrideCallback} />

            <div className="center">
                <div className="card p-d-inline-flex rounded p-shadow-20">
                    <input type="image" src={ photoDefault } className="img-profile bg-dark-intense" alt="photo-profile"/>
                </div>
            </div>

            <div className="card p-grid">

                <div className="title-user">
                    <h2>{ `${user?.person?.names} ${user?.person?.last_names}` }</h2>
                    { btn ? (<Button type= 'button' label='Verificar Datos' className='p-button-outlined' onClick={()=> setVerify(true)}></Button>):''}
                    <br/><br/>
                </div>
                <div className="p-col-12 p-lg-6 p-shadow-5">
                    <div className="card">
                        <h5>Personal Information</h5>
                        <Fieldset legend="Personal Information" toggleable>
                            <ProfilePerson person={ person } user={ user?.username }/>
                        </Fieldset>
                    </div>
                </div>

                <div className="p-col-12 p-lg-6 p-shadow-5">
                    <div className="card">
                        <h5>User Data</h5>
                        <Fieldset legend="User Data" toggleable>
                            <ProfileUser user={ user } v={verify}/>
                        </Fieldset>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Profile
