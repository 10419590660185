import React from 'react';
import PropTypes from 'prop-types';

const CARD_OVERVIEW_COLORS = ['blue', 'orange', 'gray', 'white', 'darkgray']

/**
 * @param {int/string} value
 * @param {string} color (blue, orange, gray, white, darkgray)
 * @param {string} icon
 * @returns Card with info
 */
const NroInfoCard = ({ label, color, value=0, icon='pi pi-check', valuePrefix='', valueSuffix='' }) => {
    return (
        <div className="p-col">
            <div
                className={ 'card overview-box ' + color } >
                <div className="overview-info">
                    <h6>{ label }</h6>
                    <h2>
                        <span className='p-pr-1' style={{ fontSize: '1.3rem' }}>{ valuePrefix }</span>
                        { value }
                        <span className='p-pl-1' style={{ fontSize: '1.3rem' }}>{ valueSuffix }</span>
                    </h2>
                </div>
                <i className={ icon } ></i>
            </div>
        </div>
    )
}

NroInfoCard.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    color: PropTypes.oneOf(CARD_OVERVIEW_COLORS),
}

export default NroInfoCard;