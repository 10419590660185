import React, { useEffect, useRef, useState, useCallback } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import * as processActions from "../../../../redux/actions/process.actions";

const emptyResource = {
    process_id: 0,
    long_description: "",
};


const ResultsList = (props) => {

    const dispatch = useDispatch();
    const toast = useRef(null);
    const dt = useRef(null);
    const userState = useSelector((state) => state.LoginState);
    const process = useSelector((state) => state.ProcessState.process);
    const result_process = useSelector((state) => state.ProcessState?.result_process);

    const [random, setRandom] = useState();
    const [globalFilter, setGlobalFilter] = useState("");
    const [selectedResource, setSelectedResource] = useState(null);
    const [result, setResult] = useState(emptyResource);
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteChannelDialog, setDeleteChannelDialog] = useState(false);
    const [channelDialog, setChannelDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const loadChannels = useCallback(async () => {
        try {
            const channels = await dispatch(processActions.getResultsByProcessId(process?.id,userState.data.access_token));
            setResults(channels);
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        loadChannels().then(() => {
            setLoading(false);
        });  
    }, []);

    const openNew = () => {
        setResult(emptyResource);
        setSubmitted(false);
        setChannelDialog(true);
    };

    const saveResult = async (e) => {
        
        e.preventDefault();
        setSubmitted(true);

        let resp;
        let _status;

        if (!result.process_id) {
            result.process_id = process?.id;
            resp = await dispatch(
            processActions.createResultProcess(result,userState.data.access_token)
            );

            toast?.current?.show({
                severity: "success",
                summary: "Successful",
                detail:  "Resultado creado",
                life: 3000,
            });
            
        } else {
            //update
            resp = await dispatch(
            processActions.updateResultProcess(process?.id,result?.id,userState.data.access_token,result)
            );

            toast?.current?.show({
                severity: "success",
                summary: "Successful",
                detail:  "Resultado actualizado",
                life: 3000,
            });
        }
        if (resp) {
            setResults(resp);
        } else {
            toast?.current?.show({
                severity: "error",
                summary: "Error",
                detail: "Tenemos algunos inconvenientes. Intente luego.",
                life: 3000,
            });
        }
        setChannelDialog(false);
        setResult(emptyResource);
    };

    const deleteChannel = async (data) => {
        const resp = await dispatch(
            processActions.deleteResultProcess(process?.id,result?.id,userState.data.access_token)
        );
        
        setResults(resp);
        setDeleteChannelDialog(false);
        setResult(emptyResource);
        toast.current.show({
            severity:  "success",
            summary: "Successful",
            detail: "Resultado Eliminado",
            life: 3000,
        });
    };

    const hideDeleteChannelDialog = () => {
        setDeleteChannelDialog(false);
    };

    const deleteDialogFooter = (
        <>
            <Button
                label="No"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideDeleteChannelDialog} />
            <Button
                label="Yes"
                icon="pi pi-check"
                className="p-button-text"
                onClick={deleteChannel} />
        </>
    );

    const hideDialog = () => {
        setSubmitted(false);
        setChannelDialog(false);
    };

    const editChannel = (c) => {
        setResult({ ...c });
        setChannelDialog(true);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _resource = { ...result };
        _resource[`${name}`] = val;
        setResult(_resource);
    };

    const confirmDeleteChannel = (c) => {
          setResult(c);
          setDeleteChannelDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-warning p-mr-1"
                    onClick={() => editChannel(rowData)} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger p-mr-1"
                    onClick={() => confirmDeleteChannel(rowData)} />
            </div>
        );
    };

    const header = (
      <div className="table-header" >

          <div className='p-grid'>
              <div className="p-col" style={{ display: 'flex', alignItems: 'center' }}>
                  <h5 className='p-m-0'>
                      Resultados
                  </h5>
              </div>
              <div className="p-col">
                  <Button
                      label="Nuevo"
                      icon="pi pi-plus"
                      className="p-button-success p-mr-2 p-mb-2"
                      onClick={ openNew } />
              </div>
          </div>
          
          <div>
              <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    type="search"
                    onInput={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..." />
              </span>
          </div>
      </div>
    );

    return (
        <>
            <Toast ref={toast} />
            
            <div className="crud-demo">
                <DataTable
                    id={random} ref={dt} value={result_process} selection={selectedResource}
                    onSelectionChange={(e) => setSelectedResource(e.value)}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    globalFilter={globalFilter}
                    emptyMessage="No se encontraron resultados."
                    header={header} >

                    <Column
                        style={{ width: "65%" }}
                        field="long_description"
                        header="Descripción" />
                      
                    <Column style={{ width: "15%" }} body={actionBodyTemplate} />
                </DataTable>
            </div>

            <Dialog
                visible={channelDialog}
                style={{ width: "400px" }}
                header="Resultado" modal
                className="p-fluid"
                onHide={ hideDialog } >
                
                <form onSubmit={ saveResult }>
                    <div className="p-field">
                        <label className="required">Descripción</label>
                        <InputTextarea
                            name="long_description"
                            value={result?.long_description}
                            rows={7} autoFocus required autoResize
                            placeholder="Máximo 50 palabras ..."
                            className={classNames({"p-invalid": submitted && !result?.long_description})}
                            onChange={(e) => onInputChange(e, "long_description")} />
                        {submitted && !result?.long_description && (
                            <small className="p-invalid">La descripción es requerida.</small>
                        )}
                    </div>

                    <div className="p-field">
                        <Button
                            label="Guardar"
                            className="p-button-success"
                            type="submit" />
                    </div>
                </form>

            </Dialog>

            <Dialog
                visible={deleteChannelDialog}
                style={{ width: "450px" }}
                header="Confirm"
                modal
                footer={deleteDialogFooter}
                onHide={hideDeleteChannelDialog} >

                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    {result && (
                        <span>
                            Está seguro que desea eliminar el resultado <b>{result?.name}</b>
                        </span>
                    )}
                </div>
            </Dialog>
        </>
    );
};

export default ResultsList;
