import axios from "axios";
import * as urls from  '../../api/urls';

export const retrieveStartEventByStartEventId = async (start_event_id, process_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.get(urls.urlRetrieveStartEventByStartEventId + start_event_id + '/' + process_id + stage_role, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}


export const createStartEvent = async (data, token, role) => {

    const body = {
        start_event_id: data?.start_event_id,
        process_id: data?.process_id,
    };

    const response = await axios.post(urls.urlCreateStartEvent + role, body, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deleteStartEventByStartEventId = async (start_event_id, process_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.delete(urls.urlDeleteStartEventByStartEventId + start_event_id + '/' + process_id + stage_role, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}