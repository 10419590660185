import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Steps } from 'primereact/steps';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { TextEditor } from '../../../components/UI';
import { useDispatch, useSelector } from 'react-redux';
import { Messages } from 'primereact/messages';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
  getBasicInfo,
  updateBasicInfo,
} from '../../../../redux/actions/model.actions';
// CONSTANTS
const PRINCIPLES = 'principles';
const PURPOSE = 'purpose';
const SCOPE = 'scope';
const OBJECTIVES = 'objectives';

const emptyBasicInfo = {
  principles: '',
  purpose: '',
  scope: '',
  objectives: '',
};

const Stage1 = (props) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const message = useRef();

  const modelName = useSelector((state) => state.ModelState?.model?.name);
  const role = useSelector((state) => state.RolNavigationState?.state);

  const [index, setIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [basicInfo, setBasicInfo] = useState(emptyBasicInfo);
  // For keeping track of changes in the input in order to disable SAVE button
  const [_basicInfoPrev, _setBasicInfoPrev] = useState(emptyBasicInfo);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [modelId, setModelId] = useState(location.state.modelId);

  const onInputChange = (e, name) => {
    const val = e.textValue || '';
    let _basicInfo = { ...basicInfo };
    _basicInfo[name] = val;
    setBasicInfo(_basicInfo);

    if (_basicInfoPrev[name] !== _basicInfo[name]) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  };

  const showMessage = (severity, summary, detail) => {
    console.log('message');
    console.log(message);
    message?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  const onUpdateBasicInfo = async (attribute) => {
    setSubmitted(true);
    setIsLoading(true);
    try {
      if (hasChanged) {
        await dispatch(updateBasicInfo(attribute, basicInfo[attribute], role));
        setHasChanged(false);
        _setBasicInfoPrev(basicInfo);
        showMessage('success', 'Éxito', 'Guardado.');
      }
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
    setSubmitted(false);
  };

  useEffect(() => {
    if (!modelId) {
      history.push({
        pathname: '/creators/model-designer',
      });
    }
  }, []);

  useEffect(() => {
    if (error) {
      showMessage('error', 'Error', error.message);
    }
  }, [error]);

  const loadBasicInfo = useCallback(async () => {
    try {
      const _basicInfo = await dispatch(getBasicInfo(modelId, role));
      setBasicInfo(_basicInfo);
      _setBasicInfoPrev(_basicInfo);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadBasicInfo().then(() => {
      setIsLoading(false);
    });
  }, []);

  const wizardItems = [
    { label: 'Principios' },
    { label: 'Propósito' },
    { label: 'Objetivos' },
    { label: 'Alcance' },
  ];

  const renderSwitch = (i) => {
    switch (i) {
      case 0:
        return (
          <TextEditor
            onInputChange={onInputChange}
            name={PRINCIPLES}
            value={basicInfo?.principles}
            title={'Principios del MIT'}
            subtitle={'Describa los principios teóricos que rigen sobre el MIT'}
            submitted={submitted}
            messageError={'Se requiere agregar principios'}
            onSave={onUpdateBasicInfo}
            actionButtonDisabled={!hasChanged}
            helpInformation={
              'Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocarn en los principios.'
            }
          />
        );
      case 1:
        return (
          <TextEditor
            onInputChange={onInputChange}
            name={PURPOSE}
            value={basicInfo?.purpose}
            title={'Propósito del MIT'}
            subtitle={'Describa el propósito del MIT'}
            submitted={submitted}
            messageError={'Se requiere agregar el propósito'}
            onSave={onUpdateBasicInfo}
            actionButtonDisabled={!hasChanged}
            helpInformation={
              'Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocarn en el propósito.'
            }
          />
        );
      case 2:
        return (
          // <></>
          <TextEditor
            onInputChange={onInputChange}
            name={OBJECTIVES}
            value={basicInfo?.objectives}
            title={'Objetivos del MIT'}
            subtitle={'Especifique los objetivos del MIT'}
            submitted={submitted}
            messageError={'Se requieren agregar objetivos'}
            onSave={onUpdateBasicInfo}
            actionButtonDisabled={!hasChanged}
            helpInformation={
              'Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocar en los objetivos.'
            }
          />
        );
      case 3:
        return (
          <TextEditor
            onInputChange={onInputChange}
            name={SCOPE}
            value={basicInfo?.scope}
            title={'Alcance del MIT'}
            subtitle={'Describa el alcance planteado del MIT'}
            submitted={submitted}
            messageError={'Se requiere agregar el alcance'}
            onSave={onUpdateBasicInfo}
            actionButtonDisabled={!hasChanged}
            helpInformation={
              'Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocarn en el alcance.'
            }
          />
        );
      default:
        return;
    }
  };

  const onContinueButtonHandler = () => {
    setIndex(index + 1);
    return;
  };

  const getButtons = () => {
    const classBtn = (type) => `p-button-${type} p-px-6`;

    const continueButton = (
      <Button
        icon="pi pi-arrow-right"
        onClick={onContinueButtonHandler}
        className={classBtn('info')}
        iconPos="right"
        label="Siguiente"
      />
    );

    const backButton = (
      <Button
        icon="pi pi-arrow-left"
        onClick={() => setIndex(index + -1)}
        className={classBtn('danger')}
        label="Atrás"
      />
    );

    return (
      <Toolbar
        className="p-mb-4 p-toolbar"
        left={index > 0 ? backButton : null}
        right={index < 3 ? continueButton : null}
        style={{ border: '0px' }}
      />
    );
  };

  // if (isLoading) {
  //   return <Spinner />;
  // }
  const [data, setData] = useState({ value: '' });

  return (
    <>
      <h3 style={{ textAlign: 'center' }}>
        Indentidad del MIT
      </h3>
      <div>
        <Messages ref={message} />
        <div className="p-md-9" style={{ margin: 'auto' }}>
          <Steps activeIndex={index} model={wizardItems} readonly={true} />
        </div>
      </div>
      <div className="card p-shadow-10">
        {renderSwitch(index)}
        {getButtons()}
      </div>
    </>
  );
};

export default Stage1;
