import React from 'react';
import { Divider } from 'primereact/divider';

const DividerMenu = ({ windowSize }) => {
    
    if (windowSize > 900) {
        return (
            <div className='p-col-1'>
                <Divider layout='vertical' className='' >
                    <i className='pi pi-arrow-right p-my-3' style={{ fontSize: '2rem' }}></i>
                </Divider>
            </div>
        )
    } else {
        return (<></>);
    }
}

export default DividerMenu;