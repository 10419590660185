import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

import classNames from "classnames";
import HelpButton from "./HelpButton";
import { autocompleteClasses } from "@mui/material";

const IndicatorPreviewItem = (props) => {
  const [isClosed, setIsClosed] = useState(true);
  
  const dialogFooter = (
    <>
      <Button
        label="Listo"
        className="p-button-text"
        onClick={() => {
          setIsClosed(true);
        }}
      />
    </>
  );

  return (
    <>
      <div className="card" style={{ height: isClosed ? 80 : 200 }}>
        <div style={{ display: "flex" }}>
          
          <div style={{ marginRight: 15 }}>
            <h5>{props.title}</h5>
          </div>
          <div className="actions">
            <Button
              icon={props.description ? "pi pi-eye" : "pi pi-pencil"}
              className={
                props.description
                  ? "p-button-rounded p-button-success p-mr-2"
                  : "p-button-rounded p-button-warning p-mr-2"
              }
              onClick={() => {
                setIsClosed(!isClosed);
              }}
            />
          </div>
          <div style={{ marginLeft: "auto", marginRight: 0, width: 30}} >
            {props.helpInfo ? <HelpButton message={props.helpInfo} /> : <></>}
          </div>
        </div>

        <div hidden={!isClosed} style={{ margin: 5 }}>
          <p
            style={{
              marginLeft: 10,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {props.description}
          </p>
        </div>

        <div hidden={isClosed} style={{ marginTop: 10 }}>
          <InputTextarea
            id={props.name}
            value={props?.description}
            onChange={(e) => props.onInputChange(e, props.name)}
            required
            style={{ position: "relative" }}
            className={classNames({
              "p-invalid": props.submitted && !props?.description,
            })}
            rows={6}
          />
          {dialogFooter}
        </div>
      </div>
    </>
  );
};

export default IndicatorPreviewItem;
