import React, { useEffect, useRef, useState } from "react";
import PaletteProvider from 'bpmn-js/lib/features/palette/PaletteProvider';
import ContextPadProvider from 'bpmn-js/lib/features/context-pad/ContextPadProvider';
import DialogActivity from "./DialogActivity";
import Modeler from "bpmn-js/lib/Modeler";
import MenuOptions from "./MenuOptions";
import {  handleConnections, handleEndEvent, handleGateway, handleStartEvent, handleTask, importXML, setCustomColorBPMN, _getSVG } from '../../../utils/helpers/bpmnHelpers';
import { getXMLProcessById, updateProcessXML } from "../../../../../redux/actions/process.actions";
import { retrieveActivityByTask } from "../../../../../redux/actions/activity.actions";
import { eventsBpmn, BLANK_XML } from '../utils/variables';
import { useSelector } from "react-redux";
import { Toast } from 'primereact/toast';
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import '../../../../assets/scss/BPMN-JS.scss';

var _getPaletteEntries = PaletteProvider.prototype.getPaletteEntries;

PaletteProvider.prototype.getPaletteEntries = function(element) {
    var entries = _getPaletteEntries.apply(this);
    delete entries['create.participant-expanded'];
    delete entries['create.subprocess-expanded'];
    delete entries['create.intermediate-event'];
    delete entries['create.data-object'];
    delete entries['create.data-store'];
    delete entries['create.group'];
    delete entries['space-tool'];
    return entries; 
}

// https://forum.bpmn.io/t/how-to-remove-elements-from-context-menu/4088/9
const _getContextPadEntries = ContextPadProvider.prototype.getContextPadEntries;

ContextPadProvider.prototype.getContextPadEntries = function(element) {
    const entries = _getContextPadEntries.apply(this, [element]);
    delete entries["append.end-event"];
    delete entries["append.intermediate-event"];
    delete entries["replace"];
    return entries;
};

const BuilderBPMN = (params) => {

    const process = useSelector((state) => state.ProcessState.process);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);
    const toast  = useRef(null);
    
    // XML that modeler was working, this data is in string
    const [ diagramXML, setDiagramXML ] = useState('');
    const [ diagramSVG, setDiagramSVG ] = useState('');
    const [ dialog, setDialog ] = useState(false);
    const [ saving, setSaving ] = useState(false);
    // Activity object selected for dialog, this data is a object
    const [ activity, setActivity ] = useState({});

    const saveChanges = async (modeler) => {

        try {
            setSaving(true);

            // Get xml string of modeler
            const result = await modeler.saveXML({ format: false });
            const { xml } = result;

            setDiagramXML(xml);
            _getSVG(modeler, setDiagramSVG);

            // Update xml string to database
            updateProcessXML(process?.id, xml, user?.access_token, role).then((data) => {
                setSaving(false);
            });
        } catch (err) {
            toast.current.show({ 
                severity: 'error', 
                summary: 'Error updating BPMN schema', 
                detail: 'An unexpected error has occurred', life: 2000 
            });
        }
    }

    useEffect(() => {

        getXMLProcessById(process.id, user.access_token, role).then(response => {
            
            // Get and clean modeler container
            const container = document.getElementById("container");
            container.innerHTML = ''; 
            // Create modeler
            const modeler = new Modeler({
                container,
                keyboard: { bindTo: document },
                additionalModules: [ { zoomScroll: [ 'value', '' ] } ],
            });

            const modeling = modeler.get('modeling');
            const eventBus = modeler.get('eventBus');
            const overlays = modeler.get('overlays');
            
            if (response?.xml_body) { 
                importXML(setDiagramXML, setDiagramSVG, modeler, response?.xml_body, );
            } else { 
                importXML(setDiagramXML, setDiagramSVG, modeler, BLANK_XML);
            }

            eventsBpmn.forEach(function(event) {
                eventBus.on(event, function(e) {

                    const element = e?.element;

                    // Set contextmenu event for open activity dialog, if is task element
                    if (event === 'element.contextmenu') {
                        if (element?.type === 'bpmn:Task') {
                            retrieveActivityByTask(element?.id, process?.id, user?.access_token, role).then(response => {
                                setActivity(response);
                                setDialog(true);
                            });
                        } return false;
                    }

                    // Save changes when BPMN elements chages
                    if (event === 'commandStack.changed') {
                        saveChanges(modeler);
                    }

                    if (event === 'shape.added') {
                        // Wait to load BPMN elements  
                        setTimeout(function() {
                            setCustomColorBPMN(modeling, element, element?.type);
                            saveChanges(modeler);
                        }, 500);

                        if (element?.type === 'bpmn:Task') {

                            overlays.add(element?.id, {
                                position: { top:-20, right:10 },
                                html: (`
                                <button
                                    id='task-overlays-${ element?.id }'
                                    class='p-button p-button-rounded center' 
                                    style='width: 35px; height: 35px;' >
                                    <i class='pi pi-cog'></i>
                                </button>
                                `),
                            });

                            try {
                                var taskOverlay = document.getElementById(`task-overlays-${ element?.id }`);
                                taskOverlay.addEventListener('click', function() {
                                    retrieveActivityByTask(element?.id, process?.id, user?.access_token, role).then(response => {
                                        setActivity(response);
                                        setDialog(true);
                                    });
                                }, false); 
                            } catch (error) {
                                throw error;
                            }
                        }
                    }

                    if (event === 'shape.removed') {
                        if (element?.type === 'bpmn:Task') {
                            handleTask(element, process, user, toast, event);
                        } else if (element?.type === 'bpmn:ExclusiveGateway') {
                            handleGateway(element, process, user, toast, event);
                        } else if (element?.type === 'bpmn:StartEvent') {
                            handleStartEvent(element, process, user, toast, event);
                        } else if (element?.type === 'bpmn:EndEvent') {
                            handleEndEvent(element, process, user, toast, event);
                        }
                    }

                    if (event === 'shape.changed') {
                        if (element?.type === 'bpmn:Task') {
                            handleTask(element, process, user, toast, event);
                        } else if (element?.type === 'bpmn:ExclusiveGateway') {
                            handleGateway(element, process, user, toast, event);
                        } else if (element?.type === 'bpmn:StartEvent') {
                            handleStartEvent(element, process, user, toast, event);
                        } else if (element?.type === 'bpmn:EndEvent') {
                            handleEndEvent(element, process, user, toast, event);
                        }
                    }

                    if (event === 'connection.changed') {
                        handleConnections(element, process, user, event);
                    }

                    if (event === 'connection.removed') {
                        handleConnections(element, process, user, event);
                    }
                });
            });

            return () => {
                // Clean all events of bpmn modeler of events list
                eventsBpmn.forEach(function(event) {
                    eventBus.off(event);
                });
            };
        })

    }, [ process, user ]);
    
    return (
        <div className="card" >
            <MenuOptions
                saving={ saving }
                diagramXML={ diagramXML }
                diagramSVG={ diagramSVG }
                process={ process } />

            <div
                className="p-shadow-10 p-p-2"
                id="container"
                style={{
                    height: "60vh",
                    background: '#575757',
                    borderRadius: '5px',
                    color: '#000',
                    border: '1px solid gray'
                }} >
            </div>

            <Toast ref={ toast } />

            <DialogActivity 
                dialog={ dialog }
                setDialog={ setDialog }
                process={ process }
                activity={ activity }
                setActivity={ setActivity } />
        </div>
    );
}

export default BuilderBPMN;