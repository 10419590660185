import TextField from '@material-ui/core/TextField';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox, IconButton, Radio } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Typography } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { v4 as uuid } from 'uuid';
import {
  QUESTION_TYPE_CLOSE,
  QUESTION_TYPE_MULTIPLE,
  QUESTION_TYPE_OPEN,
  VARIABLE_DOUBLE,
  VARIABLE_INTEGER,
  VARIABLE_STRING,
} from '../../constants/Surveys';

const CreateQuestion = ({
  setIndexIndicator,
  index,
  questions,
  setQuestions,
  question,
  error,
  setInde,
}) => {
  const handleQuestionValue = (text, i) => {
    let optionsOfQuestion = [...questions];
    optionsOfQuestion[i].label = text;
    setQuestions(optionsOfQuestion);
  };

  const handleOptionValue = (text, i, j) => {
    let optionsOfQuestion = [...questions];
    optionsOfQuestion[i].question_options[j].value = text;
    setQuestions(optionsOfQuestion);
  };

  const addOption = (i) => {
    let optionsOfQuestion = [...questions];
    if (optionsOfQuestion[i].question_options.length < 20) {
      optionsOfQuestion[i].question_options.push({
        id: uuid(),
        key: optionsOfQuestion[i].question_options.length + 1,
        value: '',
        selected: false,
      });
    }
    setQuestions(optionsOfQuestion);
  };

  const removeOption = (i, j) => {
    let optionsOfQuestion = [...questions];
    if (optionsOfQuestion[i].options.length > 1) {
      optionsOfQuestion[i].options.splice(j, 1);
      setQuestions(optionsOfQuestion);
    }
  };

  const expandCloseAll = () => {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      qs[j].open = false;
    }
    setQuestions(qs);
  };

  const addNewSubQuestion = (
    parent_question_id,
    parent_option_id,
    position
  ) => {
    expandCloseAll();
    let sub_question_position = questions.filter(
      (s) => s?.parent_question_id === parent_question_id
    );
    let sub_question = [...questions];
    if (sub_question_position.length === 0) {
      setIndexIndicator(position);

      let sub_ques = {
        id: uuid(),
        parent_question_id: parent_question_id,
        parent_option_id: parent_option_id,
        position: position + 1,
        label: '',
        variable: {},
        question_options: [],
        open: true,
      };
      sub_question.splice(position, 0, sub_ques);
      sub_question.map((qs) => {
        if (qs?.position >= position + 1 && qs?.parent_question_id === null) {
          qs.position = qs.position + 1;
        }
      });
      setQuestions(sub_question);
    } else {
      setIndexIndicator(position + sub_question_position.length);
      let sub_ques = {
        id: uuid(),
        parent_question_id: parent_question_id,
        parent_option_id: parent_option_id,
        position: position + sub_question_position.length + 1,
        label: '',
        variable: {},
        question_options: [],
        open: true,
      };
      sub_question.splice(position + sub_question_position.length, 0, sub_ques);
      sub_question.map((qs) => {
        if (
          qs?.position >= position + sub_question_position.length + 1 &&
          qs?.parent_question_id === null
        ) {
          qs.position = qs.position + 1;
        }
      });
      setQuestions(sub_question);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', width: 'auto%' }}>
        <Typography style={{ marginTop: '8%' }}>{index + 1}.</Typography>
        <TextField
          placeholder="Question Text"
          style={{ marginBottom: '18px' }}
          minRows={2}
          maxRows={20}
          multiline={true}
          value={questions[index]?.label}
          variant="filled"
          error={error?.error}
          onChange={(e) => {
            handleQuestionValue(e.target.value, index);
          }}
        />
      </div>

      {error?.error && (
        <span className="p-ml-2" style={{ color: 'red', fontSize: '75%' }}>
          {error?.text}
        </span>
      )}

      <div style={{ width: '100%' }}>
        {question?.type_option?.label === QUESTION_TYPE_OPEN?.label ? (
          <div>
            {question?.variable?.variable_type?.label === VARIABLE_STRING ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '-12.5px',
                  justifyContent: 'space-between',
                  width: '375px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                <TextField
                  className="p-ml-6"
                  disabled
                  id="standard-basic"
                  label="Enter your answer"
                  variant="standard"
                />
              </div>
            ) : (
              ''
            )}

            {question?.variable?.variable_type?.label === VARIABLE_INTEGER ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '-12.5px',
                  justifyContent: 'space-between',
                  width: '375px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                <InputNumber
                  className="p-ml-5"
                  value={0}
                  disabled
                  showButtons
                />
              </div>
            ) : (
              ''
            )}

            {question?.variable?.variable_type?.label === VARIABLE_DOUBLE ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '-12.5px',
                  justifyContent: 'space-between',
                  width: '375px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                <InputNumber
                  className="p-ml-5"
                  value={0.0}
                  disabled
                  mode="decimal"
                  showButtons
                />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : null}
        {question.type_option?.label === QUESTION_TYPE_MULTIPLE?.label
          ? question?.question_options.map((op, j) => (
              <div key={j}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '-12.5px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                  }}
                >
                  <Checkbox disabled />
                  <TextField
                    key={j.toString()}
                    placeholder="Option text"
                    style={{ marginTop: '5px' }}
                    value={question?.question_options[j].value}
                    onChange={(e) => {
                      handleOptionValue(e.target.value, index, j);
                    }}
                  />
                  <div hidden={question?.parent_question_id !== null}>
                    <Button
                      size="small"
                      onClick={() => {
                        addNewSubQuestion(
                          question?.id,
                          question?.question_options[j]?.id,
                          question?.position
                        );
                      }}
                      style={{ textTransform: 'none', marginLeft: '-5px' }}
                    >
                      Implementar pregunta
                    </Button>
                  </div>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      removeOption(index, j);
                    }}
                  >
                    <i className="pi pi-times"></i>
                  </IconButton>
                </div>
              </div>
            ))
          : null}
        {question.type_option?.label === QUESTION_TYPE_CLOSE?.label
          ? question?.question_options.map((op, j) => (
              <div key={j}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '-12.5px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                  }}
                >
                  <Radio disabled />
                  <TextField
                    key={j.toString()}
                    placeholder="Option text"
                    style={{ marginTop: '5px' }}
                    value={question?.question_options[j].value}
                    onChange={(e) => {
                      handleOptionValue(e.target.value, index, j);
                    }}
                  />
                  <div hidden={question?.parent_question_id !== null}>
                    <Button
                      hidden={question?.parent_question_id !== null}
                      size="small"
                      onClick={() => {
                        addNewSubQuestion(
                          question?.id,
                          question?.question_options[j]?.id,
                          question?.position
                        );
                      }}
                      style={{ textTransform: 'none', marginLeft: '-5px' }}
                    >
                      Implementar pregunta
                    </Button>
                  </div>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      removeOption(index, j);
                    }}
                  >
                    <i className="pi pi-times"></i>
                  </IconButton>
                </div>
              </div>
            ))
          : null}
        {(question?.type_option?.label === QUESTION_TYPE_CLOSE?.label &&
          question?.variable?.variable_type) ||
        (question?.type_option?.label === QUESTION_TYPE_MULTIPLE?.label &&
          question?.variable?.variable_type) ? (
          <div>
            <FormControlLabel
              disabled
              control={<Radio />}
              label={
                <Button
                  size="small"
                  onClick={() => {
                    addOption(index);
                  }}
                  style={{ textTransform: 'none', marginLeft: '-5px' }}
                >
                  Add Option
                </Button>
              }
            />
          </div>
        ) : (
          ''
        )}{' '}
        <br /> <br />
      </div>
    </div>
  );
};

export default CreateQuestion;
