import React, { useState } from 'react';
import App from './../../App';
import Register from '../pages/Register';
import RegisterGuest from '../pages/RegisterGuest';
import PublicRoute from '../routes/PublicRoute';
import PaymentMethod from '../pages/Register/PaymentMethod';
import { Route, withRouter, Switch } from 'react-router-dom';
import { Login } from '../pages/Login';
import { Error } from '../pages/Error';
import { NotFound } from '../pages/NotFound';
import { Access } from '../pages/Access';
import { useSelector } from 'react-redux';
import PrivateRoute from '../routes/PrivateRoute';

const AppWrapper = (props) => {
;
	const colorScheme = 'dark'
	const { login } = useSelector((state) => state.LoginState);

	return (
		<Switch>
			<PublicRoute
				exact path="/login"
				component={ Login }
				isAuthenticated={ login } />

			<PublicRoute
				exact path="/register"
				component={ Register }
				isAuthenticated={ login } />

			<PublicRoute
				exact path="/register-team"
				component= { RegisterGuest }
				isAuthenticated= { login } />

			<Route path="/register/payment-method" render={() => <PaymentMethod /> } />

			<Route path="/error" render={() => <Error colorScheme={ colorScheme } />} />
			<Route path="/notfound" render={() => <NotFound colorScheme={ colorScheme } />} />
			<Route path="/access" render={() => <Access colorScheme={ colorScheme } />} />

			<PrivateRoute
				path='/'
				component={ App }
				isAuthenticated={ login } />
				
		</Switch>
	);
}

export default withRouter(AppWrapper);
