import dinersclub from "./dinersclub.js";
import amex from "./amex.js";
import discover from "./discover.js";
import hipercard from "./hipercard.js";
import jcb from "./jcb.js";
import unionpay from "./unionpay.js";
import mastercard from "./mastercard.js";
import placeholder from "./placeholder.js";
import visa from "./visa.js";

export default {
    amex,
    dinersclub,
    discover,
    hipercard,
    jcb,
    unionpay,
    mastercard,
    placeholder,
    visa,
};