import React, { useState } from "react";
import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";

const DimensionsList = (props) => {
  const [layout, setLayout] = useState("grid");

  const itemTemplate = (data, observationUnitIdentifier) => {
    if (!data) {
      return;
    }
    return (
      <div className="p-col-12 p-md-3">
        <div className="product-grid-item card">
          <div className="product-grid-item-top" style={{ marginBottom: 15 }}>
            <div className="product-name" style={{ marginBottom: 10 }}>
              <h6>{data.name}</h6>
            </div>
            <div className="product-description">
              {data.definition.length > 40
                ? data.definition.substring(0, 40) + "..."
                : data.definition}
            </div>
          </div>

          <div className="actions">
            <Button
              icon="pi pi-pencil"
              className="p-button-rounded p-button-warning p-mr-2"
              onClick={() => props.onEditDimension(data)}
            />

            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger"
              onClick={() => props.onDeleteDimension(data)}
            />
          </div>
        </div>
      </div>
    );
  };

  const DataTitle = () => {
    return (
      <>
        <div style={{ marginRight: 10 }}>
          <h5>Variable: {props?.data?.name}</h5>
        </div>
        <div>
          <Button
            label="Añadir Dimensión"
            icon="pi pi-plus"
            className="p-button-success p-mr-2 p-mb-2"
            onClick={() => props.onCreateDimension(props?.data?.id)}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="p-col-12">
        <div className="card">
          <DataTitle />

          <DataView
            value={props?.data?.components}
            layout={layout}
            paginator
            rows={8}
            itemTemplate={itemTemplate}
          />
        </div>
      </div>
    </>
  );
};

export default DimensionsList;
