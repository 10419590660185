import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GoogleText from '../UI/GoogleText';
import PaypalText from '../UI/PaypalText';
import StripePayment from './StripePayment';
import GooglePayment from './GooglePayment';
import PaypalPayment from './PaypalPayment';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';

/* 
Poduct Object
    code: Product code for identificate with this product app
    type: Product type for save type product in db
    price: Product price
    name: Product name

Customer Bbject
    names:
    last_names:
    email: Send notifications
    code: Identification for this app
*/

const emptyFunc = () => { return; };

const PaymentMethod = ({ product, callback=emptyFunc }) => {

    const user = useSelector((state) => state.LoginState.data);
    const customer = {
        code: user?.id,
        names: user?.person?.names,
        last_names: user?.person?.last_names,
        email: 'andres2016sapatanga@gmail.com',
    }

    const [ submited, setSubmited ] = useState(false);
    const { REACT_APP_STRIPE_PUBLISHABLE_KEY, REACT_APP_PAYPAL_CLIENT_ID } = process.env;
    const stripePromese = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

    return (
        <>
            <div className="p-px-3 p-pt-3" style={{ textAlign: 'center' }}>
                <div>
                    <p className='p-mt-2' style={{ fontSize: '.9rem' }} >
                        Pay with card:
                    </p>
                    <p>
                        Test with those card numbers <a target='_blank' href='https://stripe.com/docs/testing'>https://stripe.com/docs/testing</a>
                    </p>
                    <Elements stripe={ stripePromese }>
                        <StripePayment 
                            product={ product } 
                            customer={ customer } 
                            submited={ submited }
                            setSubmited={ setSubmited }
                            callback={ callback } />
                    </Elements>
                </div>

                <div className='p-mt-3'>
                    <p style={{ fontSize: '.9rem' }}>
                        If you have a payment method with 
                        <a target='_blank' href='https://payments.google.com'>
                            <GoogleText className='p-text-bold p-mx-1' /> 
                        </a>
                        or 
                        <a target='_blank' href='https://www.paypal.com'>
                            <PaypalText className='p-text-bold p-ml-1' />
                        </a>
                        , you can:
                    </p>

                    <GooglePayment
                        product={ product }
                        customer={ customer }
                        submited={ submited }
                        setSubmited={ setSubmited } />

                    <div className='p-mt-2' style={{ maxWidth: '240px', margin: 'auto' }}>
                        
                        <PayPalScriptProvider
                            options={{
                                'client-id': REACT_APP_PAYPAL_CLIENT_ID, 'components': 'buttons', 'currency': 'USD',
                            }} >
                            <PaypalPayment 
                                product={ product } 
                                currency={ 'USD' } 
                                showSpinner={ false }
                                submited={ submited }
                                setSubmited={ setSubmited }
                                customer={ customer }
                                callback={ callback } />
                        </PayPalScriptProvider>

                    </div>
                </div>
            </div>
        </>
    )
}

PaymentMethod.propTypes = {
    product: PropTypes.object.isRequired,
}

export default PaymentMethod;