import React, { useEffect, useState } from "react";
import TheoriesLawsAndPrinciples from "../../components/theories-laws-and-principles/TheoriesLawsAndPrinciples";
import VariableOperationalization from "../VariableOperationalization/VariableOperationalization";
import KeyTopicsAndTrends from "../../components/key-topics-and-trends/KeyTopicsAndTrend";
import KnowledgeBase from "../../components/knowledge-base/KnowledgeBase";
import SocialNetwork from "./SocialNetwork/SocialNetwork";
import ComplexSystem from "../Core/ComplexSystem";
import DividerMenu from "../M&E/DividerMenu";
import queryString from "query-string";
import Processes from "../Processes";
import Stage1 from "./Stage1";
import Polls from "../Polls";
import Menu from "./Menu";
import Toolbox from "../Core/Toolbox";
import { useLocation } from 'react-router-dom';
import '../../assets/scss/formio.scss';
import { gearthComponetsTypes as gearth } from "../utils/types/gearthTypes";

const ModelDesigner = () => {

    const location = useLocation();
    const [ windowSize, setWindowSize ] = useState(window.innerWidth);
    const { tab = gearth.identity } = queryString.parse( location.search );
    const [ key, setKey ] = useState(tab);

    const handleResize = () => {
        setWindowSize(window.innerWidth);
    }    
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderSwitch = (key) => {
        switch (key) {
            case gearth.identity:
                return <Stage1 />
            case gearth.complexSystem:
                return <ComplexSystem />
            case gearth.indicatorsSystem:
                return <VariableOperationalization />
            case gearth.processes:
                return <Processes />
            case gearth.appRed:
                return <SocialNetwork />
            case gearth.appInfo:
                return <Polls />
            case gearth.knowledgeBase:
                return <KnowledgeBase />
            case gearth.keyTopicsTrends:
                return <KeyTopicsAndTrends />
            case gearth.principlesLawsTheory:
                return <TheoriesLawsAndPrinciples />
            case gearth.tools:
                return <Toolbox />
            default:
                return <Stage1 />
        };
    }

    return (
        <>
            <div className='card' style={{ 
                minHeight: '70vh', 
                // minHeight: '550px' ,
            }} >
                <div className='p-grid'>

                    {/* Render a menu depending with */}
                    <Menu windowSize={ windowSize } setKey={ setKey } keyTab={ key } />

                    {/* Render a dividir depending with */}
                    <DividerMenu windowSize={ windowSize } />

                    <div className={(windowSize > 900) ? 'p-col-9' : 'p-col-12' } >
                        { renderSwitch(key) }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModelDesigner;