import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { inDesignComplexSystemActions } from "../../../redux/actions/complex-system";

const ModelComponentsTable = (props) => {
  const [components, setComponents] = useState(props.data);
  const dispatch = useDispatch();

  useEffect(() => {
    setComponents(props.data);
  }, [props.data]);

  const componentBodyTemplate = (data) => {
    return (
      <div className="p-col-12 p-lg-8">{data?.selected_component?.name}</div>
    );
  };

  const onCheckboxChange = async (e) => {
    const index = components.findIndex((c) => c.id == e.target.value.id);
    let _component = components[index];

    if (e.target.name === "key") {
      _component.is_key = !e.target.value.is_key;
    } else if (e.target.name === "variable") {
      _component.is_variable = !e.target.value.is_variable;
    }

    try {
      const _upComponent = await dispatch(
        inDesignComplexSystemActions.updateSelectedComponent(_component)
      );
      const _components = [...components];
      _components[index] = _upComponent;
      setComponents(_components);
      props.reloadGraph(true);
    } catch (error) {
      props.setError(error);
    }
  };

  const onRemoveSelectedComponent = async (id) => {
    try {
      await dispatch(inDesignComplexSystemActions.deleteSelectedComponent(id));
      const _components = components.filter((c) => c.component_id !== id);
      setComponents(_components);
      props.reloadGraph(true);
    } catch (error) {
      props.setError(error);
    }
  };

  const attributesTemplate = (data) => {
    return (
      <>
        <div className="p-col-12 p-lg-4">
          <div className="p-grid">
            <div className="p-col-12 p-md-4">
              <div className="p-field-checkbox">
                <Checkbox
                  id={`k_${data.id}`}
                  inputId={`k_${data.id}`}
                  name="key"
                  value={data}
                  checked={data.is_key}
                  onChange={onCheckboxChange}
                />
                <label htmlFor={`k_${data.id}`}>Key</label>
              </div>
            </div>
            <div className="p-col-12 p-md-4">
              <div className="p-field-checkbox">
                <Checkbox
                  id={`v_${data.id}`}
                  inputId={`v_${data.id}`}
                  name="variable"
                  value={data}
                  checked={data.is_variable}
                  onChange={onCheckboxChange}
                />
                <label htmlFor={`v_${data.id}`}>Variable</label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <div className="p-col-12 p-lg-4">
          <div className="actions">
            <Button
              key={data.component_id}
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger"
              onClick={(e) => {
                onRemoveSelectedComponent(data.component_id);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <DataTable
        value={components}
        dataKey="id"
        rowHover
        emptyMessage="No se han seleccionado componentes."
      >
        <Column
          style={{ width: "40%" }}
          field="name"
          header="Componente"
          body={componentBodyTemplate}
        />
        <Column
          style={{ width: "40%" }}
          field="key"
          header="Atributos"
          body={attributesTemplate}
        />
        <Column
          style={{ width: "20%" }}
          field="key"
          header="Eliminar"
          body={actionBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

export default ModelComponentsTable;
