import React, { useState, useEffect, useCallback } from 'react';
// import GoogleButton from '../../../components/GoogleButton';
import { useLogin } from '../../../../redux/hooks/useUser';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { authLogin } from '../../../../redux/actions/user.action';
import { useDispatch } from 'react-redux';
import '../../../assets/scss/login.scss';

const LoginTemplate = (props) => {
  
  const history = useHistory();
  const dispatch = useDispatch();

  const { t: tlogin } = useTranslation(['auth']);
  const { t: tglobal } = useTranslation(['global']);

  const { login, error } = useLogin();
  const [ ui, setUi] = useState({ loading: false, errorMsg: '' });
  const [ user, setUser] = useState({ username: '', password: '' });
  const { username, password } = user;
  const [ autoLogError, setAutoLogError ] = useState(false);

  const { state } = useLocation();

  const autoLogin = useCallback(async (username, password) => {
    try {
      await dispatch(authLogin({ username: username, password }));
    } catch (error) {
      setAutoLogError(true);
    }
  }, []);

  useEffect(() => {
    if (state?.username && state?.password && !autoLogError) {
      autoLogin(state?.username, state?.password);
    }
  }, [state]);

  useEffect(() => {
    if (login) {
      history.push('/');
    }
    if (error) {
      setUi((data) => {
        return { ...data, errorMsg: error?.message };
      });
    }
  }, [login, history, error]);

  const handleInputChange = ({ target }) => {
    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  const handleLogin = async (e) => {
    setUi({ ...ui, loading: true });

    if (username.trim().length <= 0) {
      setUi({ ...ui, errorMsg: 'Nombre de usuario vacío', loading: false });
      return;
    }

    if (password.length <= 0) {
      setUi({ ...ui, errorMsg: 'Contraseña vacío', loading: false });
      return;
    }

    try {
      await dispatch(authLogin({ username: username.trim(), password }));
    } catch (error) {
      setUi({ loading: false, errorMsg: error?.response?.data?.detail});
    }
  };

  return (
    <>
      <button className="logo p-link p-my-3">
        <img
          src="gearth-logo.png"
          style={{ width: '20rem', height: 'auto' }}
          alt="gearth-logo"
        />
      </button>

      {/* <GoogleButton iniciaSesion={ iniciaSesion }/> */}

      {/* <span className="p-mb-3" style={{ color: '#ccc' }}>OR</span> */}

      <InputText
        style={{
          background: '#545B67',
          color: '#eee',
          border: '1px solid yellow',
        }}
        value={username}
        onChange={handleInputChange}
        id="username"
        name="username"
        placeholder={tglobal('username')}
        required
      />

      <InputText
        type="password"
        name="password"
        style={{
          background: '#545B67',
          color: '#eee',
          border: '1px solid yellow',
        }}
        value={password}
        onChange={handleInputChange}
        id="password"
        placeholder={tglobal('password')}
        required
      />

      {ui.errorMsg && (
        <span style={{ fontSize: '.9rem' }} className="color-danger p-mb-3">
          {ui.errorMsg}
        </span>
      )}

      <Button
        className="p-button-rounded"
        icon={ui.loading && 'pi pi-spin pi-spinner'}
        disabled={ui.loading}
        label={tglobal('titles.sign-in')}
        onClick={handleLogin}
      />

      <button
        disabled={ui.loading}
        style={{ color: '#ccc' }}
        className="p-link forget-password"
      >
        {tlogin('forgot-pass')}
      </button>

      <p style={{ color: '#eee' }}>
        {tlogin('not-account')}
        <button
          disabled={ui.loading}
          value="singup"
          className="p-link p-ml-1"
          onClick={() => {
            history.push('/register');
          }}
        >
          {tglobal('titles.sign-up')}
        </button>
      </p>
    </>
  );
};

export default LoginTemplate;
