import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'primereact/toast';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { savePaypalSubscriptionData } from '../../../redux/actions/payment-method.actions';

// This values are the default props in the UI
const style = { layout:'vertical', height: 40, color: 'gold', label: 'subscribe' };
const emptyFunc = () => { return; };

// Custom component to wrap the PayPalButtons and handle currency changes
// https://paypal.github.io/react-paypal-js/?path=/docs/example-subscriptions--default
const PaypalSubscriptionPayment = React.memo(({ 
    product,
    customer,
    submited, 
    setSubmited,
    showSpinner=true, 
    callback=emptyFunc,
}) => {

    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
	const [{ options }, dispatch] = usePayPalScriptReducer();
    const toast  = useRef(null);

	useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [ ]);

    return (
        <>
            { (showSpinner) &&
                <div>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }}></i> 
                </div>
            }

            <Toast ref={ toast } />
            
            <PayPalButtons
                style={ style }
                disabled={ submited }
                forceReRender={[ product, style ]}
                fundingSource='paypal'
                createSubscription={(data, actions) => {
                    return actions.subscription
                        .create({
                            plan_id: 'P-66D080558F7298641MIL5LAQ',
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    console.log(data);
                    savePaypalSubscriptionData(data.subscriptionID, '2', customer?.code).then(response => {
                        toast.current.show({ 
                            severity: 'success', 
                            summary: `${ response?.message }`,
                            life: 3000,
                        });
                        callback();
                    })
                }}
                onCancel={() => {
                    toast.current.show({ 
                        severity: 'warn', 
                        summary: 'You cancelled the payment.', 
                        life: 3000,
                    });
                    setSubmited(false);
                }}
                onError={() => {
                    toast.current.show({
                        severity: 'error', 
                        summary: 'There was an error with the payment. Try again, please.', 
                        life: 3000
                    });
                    setSubmited(false);
                }}
                onClick={() => {
                    setSubmited(true);
                }}
            />
        </>
    );
});

PaypalSubscriptionPayment.propTypes = {
    currency: PropTypes.string,
    showSpinner: PropTypes.bool,
    submited: PropTypes.bool.isRequired,
    setSubmited: PropTypes.func.isRequired,
}

export default PaypalSubscriptionPayment;