import React from 'react';
import { FormControlLabel, Radio, Typography } from '@mui/material';
import '../../assets/scss/surveys.scss';
import { Checkbox } from '@material-ui/core';
import {
  QUESTION_TYPE_CLOSE,
  QUESTION_TYPE_MULTIPLE,
  QUESTION_TYPE_OPEN,
} from '../../constants/Surveys';

const CloseQuestion = ({ ques, questions, index }) => {
  return (
    <div className="survey-designer-wrapper">
      <div className="p-mt-3 indicator-survey">
        <div id="indicators">
          {questions?.parent_question_id !== null ? (
            <>
              <div style={{ marginLeft: '15px' }}>
                <Typography variant="h5" color={'blue'}>
                  <i
                    className="pi pi-chevron-right"
                    style={{ fontSize: '2rem' }}
                  ></i>{' '}
                  Sub pregunta{' '}
                </Typography>
                <br />
              </div>
            </>
          ) : (
            ''
          )}
          {questions?.variable?.label ? (
            <>
              <div className="p-ml-4" style={{ marginLeft: '20px' }}>
                <Typography variant="h6"> Variable del indicador:</Typography>
                <Typography>
                  &nbsp;&nbsp;{questions?.variable?.label}
                </Typography>
                <br />
              </div>
              <div className="p-ml-5" style={{ marginLeft: '20px' }}>
                <Typography variant="h6"> Tipo opcion:</Typography>
                <Typography>
                  &nbsp;&nbsp;{questions?.variable?.variable_type?.label}
                </Typography>
                <br />
              </div>
            </>
          ) : (
            <>
              <div style={{ marginLeft: '15px' }}>
                <Typography variant="h6"> Indicator:</Typography>
                <Typography style={{ marginLeft: '10px' }}>
                  No contiene indicadores{' '}
                </Typography>
                <br />
              </div>
              <div style={{ marginLeft: '15px' }}>
                <Typography variant="h6"> Tipo de pregunta:</Typography>
                {questions?.type_option?.label === QUESTION_TYPE_OPEN?.label ? (
                  <Typography style={{ marginLeft: '10px' }}>
                    Pregunta abierta
                  </Typography>
                ) : (
                  ''
                )}
                {questions?.type_option?.label ===
                QUESTION_TYPE_CLOSE?.label ? (
                  <Typography style={{ marginLeft: '10px' }}>
                    Selección única
                  </Typography>
                ) : (
                  ''
                )}
                {questions?.type_option?.label ===
                QUESTION_TYPE_MULTIPLE?.label ? (
                  <Typography style={{ marginLeft: '10px' }}>
                    Selección múltiple
                  </Typography>
                ) : (
                  ''
                )}
                <br />
              </div>

              <div className="p-ml-5" style={{ marginLeft: '20px' }}>
                <Typography variant="h6"> Tipo opcion:</Typography>
                <Typography>
                  &nbsp;&nbsp;{questions?.variable?.variable_type?.label}
                </Typography>
                <br />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="question-survey">
        <div id="quesdiv" className="p-mt-5">
          <Typography variant="h6">
            {index + 1}. {questions?.label}
          </Typography>{' '}
          <br />
          <br />
          {/* {questions?.type_option?.label === QUESTION_TYPE_OPEN?.label ? (
                        <div>
                            { questions?.variable?.variable_type?.label === VARIABLE_STRING ? (
                                <div className='p-ml-4'>
                                    <TextField disabled id='standard-basic' label='Enter your answer' variant='standard' />
                                </div>
                            ):''}
                        
                            { questions?.variable?.variable_type?.label === VARIABLE_INTEGER ? (
                                <div className='p-ml-4'>
                                    <InputNumber  value={0} disabled showButtons/>
                                </div>
                            ):''}
                        
                            { questions?.variable?.variable_type?.label === VARIABLE_DOUBLE ? (
                                <div className='p-ml-4'>
                                    <InputNumber  value={0.0} disabled mode='decimal' showButtons/>
                                </div>
                            ):''} 
                        </div>
                    ):null} */}
          {questions?.type_option?.label === QUESTION_TYPE_MULTIPLE?.label
            ? questions?.question_options.map((op, j) => (
                <div key={j}>
                  <div className="p-ml-4" style={{ display: 'flex' }}>
                    <FormControlLabel
                      disabled
                      control={<Checkbox style={{ marginRight: '3px' }} />}
                      label={
                        <Typography style={{ color: '#555555' }}>
                          {questions?.question_options[j]?.value}
                        </Typography>
                      }
                    />
                  </div>
                </div>
              ))
            : null}
          {questions?.type_option?.label === QUESTION_TYPE_CLOSE?.label
            ? questions?.question_options.map((op, j) => (
                <div key={j}>
                  <div className="p-ml-4" style={{ display: 'flex' }}>
                    <FormControlLabel
                      disabled
                      control={<Radio style={{ marginRight: '3px' }} />}
                      label={
                        <Typography style={{ color: '#555555' }}>
                          {questions?.question_options[j]?.value}
                        </Typography>
                      }
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
export default CloseQuestion;
