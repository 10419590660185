import { SystemicHistoryComponentsDto } from "../types/dtos";

export const GUIDE_NAME = "guide";
export const CAUSES_AND_EFFECTS_NAME = "causes_and_effects";
export const TEMPORAL_AND_GEOGRAPHICAL_LOCATION_NAME =
  "temporal_and_geographical_location";
export const AGENTS_NAME = "agents";
export const ACTUAL_OR_POTENTIAL_NAME = "actual_or_potential";
export const PROCESSES_NAME = "processes";
export const MANIFESTATIONS_NAME = "manifestations";
export const NAME_NAME = "name";

export const emptySystemicHistoryComponents: SystemicHistoryComponentsDto = {
  id: null,
  [GUIDE_NAME]: "",
  [CAUSES_AND_EFFECTS_NAME]: "",
  [TEMPORAL_AND_GEOGRAPHICAL_LOCATION_NAME]: "",
  [AGENTS_NAME]: "",
  [ACTUAL_OR_POTENTIAL_NAME]: "",
  [PROCESSES_NAME]: "",
  [MANIFESTATIONS_NAME]: "",
  [NAME_NAME]: "",
};
