import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextEditor } from '../../../components/UI';
import { updateProcess } from "../../../../redux/actions/process.actions";

const LearningProcess = ({ showMessage, values, handleInputChange }) => {

    const process = useSelector((state) => state.ProcessState?.process);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ hasChanged, setHasChanged ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);

    useEffect(() => {
        if (values?.knowledge_process !== process?.knowledge_process) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [ values?.knowledge_process, process?.knowledge_process ]);

    const handleSave = async (attribute) => {
        setSubmitted(true);

        if (hasChanged) {

            const data = { knowledge_process: values?.knowledge_process }

            updateProcess(process?.id, data, user?.access_token, role).then(data => {
                setHasChanged(false);
                showMessage("success", "Conocimiento actualizado", "");
            })
        }
        setSubmitted(false);
    };

    return (
        <>
            <TextEditor 
                title="Aprendizaje" 
                subtitle="Ingrese los aspectos de aprendizaje que genera el proceso"
                messageError="Error al almacenar aprendizaje"
                value={ values?.knowledge_process }
                submitted={ submitted } 
                onSave={ handleSave }
                actionButtonDisabled={ !hasChanged }
                onInputChange={(e) => handleInputChange({ target: { name: 'knowledge_process', value: e?.htmlValue } })}
                helpInformation="Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocarn en el aprendizaje." />
        </>
    );
}

export default LearningProcess;