import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LocationForm from './LocationForm';
import BudgetForm from './BudgetForm';
import ShapesForm from './ShapesForm';
import useForm from '../../../../../hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { itemsExecutionModel } from '../../../../pages/utils/constants/menuItemsConstants';
import { getModelById, retrieveModelGeneralitiesByModel, updateModelGeneralities, updateModelLocation } from '../../../../../redux/actions/model.actions';
import { Steps } from 'primereact/steps';
import { Toolbar } from 'primereact/toolbar';
import { useHistory } from 'react-router';
import { Button } from 'primereact/button';

/**
 * @param {boolean} finish -> indicate if this template as for execute or edit
 * @returns react node
 */
const ExecuteForm = ({ model, showMessage, finish=true }) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.LoginState.data);

    const [ activeIndex, setActiveIndex ] = useState(0);
    const [ modelGeneralities, setModelGeneralities ] = useState({});
    const { values, setValues, handleInputChange } = useForm({});

    useEffect(() => {
        if (model?.id) {
            retrieveModelGeneralitiesByModel(model?.id, user?.access_token).then(data => {
                if (data) setModelGeneralities(data);
            });
        }
    }, [ model, user ])

    useEffect(() => {
        setValues({
            ...modelGeneralities,
            model_location: {
                ...modelGeneralities?.model_location,
                country_name: 'Ecuador',
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ modelGeneralities ])

    const handleFinish =  async () => {

        if (values?.budget && values?.budget !== modelGeneralities?.budget) {
            try {
                const data = { budget: values?.budget }
                const generalities = await dispatch(updateModelGeneralities(model?.id, data));

                setModelGeneralities(generalities);
                showMessage('success', 'Presupuesto actualizado', '$' + generalities?.budget);
            } catch (error) {
                showMessage('error', 'Presupuesto no guardado', 'Error inesperado');                
            }
        }

        if (values?.model_location_id 
            && values?.model_location?.country_name
            && values?.model_location?.neighborhood_name
            && (values?.model_location?.country_name !== modelGeneralities?.model_location?.country_name
            || values?.model_location?.neighborhood_name !== modelGeneralities?.model_location?.neighborhood_name)) {

            try {
                const data = {
                    country_name: values?.model_location?.country_name,
                    neighborhood_name: values?.model_location?.neighborhood_name,
                }
                const location = await dispatch(updateModelLocation(values?.model_location_id, data));
                setModelGeneralities({
                    ...modelGeneralities,
                    model_location: { ...location }
                });
                showMessage('success', 'Ubicación actualizado', location?.neighborhood_name);

            } catch (error) {
                showMessage('error', 'Ubicación no guardado', 'Error inesperado');                
            }
        }

        if (finish) {
            try {                
                await dispatch(getModelById(model?.id));
                history.push({
                    pathname: '/admin/model-designer',
                    state: { modelId: model.id },
                });        
            } catch (error) {
                showMessage('error', 'No se pudo redirigir', 'Error de conexión');
            }
        }
    }

    const handleSwitch = () => {
        switch (activeIndex) {
            case 0:
                return <BudgetForm values={ values } handleInputChange={ handleInputChange } />;
            case 1:
                return <LocationForm values={ values } handleInputChange={ handleInputChange } />;
            case 2:
                return <ShapesForm />;
            default:
                break;
        };
    }

    return (
        <div style={{ maxWidth: '500px', margin: 'auto' }}>
            <Steps
                className='p-mt-1 p-mb-3'
                model={ itemsExecutionModel }
                readOnly={ false }
                activeIndex={ activeIndex }
                onSelect={(e) => setActiveIndex(e.index)} />

            <div style={{ height: '285px' }}>
                { handleSwitch() }
            </div>

            {/* Hide or show finish button, depending if this template is used as execution or editing */}
            {(finish) ?
                <Toolbar
                    className='p-p-0'
                    style={{ border: '0px' }}
                    left={
                        <div>
                            {(activeIndex > 0) &&
                                <Button
                                    className='p-button-warning'
                                    label='Regresar'
                                    icon='pi pi-arrow-left'
                                    onClick={() => setActiveIndex(activeIndex - 1) } />
                            }
                        </div>
                    }
                    right={
                        <div>
                            {(activeIndex < 2) ?
                                <Button
                                    className='p-button-info'
                                    label='Siguiente'
                                    icon='pi pi-arrow-right'
                                    iconPos='right'
                                    onClick={() => setActiveIndex(activeIndex + 1) } />
                                :
                                <Button
                                    className='p-button-success'
                                    label='Finalizar'
                                    icon='pi pi-check'
                                    onClick={ handleFinish } />
                            }
                        </div>
                    } />

                // Hide save button in upload files
                : (activeIndex  < 2) &&
                <div className='p-fluid'>
                    <Button
                        className='p-button-success'
                        label='Guardar'
                        icon='pi pi-check'
                        onClick={ handleFinish } />
                </div>
            }
        </div>
    )
}

ExecuteForm.propTypes = {
    model: PropTypes.object.isRequired,
    showMessage: PropTypes.func.isRequired,
}

export default ExecuteForm;