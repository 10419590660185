import { useState } from 'react';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import '../../assets/scss/surveys.scss';
import CreateTypeQuestion from './CreateTypeQuestion';
import CreateQuestion from './CreateQuestion';

const OpenQuestion = ({
  setIndexIndicator,
  toggleValue,
  questions,
  setQuestions,
  index,
  question,
  error,
}) => {
  const [optionType, setOptionType] = useState('');

  return (
    <AccordionDetails>
      <div className="survey-designer-wrapper">
        <div className="p-ml-4 question-survey">
          <div id="quesdiv">
            <CreateQuestion
              setIndexIndicator={setIndexIndicator}
              index={index}
              questions={questions}
              setQuestions={setQuestions}
              question={question}
              error={error}
            />
          </div>
        </div>

        <div className="indicador-survey">
          <div id="indicators">
            <CreateTypeQuestion
              index={index}
              questions={questions}
              setQuestions={setQuestions}
              toggleValue={toggleValue}
              optionType={optionType}
              setOptionType={setOptionType}
            />
          </div>
        </div>
      </div>
    </AccordionDetails>
  );
};
export default OpenQuestion;
