import {useDispatch,useSelector} from 'react-redux';

import { useHistory } from 'react-router-dom';
import { getSystems,getSystemsByLevel,createSystem,updateSystem } from '../actions/complex-systems.action';

export const useComplexSystems = () =>{
    const history = useHistory();
    const state = useSelector((state) => state.SystemsState);
    const dispatch = useDispatch();

    const listSystems = () =>{
        dispatch(getSystems(history));
    }
    const getSubsystemsByLevelAction = (level) =>{
        dispatch(getSystemsByLevel(history,level));
    }
    
    const insertSystem = (data) =>{
        dispatch(createSystem(data));
    }
    const modifySystem = (data) =>{
        dispatch(updateSystem(data));
    }
    /*
    const insertComplementaryTool = (data) =>{
        dispatch(createComplementaryTool(data));
    }
    const getCurrentTool = (tool_id) =>{
        dispatch(getToolById(history,tool_id));
    }

    const insertToolData = (data) =>{
        dispatch(createToolAditionalData(data));
    }
    const removeTool = () =>{
        dispatch(cleanTool());
    }
    */


    return {
        ...state,
        listSystems,
        getSubsystemsByLevelAction,
        insertSystem,
        modifySystem,
        SystemsData: state?.data,
        SystemsDataByLevel: state?.dataByLevel,
        loading:state?.loading
        /*insertTool,
        insertComplementaryTool,
        getCurrentTool,
        getDistinctTool,
        insertToolData,
        removeTool,
        toolboxData: state?.data,
        currentTool:state?.dataCurrent,
        distinctTool:state?.dataDistinct,
        complementaryTool:state?.dataComplementary*/
    }
}