import { Autocomplete, Typography } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import {
  QUESTION_TYPE_CLOSE,
  QUESTION_TYPE_MULTIPLE,
  QUESTION_TYPE_OPEN,
  VARIABLE_DOUBLE,
  VARIABLE_INTEGER,
  VARIABLE_STRING,
} from '../../constants/Surveys';
import { useState } from 'react';

const SelectIndicator = ({
  index,
  questions,
  setQuestions,
  listVariables,
  indicator,
  vac,
  variables,
}) => {
  const [type, setType] = useState(true);

  const handleVariableValue = (i, data) => {
    setType(true);
    let optionsOfQuestion = [...questions];
    optionsOfQuestion[i].type_option = { id: 0, label: '' };
    optionsOfQuestion[i].question_options = [];

    optionsOfQuestion[i].variable = data;

    const variableOption = data?.variable_type?.label;

    switch (variableOption) {
      case VARIABLE_INTEGER:
        optionsOfQuestion[i].type_option = QUESTION_TYPE_OPEN;

        if (data?.measure_type?.label !== 'Escalar') {
          optionsOfQuestion[i].type_option = QUESTION_TYPE_CLOSE;
          const op =
            data?.measure_type_characteristics_values[0].value.split(',');
          op.map((r, n) => {
            let idOption = r.split(':');
            optionsOfQuestion[i].question_options = [
              ...optionsOfQuestion[i].question_options,
              {
                id: idOption[0],
                key: n + 1,
                value: idOption[1],
                selected: false,
              },
            ];
          });
          setType(false);
          setQuestions(optionsOfQuestion);
        }

        break;

      case VARIABLE_STRING:
        optionsOfQuestion[i].type_option = QUESTION_TYPE_OPEN;
        setQuestions(optionsOfQuestion);
        break;

      case VARIABLE_DOUBLE:
        optionsOfQuestion[i].type_option = QUESTION_TYPE_OPEN;
        setQuestions(optionsOfQuestion);
        break;
      default:
        break;
    }

    setQuestions(optionsOfQuestion);
  };

  const handleTypeQuestion = (typeQuestion, index) => {
    let typeOfQuestion = [...questions];
    switch (typeQuestion) {
      case 'OM':
        typeOfQuestion[index].type_option = QUESTION_TYPE_MULTIPLE;
        setQuestions(typeOfQuestion);

        break;
      case 'OU':
        typeOfQuestion[index].type_option = QUESTION_TYPE_CLOSE;
        setQuestions(typeOfQuestion);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <div>
        <Typography variant="h6" style={{ marginTop: '10px' }}>
          Indicator:
        </Typography>
        <Autocomplete
          onChange={(event, option) => {
            if (option !== null) {
              listVariables(option?.id, option?.label, index);
            }
          }}
          options={indicator}
          sx={{ width: 200 }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label="Indicador" />}
        />
        <br />
      </div>
      <div>
        <Typography
          variant="subtitle1"
          style={{ marginLeft: '10px', marginTop: '10px' }}
        >
          Variable:
        </Typography>
        <Autocomplete
          style={{ marginLeft: '10px' }}
          key={vac}
          disabled={vac}
          onChange={(event, option) => {
            if (option !== null) {
              handleVariableValue(index, option);
            }
          }}
          options={variables}
          sx={{ width: 200 }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label="Variables" />}
        />
        <br />
      </div>

      <div hidden={type}>
        <Autocomplete
          style={{ marginLeft: '10px' }}
          onChange={(event, option) => {
            if (option !== null) {
              handleTypeQuestion(option?.id, index);
            }
          }}
          options={[
            { label: 'Selección múltiple', id: 'OM' },
            { label: 'Selección única', id: 'OU' },
          ]}
          sx={{ width: 200 }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField {...params} label="Tipo de pregunta" />
          )}
        />
      </div>
    </div>
  );
};
export default SelectIndicator;
