import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';

import * as personsActions from '../../../redux/actions/persons.actions';
import * as tableTemplates from '../utils/templates/tableTemplates';


import InputGender from '../../components/Register/InputGender';
import useForm from '../../../hooks/useForm';

import {useSelector} from 'react-redux';

const Persons =()=>{

    const [ persons, setPersons ] = useState([]);
    const [ globalFilter, setGlobalFilter ] = useState('');
    const [ selectedPerson, setSelectedPerson ] = useState([]);

    const [ personDialog, setPersonDialog ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const toast = useRef(null);
    const dt = useRef(null);

    const { values, setValues, handleInputChange } = useForm();

    const gUser = useSelector((state) => state.LoginState?.data);

  
    const loadPersons = async() =>{
        personsActions.getPersons(gUser?.access_token).then(response=>{
            setPersons(response);
            setLoading(false);
        });
    };

    useEffect(() => {
        loadPersons();
    }, [gUser]);

    const leftToolbarTemplate = () => {
        return (
            <h4>Administracion Personas en Gearth</h4>
        );
    };

    const rightToolbarTemplate = () => {
      return (
          <>
              <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>
          </>
        )
    };

    const header = (

        <div className="table-header">
            <h5 className = "p-m-0">Personas dentro de Gearth</h5>
      
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>

    );

    const exportCSV = () =>{
        dt.current.exportCSV();
    };

    const editPerson = (p) => {
        setValues({...p});
        setPersonDialog(true);
    };

    const dialogFooter = (
      <>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={()=>{setSubmitted(false); setPersonDialog(false);}}/>
          <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={()=> console.log(values)}/>
      </>
    );

    const actionBodyTemplate = (rowData) => {

        //TODO Implementar el editar usuario al arreglar el servicio 

        return (
          <div className="actions">
              <Button icon="pi pi-pencil" disabled className="p-button-rounded p-button-warning p-mr-1"
                      onClick={() => editPerson(rowData)}/>
          </div>
      )
    };

    return(
        <>
            <div className="crud-demo card">

                <div className = "p-col-12">
                    <Toast ref={toast}/>

                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={persons} paginator className='datatable-responsive p-datatable-customers p-ml-3' rows={10} dataKey='id' rowHover selection={ selectedPerson } onSelectionChange={ (e) => setSelectedPerson(e.value)} 
                        globalFilter={globalFilter} emptyMessage='Informacion no disponible' selectionMode='single'  loading={loading} header={header}>

                        <Column field="identification" header="Identification" sortable body={tableTemplates.identificationBodyTemplate}/>
                        
                        <Column field="names" header="Name" sortable body={tableTemplates.namesBodyTemplate}/>
                        
                        <Column field="last_names" header="Last Name" sortable body={tableTemplates.lastNamesBodyTemplate}/>

                        <Column field="gender" header="Gender" sortable body={tableTemplates.genderBodyTemplate}/>

                        <Column field="active" header="Active" sortable body={tableTemplates.statusBodyTemplate}/>

                        <Column header='Actions' body={actionBodyTemplate}/>

                    </DataTable>

                    {/*Dialogo para editar a una persona*/}

                    <Dialog visible={personDialog} style={{width: '350px'}} header="Person" modal
                            className="p-fluid" footer={dialogFooter} onHide={()=>{setSubmitted(false); setPersonDialog(false);}}>

                        <div className="p-field">

                            <label htmlFor="names">Nombres</label>
                            <InputText  id="names" name='names' value={values?.names} onChange={handleInputChange}
                                        required autoFocus
                                        className={(submitted && !values?.names) ? 'p-invalid' :''}/>
                                        {submitted && !values?.names && <small className="p-invalid">Name is required.</small>}
                        
                        </div>

                        <div className="p-field">
                            
                            <label htmlFor="last_names">Apellidos</label>
                            <InputText  id="last_names" 
                                        name='last_names'
                                        value={values?.last_names} 
                                        onChange={handleInputChange}
                                        required autoFocus
                                        className={classNames({'p-invalid': submitted && !values?.lname})}/>
                                        {submitted && !values?.lname &&
                                            <small className="p-invalid">Last Name is required.</small>}
                        
                        </div>

                        <div className="p-field">
                            <label htmlFor="gender">Genero</label>
                            <Toolbar
                                className='p-mb-3'
                                left={
                                    <>
                                        <InputGender values={values} handleInputChange={handleInputChange} info={true}/>
                                    </>
                                }/>
                        </div>
                        
                    </Dialog>
                </div>
            </div>
        </>
    )
};

export default Persons;
