import axios from "axios";
import * as urls from  '../../api/urls';
import { REACT_APP_CODE_PAYMENT } from "../../app/pages/utils/constants/payment-gateways";

export const getPaymentMethods = async (token) => {
    const response = await axios.get(`${urls.URL_BASE}${urls.urlGetPaymentMethods}`,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const createPaymentMethod = async (data, token) => {

    const paymentMethod = {
        name: data?.name,
        description: data?.description,
        logo: data?.logo,
    };

    const response = await axios.post(`${urls.URL_BASE}${urls.urlCreatePaymentMethod}`, paymentMethod, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const updatePaymentMethod = async(data, token) =>{
    const paymentId = data?.id;

    const dataEdit ={
        name: data?.name,
        description: data?.description,
        logo: data?.logo
    }

    const response = await axios.put(`${urls.URL_BASE}${urls.urlUpdatePaymentMethod}${paymentId}`, dataEdit, {
        headers:{
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deletePaymentMethod = async(id, token) =>{
    const response = await axios.put(`${urls.URL_BASE}${urls.urlDeletePaymentMethod}${id}`,{},{
        headers:{
            Authorization:'Bearer ' + token
        }
    });

    return response.data;
}

/* From Django */

export const getPaypalButton = async () => {

    const response = await axios.get(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlGetPaypalButton}`, {
        headers: {
            'Accept': 'text/html; charset=utf-8',
        },
    });

    return response.data;
}

export const createPagoPaypal = async (orderID, customerCode, product) => {
    
    const body = {
        orderID: orderID,
        customer_code: customerCode,
        app_code: REACT_APP_CODE_PAYMENT,
        product_code: product?.code,
        product_type: product?.type,
        product_name: product?.name,
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlCreatePagoPaypal}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    return response.data;
}

export const createPagoStripe = async (payment_intent_id, customer, product) => {
    
    const body = {
        payment_intent_id: payment_intent_id,
        app_code: REACT_APP_CODE_PAYMENT,
        customer_code: customer?.code,
        names: customer?.names,
        last_names: customer?.last_names,
        product_code: product?.code,
        product_type: product?.type,
        product_name: product?.name,
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlCreatePagoStripe}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    console.log(response);
    return response.data;
}

export const isPurchased = async (customer_code, product_code, product_type) => {
    
    const body = {
        app_code: REACT_APP_CODE_PAYMENT,
        customer_code: customer_code,
        product_code: product_code,
        product_type: product_type,
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlIsPurchased}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    return response.data;
}

export const getPaymentMethodsByCustomer = async (customer_code) => {
    
    const body = {
        app_code: REACT_APP_CODE_PAYMENT,
        customer_code: customer_code,
        payment_gateway_type: 'STRIPE',
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlGetPaymentMethodsByCustomer}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    return response.data;
}

export const savePaypalSubscriptionData = async (subscription_id, plan_code, customer_code) => {

    const body = {
        app_code: REACT_APP_CODE_PAYMENT,
        customer_code: customer_code,
        plan_code: plan_code,
        subscription_id: subscription_id,
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlSavePaypalSubscriptionData}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    return response.data;
}

export const createStripeSubscription = async (customer_code, plan_code, payment_method_code) => {
    
    const body = {
        app_code: REACT_APP_CODE_PAYMENT,
        customer_code: customer_code,
        plan_code: plan_code,
        payment_method_code: payment_method_code,
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlCreateStripeSubscription}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    console.log(response);
    return response.data;
}

export const getOrCreateStripeCustomer = async (customer, payment_method_code) => {

    const body = {
        app_code: REACT_APP_CODE_PAYMENT,
        customer_code: customer?.code,
        payment_method_code: payment_method_code ,
        last_names: customer?.last_names,
        email: customer?.email,
        names: customer?.names ,
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlGetOrCreateStripeCustomer}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    return response.data;
}

export const subscriptionHistory = async (customer_code) => {

    const body = {
        app_code: REACT_APP_CODE_PAYMENT,
        customer_code: customer_code,
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlGetSubscriptionHistory}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    return response.data;
} 

export const retriveLastestSubscription = async (customer_code) => {
    const body = {
        app_code: REACT_APP_CODE_PAYMENT,
        customer_code: customer_code,
    }

    const response = await axios.post(`${urls.URL_BASE_PASARELA_PAGOS}${urls.urlGetLastestSubscription}`, body, {
        headers: {
            "Content-type": "application/json"
        }
    });

    return response.data;
} 