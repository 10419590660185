import React, { useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

const RelateIndicator = ({ opRef, indicators, setRelationships, input, setInput }) => {

    const [ indicator, setIndicator ] = useState({});
    const [ variable, setVariable ] = useState({});

    const handleAdd = () => {

        setRelationships((relationships) => {
            const index = relationships.findIndex(rel => rel.input.key === input.key);

            relationships[index] = {
                ...relationships[index],
                variable: {
                    id: variable?.id,
                    label: variable?.label,
                }
            };

            return relationships;
        });
        
        // Clear and close
        opRef.current.hide();
        clearAll();
    }

    const clearAll = () => {
        setIndicator({});
        setVariable({});
        setInput({});
    }

    return (
        <div>
            <OverlayPanel 
                ref={ opRef } 
                appendTo={document.body} 
                showCloseIcon 
                id="overlay_panel" 
                style={{ width: '300px' }}  >

                <div>
                    <h5 className='p-text-center'>Relate to Indicator</h5>
                    
                </div>

                <div className='p-text-center p-mb-4'>
                    <div className='p-mb-1'>
                        Indicator
                        <span className='color-danger'> *</span>
                    </div>
                    <Dropdown
                        id='dropdown-indicators'
                        className=''
                        style={{ width: '95%' }}
                        value={ indicator } 
                        onChange={(e) => setIndicator(e.value)}
                        options={ indicators } 
                        optionLabel="name"
                        autoFocus
                        placeholder="Select a indicator" />
                </div>

                <div className='p-text-center'>
                    <div className='p-mb-1'>
                        Variable
                        <span className='color-danger'> *</span>
                    </div>
                    <Dropdown
                        id='dropdown-indicators'
                        className=''
                        style={{ width: '95%' }}
                        value={ variable } 
                        onChange={(e) => setVariable(e.value)}
                        options={ indicator?.variables } 
                        optionLabel="label"
                        autoFocus
                        placeholder="Select a variable"
                        disabled={ (Object.keys(indicator).length > 0) ? false:true } />
                </div>

                <div className='p-text-center p-mt-4'>
                    <Button
                        style={{ width: '95%' }}
                        label='Add'
                        className='p-button-text p-shadow-5'
                        onClick={ handleAdd }
                        disabled={ (Object.keys(variable).length > 0) ? false:true } />
                </div>
                
            </OverlayPanel>
        </div>
    )
}

export default RelateIndicator;