import { useState } from 'react';
import * as surveyActions from '../../../redux/actions/survey.actions';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

const CreateSurvey = ({
  process,
  activity,
  setActualizar,
  toast,
  model,
  setOpen,
  open,
}) => {
  const user = useSelector((state) => state.LoginState?.data);
  const dispatch = useDispatch();
  const [pollTitle, setPollTitle] = useState('');
  const [pollDescription, setPollDescription] = useState('');

  const [emptyT, setEmptyT] = useState(false); //Variable que controla que al crear un nueva encuesta el titulo no este vacio.
  const [emptyD, setEmptyD] = useState(false); //Variable que controla que al crear un nueva encuesta la descripcion no este vacia.

  const cancelAddPoll = () => {
    setOpen(false);
    setPollTitle('');
    setPollDescription('');
    setEmptyT(false);
    setEmptyD(false);
  };

  const createPoll = async () => {
    let survey = {
      name: pollTitle,
      description: pollDescription,
    };

    if (survey.name !== '') {
      setEmptyT(false);

      if (survey.description !== '') {
        setEmptyD(false);

        let status = null;

        if (process === undefined || activity === undefined) {
          status = await dispatch(
            surveyActions.createSurvey(
              survey,
              0,
              0,
              model?.id,
              user?.access_token
            )
          );
        } else {
          status = await dispatch(
            surveyActions.createSurvey(
              survey,
              process?.id,
              activity?.id,
              model?.id,
              user?.access_token
            )
          );
        }

        if (status !== 201) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'An error has occurred!',
            life: 3000,
          });
          return;
        } else {
          toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: 'Survey Created!',
            life: 3000,
          });
          setActualizar(true);
          setOpen(false);
          setPollTitle('');
          setPollDescription('');
          setEmptyT(false);
          setEmptyD(false);
        }
      } else {
        setEmptyD(true);
      }
    } else {
      setEmptyT(true);
    }
  };

  return (
    <div>
      {/* Dialogo para la creacion de nuevas encuestas */}

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle id="form-dialog-title">Crear Encuesta</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Creando una nueva encuesta vacía, simplemente agregue el nombre y la
            descripción de la encuesta.
          </DialogContentText>
          <TextField
            id="name"
            autoFocus
            margin="dense"
            label="Nombre de la Encuesta"
            type="text"
            fullWidth={false}
            value={pollTitle}
            onChange={(e) => {
              setPollTitle(e.target.value);
            }}
            error={emptyT === true}
            helperText={emptyT === true ? 'Empty field!' : ' '}
          />{' '}
          <br />
          <TextField
            id="description"
            margin="dense"
            label="Descripción de la Encuesta"
            type="text"
            fullWidth
            value={pollDescription}
            error={emptyD === true}
            helperText={emptyD === true ? 'Empty field!' : ' '}
            onChange={(e) => {
              setPollDescription(e.target.value);
            }}
          />{' '}
          <br />
        </DialogContent>

        <DialogActions>
          <Button
            label="Cancelar"
            className="p-button-text p-mr-2 p-mb-2"
            onClick={cancelAddPoll}
          />
          <Button
            label="Crear"
            className="p-button-text p-mr-2 p-mb-2"
            onClick={createPoll}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateSurvey;
