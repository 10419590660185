import { createAction } from 'redux-actions';
import api from '../../api';
import axios from 'axios';
import * as urls from '../../api/urls';
import { resetUserPreferencesAction } from './user-preferences.actions';

export const setLoadingLoginAction = createAction('SET_LOADING_LOGIN_ACTION');
export const setLoginDataAction = createAction('SET_LOGIN_DATA_ACTION');
export const setLoginErrorAction = createAction('SET_LOGIN_ERROR_ACTION');
export const setIsLogedIn = createAction('IS_LOGED_IN');
export const setResetLoginAction = createAction('SET_RESET_LOGIN_ACTION');

export const authLogin = (user) => async (dispatch, getState) => {

  const body = {
    username: user?.username,
    password: user?.password,
    app: 'web',
  };

  await axios.post(`${urls.URL_BASE}${urls.urlLogin}`, body).then((data) => {
    dispatch(setLoginDataAction(data?.data));
    dispatch(setIsLogedIn(true));
  }).catch((err) => {
    throw err;
  });
};

export const authLogout = (props) => async (dispatch, getState) => {

  dispatch(setResetLoginAction());
  dispatch(setLoadingLoginAction(false));

  try {
    dispatch(resetUserPreferencesAction());
    dispatch(setIsLogedIn(false));
    dispatch(setLoginDataAction({}));
    dispatch(setLoginErrorAction(null));

    props.history.push('/login');
  } catch (error) {

  }
};

export const updateUserTutorial =
  (id, token, data) => async (dispatch, getState) => {
    try {
      const response = await api.put(
        `${urls.URL_BASE}${urls.urlUpdateInvitation}?user_id=${id}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      if (response?.status === 200) {
        const newData = {
          ...data,
          user_complete_tutorial: true,
        };

        dispatch(setLoginDataAction(newData));
      }

      return response.status;
    } catch (e) {
      console.log(e);
    }
  };

export const verifyTextCode = async (username, activationCode) => {
  try {
    const { data, status } = await axios.post(
      `${urls.URL_BASE}${urls.verifyTextCodeUrl}`,
      {
        username: username,
        activation_code: activationCode,
      }
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const registerGuest = async (
  userValues,
  username,
  activationCodeId,
  teamId
) => {
  try {
    const GUEST_DATA = {
      username: username.trim(),
      names: userValues?.names.trim(),
      last_names: userValues?.lnames.trim(),
      gender: userValues?.gender.trim(),
      password: userValues?.password.trim(),
      team_id: teamId,
      activation_code_id: activationCodeId,
    };
    const { data } = await axios.post(
      `${urls.URL_BASE}${urls.urlRegisterGuest}`,
      GUEST_DATA
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const registrarUsuario = async (data) => {
  const USER_DATA = {
    username: data?.email,
    names: data?.names,
    last_names: data?.lnames,
    identification: data?.id,
    gender: data?.gender,
    password: data?.password,
    description: data?.desc,
    team_name: data?.team,
    plan_id: 1,
    role_id: 1,
  };

  const response = await axios.post(
    `${urls.URL_BASE}${urls.urlRegister}`,
    USER_DATA
  );

  return response.data;

  // return new Promise(function (resolve, reject) {
  //     axios.post(`${urls.URL_BASE}${urls.urlRegister}`, USER_DATA).then(
  //         response => {
  //             var result = response.data;
  //             resolve(result);
  //         }, error => {
  //             reject(error);
  //         }
  //     );
  // });
};

// CRUD DE LOS DATOS DE LOS USUARIOS DE LA APLICACION INFORMATIVA

export const registerUserInfoApp = async (data, token, teamId) => {
  const INFO_APP_USER = {
    username: data?.username,
    names: data?.names,
    last_names: data?.last_names,
    identification: data?.identification,
    gender: data?.gender,
    password: data?.password,
    description: '',
    team_id: teamId,
    role_name: data?.role_name,
    profile_picture_url: '',
  };

  console.log(INFO_APP_USER);

  const response = await axios.post(
    `${urls.URL_BASE}${urls.urlRegisterUserInfoApp}`,
    INFO_APP_USER,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response.data;
};

export const getInfoAppUsersByTeam = async (teamId, token) => {
  const response = await axios.get(
    `${urls.URL_BASE}${urls.urlListUserInfoAppByTeam}${teamId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response.data;
};

export const updateAppUser = async (data, token) => {
  const userId = data?.id;

  const EDIT_USER = {
    names: data?.names,
    last_name: data?.last_name,
    gender: data?.gender,
    password: data?.password,
  };

  const response = await axios.put(
    `${urls.URL_BASE}${urls.urlUpdateUSerInfoApp}/${userId}`,
    EDIT_USER,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  return response.data;
};

export const getAllUsers = async (token) => {
  const response = axios.get(urls.URL_BASE + urls.urlGetUsers, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });

  return (await response).data;
};

export const deleteUser = async (user_id, token) => {
  const response = await axios.delete(
    urls.URL_BASE + urls.urlDeleteUser + user_id,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );

  return response.data;
};
