import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';

import * as tableTemplates from './utils/tabletemplates';
import NewUserInfoApp from './templates/NewUserInfoApp';
import ChooseSurvey from './templates/ChooseSurvey';
import { getInfoAppUsersByTeam } from '../../../redux/actions/user.action';

const InfoApp = () => {
  const toast = useRef(null);
  const user = useSelector((state) => state.LoginState?.data);
  const { t } = useTranslation(['global']);

  const emptyUser = {
    username: '',
    names: '',
    last_names: '',
    identification: '',
    gender: 'M',
    password: '',
    description: '',
    team_id: user?.team?.id,
    role_name: 'info-app',
    profile_picture_url: '',
  };

  const [userInfo, setUserInfo] = useState(emptyUser);
  const [usersInfo, setUsersInfo] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [globalFilter, setGlobalFilter] = useState();

  const [loading1, setLoading1] = useState(true);
  const [userInfoAppDialog, setUserInfoDialog] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState(false);

  const loadData = async () => {
    getInfoAppUsersByTeam(user?.team?.id, user?.access_token)
      .then((response) => {
        setUsersInfo(response);
        setGlobalFilter(response);
        setLoading1(false);
      })
      .catch(() => {
        setLoading1(false);
        toast.current.show({
          severity: 'warn',
          summary: 'Error al listar los usuarios',
          detail:
            'A ocurrido un error de conexión, por favor intentalo de nuevo!',
          life: 3000,
        });
      });
  };

  useEffect(() => {
    loadData();
  }, [user]);

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button
          label={t('adminInfo.toolbar.new')}
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setUserInfoDialog(true);
          }}
        />
      </>
    );
  };

  const searchUser = (text) => {
    if (text) {
      const newData = usersInfo.filter((item) => {
        const itemData = item?.person?.names
          ? item?.person?.names.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setGlobalFilter(newData);
    } else {
      setGlobalFilter(usersInfo);
    }
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Usuarios</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => searchUser(e.target.value)}
          placeholder="Buscar Usuario..."
        />
      </span>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    const editUser = {
      id: rowData?.id,
      username: rowData?.username,
      names: rowData?.person?.names,
      last_names: rowData?.person?.last_names,
      identification: rowData?.person?.identification,
      gender: rowData?.person?.gender,
      password: '**********',
      description: rowData?.description,
      team_id: rowData?.team?.id,
      role_name: 'info-app',
      profile_picture_url: '',
    };

    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning p-mr-2"
          onClick={() => {
            setSelectedUser(rowData);
            setUserInfo(editUser);
            setUserInfoDialog(true);
          }}
          tooltip={t('adminInfo.toolbar.edit')}
        />

        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-success"
          onClick={() => {
            setSelectedUser(rowData);
            setUserId(rowData.id);
            setSurveyInfo(true);
          }}
          tooltip={t('adminInfo.toolbar.to_assign')}
        />
      </div>
    );
  };

  return (
    <>
      <div className="p-grid crud-demo">
        <div className="p-col-12">
          <div className="card">
            <Toast ref={toast} />
            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} />

            <DataTable
              value={globalFilter}
              paginator
              className="p-datatable-customers p-ml-3 datatable-responsive"
              rows={10}
              dataKey="id"
              rowHover
              selection={selectedUser}
              onSelectionChange={(e) => setSelectedUser(e.value)}
              emptyMessage="Informacion no disponible"
              selectionMode="single"
              loading={loading1}
              header={header}
            >
              <Column
                filed="person.names"
                header={t('adminInfo.table.names')}
                sortable
                body={tableTemplates.nameBodyTemplate}
              />
              <Column
                filed="person.last_names"
                header={t('adminInfo.table.last_names')}
                sortable
                body={tableTemplates.lnameBodyTemplate}
              />
              <Column
                filed="username"
                header={t('adminInfo.table.user')}
                sortable
                body={tableTemplates.emailBodyTemplate}
              />
              <Column
                filed="status"
                header={t('adminInfo.table.active')}
                sortable
                body={tableTemplates.statusBodyTemplate}
              />
              <Column
                style={{ width: '10rem' }}
                header={t('adminInfo.table.actions')}
                body={actionBodyTemplate}
              />
            </DataTable>

            <NewUserInfoApp
              open={userInfoAppDialog}
              setOpen={setUserInfoDialog}
              userInfoApp={userInfo}
              setUserInfoApp={setUserInfo}
              callback={loadData}
            />

            <ChooseSurvey
              open={surveyInfo}
              setOpen={setSurveyInfo}
              userId={userId}
              callback={loadData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoApp;
