export const steps = {
    body:[
      {
        content:(
          <>
            <h5>¡Ahora administra tu equipo de trabajo!</h5>
            <br/>
            En la siguiente pestaña podras manejar a tu equipo de trabajo
          </>
        ),
        locale:{skip: <p aria-label='skip'>Dissmis</p>},
        placement:'center',
        target:'body',
      },
    ],
    newM:[
      {
        content:(
          <>
            Invita a mas gente a formar parte de tu grupo de trabajo
            <br/>
            !Haz click en Add Member¡
          </>
        ),
        locale:{skip: <p aria-label='skip'>Dissmis</p>},
        disableBeacon: true,
        //hideCloseButton: true,
        placement:'auto',
        target:'.button_new',
        title:'Nuevo Miembro'
      },
    ],
    newMem:[
      {
        content:(
          <>
            Ingresa el correo electronico del nuevo miembro, y al terminar dale click en ¡Send Invitation! para invitarlo a tu grupo de trabajo
          </>
        ),
        locale:{skip: <p aria-label='skip'>Dissmis</p>},
        placement:'auto',
        target:'.email',
        disableBeacon: true,
        hideCloseButton: true,
      },
      {
        content:(
          <>
            O puedes invitar a personas de tus diferentes grupo de trabajo, buscalas en esta tabla,
            una ves que encuentres a la persona que deseas invitar dale click al boton de la derecha.
          </>
        ),
        locale:{skip: <p aria-label='skip'>Dissmis</p>},
        placement:'auto',
        target:'.dataTabla',
        disableBeacon: true,
        hideCloseButton: true,
      },
    ]
  };
