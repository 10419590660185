import axios from 'axios';

import {
  urlDesignCSCInit,
  urlDesignCSC,
  urlDesignCSCGraph,
  urlDesignCSCComposition,
  urlDesignCSCInteractions,
  urlDesignCSCMechanisms,
  urlDesignCSLimits,
  urlDesignCSCEnvironment,
  urlDesignCSCProperties,
  urlDesignCSCMeaning,
} from '../../../api/urls';

/* ******** COMPONENTS ******** */

export const getComponentsGraphRepresentation =
  () => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;
    try {
      const { data, status } = await axios.get(
        `${urlDesignCSCGraph}?role=${role}&model_id=${modelId}`,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        return data;
      } else {
        throw new Error('Error retrieving components');
      }
    } catch (error) {
      throw error;
    }
  };

export const createComponent =
  (newComponent, parentComponent) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { status, data } = await axios.post(
        `${urlDesignCSC}?role=${role}`,
        {
          model_id: modelId,
          parent_id: parentComponent.id,
          name: newComponent.name,
          definition: newComponent.definition,
          group_id: parentComponent.groupId,
          ref_id: newComponent.ref_id,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        return data;
      } else {
        throw new Error('Error creating component.');
      }
    } catch (error) {
      throw error;
    }
  };

export const updateComponent = (component) => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    console.error(component);
    const { status, data } = await axios.put(
      `${urlDesignCSC}?role=${role}`,
      {
        id: component.id,
        model_id: modelId,
        name: component.name,
        definition: component.definition,
        group_id: component.groupId,
        ref_id: component.ref_id,
      },
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      return data;
    } else {
      throw new Error('Error updating component.');
    }
  } catch (error) {
    throw error;
  }
};

export const deleteComponent = (componentId) => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const role = getState().RolNavigationState?.state;

  try {
    const { status } = await axios.delete(
      `${urlDesignCSC}?role=${role}&component_id=${componentId}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      return;
    } else {
      throw new Error('Error deleting ');
    }
  } catch (error) {
    throw error;
  }
};

/* ******** INSTANTIATE INITIAL COMPONENTS FOR THE MODEL ******** */

export const instantiateCompleSystem =
  (modelId) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    try {
      const { status } = await axios.get(
        `${urlDesignCSCInit}?model_id=${modelId}`,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status !== 200) {
        throw new Error('Error instantiating components');
      }
    } catch (error) {
      throw error;
    }
  };

/* ******** COMPOSITION ******** */

export const getComponentsAddedToModel = () => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.get(
      `${urlDesignCSCComposition}/${modelId}?role=${role}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      return data;
    } else {
      throw new Error('Error fetching components added to model.');
    }
  } catch (error) {
    throw error;
  }
};

export const addComponentToModel =
  (component) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { status, data } = await axios.post(
        `${urlDesignCSCComposition}/${modelId}?role=${role}`,
        {
          model_id: modelId,
          component_id: component.id,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        return data;
      } else {
        throw new Error('Error adding component to model.');
      }
    } catch (error) {
      throw error;
    }
  };

export const updateSelectedComponent =
  (component) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { status, data } = await axios.put(
        `${urlDesignCSCComposition}/${component.id}?role=${role}`,
        {
          id: component.id,
          model_id: modelId,
          component_id: component.component_id,
          is_key: component.is_key,
          is_variable: component.is_variable,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200)
        throw new Error(`Error updating attribures of selected component.`);
      return data;
    } catch (error) {
      throw error;
    }
  };

export const deleteSelectedComponent =
  (componentId) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { status } = await axios.delete(
        `${urlDesignCSCComposition}/${modelId}/${componentId}?role=${role}`,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        return;
      } else {
        throw new Error('Error deleting selected component');
      }
    } catch (error) {
      throw error;
    }
  };

/* ******** INTERACTIONS ******** */

export const getInteractions = () => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.get(
      `${urlDesignCSCInteractions}/${modelId}?role=${role}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      return data;
    } else {
      throw new Error('Error fetching interactions.');
    }
  } catch (error) {
    throw error;
  }
};

export const createInteraction =
  (interaction) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { status, data } = await axios.post(
        `${urlDesignCSCInteractions}/${modelId}?role=${role}`,
        {
          model_id: modelId,
          source_id: interaction.source_id,
          target_id: interaction.target_id,
          name: interaction.name,
          definition: interaction.definition,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        return data;
      } else {
        throw new Error('Error creating interaction.');
      }
    } catch (error) {
      throw error;
    }
  };

export const updateInteraction =
  (interaction) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
      const { status, data } = await axios.put(
        `${urlDesignCSCInteractions}/${interaction.id}?role=${role}`,
        {
          id: interaction.id,
          model_id: modelId,
          source_id: interaction.source_id,
          target_id: interaction.target_id,
          name: interaction.name,
          definition: interaction.definition,
          is_key: interaction.is_key,
          intensity_id: interaction.intensity_id,
          direction_id: interaction.direction_id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200)
        throw new Error(`Error updating attribures of interaction.`);
      return data;
    } catch (error) {
      throw error;
    }
  };

export const deleteInteraction =
  (sourceId, targetId) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const role = getState().RolNavigationState?.state;

    try {
      const { status } = await axios.delete(
        `${urlDesignCSCInteractions}/${sourceId}/${targetId}?role=${role}`,
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        return;
      } else {
        throw new Error('Error deleting interaction');
      }
    } catch (error) {
      throw error;
    }
  };

//////////////////////
///// MODELIZATION COMPONENTS : MECHANISMS
//////////////////////

const getObjectiveUrl = (step) => {
  let url = '';
  if (step === 'mechanisms') url = urlDesignCSCMechanisms;
  else if (step === 'limits') url = urlDesignCSLimits;
  else if (step === 'environment') url = urlDesignCSCEnvironment;
  else if (step === 'properties') url = urlDesignCSCProperties;
  else if (step === 'meaning') url = urlDesignCSCMeaning;
  return url;
};

export const getModelizationComponents =
  (step) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const url = getObjectiveUrl(step);
    const role = getState().RolNavigationState?.state;

    try {
      const { status, data } = await axios.get(
        `${url}/${modelId}?role=${role}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status === 200) return data;
      else throw new Error('Error fetching modelization components.');
    } catch (error) {
      throw error;
    }
  };

export const updateModelizationComponents =
  (component_id, attribute, component, step) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const url = getObjectiveUrl(step);
    const role = getState().RolNavigationState?.state;

    try {
      const { status } = await axios.put(
        `${url}/${component_id}?role=${role}`,
        {
          id: component.id,
          label: attribute,
          value: component[attribute],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200)
        throw new Error(`Error updating attribute: ${attribute}`);
    } catch (error) {
      throw error;
    }
  };
