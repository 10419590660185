import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { Dashboard } from "../components/Dashboard";
import { Plan } from "../pages/Plan";
import { Menu } from "../pages/Menu";
import { Repository } from "../pages/Repository";
import Persons from "../pages/Persons";
import ListMembers from "../pages/TeamManage";
import IndexME from "../pages/M&E";
import Roles from "../pages/Roles";
import Teams from "../pages/Teams";
import PaymentMethods from "../pages/PaymentMethods";
import MenuRole from "../pages/MenuRole";
import RendersTest from "../pages/RendersTest";
import Models from "../pages/Models";
import Processes from "../pages/Processes";
import Process from "../pages/Processes/Process";
import SignUp from "../pages/SignUp";
import GraphModelizer from "../pages/GraphModelizer";
import ModelsConsole from "../pages/ModelsConsole/ModelsConsole";
import Profile from "../pages/Profile";
import BuilderBPMN from "../pages/Processes/BPMN/Builder";
import ViewerBPMN from "../pages/Processes/BPMN/Viewer";
import RepositoryList from "../pages/Repository/RepositoryList";
import ModelDesigner from "../pages/ModelDesigner";
import Moments from "../pages/ModelDesigner/Stage2/Moments/index";
import SocialNetwork from "../pages/ModelDesigner/SocialNetwork/SocialNetwork";
import VariableOperationalization from "../pages/VariableOperationalization/VariableOperationalization";
import ComplexSystem from "../pages/Core/PreviousVersion-ComplexSystem";
import KnowledgeBase from "../pages/Core/KnowledgeBase";
import Toolbox from "../pages/Core/Toolbox";
import Polls from "../pages/Polls";
import EditPoll from "../pages/Polls/templates/EditPoll";
import NewTool from "../pages/Core/Toolbox/Update";
import NewItem from "../pages/Core/KnowledgeBase/NewItem";
import ViewTool from "../pages/Core/Toolbox/Detail";
import ViewItem from "../pages/Core/KnowledgeBase/ViewItem";
import Indicators from "../pages/Core/Indicators";
import NewIndicator from "../pages/Core/Indicators/NewIndicator";
import ModelDetails from "../pages/Models/ModelDetails";
import PlanManage from "../pages/PlanManage";
import FormBuilder from "../pages/Forms/Builder";
import FormViewer from "../pages/Forms/Viewer";
import InfoApp from "../pages/infoApp";
import SurveyView from "../pages/Polls/templates/SurveyView";
import ProblemSolver from "../pages/problem-solver/ProblemSolver.tsx";

const Routes = ({ colorScheme, help: Help }) => {
  return (
    <React.Fragment>
      <Switch>
        {/* Root */}
        <Route exact path="/" component={Dashboard} />

        {/* Admin routes */}
        <Route path="/admin/role" component={Roles} />
        <Route path="/admin/team" component={Teams} />
        <Route path="/admin/payment-method" component={PaymentMethods} />
        <Route path="/admin/persons" component={Persons} />
        <Route path="/admin/menu" component={Menu} />
        <Route path="/admin/menu-role" component={MenuRole} />
        <Route path="/admin/members" component={ListMembers} />
        <Route exact path="/admin/plan" component={Plan} />
        <Route exact path="/admin/plan/manage" component={PlanManage} />

        <Route path="/creators/moments" component={Moments} />
        <Route path="/admin/graph-modelizer" component={GraphModelizer} />
        <Route path="/profile" component={Profile} />
        <Route path="/admin/renders-test" component={RendersTest} />
        <Route path="/admin/repositories" component={RepositoryList} />
        <Route path="/core/repository" component={Repository} />
        <Route path="/singup" component={SignUp} />

        {/* Process routes */}
        <Route path="/admin/processes" component={Processes} />
        <Route path="/admin/add-process" component={Process} />W
        <Route path="/bpmn/builder" component={BuilderBPMN} />
        <Route path="/bpmn/viewer" component={ViewerBPMN} />
        <Route path="/admin/model-designer" component={ModelDesigner} />

        {/* Social Network routes */}
        <Route path="/admin/social-network" component={SocialNetwork} />

        {/* Microfronted: FormIO Routes */}
        <Route path="/form/builder" component={FormBuilder} />
        <Route path="/form/viewer" component={FormViewer} />

        {/* Polls routes*/}
        <Route path="/admin/surveys" component={Polls} />
        <Route path="/admin/survey/edit" component={EditPoll} />

        <Route
          exact
          path="/creators/model-designer"
          component={ModelsConsole}
        />

        {/* Models's sales routes */}
        <Route exact path="/models" component={Models} />
        <Route exact path="/models/details/:id" component={ModelDetails} />

        {/* Territorial System routes */}
        <Route path="/core/complex-system" component={ComplexSystem} />
        <Route path="/core/knowledge" exact component={KnowledgeBase} />
        <Route path="/core/knowledge/items" component={NewItem} />
        <Route path="/core/knowledge/view" component={ViewItem} />
        <Route path="/core/toolbox" exact component={Toolbox} />
        <Route path="/core/toolbox/tools" component={NewTool} />
        <Route path="/core/toolbox/view" component={ViewTool} />
        <Route path="/core/indicators" exact component={Indicators} />
        <Route path="/core/indicators/new" component={NewIndicator} />
        <Route path="/core/indicators/view" component={ViewTool} />

        {/* Variable Operationalization routes */}
        <Route
          path="/admin/operationalization"
          component={VariableOperationalization}
        />

        {/* Problem Solver */}
        <Route path="/apps/problem-solver" component={ProblemSolver} />
        <Route
          exact
          path="/apps/monitoring-and-evaluation"
          component={IndexME}
        />

        {/* Ruta para el manejo de los usuarios de la aplicacion informativa */}
        <Route path="/admin/info-users" component={InfoApp} />
        <Route path="/view/survey" component={SurveyView} />

        {/* Executor model routes */}
        <Route path="/executor/models" component={ModelsConsole} />

        {/* Redirect to dashboard, if path route isn't exist in this routers switch */}
        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
