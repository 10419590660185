import React from "react";

import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

import classNames from "classnames";
import VariableCharacteristics from "./VariableCharacteristics";

const CreateIndicatorVariableDialog = (props) => {
  const dialogFooter = () => {
    return (
      <>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-text"
          onClick={() => {
            props.setCreateDialog(false);
            props.setSubmitted(false);
          }}
        />
        <Button
          label="Guardar"
          icon="pi pi-check"
          className="p-button-text"
          onClick={props.createTargetObject}
        />
      </>
    );
  };

  return (
    <>
      <Dialog
        visible={props.createDialog}
        style={{ width: "850px" }}
        header={props.targetObject.label}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={() => {
          props.setCreateDialog(false);
        }}
      >
        <div className="p-grid">
          <div className="p-col">
            <div className="p-field">
              <label htmlFor="definition">Definición</label>
              <InputTextarea
                id="definition"
                value={props.targetObject?.definition}
                onChange={(e) => props.onInputChange(e, "definition")}
                required
                className={classNames({
                  "p-invalid":
                    props.submitted && !props.targetObject?.definition,
                })}
                style={{ height: "490px" }}
              />
              {props.submitted && !props.targetObject?.definition && (
                <small className="p-invalid">
                  Se requiere de una definición.
                </small>
              )}
            </div>
          </div>

          <VariableCharacteristics
            variableTypes={props.variablesTypes}
            onSelectorChange={props.onSelectorChange}
            onCharacteristicInputChange={props.onCharacteristicInputChange}
            targetObject={props.targetObject}
          />
        </div>
      </Dialog>
    </>
  );
};

export default CreateIndicatorVariableDialog;
