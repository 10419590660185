import React from 'react';
import PropTypes from 'prop-types';
import { 
    GENDER_FEMALE_SIGN, 
    GENDER_FEMALE_VALUE, 
    GENDER_MALE_SIGN, 
    GENDER_MALE_VALUE,
    GENDER_OTHER_SIGN 
} from '../../pages/utils/constants/values';

const ProfileField = ({ field, label, icon = 'user', gender = null }) => {

    /* 
    field -> Value of field
    label -> field description
    icon -> Icon for field
    gender -> For icon gender validate, if gender isn't null the icon is 
    */

    const getIconGender = (genderValue) => {
        
        if (genderValue === GENDER_MALE_VALUE) {
            return (
                <i className={`pi p-mr-3`}>{ GENDER_MALE_SIGN }</i>
            );
        } else if (genderValue === GENDER_FEMALE_VALUE) {
            return (
                <i className={`pi p-mr-3`}>{ GENDER_FEMALE_SIGN }</i>
            );
        } else {
            return (
                <i className={`pi p-mr-3`}>{ GENDER_OTHER_SIGN }</i>
            );
        }
    };

    return (
        <>
            <div>

                <h5>
                    { (gender) ? getIconGender(gender) : <i className={`pi pi-${icon} p-mr-3`}></i> }
                    { field }
                </h5>

                <i>{ label }</i>

            </div>
        </>
    );
}

ProfileField.propTypes = {
    label: PropTypes.string,
}

export default ProfileField;
