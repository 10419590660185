import React, { useEffect, useState } from "react";
import HelpButton from "./HelpButton";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import {
  MEASURE_TYPE_ID,
  NOT_APLICABLE,
  VARIABLE_TYPE_FORMAT_ID,
  VARIABLE_TYPE_ID,
} from "../../constants/VariableTypes";

const VariableCharacteristics = (props) => {
  const {
    variableTypes,
    onSelectorChange,
    onCharacteristicInputChange,
    targetObject,
  } = props;

  const _selectedVariableType = variableTypes?.find(
    (v) => v.id === targetObject?.variable_type_id
  );
  const _selectedMeasureType = _selectedVariableType?.measure_types?.find(
    (m) => m?.id === targetObject?.measure_type_id
  );
  const _selectedFormatType = _selectedVariableType?.formats?.find(
    (f) => f?.id === targetObject?.variable_type_format_id
  );

  const [variableTypeSelected, setVariableTypeSelected] = useState(
    _selectedVariableType
  );
  const [measureTypeSelected, setMeasureTypeSelected] =
    useState(_selectedMeasureType);
  const [formatSelected, setFormatSelected] = useState(_selectedFormatType);

  useEffect(() => {
    if (!variableTypeSelected) {
      setVariableTypeSelected(variableTypes[0]);
      onSelectorChange(variableTypes[0]?.id, VARIABLE_TYPE_ID);
      setMeasureTypeSelected(variableTypes[0]?.measure_types[0]);
    }
  }, []);

  useEffect(() => {
    if (variableTypeSelected?.measure_types) {
      setMeasureTypeSelected(variableTypeSelected?.measure_types[0]);
      onSelectorChange(
        variableTypeSelected?.measure_types[0]?.id,
        MEASURE_TYPE_ID
      );
      setFormatSelected(null);
    }
    if (variableTypeSelected?.formats?.length > 0) {
      setFormatSelected(variableTypeSelected?.formats[0]);
      onSelectorChange(
        variableTypeSelected?.formats[0]?.id,
        VARIABLE_TYPE_FORMAT_ID
      );
    }
  }, [variableTypeSelected]);

  const onVariableTypeChange = (e, name) => {
    setVariableTypeSelected(e.value);
    setMeasureTypeSelected(e.value.measure_types[0]);
    onSelectorChange(e.value?.id, name);
  };

  const onMeasureTypeChange = (e, name) => {
    setMeasureTypeSelected(e.value);
    onSelectorChange(e.value?.id, name);
  };

  const onFormatTypeChange = (e, name) => {
    setFormatSelected(e.value);
    onSelectorChange(e.value?.id, name);
  };

  const CharacteristicItem = (item) => {
    if (item.label === NOT_APLICABLE) return <></>;

    const value = targetObject?.measure_type_characteristics_values?.find(
      (t) => t?.characteristics_id === item?.id
    )?.value;

    return (
      <div
        className="p-grid"
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          margin: "10px 0 10px 0",
        }}
        key={`${item.id}_${item.label}`}
      >
        <div className="p-col-6">
          <div className="p-grid">
            <div>
              <label>{item.label}</label>
              <HelpButton message={item.definition} />
            </div>
          </div>
        </div>
        <div className="p-col-6">
          <InputText
            onChange={(e) => onCharacteristicInputChange(e, item.id)}
            placeholder={item.placeholder}
            value={value}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="p-col">
        <div className="p-grid">
          <div className="p-col-3">
            <div className="box">Caracterización</div>
          </div>
        </div>

        <div className="p-grid">
          <div className="p-field p-col-12">
            <label htmlFor="definition">Tipo de variable</label>

            <Dropdown
              options={variableTypes}
              optionLabel="label"
              placeholder="Tipos de variables"
              onChange={(e) => {
                onVariableTypeChange(e, VARIABLE_TYPE_ID);
              }}
              value={variableTypeSelected}
            />
          </div>
        </div>

        {measureTypeSelected && measureTypeSelected?.label !== NOT_APLICABLE && (
          <div className="p-grid">
            <div className="p-field p-col-12">
              <label htmlFor="definition">Tipo de medida</label>

              <Dropdown
                options={variableTypeSelected?.measure_types}
                optionLabel="label"
                placeholder="Tipos de medidas"
                onChange={(e) => {
                  onMeasureTypeChange(e, MEASURE_TYPE_ID);
                }}
                value={measureTypeSelected}
              />
            </div>
          </div>
        )}

        {variableTypeSelected && variableTypeSelected?.formats?.length > 0 && (
          <div className="p-grid">
            <div className="p-field p-col-12">
              <label htmlFor="definition">Formatos</label>

              <Dropdown
                options={variableTypeSelected?.formats}
                optionLabel="label"
                placeholder="formats"
                onChange={(e) => {
                  onFormatTypeChange(e, VARIABLE_TYPE_FORMAT_ID);
                }}
                value={formatSelected}
              />
            </div>
          </div>
        )}

        <Divider layout="horizontal" align="left">
          Características
        </Divider>

        <div className="p-field">
          {variableTypeSelected &&
            measureTypeSelected &&
            measureTypeSelected?.measure_characteristics?.map((item) =>
              CharacteristicItem(item)
            )}
        </div>
      </div>
    </>
  );
};

export default VariableCharacteristics;
