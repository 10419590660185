import React, { useEffect, useState } from 'react';
import queryString from "query-string";
import Activities from './Activities';
import DividerMenu from './DividerMenu';
import Processes from './Processes';
import MenuME from './MenuME';
import Model from './Model';
import Settings from './Settings';
import ActivitiesList from './Activities/ActivitiesList';
import ProcessViewer from './Processes/ProcessViewer';
import ProcessEditor from './Processes/ProcessEditor';
import ProcessList from './Processes/ProcessList';
import { useLocation } from 'react-router-dom';
    
const IndexME = props => {

    const location = useLocation();
    const { tab = 'dashboard' } = queryString.parse( location.search );
    const [ windowSize, setWindowSize ] = useState(window.innerWidth);
    const [ key, setKey ] = useState(tab);

    const handleResize = () => {
        setWindowSize(window.innerWidth);
    }    
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderSwitch = (key) => {
        switch (key) {
            case 'model-dashboard':
                return <Model />
            // Process menu
            case 'processes-dashboard':
                return <Processes />
            case 'processes-list':
                return <ProcessList />
            case 'processes-viewer':
                return <ProcessViewer />
            case 'processes-editor':
                return <ProcessEditor />
            // Activity menu
            case 'activities-dashboard':
                return <Activities />
            case 'activities-list':
                return <ActivitiesList />
            case 'settings':
                return <Settings />
            default:
                return <Model />
        };
    }

    return (
        <>
            <div className='card' >
                <div className='p-grid'>

                    {/* Render a menu depending with */}
                    <MenuME windowSize={ windowSize } setKey={ setKey } tab={ tab } />

                    {/* Render a dividir depending with */}
                    <DividerMenu windowSize={ windowSize } />

                    <div className={(windowSize > 900) ? 'p-col-9' : 'p-col-12' } >
                        {
                            renderSwitch(key)
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndexME;