import React from 'react';
import PropTypes from 'prop-types';
import PaymentMethod from '../../../components/Payments/PaymentMethod';
import { Sidebar } from 'primereact/sidebar';

const SidebarPricing = ({ open, setOpen, product, callback }) => {

    return (
        <>
            <Sidebar 
                className='sidebar-pricing'
                visible={ open } 
                onHide={() => setOpen(false)} baseZIndex={1000} position="left">

                <h3 style={{ textAlign: 'center' }} >{ product?.name }</h3>

                <h4 style={{ textAlign: 'center' }}>
                    Total: ${ product?.price }
                </h4>

                <PaymentMethod product={ product } callback={ callback } />
            </Sidebar>
        </>
    )
}

SidebarPricing.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
}

export default SidebarPricing;