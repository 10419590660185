import React from "react";
import { TextEditor } from "../UI";
const Meaning = (props) => {
  return (
    <>
      <div className="p-grid list-demo">
        <div className="p-col-12 p-lg-6">
          <TextEditor
            onInputChange={props.onInputChange}
            name={props.originMeaningName}
            value={props.originMeaningValue}
            title={"Significado de origen"}
            subtitle={"Describa..."}
            submitted={props.submitted}
            messageError={"Se requiere agregar un significado de origen"}
            onSave={props.onSave}
            actionButtonDisabled={!props.hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        </div>
        <div className="p-col-12 p-lg-6">
          <TextEditor
            onInputChange={props.onInputChange}
            name={props.localMeaningName}
            value={props.localMeaningValue}
            title={"Significado local"}
            subtitle={"Describa..."}
            submitted={props.submitted}
            messageError={"Se requiere agregar un significado local"}
            onSave={props.onSave}
            actionButtonDisabled={!props.hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        </div>
      </div>
    </>
  );
};

export default Meaning;
