import React, { useState } from "react";
import { TabMenu } from "primereact/tabmenu";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { TextEditor } from ".";

import Meaning from "../knowledge-base/Meaning";

const LOCAL_MEANING = "local_meaning";
const ORIGIN_MEANING = "origin_meaning";
const PURPOSE = "purpose";
const THEORY = "theory";
const METHOD = "method";
const TOOLS = "tools";
const DUALITY = "duality";
const KEY_INTERACTIONS = "key_interactions";

const components = [
  { id: 0, label: "Significado", icon: "pi pi-sun" },
  { id: 1, label: "Propósito", icon: "pi pi-compass" },
  { id: 2, label: "Teoría", icon: "pi pi-book" },
  { id: 3, label: "Método", icon: "pi pi-table" },
  { id: 4, label: "Herramientas", icon: "pi pi-briefcase" },
  { id: 5, label: "Dualidad", icon: "pi pi-eye" },
  { id: 6, label: "Interacciones clave", icon: "pi pi-link" },
];

const KnowledgeItems = (props) => {
  const [tabSelected, setTabSelected] = useState(components[0]);
  const [hasChanged, setHasChanged] = useState(false);

  const dialogFooter = (
    <>
      <Button
        label="Cerrar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          props.setOpen();
          props.submitted(false);
          setTabSelected(components[0])
        }}
      />
    </>
  );

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  return (
    <>
      <Dialog
        visible={props.open}
        style={{ width: "90%", zindex: 20 }}
        header={props.title}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={() => {
          props.setOpen();
        }}
      >
        <h6>{props.subtitle}</h6>
        <div className="p-grid list-demo">
          <div className="p-col-12 p-md-12">
            <div className="card card-w-title">
              <h3>Base de conocimiento</h3>
              <TabMenu
                model={components}
                onTabChange={(e) => {
                  tabChangeHandler(e.value);
                }}
                activeItem={tabSelected}
              />

              {tabSelected.id === 0 ? (
                <Meaning
                  localMeaningName={LOCAL_MEANING}
                  localMeaningValue={props.knowledge[LOCAL_MEANING]}
                  originMeaningName={ORIGIN_MEANING}
                  originMeaningValue={props.knowledge[ORIGIN_MEANING]}
                  onSave={props.onSaveAttribute}
                  onInputChange={props.onInputChange}
                  hasChanged={props.hasChanged}
                />
              ) : tabSelected.id === 1 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={PURPOSE}
                  value={props.knowledge[PURPOSE]}
                  title={"Propósito"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar propósito"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 2 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={THEORY}
                  value={props.knowledge[THEORY]}
                  title={"Teoría"}
                  subtitle={"Describa ..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar teoría"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 3 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={METHOD}
                  value={props.knowledge[METHOD]}
                  title={"Método"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar método"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 4 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={TOOLS}
                  value={props.knowledge[TOOLS]}
                  title={"Herramientas"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar herramientas"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 5 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={DUALITY}
                  value={props.knowledge[DUALITY]}
                  title={"Dualidad"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar dualidad"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : tabSelected.id === 6 ? (
                <TextEditor
                  onInputChange={props.onInputChange}
                  name={KEY_INTERACTIONS}
                  value={props.knowledge[KEY_INTERACTIONS]}
                  title={"Interacciones clave"}
                  subtitle={"Describa..."}
                  submitted={props.submitted}
                  messageError={"Se requiere agregar interaccones clave"}
                  onSave={props.onSaveAttribute}
                  actionButtonDisabled={!props.hasChanged}
                  helpInformation={"Información de ayuda de este componente"}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default KnowledgeItems;
