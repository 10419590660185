import axios from 'axios';
import { createAction } from 'redux-actions';
import {
  urlDesignIndicators,
  urlVariablesTypes,
  urlDesignIndicatorsByIds,
} from '../../../api/urls';

export const setIndicators = createAction('SET_INDICATORS');
export const setErrorAction = createAction('SET_ERROR_ACTION');
export const reset = createAction('RESET');

export const getVariablesTypes = () => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const role = getState().RolNavigationState?.state;

  try {
    const { data, status } = await axios.get(
      `${urlVariablesTypes}?role=${role}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      return data;
    } else {
      throw new Error('Error fetching variables types data');
    }
  } catch (error) {
    throw error;
  }
};

export const getIndicators = () => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { data, status } = await axios.get(
      `${urlDesignIndicators}?role=${role}&model_id=${modelId}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      dispatch(setIndicators(data));
      return data;
    } else {
      throw new Error('Error fethching indicators.');
    }
  } catch (error) {
    throw error;
  }
};

export const getIndicatorsByIds =
  (token, ids) => async (dispatch, getState) => {
    const body = [];

    const role = getState().RolNavigationState?.state;

    ids.forEach((id) => {
      body.push({ id: id });
    });

    const response = await axios.post(
      `${urlDesignIndicatorsByIds}?role=${role}`,
      body,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  };

export const createIndicator =
  (indicator, parentId) => async (dispatch, getState) => {
    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;
    try {
      const { status, data } = await axios.post(
        `${urlDesignIndicators}?role=${role}`,
        {
          id: indicator.id,
          model_id: modelId,
          parent_id: parentId,
          name: indicator.name,
          definition: indicator.definition,
          context: indicator.context,
          instruments: indicator.instruments,
          interpretation: indicator.interpretation,
          periodicity: indicator.periodicity,
          territorial_unit: indicator.territorial_unit,
          social_group: indicator.social_group,
          infix_expression: indicator.infix_expression,
          variables: indicator.variables,
          tags: indicator.tags,
        },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
          },
        }
      );
      if (status === 200) {
        const _indicators = getState().OperativationState?.indicators;
        dispatch(setIndicators([data, ..._indicators]));
        return data;
      } else {
        throw new Error('Error creating indicator.');
      }
    } catch (error) {
      throw error;
    }
  };

export const updateIndicator = (indicator) => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.put(
      `${urlDesignIndicators}?role=${role}`,
      {
        id: indicator.id,
        model_id: modelId,
        parent_id: indicator.parent_id,
        name: indicator.name,
        definition: indicator.definition,
        context: indicator.context,
        instruments: indicator.instruments,
        interpretation: indicator.interpretation,
        periodicity: indicator.periodicity,
        territorial_unit: indicator.territorial_unit,
        social_group: indicator.social_group,
        infix_expression: indicator.infix_expression,
        variables: indicator.variables,
        tags: indicator.tags,
      },
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      let _indicators = [...getState().OperativationState?.indicators];
      const index = _indicators.findIndex((c) => c.id === indicator.id);
      _indicators[index] = data;
      dispatch(setIndicators(_indicators));
      return data;
    } else {
      throw new Error('Error updating indicator.');
    }
  } catch (error) {
    throw error;
  }
};

export const deleteIndicator = (indicatorId) => async (dispatch, getState) => {
  const accessToken = getState().LoginState?.data?.access_token;
  const role = getState().RolNavigationState?.state;

  try {
    const { status } = await axios.delete(
      `${urlDesignIndicators}?role=${role}&indicator_id=${indicatorId}`,
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
    if (status === 200) {
      const _indicators = [...getState().OperativationState?.indicators];
      dispatch(setIndicators(_indicators.filter((c) => c.id !== indicatorId)));
    } else {
      throw new Error('Error deleting indicator.');
    }
  } catch (error) {
    throw error;
  }
};
