import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { Rating } from 'primereact/rating';

const GridItem = ({ data, handleItemClick }) => {

    return (
        <div className ="p-col-12 p-md-4" >

            <div 
                style={{
                    position: 'absolute', color: '#eee',
                    borderRadius: '50%',  fontSize: '1.9rem', fontFamily: 'monospace',
                    width: '55px', height: '55px', transform: 'rotate(-25deg)',
                    display: 'flex', alignContent: 'center', verticalAlign: 'center', alignItems: 'center' 
                }} 
                className='p-shadow-10 center p-text-bold bg-dark' >
                $10
            </div>

            <div className="cardif product-grid-item card" style={{ height: '100%' }} >

                <div className="product-grid-item-top">
                    <span></span>
                    <div>
                        <i className="pi pi-tag product-category-icon"></i>
                        <span className="product-category">{data?.category?.name}</span>
                    </div>
                </div>

                <div className="product-grid-item-content ">

                    <div className="p-text-center" style={{ overflow: 'auto' }} >
                        <img src={ data?.image} alt={ data?.name } style={{ height: '100%', width: 'auto', maxHeight: '300px' }}/>
                    </div>

                    <div className="product-name p-mt-">
                        { data?.name }
                    </div>

                    <div className="product-description">
                        { data?.description }
                    </div>

                    <Rating value={ 3 } readonly cancel={false}></Rating>
                </div>

                <div className='p-text-center'>
                    <Button
                        icon='pi pi-eye'
                        label='Detalles'
                        style={{ width: '90%' }}
                        className='p-button-text p-button-info p-shadow-5 p-mt-3'
                        onClick={ () => handleItemClick(data) } />
                </div>
            </div>
        </div>
    );
}

GridItem.propTypes = {
    data: PropTypes.object.isRequired,
}

export default GridItem