export const buildDataStructure = (lists) => {
  const o = _group_by_parent_id(lists.pop());
  return _buildDataStructure(lists, o);
};

const _group_by_parent_id = (l) => {
  let newStructure = {};
  for (let i = 0; i < l.length; i++) {
    if (newStructure[l[i]?.parent_id] === undefined) {
      newStructure[l[i]?.parent_id] = [];
    }
    newStructure[l[i]?.parent_id]?.push(l[i]);
  }
  return newStructure;
};

const _buildDataStructure = (l, o) => {
  let currentList = l?.pop();
  for (let i = 0; i < currentList?.length; i++) {
    currentList[i].components =
      o[currentList[i]?.id] !== undefined ? o[currentList[i]?.id] : [];
  }
  if (l.length === 0) {
    return currentList;
  }
  const new_o = _group_by_parent_id(currentList);
  return _buildDataStructure(l, new_o);
};

const _buildGraphStructure = (neighbours, graph, level) => {
  if (neighbours === undefined) {
    return graph;
  }
  for (let i = 0; i < neighbours?.length; i++) {
    graph[neighbours[i]?.id] = {
      name: neighbours[i]?.name,
      groupId: neighbours[i]?.groupId,
      components: neighbours[i]?.components?.map((c) => c?.id) || [],
      definition: neighbours[i]?.definition,
    };
    graph = _buildGraphStructure(neighbours[i]?.components, graph, level);
  }
  return graph;
};

export const buildGraphStructure = (data, modelName, modelId, level) => {
  let graph = {
    [modelId.toString()]: {
      name: modelName,
      groupId: modelId.toString(),
      components: data?.map((c) => c?.id),
      definition: "Modelo",
    },
  };

  return _buildGraphStructure(data, graph, level);
};

