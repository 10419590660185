import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextEditor } from '../../../components/UI';
import { updateProcess } from "../../../../redux/actions/process.actions";

const RiskProcess = ({ showMessage, values, handleInputChange }) => {

    const process = useSelector((state) => state.ProcessState?.process);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ hasChanged, setHasChanged ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);
    
    useEffect(() => {
        if (values?.risk_process !== process?.risk_process) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [ values?.risk_process, process?.risk_process ]);

    const handleSave = async (attribute) => {
        setSubmitted(true);
        if (hasChanged) {

            const data = { risk_process: values?.risk_process }

            updateProcess(process?.id, data, user?.access_token, role).then(data => {
                setHasChanged(false);
                showMessage("success", "Riesgo actualizado", "");
            })
        }
        setSubmitted(false);
    };
     
    return (
        <div>
            <TextEditor 
                title="Riesgos" 
                subtitle="Ingrese los riesgos asociados al proceso"
                messageError="Error al almacenar riesgos"
                value={ values?.risk_process }
                submitted={ submitted }
                onSave={ handleSave }
                actionButtonDisabled={ !hasChanged }
                onInputChange={(e) => handleInputChange({ target: { name: 'risk_process', value: e?.htmlValue } })}
                helpInformation="Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocarn en los riesgos." />
        </div>
    ); 
}

export default RiskProcess;