export const gearthComponetsTypes = {
    identity: 'identity',
    complexSystem: 'complex-system',
    indicatorsSystem: 'indicators-system',
    processes: 'processes',
    knowledgeBase: 'knowledge-base',
    keyTopicsTrends: 'key-topics-trends',
    principlesLawsTheory: 'principles-laws-theory',
    tools: 'tools',
    // Apps
    appRed: 'app-red',
    appInfo: 'app-info',
}