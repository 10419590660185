import { useDispatch,useSelector } from 'react-redux';
import { authLogout, authLogin } from '../actions/user.action';

export const useLogin = () =>{

    const state = useSelector((state) => state.LoginState);
    const dispatch = useDispatch();

    const iniciaSesion = (datosLogin) =>{
        dispatch(authLogin(datosLogin));
    }
    
    const finalizaSesion = () =>{
        dispatch(authLogout());
    }

    return {
        ...state,
        iniciaSesion,
        finalizaSesion,
        idUsuario: state?.data?.id || 0,
        login: state?.login,
        error: state?.error,
        loading: state?.loading,
    }
}