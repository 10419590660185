import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const GenericDialog = (props) => {

  const dialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={props.onCancelAction}
      />
      <Button
        label="Sí"
        icon="pi pi-check"
        className="p-button-text"
        onClick={props.onConfirmationAction}
      />
    </>
  );

  return (
    <>
      <Dialog
        visible={props.visible}
        style={{ width: "350px" }}
        header={props.header}
        modal
        footer={dialogFooter}
        onHide={props.onHide}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          {props.condition && (
            <span>
              {props.message}
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default GenericDialog;
