import { useState } from "react";
import { TabMenu } from "primereact/tabmenu";
import { TextEditor } from "../../../components/UI";
import { SystemicHistoryComponentsDto } from "../types/dtos";
import { WizardItem } from "../types/UI";
import {
  GUIDE_NAME,
  CAUSES_AND_EFFECTS_NAME,
  TEMPORAL_AND_GEOGRAPHICAL_LOCATION_NAME,
  AGENTS_NAME,
  ACTUAL_OR_POTENTIAL_NAME,
  PROCESSES_NAME,
  MANIFESTATIONS_NAME,
  NAME_NAME,
} from "../constants/SystemicHistoryComponentsNames";

const wizardItems: WizardItem[] = [
  { id: 0, label: "Guía", icon: "pi pi-table" },
  { id: 1, label: "Causas y efectos", icon: "pi pi-cloud" },
  { id: 2, label: "Ubicación geográfica y temporal", icon: "pi pi-spinner" },
  { id: 3, label: "Agentes", icon: "pi pi-circle-on" },
  { id: 4, label: "Actual o potencial", icon: "pi pi-circle-on" },
  { id: 5, label: "Procesos", icon: "pi pi-circle-on" },
  { id: 6, label: "Manifestaciones", icon: "pi pi-circle-on" },
  { id: 7, label: "Nombre", icon: "pi pi-circle-on" },
];

type SystemicHistoryProps = {
  data: SystemicHistoryComponentsDto;
  onInputChange: (e: any, attribute: string) => void;
};

const SystemicHistory = ({
  data: systemicHistoryComponents,
  onInputChange,
}: SystemicHistoryProps) => {
  const [tabSelected, setTabSelected] = useState<number>(0);

  const [submitted, setSubmitted] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);

  const tabChangeHandler = (tab: any) => {
    setTabSelected(tab);
  };

  const onSaveAttribute = async (attribute: any) => {
    // setSubmitted(true);
    // setLoading(true);
    // try {
    //   if (hasChanged) {
    //     await dispatch(
    //       csActions.updateModelizationComponents(
    //         component.id,
    //         attribute,
    //         component,
    //         MECHANISM_STEP
    //       )
    //     );
    //     setHasChanged(false);
    //     _setPreviousVersion(component);
    //     showMessage("success", "Éxito", "Guardado.");
    //   }
    // } catch (error) {
    //   setError(error);
    // }
    // setLoading(false);
    // setSubmitted(false);
  };

  return (
    <>
      <div className="card">
        <TabMenu
          model={wizardItems}
          onTabChange={(e) => {
            tabChangeHandler(e.value);
          }}
          activeIndex={tabSelected}
        />

        {tabSelected === 0 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={GUIDE_NAME}
            value={systemicHistoryComponents[GUIDE_NAME]}
            title={"Guía"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar guía"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected === 1 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={CAUSES_AND_EFFECTS_NAME}
            value={systemicHistoryComponents[CAUSES_AND_EFFECTS_NAME]}
            title={"Causas y efectos"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar causas y efectos"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected === 2 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={TEMPORAL_AND_GEOGRAPHICAL_LOCATION_NAME}
            value={
              systemicHistoryComponents[TEMPORAL_AND_GEOGRAPHICAL_LOCATION_NAME]
            }
            title={"Ubicación geográfica y temporal"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar Ubicación geográfica y temporal"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected === 3 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={AGENTS_NAME}
            value={systemicHistoryComponents[AGENTS_NAME]}
            title={"Agentes"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar Agentes"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected === 4 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={ACTUAL_OR_POTENTIAL_NAME}
            value={systemicHistoryComponents[ACTUAL_OR_POTENTIAL_NAME]}
            title={"Actual o potencial"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar Actual o potencial"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected === 5 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={PROCESSES_NAME}
            value={systemicHistoryComponents[PROCESSES_NAME]}
            title={"Procesos"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar Procesos"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected === 6 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={MANIFESTATIONS_NAME}
            value={systemicHistoryComponents[MANIFESTATIONS_NAME]}
            title={"Manifestaciones"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar Manifestaciones"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : tabSelected === 7 ? (
          <TextEditor
            onInputChange={onInputChange}
            name={NAME_NAME}
            value={systemicHistoryComponents[NAME_NAME]}
            title={"Nombre"}
            subtitle={"Describa..."}
            submitted={submitted}
            messageError={"Se requiere agregar Nombre"}
            onSave={onSaveAttribute}
            actionButtonDisabled={!hasChanged}
            helpInformation={"Información de ayuda de este componente"}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SystemicHistory;
