import React, { useState,useRef,useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import {Messages} from "primereact/messages";
import { FileUpload } from 'primereact/fileupload';
import { URL_BASE } from '../../../../../../api/urls';
import { useRepository } from '../../../../../../redux/hooks/useRepository';
import { Editor } from 'primereact/editor';
import { useKnowledge } from '../../../../../../redux/hooks/useKnowledge';


export const Application = ({setSaved,action}) => {



    const {insertTool,dataCurrent,removeItem}= useKnowledge();
    useEffect(() => {
        if(action === "new"){
              removeItem(); 
        }
        return () => {

        }
    }, [])


    const message = useRef();


    const[dataItem,setDataItem] = useState({
        image:action==="edit"?dataCurrent?.image:"",
        name:action==="edit"?dataCurrent?.name:"",
        keywords: action==="edit"?dataCurrent?.keywords:"",
        source_meaning: action==="edit"?dataCurrent?.source_meaning:"",
        description: action==="edit"?dataCurrent?.description:"",
        local_meaning: action==="edit"?dataCurrent?.local_meaning:"",
    })

    const handleInputChange = ({target}) =>{

       
        const {name, value} = target;
        console.log(value);
        setDataItem({
            ...dataItem,
            [ name ]: value
        
        });
    };

    const addInfoMessageSaved = (status, content) => {
        message.current.show({severity: status, content: content});
    };

    const [dropdownItem, setDropdownItem] = useState(null);
    const [dropdownItem2, setDropdownItem2] = useState(null);
    const dropdownItems = [
        { name: 'Socio Cultural', code: 1 },
        { name: 'Medio Construido', code: 2 },
        { name: 'Político Institucional', code: 3 },
        { name: 'Político Institucional', code: 3 },
    ];
    const dropdownItems2 = [
        { name: 'Demografía', code: 1 },
        { name: 'Medio Construido', code: 2 },
        { name: 'Político Institucional', code: 3 },
        { name: 'Político Institucional', code: 3 },
    ];
    const dropdownItems3 = [
        { name: 'Etnia', code: 1 },
        { name: 'Medio Construido', code: 2 },
        { name: 'Político Institucional', code: 3 },
        { name: 'Político Institucional', code: 3 },
    ];

    const fileUploadHandler = ({files}) => {
        const [file] = files;
        console.log(file);
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            uploadFile(file);
        };
        fileReader.readAsDataURL(file);
    };

    const save = (e) =>{
e.preventDefault()
if (action==="new"){
        if(name && description  && keywords && image && local_meaning && source_meaning){
            console.log(image);
            insertTool({
                name: name,
                description: description,
                source_meaning:source_meaning,
                local_meaning:local_meaning,
                keywords:keywords,
                folder:"knowledge",
                team_id:0,
                type:2,
                dataImage:image
            })
        }

    }
}
       /* if(values?.name && values?.description && values?.long_description && dropdownItem){
            insertRepository({
                name:name,
                description:description,
                long_description:long_description,
                category_id:dropdownItems.code
            });
        }*/

    
    const uploadFile = async (invoiceFile) => {
        let formData = new FormData();
        formData.append('file_obj', invoiceFile);
        console.log(dropdownItem);
        /*
        if(values?.name && values?.description && values?.long_description && dropdownItem){
       
            //errors.name = "Name required";
            axios({
                url: `${URL_BASE}uploads?folder=repository&team_id=1`,
                method: 'POST',
                data: formData,
                headers: {
                'Content-Type': "multipart/form-data"
                }
                }).then((response) => {
                console.log(response.data);
                addInfoMessageSaved('success', response.data);
                
                //toast.current.show({ severity: 'info', summary: 'Success', detail: response.data, life: 3000 });
        
                }).catch((error) => {
                    addInfoMessageSaved('error', error);
                });
        }
        else{
            addInfoMessageSaved('error', "Ingrese toda la información solicitada antes de subir un archivo");
        }*/

    
    
        /*
        const response = await fetch(`orders/${orderId}/uploadInvoiceFile`,
            {
                method: 'POST',
                body: formData
            },
        );
        */
    };
    
    //const {name,description,long_description} = values;
    const {name,keywords,source_meaning,description,image,local_meaning} = dataItem;
    return (
        <div className="p-grid">
            

            

            <div className="p-col-12">
                <div className="card">
                    <h3>Aplicación del Indicador</h3>
                    <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                    </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="name">Instrumento de verificación</label>
                            <InputText id="name" name={"name"} type="text" value={name} onChange={handleInputChange} placeholder="Ingrese el nombre del indicador"/>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="description">Frecuencia</label>
                            <InputText id="keywords" name={"keywords"} type="text" placeholder="Ingrese separadas por coma" value={keywords} onChange={handleInputChange}/>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="name">Representación</label>
                            <InputText id="name" name={"name"} type="text" value={name} onChange={handleInputChange} placeholder="Ingrese la unidad de medida"/>
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="description">Unidad Territorial</label>
                            <InputText id="keywords" name={"keywords"} type="text" placeholder="Ingrese el responsable" value={keywords} onChange={handleInputChange}/>
                        </div>   
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="description">Grupo social</label>
                            <InputText id="keywords" name={"keywords"} type="text" placeholder="Ingrese el responsable" value={keywords} onChange={handleInputChange}/>
                        </div>  

                <Button label= "Guardar" className="p-button-rounded p-mr-2 p-mb-2" onClick={save}/>
                </div> 
                <div style={{ display: "inline-block", width: 250, position: 'fixed', bottom: '0%', left: '140px'}}>
                        <Messages  ref={message}/>
                </div>
            </div>
            </div>
        </div>
    )
}