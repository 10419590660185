import React, { useState } from "react";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ListBox } from "primereact/listbox";
import { ImageUpload } from "..";
import { useSelector } from "react-redux";
import { RolesEnum } from "../../../enums/roles";
import { SelectButton } from "primereact/selectbutton";
import ExecuteForm from "./ExecuteForm";

const itemsButtonMenu = [
    { id: 1, name: 'Información Básica' },
    { id: 2, name: 'Generalidades' },
];

const CreateModelFormDialog = (props) => {

    const [ selectButtonMenu, setSelectButtonMenu ] = useState(itemsButtonMenu[0])
    const role = useSelector((state) => state.RolNavigationState?.state);

    const getCategoryIds = () => {

        if (!props.model?.categories_ids) {

            const ids = [];
            
            if (props.model?.categories) {
                props.model?.categories.map(category => {
                    if (category?.category_id) {
                        ids.push(category?.category_id);
                    }
                })
            }
            
            return ids;
        } else {
            return props.model?.categories_ids;
        }
    }

    const dialogFooter = (
        <>
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={props.hideCreateModelDialog} />
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={props.saveModel} />
        </>
    );

    const dialogHeader = () => (
        <div>
            <h5>
                {(props.model?.id) ? 'Editar' : 'Nuevo'} MIT
            </h5>
        </div>
    );

    return (
        <>
            <Dialog
                modal
                className="p-fluid"
                style={{ width: "650px" }}
                contentStyle={{ minHeight: '600px' }}
                visible={props.openCreateModelDialog}
                footer={dialogFooter} header={ dialogHeader }
                onHide={props.hideCreateModelDialog} >

                <div>
                    {(role === RolesEnum.Executor) &&
                        <SelectButton 
                            className="p-mt-1 p-mb-3"
                            value={ selectButtonMenu } 
                            onChange={(e) => setSelectButtonMenu(e.value)} 
                            options={ itemsButtonMenu } 
                            optionLabel="name" />
                    }

                    {(selectButtonMenu.id === 1) ?
                        <div>

                            <div className="p-field" style={{ textAlign: "left" }}>
                                <label htmlFor="logo">Logo</label>
                                <ImageUpload
                                    maxFileSize={1000000}
                                    image={props.image}
                                    setImage={props.setImage}
                                    onInputChange={props.onInputChange} />
                                {props.submitted && !props.image && !props.model?.image && (
                                    <small className="p-invalid">Se require una imagen.</small>
                                )}
                            </div>

                            <div className="p-field">
                                <label className="required" htmlFor="name">Nombre</label>
                                <InputText
                                    id="name"
                                    value={props.model?.name}
                                    onChange={(e) => props.onInputChange(e, "name")}
                                    required autoFocus
                                    className={classNames({
                                        "p-invalid": props.submitted && !props.model?.name,
                                    })} />

                                {props.submitted && !props.model?.name && (
                                    <small className="p-invalid">Se requiere un nombre.</small>
                                )}
                            </div>

                            <div className="p-field">
                                <label htmlFor="description">Descripción</label>
                                <InputTextarea
                                    id="description"
                                    value={props.model?.description}
                                    onChange={(e) => props.onInputChange(e, "description")}
                                    required rows={6}
                                    className={classNames({
                                        "p-invalid": props.submitted && !props.model?.description,
                                    })} />
                                {props.submitted && !props.model?.description && (
                                    <small className="p-invalid">Se requiere una descripción.</small>
                                )}
                            </div>

                            <div className="p-field">
                                <label htmlFor="description">Seleccione las categorías relacionadas al modelo</label>
                                <ListBox
                                    value={ getCategoryIds() }
                                    multiple={ true }
                                    onChange={(c) => {props.onInputChange(c.value.sort(), "categories_ids");}}
                                    options={ props.categories }
                                    optionLabel="name" />
                                {props.submitted && props.model?.categories_ids?.length === 0 && (
                                    <small className="p-invalid">Se requiere al menos una categoría.</small>
                                )}
                            </div>
                        </div>
                        :
                        <ExecuteForm model={ props.model } showMessage={ props.showMessage } finish={ false } />
                    }
                </div>
            </Dialog>
        </>
    );
};

export default CreateModelFormDialog;
