
export const nameBodyTemplate = (rowData) =>{

        return(
            <>
                {/*<span className="p-column-title">Names</span>*/}
                {rowData?.person?.names || rowData.name}
            </>
        )
}

export const statusBodyTemplate = (rowData) =>{
    const valueStatus = (status) => (
        (status) ? 'Active':'Inactive'
    );
  
    return (
        <>
            {/*<span className="p-column-title">Status</span>*/}
            <span className={`product-badge status-${(rowData?.status) ? 'instock' : 'outofstock'}`}>{ valueStatus(rowData?.status) }</span>
        </>
    );
  }

  export const lnameBodyTemplate = (rowData) => {
    return (
        <>
            {/*<span className="p-column-title">Last Name</span>*/}
            {rowData?.person?.last_names}
        </>
    )
}

export const identBodyTemplate = (rowData) => {
    return (
        <>
            {/*<span className="p-column-title">Identification</span>*/}
            {rowData?.person?.identification}
        </>
    )
}

export const emailBodyTemplate = (rowData) => {
    return ( 
        <>
            {/*<span className="p-column-title">Email</span>*/}
            {rowData.username}
        </>
    )
};

//Templates para la tabla de las encuestas asignadas


export const idBodyTemplate = (rowData) =>{
    return (
        <>
            {rowData.id}
        </>
    )
};

export const userBodyTemplate = (rowData) =>{
    return (
        <>
            {rowData?.user?.username}
        </>
    )
};

export const surveyBodyTemplate = (rowData) =>{
    return (
        <>
            {rowData?.survey?.survey_template?.name}
        </>
    )
};

export const assigmentBodyTemplate = (rowData) =>{
    return (
        <div className="p-ml-6">
            {rowData.assignments}
        </div>
    )
};