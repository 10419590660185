import { BreadCrumb } from "primereact/breadcrumb";
import {TabMenu} from "primereact/tabmenu";
import React, {useState,useEffect,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from "../../../../../hooks/useQueryParams";
import { useKnowledge } from "../../../../../redux/hooks/useKnowledge";
import { ItemDuality } from "./ItemDuality";
import { ItemInteractions } from "./ItemIteractions";
import { ItemMethod } from "./ItemMethod";
import { ItemOrigins } from "./ItemOrigins";
import { ItemPurpose } from "./ItemPurpose";
import { ItemTheory } from "./ItemTheory";
import { ItemTool } from "./ItemTool";




const NewItem = () =>{
    const wizardItems = [
        {id: 0, label: 'Significado de origen/ local', icon: 'pi pi-th-large'},
        {id: 1, label: 'Propósito', icon: 'pi pi-users'},
        {id: 2, label: 'Teoría', icon: 'pi pi-eye'},
        {id: 3, label: 'Método', icon: 'pi pi-eye'},
        {id: 4, label: 'Herramienta', icon: 'pi pi-eye'},
        {id: 5, label: 'La Dualidad', icon: 'pi pi-eye'},
        {id: 6, label: 'Interacciones clave', icon: 'pi pi-eye'},
    ];

    const [tabSelected, setTabSelected] = useState(wizardItems[0]);
    const [saved,setSaved] = useState(false)
    const{getCurrentItem,dataCurrent}=useKnowledge()
    const { action,item_id } = useQueryParams();
    const addInfoMessageSaved = (status, content) => {
        message.current.show({severity: status, content: content});
    };
    const user = useSelector((state) => state.LoginState.data);
    const dispatch = useDispatch();
    useEffect(() => {
       
        if(action==="edit"){
            getCurrentItem(item_id);
        }
        
    }, [action])
    const tabChangeHandler = (tab) => {
        if((saved || tab.id === 0) || action === "edit"){
            console.log(tab);
            setTabSelected(tab);
        }
        else{
            setTabSelected(wizardItems[0]);
            //TODO reemplazar por TOAST
            addInfoMessageSaved('error', "Por favor complete los datos en la primera pestaña");
          
        }
    };
    const breadcrumbHome = { icon: 'pi pi-home', to: '/' };
    const breadcrumbItems = [
        { label: 'MIT' },
        { label: 'Diseñador de MIT' },

    ];
    const message = useRef();

    return (
        <>

            <div className="p-col-12 p-md-12">
                <div className="card card-w-title">
                    <h3>Base de conocimiento</h3>
                    <TabMenu model={wizardItems} onTabChange={(e) => {
                        tabChangeHandler(e.value)
                    }} activeItem={tabSelected}/>

                    {
                        tabSelected.id === 0 ?
                            <ItemOrigins setSaved={setSaved} dataCurrent={dataCurrent} action={action} dispatch={dispatch} user ={user}/>
                            : tabSelected.id === 1 ?
                            <ItemPurpose action={action} dispatch={dispatch} user ={user}/>
                            :tabSelected.id === 2 ?
                            <ItemTheory action={action} dispatch={dispatch} user ={user}/>
                            :tabSelected.id === 3 ?
                            <ItemMethod action={action} dispatch={dispatch} user ={user}/>
                            :tabSelected.id === 4 ?
                            <ItemTool action={action} dispatch={dispatch} user ={user}/>
                            :tabSelected.id === 5 ?
                            <ItemDuality action={action} dispatch={dispatch} user ={user}/>
                            :tabSelected.id === 6 ?
                            <ItemInteractions action={action} dispatch={dispatch} user ={user}/>
                            : ""}
                </div>
            </div>


        </>
    );

    
};

export default NewItem;