import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";

import { TabMenu } from "primereact/tabmenu";
import ChannelsList from "./ChannelsList";
import AppUsers from "./AppUsers";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";

import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { getTeamMembers } from "../../../../redux/actions/team.actions";

import Code from "../../../../app/assets/img/qr-code.png";
import { RolesEnum } from "../../../enums/roles";

const dataAdapterForModerator = (teamMembers, moderatorId) => {
  for (let i = 0; i < teamMembers.length; i++) {
    teamMembers[i].moderator = teamMembers[i].id === moderatorId ? true : false;
  }
  return teamMembers;
};

const wizardItemsCre = [{ id: 0, label: "Canales", icon: "pi pi-th-large" }];

const wizardItemsEx = [
  { id: 0, label: "Canales", icon: "pi pi-th-large" },
  { id: 1, label: "Usuarios de la App", icon: "pi pi-users" },
  { id: 2, label: "Visión General", icon: "pi pi-eye" },
];

const SocialNetwork = () => {
  // TODO Remove this
  const roleNav = useSelector((state) => state.RolNavigationState.state);

  const userState = useSelector((state) => state.LoginState);
  const modelState = useSelector((state) => state.ModelState.model);

  const [wizardItems, setWizardItems] = useState(
    roleNav === RolesEnum.Designer ? wizardItemsCre : wizardItemsEx
  );
  const [tabSelected, setTabSelected] = useState(wizardItems[0]);

  const op = useRef(null);
  const op2 = useRef(null);
  const toast = useRef(null);

  const [members, setMembers] = useState(null);

  const [selectedMember, setSelectedMember] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const loadTeamMembers = useCallback(async () => {
    const teamMembers = await dispatch(
      getTeamMembers(userState?.data?.team?.id, userState?.data?.access_token)
    );
    const moderatorId = getModerator();
    const newTeamMembersList = dataAdapterForModerator(
      teamMembers,
      moderatorId
    );
    setMembers(newTeamMembersList);
  }, []);

  // TODO: check for this route when ready
  const getModerator = () => {
    return 10;
  };

  useEffect(() => {
    setIsLoading(true);
    loadTeamMembers();
    setIsLoading(false);
  }, [loadTeamMembers]);

  const tabChangeHandler = (tab) => {
    setTabSelected(tab);
  };

  const onMemberSelect = (event) => {
    console.log(event.data);

    op2?.current?.hide();
    toast?.current?.show({
      severity: "info",
      summary: "Moderador cambiado",
      detail: event.data.name,
      life: 3000,
    });
    loadTeamMembers();
  };

  const toggle = (event) => {
    op.current.toggle(event);
  };

  const toggleDataTable = (event) => {
    op2.current.toggle(event);
  };

  const moderatorBodyTemplate = (data) =>
    data.moderator ? <i class="pi pi-check"></i> : <i class="pi pi-times"></i>;

  const formatNames = (value) =>
    value[0].toUpperCase() + value.slice(1, value.length).toLowerCase();

  const nameBodyTemplate = (data) =>
    formatNames(data?.person?.names) +
    " " +
    formatNames(data?.person?.last_names);

  const manageChannelsTemplate = () => {
    if (roleNav === RolesEnum.Executor) {
      return (
        <div className="card p-fluid">
          <h5>Administrar Canales</h5>
          <div className="p-grid p-formgrid">
            <div className="p-col-6">
              <Button
                type="button"
                label="Código QR"
                onClick={toggle}
                className="p-button-success"
              />
              <OverlayPanel ref={op} appendTo={document.body} showCloseIcon>
                <div
                  style={{
                    marginLeft: "auto",
                    textAlign: "center",
                    width: 250,
                  }}
                >
                  <img
                    src={modelState.qr_code}
                    alt="qr code"
                    style={{
                      marginLeft: "auto",
                      textAlign: "center",
                      width: 250,
                    }}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "auto",
                    textAlign: "center",
                    width: 250,
                    marginTop: 10,
                  }}
                >
                  <Button
                    label="Share"
                    icon="pi pi-share-alt"
                    className="p-button-success p-mr-2 p-mb-2"
                    onClick={() => {}}
                  />
                </div>
              </OverlayPanel>
            </div>

            <div className="p-col-6">
              <Button
                type="button"
                label="Moderador"
                onClick={toggleDataTable}
                className="p-button-success"
              />
              <OverlayPanel
                ref={op2}
                appendTo={document.body}
                showCloseIcon
                id="overlay_panel"
                style={{ width: "450px" }}
              >
                <DataTable
                  value={members}
                  selection={selectedMember}
                  onSelectionChange={(e) => setSelectedMember(e.value)}
                  selectionMode="single"
                  // paginator
                  rows={10}
                  onRowSelect={onMemberSelect}
                >
                  <Column
                    field="name"
                    header="Nombre"
                    body={nameBodyTemplate}
                  ></Column>
                  <Column
                    field="price"
                    header="Moderator"
                    body={moderatorBodyTemplate}
                  ></Column>
                </DataTable>
              </OverlayPanel>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className="p-col-12 p-md-12">
        <div className="card card-w-title">
          <Toast ref={toast} />
          <h3>Consola de Administración</h3>

          {manageChannelsTemplate()}

          <TabMenu
            model={wizardItems}
            onTabChange={(e) => {
              tabChangeHandler(e.value);
            }}
            activeItem={tabSelected}
          />

          {tabSelected.id === 0 ? (
            <ChannelsList />
          ) : tabSelected.id === 1 ? (
            <AppUsers />
          ) : (
            "tab 3"
          )}
        </div>
      </div>
    </>
  );
};

export default SocialNetwork;
