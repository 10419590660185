// Table status option list, default
export const tableStatusDefault = [
    {
        id: 0,
        label: 'Inactivo',
        class: 'status-danger',
    },
    {
        id: 1,
        label: 'Activo',
        class: 'status-success',
    },
];

/**
 * 
 * @param {int*} id -> required int
 * @param {object*} statusOptions -> list of status option object
 * @returns 
 */
export const getTableStatusById = (id, statusOptions) => {
    return statusOptions.find(status => status.id === id);
}

// Custom model status 
export const tableModelStatus = [
    {
        id: 0,
        label: 'Inactivo',
        class: 'status-danger',
    },
    {
        id: 1,
        label: 'En diseño',
        class: 'status-success',
    },
    {
        id: 2,
        label: 'Publicando',
        class: 'status-warning',
    },
    {
        id: 3,
        label: 'Publicado',
        class: 'status-info',
    },
];

// Custom acquired model status 
export const tableAcquiredModelStatus = [
    {
        id: 0,
        label: 'Inactivo',
        class: 'status-danger',
    },
    {
        id: 1,
        label: 'Adquirido',
        class: 'status-primary',
    },
    {
        id: 2,
        label: 'En ejecución',
        class: 'status-violet',
    },
];