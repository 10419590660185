import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';

import { InputNumber } from 'primereact/inputnumber';
import * as templatesTable from '../utils/templates/tableTemplates';
import { usePlan } from '../../../redux/hooks/usePlan';
import { createPlan, updatePlan } from '../../../redux/actions/plan.action';
import { useSelector } from 'react-redux';

export const Plan = () =>{

    const emptyPlan = {
      name: '',
      description: '',
      team_members_nos: 0,
      model_nos: 0,
      team_app_nos: 0,
      web_storage: 0,
      social_app_storage: 0,
      neo4j_nodes_no: 0,
      neo4_relationships_no: 0,
      firebase_storage_time: 0,
      conversation_nos: 0,
      price: 0,
      featured: true,
      discount: 0
    };

    const [plan, setPlan] = useState(emptyPlan);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedPlan, setSelectedPlan] = useState(null);

    const [isNew, setIsNew] = useState(false);
    const [planDialog, setPlanDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const {listPlans,plansData} = usePlan();
    const user = useSelector ((state)=> state?.LoginState?.data);


    useEffect(()=>{
        listPlans();
    },[user]);


    const openNew = () => {
        setPlan(emptyPlan);
        setSubmitted(false);
        setPlanDialog(true);
        setIsNew(true);
    };

    const savePlan = async() => {

        setSubmitted(true);

        if (plan?.name.trim() && plan?.description.trim()) {

            createPlan(plan, user?.access_token).then((response)=>{

                if (response === 200 || response === 201) {
    
                    listPlans();
                    toast.current.show({ severity: 'success', summary:'Successful', detail:'Plan creado con éxito', life:3000});
                    setPlanDialog(false);
                
                }else{
    
                    toast.current.show({ severity: 'error', summary:'Error', detail:'Error al crear el nuevo plan', life:3000});
                
                }
    
            });   
        }
    };

    const editPlan =() =>{
      setSubmitted(true);

      updatePlan(plan, user?.access_token).then(()=>{
          toast.current.show({ severity: 'success', summary:'Successful', detail:'Plan editado con éxito', life:3000});
          listPlans();
          setPlanDialog(false);
      })
    }

    const editPlanDialog = (p) =>{
        setIsNew(false);
        setPlan({...p});
        setPlanDialog(true);
    };

    const exportCSV = () =>{
        dt.current.exportCSV();
    };

    const onInputChange = (e, name) =>{
        const val = (e.target && e.target.value) || false;
        let _plan = {...plan};
        _plan[`${name}`] = val;
        setPlan(_plan)
        
    };

    const leftToolbarTemplate = () =>{
      return (
          <div style={{display:'flex', verticalAlign:'middle',justifyContent: 'center'}}>
              <h5 style={{marginRight:'50px'}} >Planes de Gearth </h5>
              <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={ openNew } />
          </div>
      )
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={ exportCSV } />
            </>
        )
    };

    const actionBodyTemplate = (rowData) =>{
      return (
        <div className="actions">
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-1" onClick={() => editPlanDialog(rowData)} />
        </div>
      )
    };

    const header = (
      <div className="table-header">
        <h5 className = "p-m-0">Administrador de Planes</h5>

        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar ..." />
        </span>
      </div>
    );

    const dialogFooterN = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={ () => {setSubmitted(false); setPlanDialog(false)} } />
            <Button label="Crear" icon="pi pi-check" className="p-button-text" onClick={ savePlan } />
        </>
    );

    const dialogFooterE = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={ () => {setSubmitted(false); setPlanDialog(false)} }/>
            <Button label="Editar" icon="pi pi-check" className="p-button-text"  onClick={()=>{editPlan()}}/>
        </>
    );

    return (

        <>
            <div className="crud-demo card">
                <div className = "p-col-12">

                    <Toast ref = {toast}/>
                    <Toolbar className="p-mb-4 p-toolbar" left={ leftToolbarTemplate } right={ rightToolbarTemplate }></Toolbar>
                    <DataTable ref={ dt } value={ plansData } selection={ selectedPlan } onSelectionChange={(e) => setSelectedPlan(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        globalFilter={ globalFilter } selectionMode="single" emptyMessage="No plan found." header={ header }>

                        <Column field="id" header="ID" sortable body= { templatesTable.idBodyTemplate }/>

                        <Column field="name" header="Name" sortable body= { templatesTable.nameBodyTemplate }/>

                        <Column field="description" header="Description" sortable body= { templatesTable.descriptionBodyTemplate }/>

                        <Column field="team_members_nos" header="N° Members" sortable body= { templatesTable.bodyTemplate }/>

                        <Column field="discount" header="Discount" sortable body= { templatesTable.bodyTemplate }/>

                        <Column field="featured" header="Featured" sortable body= { templatesTable.bodyTemplate}/>

                        <Column field="price" header="Price" sortable body= { templatesTable.bodyTemplate}/>

                        <Column field="status" header="Status" sortable body= { templatesTable.statusBodyTemplate }/>

                        <Column body={ actionBodyTemplate }></Column>

                    </DataTable>

                    {/*Dialogo para la creacion de un nuevo plan, ademas de ser reutilizado para la actualizacion de el mismo */}

                    <Dialog visible={planDialog} style ={{width: 'auto' }} header={ isNew ? ('Create Plan'):('Edit Plan')} 
                        modal className="p-fluid" footer={ isNew ? (dialogFooterN):(dialogFooterE) } 
                        onHide={ () => {setSubmitted(false); setPlanDialog(false)}} >

                        <div className='p-mt-4 p-shadow-1 p-grid'>

                            <div className='p-col' style={{textAlign:'left'}}>

                                <div className="p-field">
                                    <label htmlFor="name">Name</label>
                                    <InputText 
                                        id="name" value={ plan?.name } onChange={(e) => onInputChange(e, 'name')} 
                                        required autoFocus className={classNames({ 'p-invalid': submitted && !plan?.name })} />
                                          
                                        { submitted && !plan?.name && <small className="p-invalid">Name is required.</small> }
                                </div>

                                <div className="p-field">
                                    <label htmlFor="description">Description</label>
                                    <InputTextarea maxLength={300000} 
                                        id="description" value={ plan?.description } onChange={(e) => onInputChange(e, 'description')} 
                                        required className={classNames({ 'p-invalid': submitted && !plan?.description })} />
                                  
                                        { submitted && !plan?.description && <small className="p-invalid">Description is required.</small> }
                                </div>

                                <div className="p-field">
                                    <label htmlFor="team_members_nos">N° Members</label>            
                                    <InputNumber 
                                        id="team_members_nos" value={plan?.team_members_nos} min={0} onValueChange={(e) => onInputChange(e, 'team_members_nos')} 
                                        required showButtons mode="decimal"></InputNumber>
                                               
                                </div>

                                <div className="p-field">
                                    <label htmlFor="model_nos">N° Models</label>
                                    <InputNumber 
                                        id="model_nos" value={plan?.model_nos} min={0} onValueChange={(e) => onInputChange(e, 'model_nos')} 
                                        required showButtons mode="decimal" ></InputNumber>
                                        
                                </div>

                                <div className="p-field">
                                    <label htmlFor="team_app_nos">Team app</label>
                                    <InputNumber 
                                        id="team_app_nos" value={plan?.team_app_nos} min={0} onValueChange={(e) => onInputChange(e, 'team_app_nos')} 
                                        required showButtons mode="decimal" ></InputNumber>
                                            
                                </div>

                                <div className="p-field">
                                    <label htmlFor="web_storage">Web storage</label>
                                    <InputNumber 
                                        id="web_storage" value={plan?.web_storage} min={0} onValueChange={(e) => onInputChange(e, 'web_storage')} 
                                        required showButtons mode="decimal" ></InputNumber>
                                        
                                </div>

                                <div className="p-field">
                                  <label htmlFor="social_app_storage">Social app storage</label>
                                    <InputNumber 
                                                id="social_app_storage" value={plan?.social_app_storage} min={0} onValueChange={(e) => onInputChange(e, 'social_app_storage')} 
                                                required showButtons mode="decimal" ></InputNumber>
                                            
                                </div>
                            </div>

                            <div className='p-col'>

                                <div className="p-field">
                                    <label htmlFor="neo4j_nodes_no">N° Neo4j nodes</label>
                                    <InputNumber 
                                        id="neo4j_nodes_no" value={plan?.neo4j_nodes_no} min={0} onValueChange={(e) => onInputChange(e, 'neo4j_nodes_no')} 
                                        required showButtons mode="decimal" ></InputNumber>

                                </div>

                                <div className="p-field">
                                    <label htmlFor="neo4_relationships_no">N° Neo4j relationships</label>
                                    <InputNumber 
                                        id="neo4_relationships_no" value={plan?.neo4_relationships_no} min={0} onValueChange={(e) => onInputChange(e, 'neo4_relationships_no')}
                                        required showButtons mode="decimal" ></InputNumber>

                                </div>
                                
                                <div className="p-field">
                                    <label htmlFor="firebase_storage_time">Time of firebase storage</label>
                                    <InputNumber 
                                        id="firebase_storage_time" value={plan?.firebase_storage_time} min={0} onValueChange={(e) => onInputChange(e, 'firebase_storage_time')} 
                                        required showButtons mode="decimal" ></InputNumber>

                                </div>
                                
                                <div className="p-field">
                                    <label htmlFor="conversation_nos">N° Conversation</label>
                                    <InputNumber 
                                        id="conversation_nos" value={plan?.conversation_nos} min={0} onValueChange={(e) => onInputChange(e, 'conversation_nos')} 
                                        required showButtons mode="decimal" ></InputNumber>

                                </div>
                                
                                <div className="p-field">
                                    <label htmlFor="price">Price</label>
                                    <InputNumber 
                                        id="price" value={plan?.price} min={0} onValueChange={(e) => onInputChange(e, 'price')} 
                                        required showButtons mode="currency" currency="USD" locale="en-US"></InputNumber>
                                        
                                </div>
                                
                                <div className="p-field">
                                    <label htmlFor = "featured" className="p-mr-2" >Featured</label>

                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <InputSwitch id="featured" className="p-mr-2" checked={plan?.featured} onChange={(e) => onInputChange(e,'featured')} />
                                        <i>{(plan?.featured) ? 'Yes':'No'}</i>
                                    </div>

                                </div>
                                
                                <div className="p-field">
                                    <label htmlFor="discount">Discount</label>
                                    <InputNumber 
                                        id="discount" value={plan?.discount}  min={0} onValueChange={(e) => onInputChange(e, 'discount')} 
                                        required showButtons mode="decimal"></InputNumber>
                                        
                                </div>
                            
                            </div>

                        </div>
                    </Dialog>
                </div>
            </div>
        </>
    )
};

export default Plan;
