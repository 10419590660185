import React, { useState, useEffect, useRef } from 'react';
import PaymentMethodsFormDialog from './templates/PaymentMethodsFormDialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { deletePaymentMethod, getPaymentMethods } from '../../../redux/actions/payment-method.actions';
import { useSelector } from 'react-redux';
import * as tableTemplates from '../utils/templates/tableTemplates';
const defaultImage =
  "https://gardensonquail.com/wp-content/uploads/2020/12/Image-Coming-Soon-400x400-1.jpg";

const PaymentMethods = () => {

    const [ paymentMethods, setPaymentMethods ] = useState(null);
    const [ paymentMethod, setPaymentMethod ] = useState(emptyPaymentMethod);
    const [ selectedPaymentMethods, setSelectedPaymentMethods ] = useState(null);
    const [ paymentMethodDialog, setPaymentMethodDialog ] = useState(false);
    const [ deletePaymentMethodDialog, setDeletePaymentMethodDialog ] = useState(false);
    const [ globalFilter, setGlobalFilter ] = useState([]);
    const user = useSelector((state) => state.LoginState.data);
    const toast = useRef(null);
    const dt = useRef(null);

    const emptyPaymentMethod = { name: '', logo: defaultImage, description: ''}

    const loadData = async () => {
        console.log('Data loaded');
        setPaymentMethods(await getPaymentMethods(user?.access_token))
    }

    useEffect(() => {
        loadData();
    }, []);

    const openNew = () => {
        setPaymentMethod(emptyPaymentMethod);
        setPaymentMethodDialog(true);
    }

    const hideDeletePaymentMethodDialog = () => {
        setDeletePaymentMethodDialog(false);
    }

    const editPaymentMethod = ( r ) => {
        setPaymentMethod({ ...r });
        setPaymentMethodDialog(true);
    }

    const confirmDeletePaymentMethod = ( r ) => {
        setPaymentMethod(r);
        setDeletePaymentMethodDialog(true);
    }

    const deletePaymentmethod = () => {
            
        deletePaymentMethod(paymentMethod?.id, user?.access_token).then((response)=>{
            setDeletePaymentMethodDialog(false);
            setPaymentMethod(emptyPaymentMethod);
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PaymentMethod Deleted', life: 3000 });
            loadData();
        }).catch((e)=>{
            console.log(e);
        })
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const leftToolbarTemplate = () => (
            <>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={ openNew } />
            </>
    );

    const rightToolbarTemplate = () => (
            <>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={ exportCSV } />
            </>
    );

    const actionBodyTemplate = (rowData) => (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editPaymentMethod(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeletePaymentMethod(rowData)} />
            </div>
    );

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Manage PaymentMethods</h5>
            <i>{ (selectedPaymentMethods && selectedPaymentMethods.length > 0) && `Selected (${selectedPaymentMethods.length})` }</i>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const deletePaymentMethodDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={ hideDeletePaymentMethodDialog } />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={ deletePaymentmethod } />
        </>
    );

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={ toast } />
                        <Toolbar className="p-mb-4 p-toolbar" left={ leftToolbarTemplate } right={ rightToolbarTemplate }></Toolbar>

                        <DataTable 
                            ref={ dt } value={ paymentMethods } selection={ selectedPaymentMethods } onSelectionChange={(e) => setSelectedPaymentMethods(e.value)} 
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" 
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
                            globalFilter={ globalFilter } emptyMessage="No Payment Methods found." header={ header } selectionMode='single'>

                            <Column
                                style={{ width: '110px' }} field="id" header="ID" sortable
                                body={ tableTemplates.idBodyTemplate } />
                            <Column
                                style={{ width: '15rem' }} field="logo" header="Logo"
                                body={ tableTemplates.imageBodyTemplate } />
                            <Column
                                field="name" header="Name" sortable
                                body={ tableTemplates.h5bodyTemplate } />
                            <Column
                                field="description" header="Description" sortable
                                body={ tableTemplates.descriptionBodyTemplate } />
                            <Column
                                style={{ width: '10rem' }} field="status" header="Status" sortable
                                body={ tableTemplates.statusBodyTemplate } />
                            <Column
                                style={{ width: '10rem' }} header="Actions"
                                body={ actionBodyTemplate } />

                        </DataTable>

                        <PaymentMethodsFormDialog
                            open={ paymentMethodDialog }
                            setOpen={ setPaymentMethodDialog }
                            paymentMethod={ paymentMethod }
                            setPaymentMethod={ setPaymentMethod }
                            callback={ loadData } />

                        <Dialog visible={ deletePaymentMethodDialog } style={{ width: '350px' }} header="Confirm" modal footer={ deletePaymentMethodDialogFooter } onHide={ hideDeletePaymentMethodDialog }>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {paymentMethod && <span>Are you sure you want to delete <b>{paymentMethod?.name}</b>?</span>}
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentMethods
