import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import * as surveyActions from '../../../../redux/actions/survey.actions';
import { useSelector } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Button as ButtonF } from 'primereact/button';
import QuestionsTab from '../../../components/Surveys/QuestionsTab';
import { Toast } from 'primereact/toast';

const EditPoll = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const role = useSelector((state) => state.RolNavigationState?.state);

  const [value, setValue] = useState(0);

  const [formDeatils, setFormDetails] = useState({});

  const toast = useRef(null);
  const user = useSelector((state) => state.LoginState?.data);
  const roleNav = useSelector((state) => state.RolNavigationState?.state);

  if (!location.state?.poll)
    history.push({
      pathname: '/admin/surveys',
      state: {
        tipo: 'activity',
        process: process,
      },
    });

  const poll = location.state?.poll;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let dat = poll;
    var formId = dat?.id;
    if (formId !== undefined) {
      setFormDetails(poll);
    }
  }, [poll]);

  const publishSurvey = () => {
    surveyActions
      .publishSurvey(formDeatils.id, user?.access_token, role)
      .then(() => {
        const dta = {
          survey_template_id: formDeatils?.id,
          model_id: formDeatils?.model_id,
        };
        surveyActions.publishedSurvey(dta, user?.access_token).then((r) => {
          toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: 'Encuesta Publicada!',
            life: 3000,
          });
          history.goBack();
        });
      });
  };

  const optionsTemplate = () => {
    const leftTemplate = (
      <>
        <h2>Gearth Survey Editor</h2>
      </>
    );
    const rightTemplate = (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          {roleNav !== 'creator' ? (
            <ButtonF
              className="p-button-succes p-mb-3 p-mr-2"
              label="Publicar"
              icon="pi pi-plus"
              onClick={() => publishSurvey()}
            />
          ) : null}
          <ButtonF
            className="p-button-danger p-mb-3 p-ml-2"
            label="Regresar"
            icon="pi pi-times"
            onClick={() => history.goBack()}
          />
        </div>
      </>
    );
    const centerTemplate = (
      <>
        <Tabs
          className={classes.title}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Questions" />
        </Tabs>
      </>
    );
    return (
      <div
        className="card p-mb-3 p-pb-2 p-shadow-3"
        style={{ borderRadius: '17px' }}
      >
        <div className="p-grid">
          <div className="p-col" style={{ textAlign: 'left' }}>
            {leftTemplate}
          </div>
          <div className="p-col" style={{ textAlign: 'center' }}>
            {centerTemplate}
          </div>
          <div className="p-col" style={{ textAlign: 'right' }}>
            {rightTemplate}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <div className={classes.root}>{optionsTemplate()}</div>

        <div>
          <TabPanel value={value} index={0}>
            <QuestionsTab formData={formDeatils} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default EditPoll;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
    justifySelf: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: 'flex',
    alignContent: 'space-between',
    alignItems: 'center',
  },
}));
