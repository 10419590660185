import axios from "axios";
import * as urls from  '../../api/urls';

export const createForm = async (data, token) => {

    const form = {
        json_body: data?.formSchema,
        activity_id: data?.activityId,
        status: 1,
    };

    const response = await axios.post(urls.urlCreateForm, form, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const getFormByActivity = async (activity_id, token) => {

    const response = await axios.get(urls.urlGetFormByActivity + activity_id, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const updateForm = async (id, formSchema, token) => {

    const data = {
        json_body: formSchema,
    };

    const response = await axios.put(urls.urlUpdateForm + id, data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deleteForm = async (id, token) => {

    const response = await axios.delete(urls.urlDeleteForm + id, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}