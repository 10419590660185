import React, { useState, useEffect } from "react";

import { CreateIndicatorVariableDialog, IndicatorVariablesList } from ".";
import { VARIABLE_TYPE_FORMAT_ID } from "../../constants/VariableTypes";

const emptyVariable = {
  id: null,
  indicator_id: null,
  label: "",
  definition: "",
  variable_type_id: null,
  measure_type_id: null,
  variable_type_format_id: null,
  measure_type_characteristics_values: []
};

const IndicatorVariablesDefinition = (props) => {
  const [variables, setVariables] = useState(props.data);
  const [variable, setVariable] = useState(emptyVariable);
  const [submitted, setSubmitted] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);

  useEffect(() => {
    setVariables(props.data);
  }, [props.data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _variable = { ...variable };
    _variable[`${name}`] = val;
    setVariable(_variable);

    
  };

  const onCharacteristicInputChange = (e, id) => {
    
    const val = (e.target && e.target.value) || "";
    let _variable = { ...variable };
    for(let i = 0; i <_variable?.measure_type_characteristics_values?.length; i++){
      if(_variable.measure_type_characteristics_values[i].characteristics_id === id){
        _variable.measure_type_characteristics_values[i].value = val
        setVariable(_variable);
        return
      }
    }
    _variable.measure_type_characteristics_values.push({"characteristics_id": id, "value": val})
    setVariable(_variable);
  };


  const onSelectorChange = (value, name) => {
    let _variable = { ...variable };

    if(name !== VARIABLE_TYPE_FORMAT_ID && _variable.measure_type_id !== value)
      _variable.measure_type_characteristics_values = []

    _variable[`${name}`] = value;
    setVariable(_variable);
  }

  const createVariable = async () => {
    setSubmitted(true);
    // Updating existing variable

    let _aux = [...variables];
    const indexVariable = _aux.findIndex((c) => c.label === variable.label);
    _aux[indexVariable] = variable;
    props.onElementChange(_aux, props.name)
    setVariable(emptyVariable);
    setCreateDialog(false);
    setSubmitted(false);
  };

  // On Buttons Clicks
  const onEditVariable = (data) => {
    setVariable(data);
    setCreateDialog(true);
  };

  return (
    <>
      <IndicatorVariablesList
        data={variables}
        onEditVariable={onEditVariable}
      />

      <CreateIndicatorVariableDialog
        createDialog={createDialog}
        setCreateDialog={setCreateDialog}
        targetObject={variable}
        submitted={submitted}
        createTargetObject={createVariable}
        onInputChange={onInputChange}
        setSubmitted={setSubmitted}
        variablesTypes={props.variablesTypes}
        title={"Información de la variable"}
        onSelectorChange={onSelectorChange}
        onCharacteristicInputChange={onCharacteristicInputChange}
      />
    </>
  );
};

export default IndicatorVariablesDefinition;
