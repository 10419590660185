import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

const HandleSteps = ({
  handleBack,
  handleContinue,
  finish = false,
  type = true,
  submited = false,
}) => {
  return (
    <div
      className="p-col-6 p-lg-6 p-pb-3"
      style={{ margin: 'auto', padding: '0px' }}
    >
      <Toolbar
        className="p-p-2 p-p-lg-3 bg-dark"
        left={
          type ? (
            <Button
              style={{ width: '150px' }}
              className="p-button-outlined p-button-danger p-mr-1"
              label="Atrás"
              icon="pi pi-arrow-left"
              disabled={submited}
              onClick={handleBack}
            />
          ) : (
            ''
          )
        }
        right={
          <Button
            type="submit"
            style={{ width: '150px' }}
            className={`p-button-outlined p-button-${
              finish ? 'info' : 'success'
            }`}
            label={finish ? 'Finalizar' : 'Continuar'}
            icon={submited ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
            disabled={submited}
            onClick={handleContinue}
          />
        }
      />
    </div>
  );
};

HandleSteps.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  finish: PropTypes.bool,
};

export default HandleSteps;
