import axios from "axios";
import * as urls from  '../../api/urls';

export const getMomentsModelByModelId = async (modelId, token) => {

    const response = await axios.get(`${urls.URL_BASE}${urls.urlListMomentsModelByMoment}${modelId}`,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const getProcessByMomentModelId = async (modelId, moments, token, role) => {

    const stage_role = '/' + role;
    const ids = [];

    moments.forEach((moment) => {
        ids.push(moment?.id);
    })

    const response = await axios.post(urls.URL_BASE + urls.urlListProcessByMomentModel + modelId + stage_role, { ints: ids }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}