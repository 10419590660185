import React, { useState, useEffect } from 'react';
import GridItem from './GridItem';
import ListItem from './ListItem';
import Filters from './Filters';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { listPublishedModels } from '../../../redux/actions/publisheds/model.actions';
import { SelectButton } from 'primereact/selectbutton';
import { useCategories } from '../../../redux/hooks/useCategories';
import { HelpButton } from '../../components/UI';
import '../../assets/scss/modelos.scss';

const Models = () => {

    const history = useHistory();
    const user = useSelector((state) => state.LoginState.data);
    const team = useSelector(state => state.LoginState.data.team);

    const [ selectedCategories, setSelectedCategories ] = useState([]);
    const { listCategories, CategoriesData } = useCategories();
    const [ models, setModels ] = useState([]);
    const [ layout, setLayout ] = useState('grid');

    useEffect(() => {
        listPublishedModels(user?.access_token).then((data) => {
            setModels([...data]);
        });
    },[ user ]);

    useEffect(() => {
        listCategories()
    }, [ ]);

    const handleModelClick = async (data) =>{
        history.push('/models/details/' + data?.id);
    };

    const dataviewHeader = (
        <div className="p-grid p-nogutter">
            <div className="p-col-6" style={{ textAlign: 'left' }}>
                <h5>Modelos</h5>
            </div>
            <div className="p-col-6" style={{ textAlign: 'right' }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div>
        </div>
    );

    const itemTemplate = (data, layout) =>{
        if(!data) return;
        else if (layout === 'list') return <ListItem data={ data } handleItemClick={ handleModelClick } />;
        else if (layout === 'grid') return <GridItem data={ data } handleItemClick={ handleModelClick } />;
    };

    return (
        <>                    
            <div className="list-demo">
                <div className="p-col-12">
                    <div className="card">

                        <Filters />

                        <div className='p-text-center p-mt-3'>
                            <div className="p-field">

                                <label className='p-mb-3' style={{ fontSize: '.9rem' }}>
                                    Categorías
                                    <HelpButton size='small' message='Seleccione las categorías de los modelos que desea filtrar.' />
                                </label>

                                <SelectButton
                                    value={ selectedCategories }
                                    onChange={(e) => setSelectedCategories(e.value)} 
                                    options={ (CategoriesData) ? CategoriesData : [] } 
                                    optionLabel="name" multiple />
                            </div>
                        </div>

                        <DataView 
                            value={ models } 
                            layout={ layout } paginator rows={ 6 } 
                            itemTemplate={ itemTemplate } header={ dataviewHeader }></DataView>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Models;