import React from 'react';
import PropTypes from 'prop-types';
import { FileUpload } from 'primereact/fileupload';

const ShapesForm = props => {
    return (
        <div>
            <form className='p-fluid'>

                <div className="p-field">
                    <label>Ingrese archivos de mapas</label>
                    <FileUpload
                        uploadOptions={{ label: 'Subir', className: 'p-button-success' }}
                        cancelOptions={{ label: 'Cancelar', className: 'p-button-warning' }}
                        chooseOptions={{ label: 'Seleccionar', className: 'p-button-info' }} />
                </div>

            </form>

            <div>
                <span>Mapas:</span>

                <div className='card p-shadow-10'>

                </div>
            </div>
        </div>
    )
}

ShapesForm.propTypes = {}

export default ShapesForm;