import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { createForm, deleteForm, getFormByActivity, updateForm } from '../../../../redux/actions/form.actions';
import { useHistory } from 'react-router';
import { DialogContent } from '@mui/material';
import { updateActivityTypeByTask } from '../../../../redux/actions/activity.actions';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Fieldset } from 'primereact/fieldset';

const MenuOptions = ({ formSchema, activity, setActivity, relationships }) => {

    const toast  = useRef(null);
    const history = useHistory();
    const user = useSelector((state) => state.LoginState.data);
    const process = useSelector((state) => state.ProcessState.process);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ deleteDilog, setDeleteDilog ] = useState(false);

    const handleSave = () => {

        // Body for create a new form in this activity
        const dataForm = {
            formSchema: {
                ...formSchema,
                relationships,
            },
            activityId: activity?.id,
        }
        
        // If activity doesn´t have form, survey or social asigned
        if (!activity?.type) {

            // Create a new form for this activity
            createForm(dataForm, user?.access_token).then(response => {

                // Update activity type as 'form'
                // For have asigned a form with this activity
                updateActivityTypeByTask(activity?.task_id, process?.id, 'form', user?.access_token, role).then(responseActivity => {

                    setActivity(responseActivity);

                    toast.current.show({ 
                        severity: 'success', 
                        summary: 'Success', 
                        detail: 'Form created and saved',
                        life: 3000 }
                    );
                });
            });

        } else if (activity?.type.toLowerCase() === 'form'.toLowerCase()) { //Update form if activity type is form

            const dataForm = {
                ...formSchema,
                relationships,
            }
            
            getFormByActivity(activity?.id, user?.access_token).then(data => {
                updateForm(data?.data?.id, dataForm, user?.access_token).then(response => {

                    toast.current.show({ 
                        severity: 'success', 
                        summary: 'Success', 
                        detail: 'Form updated',
                        life: 3000 }
                    );
                });
            });

        }
    }

    const handleDelete = () => {
        updateActivityTypeByTask(activity?.task_id, process?.id, '', user?.access_token, role).then(activityResponse => {
            getFormByActivity(activity?.id, user?.access_token).then(formResponse => {
                deleteForm(formResponse?.data?.id, user?.access_token).then(formDeleted => {

                    setActivity(activityResponse);
                    setDeleteDilog(false);

                    toast.current.show({ 
                        severity: 'error', 
                        summary: 'Success', 
                        detail: 'Form deleted',
                        life: 3000 }
                    );
                })
            });
        });
    }

    const leftTemplate = (
        <>
            <Button
                className="p-button-danger p-m-1"
                label='Delete'
                disabled={ (activity?.type) ? false:true }
                icon='pi pi-trash'
                onClick={ () => setDeleteDilog(true) } />

            <Button
                className="p-button-success p-m-1"
                label={ (activity?.type) ? 'Save':'Create' }
                icon="pi pi-save"
                onClick={ handleSave } />
        </> 
    );

    const rightTemplate = (
        <>
            <Button
                className="p-button-warning p-m-1"
                label='Back'
                icon="pi pi-arrow-left"
                onClick={() => {
                    history.goBack();
                }} />
        </>
    );

    return (
        <>
            <Toast ref={ toast } />

            <h5 className='p-text-center'>
                Constructor de formulario: { activity?.name }
            </h5>

            <Dialog 
                onClose={ () => setDeleteDilog(false) } 
                open={ deleteDilog } >

                <DialogTitle className='bg-dark color-white p-text-center'>
                    Are you sure you want to delete this form?
                </DialogTitle>

                <DialogContent className='bg-dark color-white'>
                    <div className='p-mx-3 p-mb-3'>
                        <p>
                            If you delete this form you will not be able to recover it
                        </p>
                    </div>
                    <div className='p-text-right'>
                        <button
                            className="p-button p-component p-button-text p-button-warning p-mr-1 p-mr-1"
                            style={{ width: '93px' }}
                            onClick={ () => setDeleteDilog(false) } >
                                <span className="p-button-icon p-c pi pi-times p-button-icon-left"></span>
                                <span className="p-button-label p-c">Cancel</span>
                        </button>
                        <button
                            className="p-button p-component p-button-text p-button-danger"
                            style={{ width: '93px' }}
                            onClick={ handleDelete } >
                                <span className="p-button-icon p-c pi pi-trash p-button-icon-left"></span>
                                <span className="p-button-label p-c">Delete</span>
                        </button>
                    </div>
                </DialogContent>
            </Dialog>

            <Fieldset legend='Opciones' className='p-mb-3' toggleable>
                <div className="p-grid">
                    <div className="p-col p-p-0" style={{ textAlign: 'left' }}>{ leftTemplate }</div>
                    <div className="p-col p-p-0" style={{ textAlign: 'right' }}>{ rightTemplate }</div>
                </div>
            </Fieldset>
        </>
    );
}

MenuOptions.propTypes = {
    process: PropTypes.object,
    activity: PropTypes.object,
    formSchema: PropTypes.object,
    token: PropTypes.string,
}

export default MenuOptions;