import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionTab } from 'primereact/accordion';

const DetailsTemplate = ({ model }) => {

    const getAccordionHeader = (title) => (
        <>
            <p style={{ fontSize: '1.5rem' }}>{ title }</p>
        </>
    );

    return (
        <>
            <Accordion activeIndex={1} >
                <AccordionTab
                    headerTemplate={getAccordionHeader('Visión General')} >
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
                    et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
                    Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit
                    quo minus.</p>
                </AccordionTab>
                <AccordionTab
                    headerTemplate={getAccordionHeader('Detalles')} >

                    <div className='card p-mb-0'>
                        <div className='p-grid p-mb-3'>
                            <span className='p-col-2 p-text-bold'>Nombre</span>
                            <span className='p-col-9'>{ model?.name }</span>
                        </div>
                        <div className='p-grid'>
                            <span className='p-col-2 p-text-bold'>Descripción</span>
                            <span className='p-col-9'>{ model?.description }</span>
                        </div>
                    </div>

                    <div className="card schedule p-mb-0">
                        <p>General</p>
                        <ul className='p-grid'>
                            <li className='p-col p-m-1'>
                                <div className="schedule-header">
                                    <h6>Nro. Actividades</h6>
                                    <span>
                                        <span style={{ fontSize: '.9rem' }}>
                                            254
                                        </span>
                                    </span>
                                </div>
                                <span>
                                    254 actividades
                                </span>
                            </li>
                            <li className='p-col p-m-1'>
                                <div className="schedule-header">
                                    <h6>Nro. Procesos</h6>
                                    <span>
                                        <span style={{ fontSize: '.9rem' }}>
                                            20
                                        </span>
                                    </span>                                                
                                </div>
                                <span>
                                    20 procesos
                                </span>
                            </li>
                        </ul>
                        <ul className='p-grid'>
                            <li className='p-col p-m-1'>
                                <div className="schedule-header">
                                    <h6>Nro. Herramientas</h6>
                                    <span>
                                        <span style={{ fontSize: '.9rem' }}>
                                            10
                                        </span>
                                    </span>
                                </div>
                                <span>
                                    10 herramientas
                                </span>
                            </li>
                            <li className='p-col p-m-1'>
                                <div className="schedule-header">
                                    <h6>Nro. Indicadores</h6>
                                    <span>
                                        <span style={{ fontSize: '.9rem' }}>
                                            175
                                        </span>
                                    </span>                                                
                                </div>
                                <span>
                                    175 indicadores
                                </span>
                            </li>
                        </ul>
                    </div>

                </AccordionTab>
            </Accordion>   
        </>
    )
}

DetailsTemplate.propTypes = {
    model: PropTypes.object.isRequired,
}

export default DetailsTemplate
