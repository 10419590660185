import React, { useEffect, useRef, useState } from 'react';
import NroInfoCard from '../../../../components/UI/NroInfoCard';
import { useSelector } from 'react-redux';
import { retrieveActivitySummaryByProcess } from '../../../../../redux/actions/activity.actions';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getTimeByDays } from '../../../utils/helpers/monitoringHelpers';
import { useTranslation } from 'react-i18next';
import { OverlayPanel } from 'primereact/overlaypanel';
import Settings from '../../Settings';

const ProcessInfo = ({ process, open, setOpen }) => {

    const user = useSelector((state) => state.LoginState.data);
    const op = useRef(null);
    const { t: tGlobal } = useTranslation(['global']);
    const { unitTime } = useSelector((state) => state.UserPreferencesState);
    const [ activitySummary, setActivitySummary ] = useState({
        total: 0,
        finaliseds: 0,
        running: 0,
        uninitiateds: 0,
        unassigneds: 0,
        duration: 0,
        duration_elapsed: 0,
        budget: 0,
        budget_finaliseds: 0,
        budget_running: 0,
        budget_uninitiateds: 0,
        budget_unassigneds: 0,
    });

    const timeLabel = tGlobal('times.' + unitTime?.label.toLowerCase());

    useEffect(() => {
        if (process?.id) {
            retrieveActivitySummaryByProcess(process?.id, user?.access_token).then((summary) => {
                setActivitySummary(summary);
            });
        }
    }, [ process, user ]);

    const toggle = (event) => {
        op.current.toggle(event);
    };

    return (
        <Dialog 
            header={ process?.name }
            visible={ open } 
            onHide={ () => setOpen(false) } >

            <div>

                {/* Activity Summary */}
                <div className="p-col-12">
                    <div className="p-grid" style={{ margin: '-1rem' }}>

                        <NroInfoCard
                            label='Actividades Finalizados' color='white'
                            value={ activitySummary?.finaliseds } />

                        <NroInfoCard
                            icon='pi pi-spinner' label='Actividades en Ejecución' color='blue'
                            value={ activitySummary?.running } />

                        <NroInfoCard
                            label='Actividades no Iniciadas' icon='pi pi-compass' color='gray'
                            value={ activitySummary?.uninitiateds } />

                        <NroInfoCard
                            label='Actividades sin Asignar' icon='pi pi-times' color='orange'
                            value={ activitySummary?.unassigneds } />
                    </div>
                </div>

                <div className='p-col-12'>
                    <div className="p-grid">
                        <div className="p-col">
                        <OverlayPanel ref={ op } appendTo={document.body} showCloseIcon style={{ maxWidth: '350px' }}>
                            <Settings />
                        </OverlayPanel>
                            <div className="card schedule" style={{ border: '1px solid gray' }}>
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Resumen del proceso</h6>
                                        <p className="subtitle">
                                            Medición entre actividades del proceso {process?.name}
                                        </p>
                                    </div>
                                    <p className="subtitle">
                                        <Button
                                            className='p-button-outlined p-button-rounded'
                                            icon='pi pi-cog'
                                            onClick={ toggle } />
                                    </p>
                                </div>

                                <p>Duración</p>

                                <ul>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Duración total</h6>
                                            <span>
                                                <span style={{ fontSize: '.9rem' }}>
                                                    { getTimeByDays(activitySummary?.duration, unitTime) }
                                                </span>
                                                <span className='p-ml-1'>
                                                    { timeLabel }
                                                </span>
                                            </span>
                                        </div>
                                        <span>{ activitySummary?.total } actividades en total</span>
                                    </li>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Tiempos transcurridos</h6>
                                            <span>
                                                <span style={{ fontSize: '.9rem' }}>
                                                    { getTimeByDays(activitySummary?.duration_elapsed, unitTime) }
                                                </span>
                                                <span className='p-ml-1'>
                                                    { timeLabel }
                                                </span>
                                            </span>                                                
                                        </div>
                                        <span>{ activitySummary?.finaliseds } actividades finalizadas</span>
                                    </li>
                                </ul>

                                <p>Presupuesto</p>

                                <ul>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Presupuesto total</h6>
                                            <span>
                                                <span style={{ fontSize: '.9rem' }}>
                                                    { activitySummary?.budget }
                                                </span>
                                                <span className='p-ml-1'>
                                                    dólares
                                                </span>
                                            </span>
                                        </div>
                                        <span>Suma del presupuesto de las actividades de este proceso</span>
                                    </li>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Presupuesto sin Asignaciones</h6>
                                            <span>
                                                <span style={{ fontSize: '.9rem' }}>
                                                    { activitySummary?.budget_unassigneds }
                                                </span>
                                                <span className='p-ml-1'>
                                                    dólares
                                                </span>
                                            </span>                                                
                                        </div>
                                        <span>Suma del presupuesto de las actividades sin asignaciones</span>
                                    </li>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Presupuesto Asignado</h6>
                                            <span>
                                                <span style={{ fontSize: '.9rem' }}>
                                                    { activitySummary?.budget_uninitiateds }
                                                </span>
                                                <span className='p-ml-1'>
                                                    dólares
                                                </span>
                                            </span>                                                
                                        </div>
                                        <span>Suma del presupuesto de las actividades aún no iniciadas</span>
                                    </li>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Presupuesto en Ejecución</h6>
                                            <span>
                                                <span style={{ fontSize: '.9rem' }}>
                                                    { activitySummary?.budget_running }
                                                </span>
                                                <span className='p-ml-1'>
                                                    dólares
                                                </span>
                                            </span>                                                
                                        </div>
                                        <span>Suma del presupuesto de las actividades en ejecución</span>
                                    </li>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Presupuesto Gastado</h6>
                                            <span>
                                                <span style={{ fontSize: '.9rem' }}>
                                                    { activitySummary?.budget_finaliseds }
                                                </span>
                                                <span className='p-ml-1'>
                                                    dólares
                                                </span>
                                            </span>                                                
                                        </div>
                                        <span>Suma del presupuesto de las actividades finalizadas</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

ProcessInfo.propTypes = {}

export default ProcessInfo