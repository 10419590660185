import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextEditor } from '../../../components/UI';
import { updateProcess } from "../../../../redux/actions/process.actions";

const EnvironmentProcess = ({ showMessage, values, handleInputChange }) => {

    const process = useSelector((state) => state.ProcessState?.process);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ hasChanged, setHasChanged ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);

    useEffect(() => {
        if (values?.environment_process !== process?.environment_process) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [ values?.environment_process, process?.environment_process ]);

    const handleSave = async (attribute) => {
        setSubmitted(true);
        if (hasChanged) {

            const data = { environment_process: values?.environment_process }

            updateProcess(process?.id, data, user?.access_token, role).then(data => {
                setHasChanged(false);
                showMessage("success", "Entorno actualizado", "");
            })
        }
        setSubmitted(false);
      };
 
    return (
        <div>
            <TextEditor 
                title="Entorno" 
                subtitle="Ingrese el entorno en el que se desenvuelve  el proceso"
                messageError="Error al almacenar el entorno"
                value={ values?.environment_process }
                submitted={ submitted }
                onSave={ handleSave }
                actionButtonDisabled={ !hasChanged }
                onInputChange={(e) => handleInputChange({ target: { name: 'environment_process', value: e?.htmlValue } })}
                helpInformation="Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocarn en el entorno." />
        </div>
    ); 
}

export default EnvironmentProcess;