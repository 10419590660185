import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HelpButton from '../HelpButton';
import ExecuteForm from './ExecuteForm';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { IN_EXECUTION_STATUS } from '../../../pages/utils/constants/modelConstans';
import { executeModel } from '../../../../redux/actions/model.actions';

const ExecuteDialog = ({ open, setOpen, onExecute, selectedModel, user, showMessage }) => {

    const [ model, setModel ] = useState(selectedModel);
    
    useEffect(() => {
        setModel(selectedModel);
    }, [ selectedModel ])

    const handleExecution = () => {
        executeModel(model?.id, user?.access_token).then((data) => {
            showMessage('success', 'Modelo executado', model?.name);
            setModel(data);
            onExecute(data);
        }).catch(err => {
            showMessage('error', 'Modelo no executado', 'Error inesperado');
        });
    }

    const dialogHeader = () => (
        <div>
            <h5>
                Ejecutar
                <HelpButton message='' />
            </h5>
        </div>
    );

    const dialogFooter = () => {
        if (model?.status === 1) {
            return (
                <div>
                    <Button
                        className='p-button-text'
                        icon='pi pi-times'
                        label='No'
                        onClick={() => setOpen(false)} />
                    <Button
                        className='p-button-text'
                        icon='pi pi-check'
                        label='Si'
                        onClick={ handleExecution } />
                </div>
            );
        }
    };

    return (
        <div>
            <Dialog
                visible={ open } modal 
                style={{ maxWidth: "500px", minWidth: '415px' }}
                onHide={ () => setOpen(false) }
                header={ dialogHeader } footer={ dialogFooter } >
                
                {(model?.status === IN_EXECUTION_STATUS) 
                    ? 
                    <ExecuteForm model={ model } showMessage={ showMessage } />
                    : 
                    <div>
                        <h6 className='p-text-center'>¿Desea executar el modelo?</h6>
                        <div className='p-text-center'>{ model?.name }</div>
                    </div>
                }
            </Dialog>
        </div>    
    );
}

ExecuteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    selectedModel: PropTypes.object.isRequired,
    showMessage: PropTypes.func.isRequired,
}

export default ExecuteDialog