import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { retrieveActivitySummaryByModel } from '../../../../redux/actions/activity.actions';
import NroInfoCard from '../../../components/UI/NroInfoCard';

const Activities = () => {

    const user = useSelector((state) => state.LoginState.data);
    const { model } = useSelector(state => state.ModelState);
    const [ activitySummary, setActivitySummary ] = useState({
        total: 0,
        finaliseds: 0,
        running: 0,
        uninitiateds: 0,
        unassigneds: 0,
        duration: 0,
        duration_elapsed: 0,
        budget: 0,
        budget_spent: 0,
    });

    useEffect(() => {
        if (model?.id) {            
            retrieveActivitySummaryByModel(model?.id, user?.access_token).then((summary) => {
                setActivitySummary(summary);
            });
        }
    }, [ user, model ]);

    return (
        <div className='animate__animated animate__zoomIn'>
            <div className="card p-shadow-10">

                <h5 className='p-text-center'>Resumen de las Actividades</h5>

                <div className='p-grid p-mt-3'>
                    <div className="p-col-12">
                        <div className="p-grid" style={{ margin: '-1rem' }}>

                        <NroInfoCard
                            label='Finalizados' color='white'
                            value={ activitySummary?.finaliseds } />

                        <NroInfoCard
                            label='En Ejecución' icon='pi pi-spinner' color='blue'
                            value={ activitySummary?.running } />

                        <NroInfoCard
                            label='Iniciadas' icon='pi pi-compass' color='gray'
                            value={ activitySummary?.uninitiateds } />

                        <NroInfoCard
                            label='Sin Asignar' icon='pi pi-times' color='orange'
                            value={ activitySummary?.unassigneds } />

                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    );
}

Activities.propTypes = {}

export default Activities;