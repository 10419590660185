import { createAction } from 'redux-actions';
import api from '../../api';
import * as urls from '../../api/urls';

export const setLoadingImageAction = createAction('SET_LOADING_IMAGE_ACTION');
export const setImageDataAction = createAction('SET_IMAGE_DATA_ACTION');
export const setImageErrorAction = createAction('SET_IMAGE_ERROR_ACTION');
export const setResetImageAction = createAction('SET_RESET_IMAGE_ACTION');

export const uploadImages = (data, role) => async (dispatch, getState) => {
  const token = getState()?.LoginState?.data?.access_token;
  let formData = new FormData();

  formData.append('image', data?.dataImage);
  formData.append('team_id', data?.team_id);
  formData.append('module_type', data?.type);
  formData.append('module_type_id', data?.type_id);

  dispatch(setResetImageAction());
  dispatch(setLoadingImageAction(false));

  try {
    const { data, status } = await api.post(
      urls.urlUploadImage + role,
      formData,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          ContentType: 'multipart/form-data',
        },
      }
    );

    if (status === 201) {
      return data;
    }
  } catch (error) {
    dispatch(
      setImageErrorAction({
        message: 'Error al cargar imagen',
      })
    );
    throw error;
  }
  dispatch(setLoadingImageAction(false));
};

export const uploadImage = async (data, token, role = 0) => {
  const formData = new FormData();

  formData.append('image', data?.dataImage);
  formData.append('team_id', data?.team_id);
  formData.append('module_type', data?.type);
  formData.append('module_type_id', data?.type_id);

  const response = await api.post(urls.urlUploadImage + role, formData, {
    headers: {
      Authorization: 'Bearer ' + token,
      ContentType: 'multipart/form-data',
    },
  });

  return response.data;
};
