import React, { useEffect, useRef, useState } from 'react';
import Formio from 'formiojs/dist/formio.full.min'
import MenuOptions from './MenuOptions';
import VariablesRelationship from './VariablesRelationship';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { getFormByActivity } from '../../../../redux/actions/form.actions';
import { customOptions, editForm, i18n, ignoreOptions } from '../../utils/configs/formioConfig';
import { getIndicatorsByIds } from '../../../../redux/actions/operativization/indicators.actions';
import 'formiojs/dist/formio.full.min.css';
import 'formiojs/dist/formio.builder.min.css';
import 'formiojs/dist/formio.embed.min.css';
import 'formiojs/dist/formio.form.min.css';
import '../../../assets/scss/formio.scss';

/**
 * Componets info:
 * https://github.com/formio/formio.js/wiki/Components-JSON-Schema
 * 
 * Custom form builder docs:
 * http://formio.github.io/formio.js/app/examples/custombuilder.html
 * 
 * Custom attributes fields docs
 * https://github.com/formio/formio.js/issues/1056
 * 
 */

const FormBuilder = props => {

    const location = useLocation();
    const process = useSelector((state) => state.ProcessState.process);
    const indicator_process = useSelector((state) => state.ProcessState.indicator_process);
    const user = useSelector((state) => state.LoginState.data);
    const toast  = useRef(null);

    // Esquema principal del formulario, este se va a guardar a la db
    const [ formSchema, setFormSchema ] = useState({});
    const [ indicators, setIndicators ] = useState([]);
    // Relación entre indicador y variable
    const [ relationships, setRelationships ] = useState([]);
    const [ activity, setActivity ] = useState({ ...location.state?.activity });

    useEffect(() => {

        if (indicator_process && indicator_process.length > 0) {
            const ids = []
            indicator_process.forEach((process) => { ids.push(process.id) });
            
            getIndicatorsByIds(user.access_token, ids).then(data => {
                setIndicators(data);
            });
        }
    }, [ user, indicator_process ]);

    const pushComponentsRelationships = (component) => {
        // Push a component, not related to an indicator
        setRelationships((relationships) => {

            const index = relationships.findIndex(rel => rel.input.key === component.key);

            if (index === -1) {
                return [
                    ...relationships,
                    {
                        input: {
                            key: component.key,
                            label: component.label,
                        }
                    },
                ]
            } else {
                return relationships;
            }
        });
    }

    const removeComponentsRelationships = (component) => {
        // Remove component and indicator relationships
        setRelationships((relationships) => relationships.filter(rel => rel?.input?.key !== component.key))
    }

    // Options for Builder    
    const optionsBuilder = {
        builder: {
            ...ignoreOptions,
            ...customOptions,
        },
        editForm: editForm,
        language: 'sp',
        i18n: i18n,
    };
    
    useEffect( () => {

        getFormByActivity(activity?.id, user?.access_token).then(data => {

            const json = data?.data?.json_body;

            if (json) {
                setFormSchema(json);
                setRelationships((json?.relationships) ? json?.relationships:[]);
            } else {
                setFormSchema({});
                setRelationships([]);
            }

        }).catch(error => {
            throw { detail: 'Connection error, retrieving form data' };
            // throw new Error('Conecction error, retrieving form data');
        });
    }, [ activity, user ]);

    useEffect(() => {
        Formio.builder(document.getElementById('builder'), formSchema, optionsBuilder).then((builder) => {

            builder.on('change', (schema) => {
                if (!schema?.data) setFormSchema(schema);
            });

            builder.on('saveComponent', function(component) {
                pushComponentsRelationships(component);
            });

            builder.on('removeComponent', function(component) {
                removeComponentsRelationships(component);
            });
        });
    }, [ formSchema ]);

    return (
        <div className='card'>
            <Toast ref={ toast } />

            <MenuOptions
                setActivity={ setActivity }
                activity={ activity }
                process={ process }
                formSchema={ formSchema }
                relationships={ relationships } />

            <div className='formio-builder-wrapper'>

                <div id="bootstrap-formio" >
                    {/* Div builder container */}
                    <div id="builder" />
                </div>

                <div className='indicators-formio'>

                    <VariablesRelationship 
                        relationships={ relationships }
                        setRelationships={ setRelationships }
                        indicators={ indicators }
                        setFormSchema={ setFormSchema }
                        formSchema={ formSchema } />
                </div>

            </div>
        </div>
    )
}

export default FormBuilder;