/**
 * MODEL STATUS
 */

// Acquired and In Design status
export const INACTIVE_STATUS = 0

// In Design model status
export const IN_DESIGN_STATUS = 1
export const PUBLISHING_STATUS = 2
export const PUBLISHED_STATUS = 3

// Acquired models status
export const ACQUIRED_STATUS = 1
export const IN_EXECUTION_STATUS = 2