import { BreadCrumb } from "primereact/breadcrumb";
import {TabMenu} from "primereact/tabmenu";
import React, {useState,useEffect,useRef} from 'react';
import useQueryParams from "../../../../../hooks/useQueryParams";
import { useKnowledge } from "../../../../../redux/hooks/useKnowledge";


import { ItemOrigins } from "../../KnowledgeBase/NewItem/ItemOrigins";
import { ItemPurpose } from "../../KnowledgeBase/NewItem/ItemPurpose";
import { ItemTheory } from "../../KnowledgeBase/NewItem/ItemTheory";
import { Application } from "./Application";
import CalculationMethod from "./CalculationMethod";
import { Definition } from "./Definition";





const NewIndicator = () =>{
    const wizardItems = [
        {id: 0, label: 'Definición', icon: 'pi pi-th-large'},
        {id: 1, label: 'Método de cálculo', icon: 'pi pi-users'},
        {id: 2, label: 'Aplicación', icon: 'pi pi-eye'},
    ];

    const [tabSelected, setTabSelected] = useState(wizardItems[0]);
    const [saved,setSaved] = useState(false)
    const{getCurrentItem,dataCurrent}=useKnowledge()
    const { action,item_id } = useQueryParams();
    /*const addInfoMessageSaved = (status, content) => {
        message.current.show({severity: status, content: content});
    };*/
    useEffect(() => {
       
        if(action==="edit"){
            getCurrentItem(item_id);
        }
        
    }, [action])
    const tabChangeHandler = (tab) => {
        /*if((saved || tab.id === 0) || action === "edit"){
            console.log(tab);
            setTabSelected(tab);
        }
        else{
            setTabSelected(wizardItems[0]);
            //TODO reemplazar por TOAST
            //addInfoMessageSaved('error', "Por favor complete los datos en la primera pestaña");
          
        }*/
        setTabSelected(tab);
    };
    const breadcrumbHome = { icon: 'pi pi-home', to: '/' };
    const breadcrumbItems = [
        { label: 'MIT' },
        { label: 'Diseñador de MIT' },

    ];
    //const message = useRef();

    return (
        <>

            <div className="p-col-12 p-md-12">
                <div className="card card-w-title">
                    <h3>Sistema de indicadores</h3>
                    <TabMenu model={wizardItems} onTabChange={(e) => {
                        tabChangeHandler(e.value)
                    }} activeItem={tabSelected}/>

                    {
                        tabSelected.id === 0 ?
                            <Definition setSaved={setSaved} dataCurrent={dataCurrent} action={action}/>
                            : tabSelected.id === 1 ?
                            <CalculationMethod action={action}/>
                            :tabSelected.id === 2 ?
                            <Application action={action}/>
                            : ""}
                </div>
            </div>


        </>
    );

    
};

export default NewIndicator;