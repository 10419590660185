import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'primereact/toast';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { createPagoPaypal } from '../../../redux/actions/payment-method.actions';

// This values are the default props in the UI
const currencyDefault = 'USD';
const style = { layout:'vertical', height: 40, color: 'gold', label: 'pay' };
const emptyFunc = () => { return; };

// Custom component to wrap the PayPalButtons and handle currency changes
const PaypalButtonWrapper = React.memo(({ 
    product,
    customer,
    submited, 
    setSubmited,
    currency=currencyDefault, 
    showSpinner=true, 
    callback=emptyFunc 
}) => {

    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const toast  = useRef(null);

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [ currency, showSpinner ]);

    return (
        <>
            { (showSpinner && isPending) &&
                <div>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '5rem' }}></i> 
                </div>
            }

            <Toast ref={ toast } />
            
            <PayPalButtons
                style={ style }
                disabled={ submited }
                forceReRender={[ product, currency, style ]}
                fundingSource='paypal'
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: product?.price,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            return orderId;
                        });
                    }}
                onApprove={function (data, actions) {
                    createPagoPaypal(data.orderID, customer?.code, product).then(response => {
                        toast.current.show({ severity: 'success', summary: `${response.message}`, life: 3000 });
                        callback();
                    });
                }}
                onCancel={() => {
                    toast.current.show({ 
                        severity: 'warn', 
                        summary: 'You cancelled the payment.', 
                        life: 3000,
                    });
                    setSubmited(false);
                }}
                onError={() => {
                    toast.current.show({
                        severity: 'error', 
                        summary: 'There was an error with the payment. Try again, please.', 
                        life: 3000
                    });
                    setSubmited(false);
                }}
                onClick={() => {
                    setSubmited(true);
                }}
            />
        </>
    );
});

PaypalButtonWrapper.propTypes = {
    currency: PropTypes.string,
    showSpinner: PropTypes.bool,
    submited: PropTypes.bool.isRequired,
    setSubmited: PropTypes.func.isRequired,
}

export default PaypalButtonWrapper;