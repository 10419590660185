import React, { useRef, useState } from 'react';
import CreateTool from './Create';
import ListTool from './List';
import useForm from '../../../../hooks/useForm';
import { Fieldset } from 'primereact/fieldset';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';

const Toolbox = () => {

    const imageDefault = 'https://cdn-icons-png.flaticon.com/512/2282/2282363.png';
    const model = useSelector((state) => state.ModelState.model);
    const message = useRef();

    const emptyElement = {
        name: '', 
        description: '',  
        model_id: model?.id, 
        image: imageDefault,
    }

    const { values, setValues, handleInputChange } = useForm(emptyElement);
    const [ elements, setElements ] = useState([]);

    const showMessage = (severity, summary, detail) => {
        message?.current?.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: 3000,
        });
    };

    return (
        <div>
            <Toast ref={ message } />

            <div className="card p-shadow-10">

                <div>
                    <h5>Caja de Herramientas</h5>
                </div>

                <div className='p-grid'>

                    <div className="p-col">
                        <Fieldset legend='Nueva Herramienta' toggleable >
                            <CreateTool
                                values={ values }
                                setValues={ setValues }
                                handleInputChange={ handleInputChange }
                                emptyElement={ emptyElement }
                                setElements={ setElements }
                                showMessage={ showMessage } />
                        </Fieldset>
                    </div>

                    <div className="p-col" >
                        <div className='card'>
                            <ListTool
                                setValues={ setValues }
                                values={ values }
                                emptyElement={ emptyElement }
                                elements={ elements }
                                setElements={ setElements }
                                showMessage={ showMessage } />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Toolbox;