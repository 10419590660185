import React from "react";
import { Toolbar } from "primereact/toolbar";
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";
import { HelpButton } from ".";

const TextEditor = ({ 
    name, 
    value,
    title, 
    subtitle, 
    submitted, 
    helpInformation, 
    messageError, 
    actionButtonDisabled, 
    onInputChange, 
    onSave,
    btnSaveProps={  },
}) => {
  
    const leftToolbarTemplate = () => {
        return (
            <>
                <h3>
                    {title}
                    <HelpButton message={helpInformation} />
                </h3>
            </>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <>
                <Button
                    label="Save"
                    icon="pi pi-save"
                    className="p-button-success"
                    onClick={() => onSave(name)}
                    disabled={ actionButtonDisabled }
                    { ...btnSaveProps }
                />
            </>
        );
    };

    return (
        <>
            <Toolbar
                className="p-mb-4 p-toolbar"
                left={ leftToolbarTemplate }
                right={ rightToolbarTemplate } />

            <div className="p-field p-col-12">
                <label htmlFor="long_description">{ subtitle }</label>

                <Editor
                    style={{ height: "320px", fontSize: "18px" }}
                    value={ value } required
                    onTextChange={(e) => {
                      onInputChange(e, name);
                    }} />

                {submitted && !value && (
                    <small className="p-invalid color-danger">{ messageError }</small>
                )}
            </div>
        </>
    );
};

export default TextEditor;
