import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";

const defaultChooseOptions = {
  icon: 'pi pi-upload',
  label: 'Seleccionar',
  className: 'p-button-info p-button-outlined',
}

const ImgField = (props) => {

  const [ width, setWidth ] = useState();
  const [ height, setHeight ] = useState();

  const new_image = new Image();

  new_image.src = props.preview;

  new_image.onload = () => {
    setWidth(new_image.width);
    setHeight(new_image.height);
  };

  return (
    <>
      <div className="p-fileupload-files" style={{ marginTop: 10, }}>
        <div className="p-fileupload-row">
          <div></div>
          <div>
            <img
              alt="Imagen principal del modelo"
              role="presentation"
              src={ props.preview }
              width="120"
            />
          </div>
          <div>
            <div className="p-mb-1" style={{ fontSize: '.8rem' }}>{ props.image.name }</div>
            <div style={{ fontSize: '.7rem' }}>{`${width} x ${height}`}</div>
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

const ImageUpload = ({ maxFileSize, image, setImage, onInputChange, chooseOptions={}, containerStyle={} }) => {

  const toast = useRef(null);

  const [ preview, setPreview ] = useState();
  const [ error, setError ] = useState();

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    if (!error) {
      return;
    }
    showMessage("error", "Error", error);
  }, [error]);

  const onSelectHandler = ({ files }) => {
    // Validating file max size
    if (files["0"]?.size > maxFileSize) {
      setError("Tamaño de imagen super al límite permitido (1MB)");
      return;
    }
    setImage(files["0"]);
    onInputChange(files["0"], "image")
  };

  useEffect(() => {
    // create the preview
    if (!image) {
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  return (
    <div style={ containerStyle }>

      <Toast ref={ toast } />

      <FileUpload
        auto={false}
        // customUpload={true}
        accept="image/*"
        mode="basic"
        maxFileSize={ 0.5 }
        // uploadHandler={fileUploadHandler}
        // onUpload={onUploadHandler}
        onSelect={ onSelectHandler }
        // chooseLabel="Seleccionar"
        uploadLabel="Cargar"
        cancelLabel="Cancelar"
        chooseOptions={{
          ...defaultChooseOptions,
          ...chooseOptions
        }}
      />

      { image && <ImgField image={ image } preview={ preview } /> }
    </div>
  );
};

ImageUpload.propTypes = {
    chooseOptions: PropTypes.object,
}

export default ImageUpload;