import React, { useCallback, useEffect, useState } from 'react';

import PersonTemplate from '../Register/templates/PersonTemplate';
import UserTemplate from '../Register/templates/UserTemplate';
import CodeActivation from './templates/CodeActivation';
import HandleSteps from '../../components/Register/HandleSteps';
import { Steps } from 'primereact/steps';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import '../../assets/scss/register.scss';
import {
  changeLayoutCSS,
  changeThemeCSS,
} from '../utils/helpers/changeStyleSheetsColor';
import logo from '../../assets/img/gearth-logo.png';
import useForm from '../../../hooks/useForm';

import useQueryParams from '../../../hooks/useQueryParams';

import {
  registerGuest,
  verifyTextCode,
} from '../../../redux/actions/user.action';
import { useDispatch } from 'react-redux';

import { userValidation } from '../Register/templates/utils/validations';

const RegisterGuest = () => {
  const history = useHistory();

  const { t: tGlobal } = useTranslation(['global']);
  const { t: tAuth } = useTranslation(['auth']);
  const alphabetical = tGlobal('vocabulary.plural.alphabetical');
  const uppercase = tGlobal('vocabulary.plural.uppercase');

  const [codeId, setCodeId] = useState();
  const dispatch = useDispatch();
  const { email, team_id, text_code } = useQueryParams();
  const [index, setIndex] = useState(0);
  const [error, setError] = useState();
  const { values, setValues, handleInputChange } = useForm({
    names: '',
    lnames: '',
    gender: '',
    password: '',
    confirmation: '',
  });
  const passwordFormats = [
    `1 ${alphabetical}`,
    `1 ${uppercase} ${alphabetical}`,
    tGlobal('vocabulary.no-space'),
  ];
  const alphanumeric = tGlobal('vocabulary.singular.alphanumeric');

  const [validities, setValidities] = useState({
    password: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.length-min', { min: 4 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [...passwordFormats],
        },
      ],
    },
    confirmation: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.equals-password') },
      ],
    },

    names: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.length', { min: 1, max: 50 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [alphanumeric],
        },
      ],
    },
    lnames: {
      error: false,
      errors: [
        { status: null, msg: tAuth('validation.required') },
        { status: null, msg: tAuth('validation.length', { min: 1, max: 50 }) },
        {
          status: null,
          msg: tAuth('validation.correct-format'),
          format: [alphanumeric],
        },
      ],
    },
    gender: {
      error: false,
      errors: [{ status: null, msg: tAuth('validation.required') }],
    },
  });

  useEffect(() => {
    changeLayoutCSS('dark');
    changeThemeCSS('dark');
  }, []);

  useEffect(() => {
    if (error) {
      console.log(error);
      setError();
    }
  }, [error]);

  const validateCode = useCallback(async (email, text_code) => {
    try {
      const activationCodeId = await verifyTextCode(email, text_code);
      setCodeId(activationCodeId);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    validateCode(email, text_code);
  }, [validateCode]);

  const handleContinue = async () => {
    const { response, data } = userValidation(values);
    if (!response) {
      setValidities(data);
      return;
    }
    try {
      await registerGuest(values, email, codeId, team_id);

      history.push({
        pathname: '/login',
        state: {
          username: email,
          password: values.password,
        },
      });
    } catch (error) {
      setError(error);
    }
  };

  const wizardItems = [{ label: 'Información de Usuario' }];

  const templateSwitch = () => {
    switch (index) {
      case 0:
        return (
          <UserTemplate
            setIndex={setIndex}
            values={values}
            handleInputChange={handleInputChange}
            type={true}
            validities={validities}
          />
        );
      default:
        return <></>;
    }
  };

  //TODO: Change the following component for a better one
  if (!codeId) {
    return (
      <>
        <div>
          <h2>Activación Inválida.</h2>
        </div>
      </>
    );
  }

  return (
    <div className="p-grid p-dir-col" style={{ padding: '0px', margin: '0px' }}>
      <div
        className="p-col p-d-flex"
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <h3 className="p-d-inline-block p-ml-3">Regístrate</h3>
      </div>

      <div
        className="p-col-12 p-col-md-10 p-col-lg-8"
        style={{ margin: 'auto' }}
      >
        <Steps activeIndex={index} model={wizardItems} readOnly />
      </div>

      <div className="p-col" style={{ padding: '0px', margin: '0px' }}>
        <div className="login-body">
          <div
            className="login-wrapper"
            style={{ backgroundColor: '#3E4754', height: 'auto' }}
          >
            <div style={{ width: '90%' }}>{templateSwitch()}</div>
          </div>
        </div>
      </div>

      <div className="p-mb-3" style={{ padding: '0px', margin: '0px' }}>
        <HandleSteps handleContinue={handleContinue} finish type={false} />
      </div>
    </div>
  );
};

export default RegisterGuest;
