import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import * as surveyActions from '../../../redux/actions/survey.actions';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Toast } from 'primereact/toast';
import { v4 as uuid } from 'uuid';
import { Button as BP } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dialog as DM } from '@material-ui/core';
import SelectIndicator from './SelectIndicator';
import HeaderSurvey from './/HeaderSurvey';
import QuestionsUI from './QuestionsUI';
import { getIndicatorsByIds } from '../../../redux/actions/operativization/indicators.actions';

const QuestionsTab = (props) => {
  const toast = useRef(null);
  const user = useSelector((state) => state.LoginState?.data);
  const role = useSelector((state) => state.RolNavigationState?.state);
  const dispatch = useDispatch();
  const indicator_process = useSelector(
    (state) => state.ProcessState.indicator_process
  );

  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({});
  const [loadingFormData, setLoadingFormData] = useState(true);
  const [toggleValue, setToggleValue] = useState(false);

  const [variables, setVariables] = useState([]);
  const [vac, setVac] = useState(true);
  const [indicators, setIndicators] = useState([]);
  const [indicator, setIndicator] = useState();
  const [error, setError] = useState({ text: '', error: false });
  const [deleteQS, setDeleteQS] = useState(false);

  const [indexIndicator, setIndexIndicator] = useState(0);

  const data_questions = props.formData?.questions;
  const data_survey = props?.formData;

  useEffect(() => {
    if (data_questions !== undefined) {
      if (data_questions.length !== 0) {
        setQuestions(data_questions);
      } else {
        setQuestions((questions) => [
          ...questions,
          {
            id: uuid(),
            position: 1,
            label: '',
            parent_question_id: null,
            parent_option_id: null,
            variable: {},
            question_options: [],
            open: true,
          },
        ]);
      }
      setLoadingFormData(false);
    }

    setFormData(data_survey);
  }, [user, data_survey]);

  const listIndicator = async () => {
    const ids = [];

    indicator_process?.forEach((process) => {
      ids.push(process.id);
    });
    try {
      const response = await dispatch(
        getIndicatorsByIds(user.access_token, ids)
      );

      if (response) {
        const lindicator = response.map((label) => {
          return { id: label.id, label: label.name };
        });
        setIndicators(response);
        setIndicator(lindicator);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    listIndicator();
  }, [toggleValue]);

  const listVariables = (id) => {
    setVariables([]);
    setVac(false);

    const new_indicador = indicators.find((s) => {
      if (s.id === id) {
        return s;
      }
    });

    const new_variable = new_indicador?.variables.map((response) => {
      return response;
    });

    const assignedVariables = questions.map((v) => {
      return { id: v?.variable?.id, label: v?.variable?.label };
    });

    for (let i = 0; i < assignedVariables.length; i++) {
      if (assignedVariables[i].id !== '') {
        let index = new_variable.findIndex(
          (index) => index.id === assignedVariables[i]?.id
        );
        if (index !== -1) {
          new_variable.splice(index, 1);
        }
      }
    }

    setVariables(new_variable);
  };

  const save = async (data) => {
    try {
      surveyActions
        .editSurvey(data, user?.access_token, role)
        .then(() => {
          toast.current.show({
            severity: 'success',
            summary: 'Exito',
            detail: 'Encuesta actualizada!',
            life: 2500,
          });
        })
        .catch((e) => {
          console.log(e);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Error!',
            life: 2500,
          });
        });
    } catch (e) {
      console.log(e);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Error!',
        life: 2500,
      });
    }
  };

  const saveQuestions = () => {
    let data = {
      id: formData.id,
      name: formData.name,
      model_id: formData.model_id,
      activity_id: formData.activity_id,
      description: formData.description,
      process_id: formData.process_id,
      surveyRespondent: formData?.survey_respondent,
      questions: questions,
    };

    save(data);
  };

  const expandCloseAll = () => {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      qs[j].open = false;
    }
    setQuestions(qs);
  };

  const addNewQuestion = () => {
    expandCloseAll();
    setIndexIndicator(questions.length);
    setQuestions((questions) => [
      ...questions,
      {
        id: uuid(),
        position: questions.length + 1,
        label: '',
        parent_question_id: null,
        parent_option_id: null,
        variable: {},
        question_options: [],
        open: true,
      },
    ]);
  };

  const headerDialog = () => {
    return (
      <>
        <span className="p-d-block p-text-center">Alerta!</span>
      </>
    );
  };

  const confirmationDialogFooter = () => (
    <>
      <BP
        className="p-button-text p-button-primary"
        type="button"
        label="Eliminar"
        onClick={() => console.log('hola00')}
      />

      <BP
        className="p-button-text p-button-danger"
        type="button"
        label="Cancelar"
        onClick={() => setDeleteQS(false)}
      />
    </>
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let itemgg = [...questions];
    const itemF = reorder(
      itemgg,
      result.source.index,
      result.destination.index
    );
    setQuestions(itemF);
    saveQuestions();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <div
      style={{ marginTop: '15px', marginBottom: '7px', paddingBottom: '30px' }}
    >
      <Toast ref={toast} />
      <Grid
        container
        direction="column"
        style={{ justifyContent: 'center' }}
        alignItems="center"
      >
        {loadingFormData ? <CircularProgress /> : ''}
        <Grid item xs={12} sm={7} style={{ width: '100%' }}>
          <Grid style={{ borderTop: '10px solid teal', borderRadius: 10 }}>
            <div>
              <div>
                <Paper elevation={2} style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginLeft: '15px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontFamily: 'sans-serif Roboto',
                        marginBottom: '15px',
                      }}
                    >
                      {formData?.name}
                    </Typography>
                    <Typography className="p-ml-3" variant="h5">
                      {formData?.description}
                    </Typography>
                  </div>
                  <HeaderSurvey
                    formData={formData}
                    setFormData={setFormData}
                    saveQuestions={saveQuestions}
                  />
                </Paper>
              </div>
            </div>
          </Grid>
          <Grid style={{ paddingTop: '10px' }}>
            <div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {
                        <QuestionsUI
                          questions={questions}
                          setQuestions={setQuestions}
                          setIndexIndicator={setIndexIndicator}
                          setToggleValue={setToggleValue}
                          toggleValue={toggleValue}
                          error={error}
                          setError={setError}
                          listVariables={listVariables}
                          saveQuestions={saveQuestions}
                        />
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="contained"
                  onClick={addNewQuestion}
                  icon={'pi pi-more'}
                  style={{ margin: '5px' }}
                >
                  Agregar pregunta{' '}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Dialog
          header={headerDialog}
          visible={deleteQS}
          onHide={() => {
            setDeleteQS(false);
          }}
          style={{ width: '20%' }}
          modal
          footer={confirmationDialogFooter()}
        >
          <div className="p-text-center">
            <p>La pregunta que desea eliminar contiene sub preguntas</p>
            <h6>¿Desea continuar?</h6>
          </div>
        </Dialog>
      </div>
      <div>
        <DM
          open={toggleValue}
          onClose={() => {
            setToggleValue(false);
          }}
          style={{ width: '350px' }}
        >
          <DialogTitle id="indicator-dialog-title">Indicadores</DialogTitle>
          <DialogContent>
            <div className="p-ml-4">
              <SelectIndicator
                index={indexIndicator}
                questions={questions}
                setQuestions={setQuestions}
                listVariables={listVariables}
                indicator={indicator}
                vac={vac}
                variables={variables}
              />
            </div>
            <DialogActions>
              <BP
                className="p-button-text p-button-primary"
                type="button"
                label="Cerrar"
                onClick={() => {
                  setToggleValue(false);
                }}
              />
            </DialogActions>
          </DialogContent>
        </DM>
      </div>
    </div>
  );
};

export default QuestionsTab;
