import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import inputNumberRender from '../../../../../hooks/renders/inputNumberRender';
import useForm from '../../../../../hooks/useForm';
import UnitTimeTypesDropdown from '../../../../components/UI/UnitTimeTypesDropdown';
import { Fieldset } from 'primereact/fieldset';
import { Slider } from 'primereact/slider';
import { useSelector } from 'react-redux';
import { toCapitalize } from '../../../utils/helpers/functions';
import { Button } from 'primereact/button';
import { updateActivity } from '../../../../../redux/actions/activity.actions';
import { FINALISEDS_STATUS, RUNNING_STATUS, UNINITIATEDS_STATUS } from '../../../utils/constants/monitoringConstants';

const Settings = ({ toast, activity, setActivity, process }) => {

    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ unitTimeType, setUnitTimeType ] = useState({});
    const { values, setValues, handleInputChange } = useForm({});

    useEffect(() => {
        setValues({
            progress: activity?.progress,
            duration: activity?.duration,
            budget: activity?.budget,
        });

        // Verificar si tiene una unidad de tiempo
        if (activity?.unit_time_type && activity?.unit_time_type?.label) {
            setUnitTimeType({
                ...activity?.unit_time_type,
                label: toCapitalize(activity?.unit_time_type?.label),
            });
        } else {
            setUnitTimeType({});
        }
    }, [ activity, setValues ])

    const handleSave = () => {

        const body = {
            progress: values?.progress,
            duration: values?.duration,
            budget: values?.budget,
            unit_time_type_id: unitTimeType?.id,
        }

        if (body?.progress === 0) {
            body['assignment_status'] = UNINITIATEDS_STATUS;
        } else if (body?.progress === 100) {
            body['assignment_status'] = FINALISEDS_STATUS;
        } else {
            body['assignment_status'] = RUNNING_STATUS;
        }

        updateActivity(activity?.id, body, user?.access_token, role).then((data) => {
            setActivity(data);
            toast.current.show({ severity: 'success', summary: 'Actividad actualziada', detail: data?.name, life: 2000 });
        }).catch(err => {
            toast.current.show({ severity: 'error', summary: 'Actividad no actualizado', detail: 'Error inesperado', life: 2000 });
        });
    }

    return (
        <div>
            <Fieldset legend='Configuración' toggleable collapsed={ false } >
                <div className='p-fluid'>
                    <div className="p-field">
                        <label htmlFor="duration" style={{ fontSize: '.9rem' }}>Presupuesto</label>
                        { 
                            inputNumberRender({ values, handleInputChange, component: {
                                attributes: {
                                    id: 'budget',
                                    name: 'budget',
                                    required: true,
                                    placeholder: "Write budget",
                                    // class: classNames({ 'p-invalid': errors?.duration?.status === true }),
                                    showbuttons: true,
                                    min: 0,
                                    autocomplete: 'off',
                                    step: 100,
                                    suffix: '$',
                                }
                            }})
                        }
                    </div>
                    <div className="p-field">
                        <label htmlFor="duration" style={{ fontSize: '.9rem' }}>Duration (Nro. { unitTimeType?.label })</label>
                        <div className="p-inputgroup">
                            { 
                                inputNumberRender({ values, handleInputChange, component: {
                                    attributes: {
                                        id: 'duration',
                                        name: 'duration',
                                        required: true,
                                        placeholder: "Write activity duration",
                                        // class: classNames({ 'p-invalid': errors?.duration?.status === true }),
                                        showbuttons: false,
                                        min: 0,
                                        autocomplete: 'off',
                                        style: {
                                            borderTopRightRadius: '0px',
                                            borderEndEndRadius: '0px',
                                        },
                                    }
                                }})
                            }
                            <UnitTimeTypesDropdown
                                unitTimeType={ unitTimeType }
                                setUnitTimeType={ setUnitTimeType } />
                        </div>
                    </div>
                    <div className="p-field">
                        <label style={{ fontSize: '.9rem' }} >
                            Progreso:
                            <span className='p-ml-1 p-text-bold' style={{ fontSize: '1rem' }}>{ values?.progress }</span>
                            %
                        </label>

                        <Slider
                            disabled={(activity?.assignment_status === 0) ? true:false}
                            style={{ height: '10px' }} name='progress'
                            value={ values?.progress } 
                            onChange={ (e) => setValues({ ...values, progress: e?.value }) }
                            step={ 10 } />
                    </div>

                    <Button
                        className="p-button p-button-info"
                        label="Save"
                        onClick={ handleSave } />
                </div>
            </Fieldset>
        </div>
    )
}

Settings.propTypes = {
    activity: PropTypes.object.isRequired,
}

export default Settings