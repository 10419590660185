import { useState, useEffect, useRef } from "react";
import "../../assets/scss/formio.scss";
import { SideMenu } from "./components/UI";
import { SystemicHistoryComponentsDto } from "./types/dtos";
import { emptySystemicHistoryComponents } from "./constants/SystemicHistoryComponentsNames";
import { ToastMessageType } from "./types/UI/ToastMessageType";
import SystemicHistory from "./components/SystemicHistory";

const ProblemSolver = () => {
  const [tabSelected, setTabSelected] = useState(0);
  const [error, setError] = useState<Error>();
  const toast = useRef<any>();
  const [systemicHistoryComponents, setSystemicHistoryComponents] =
    useState<SystemicHistoryComponentsDto>(emptySystemicHistoryComponents);

  const tabChangeHandler = (tab: number) => {
    setTabSelected(tab);
  };

  const showMessage = (toastMessage: ToastMessageType) => {
    toast?.current?.show(toastMessage);
  };

  useEffect(() => {
    if (error)
      showMessage({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 3000,
      });
  }, [error]);

  const onInputChange = (e: any, attribute: string) => {
    let _systemicHistoryComponents = { ...systemicHistoryComponents };
    _systemicHistoryComponents[attribute] = e.htmlValue || "";
    setSystemicHistoryComponents(_systemicHistoryComponents);
  };

  return (
    <div className="p-mt-1 " style={{ display: "flex", flexDirection: "row" }}>
      <SideMenu tabSelected={tabChangeHandler} />

      <div className="card" style={{ gridArea: "auto", width: "85%" }}>
        {tabSelected === 0 ? (
          //   <SubsystemsComponents
          //     setNodes={setNodes}
          //     reloadGraph={setReload}
          //   />
          <SystemicHistory
            data={systemicHistoryComponents}
            onInputChange={onInputChange}
          />
        ) : tabSelected === 1 ? (
          //   <Structure setEdges={setEdges} reloadGraph={setReload} />
          <></>
        ) : tabSelected === 2 ? (
          //   <Mechanisms />
          <></>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProblemSolver;
