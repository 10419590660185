import React from "react";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

import classNames from "classnames";

const CreateObjectDialog = (props) => {
  const dialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          props.setCreateDialog(false);
          props.setSubmitted(false);
        }}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={props.createTargetObject}
      />
    </>
  );

  return (
    <>
      <Dialog
        visible={props.createDialog}
        style={{ width: "450px" }}
        header={props.title}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={() => {
          props.setCreateDialog(false);
        }}
      >
        <h6>{props.subtitle}</h6>
        <div className="p-field">
          <label htmlFor="name">Nombre</label>
          <InputText
            id="name"
            value={props.targetObject?.name}
            onChange={(e) => props.onInputChange(e, "name")}
            required
            autoFocus
            className={classNames({
              "p-invalid": props.submitted && !props.targetObject?.name,
            })}
          />
          {props.submitted && !props.targetObject?.name && (
            <small className="p-invalid">Se quiere un nombre.</small>
          )}
        </div>

        <div className="p-field">
          <label htmlFor="definition">Definición</label>
          <InputTextarea
            id="definition"
            value={props.targetObject?.definition}
            onChange={(e) => props.onInputChange(e, "definition")}
            required
            className={classNames({
              "p-invalid": props.submitted && !props.targetObject?.definition,
            })}
            rows={6}
          />
          {props.submitted && !props.targetObject?.definition && (
            <small className="p-invalid">Se requiere de una definición.</small>
          )}
        </div>

        <div className="p-field">
          <label htmlFor="ref_id">Id de referencia</label>
          <InputText
            id="ref_id"
            value={props.targetObject?.ref_id}
            onChange={(e) => props.onInputChange(e, "ref_id")}
            required
            className={classNames({
              "p-invalid": props.submitted && !props.targetObject?.ref_id,
            })}
          />
          {props.submitted && !props.targetObject?.ref_id && (
            <small className="p-invalid">Se quiere un id de referencia.</small>
          )}
          <p>
            <small><strong>El id de referencia afecta la sincronización mediante archivos</strong></small>
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default CreateObjectDialog;
