import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../assets/img/icon-logo.png';
import PlanInfo from './PlanInfo';
import SubscriptionPaymentMethod from '../../../components/Payments/SubscriptionPaymentMethod';
import { Divider } from 'primereact/divider';
import { Messages } from 'primereact/messages';
import { useHistory, useLocation } from 'react-router-dom';
import { findPlanById } from '../../../../api/data';
import { changeLayoutCSS, changeThemeCSS } from '../../utils/helpers/changeStyleSheetsColor';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const PaymentMethod = props => {

    const { t: tglobal } = useTranslation(['global']);
    const { t: tPayment } = useTranslation(['payment-method']);
    
    const [ windowSize, setWindowSize ] = useState(window.innerWidth);
    const [ plan ] = useState( findPlanById(2) )
    const user = useSelector((state) => state.LoginState.data);
    const location = useLocation();
    const history = useHistory();
    const messages = useRef();

    const message = location.state?.message || 'Inserte un métodod de pago';
        
    const product = {
        name: 'Pro',
        code: '2',
        type: 'plan',
        price: '1',
    }

    const handleResize = () => {
        setWindowSize(window.innerWidth);
    }
    
    useEffect(() => {

        changeLayoutCSS('dark');
        changeThemeCSS('dark');
        messages.current.show({ severity: 'info', content: message, life: 10000 });
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <div className="p-grid p-dir-col" style={{ padding: '0px', margin: '0px' }}>

            {/* Logo and title of register page */}
            <div className="p-col p-d-flex" style={{ justifyContent: 'center', alignItems: 'center', }}>
                <button className="logo p-link p-my-2">
                    <img src={ logo } style={{ width: '5rem', height: 'auto', }}  alt="gearth-logo" />
                </button>
                <h1 className="p-d-inline-block p-ml-3">
                    <a>{ tglobal('titles.payment-method') }</a>
                </h1>
            </div>

            <div className='p-px-6'>
                <Messages ref={ messages } />
            </div>

            <div className={`p-m-3 ${(windowSize > 750) && 'p-grid'}`}>
                <div className='p-col'>
                    <h3 className='p-text-center'>Plan Information</h3>
                    <div className='p-pt-4'>
                        <p className='p-text-center'>
                            <span className='p-text-bold'>{ tglobal('username') }: </span>
                            <i>{ user?.username }</i>
                        </p>
                        <PlanInfo plan={ plan } />
                    </div>
                </div>
                <div className={`py-divider-container bg-dark ${(windowSize > 750) ? 'p-col-1':'p-col'}`} >
                    <Divider layout={(windowSize < 750) ? 'horizontal':'vertical' } style={{ justifyContent: 'center !important' }} >
                        <span style={{ fontSize: '3rem' }}>50$</span>
                        <span>/MO</span>
                    </Divider>
                </div>
                <div className='p-col'>
                    <h3 className='p-text-center'>Billing Information</h3>
                    <SubscriptionPaymentMethod product={ product } cus />
                </div>
            </div>
        </div>
    )
}

PaymentMethod.propTypes = {

}

export default PaymentMethod;