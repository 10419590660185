import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextEditor } from '../../../components/UI';
import { updateProcess } from "../../../../redux/actions/process.actions";

const TheoryProcess = ({ showMessage, values, handleInputChange }) => {

    const process = useSelector((state) => state.ProcessState?.process);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ hasChanged, setHasChanged ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);

    useEffect(() => {
        if (values?.theory_process !== process?.theory_process) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [ values?.theory_process, process?.theory_process ]);

    const handleSave = async (attribute) => {
        setSubmitted(true);
        if (hasChanged) {

            const data = { theory_process: values?.theory_process }

            updateProcess(process?.id, data, user?.access_token, role).then(data => {
                setHasChanged(false);
                showMessage("success", "Teoría actualizado", "");
            });
        }
        setSubmitted(false);
    };

    return (
        <div>
            <TextEditor 
                title="Teoría" 
                subtitle="Ingrese el texto referente a la teoría del proceso"
                messageError="Error al almacenar la teoría"
                value={ values?.theory_process }
                submitted={ submitted }
                onSave={ handleSave }
                actionButtonDisabled={ !hasChanged }
                onInputChange={(e) => handleInputChange({ target: { name: 'theory_process', value: e?.htmlValue } })}
                helpInformation='Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocarn en la teoría.' />
        </div>
    );    
}

export default TheoryProcess;