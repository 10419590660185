import { getTableStatusById, tableStatusDefault } from '../../utils/constants/uiConstants';

/* 
 * GENERICS TABLE TEMPLATES *********************************************************************************************************************
 */

/**
 * 
 * @param {object} data -> Reference to object
 * @param {object} props -> default props of column table, the field is used to show
 * @returns jsx template
 */
export const bodyTemplate = (data, props) => {
    return (
        <>
            {/* This title use header colum prop to render */}
            <span className="p-column-title">{props.header}</span>
            {data[props.field]}
        </>
    );
};


//  H TAG TEMPLATES
export const h2bodyTemplate = (data, props) => {
    return (
        <>
            <span className="p-column-title">{props.header}</span>
            <h2>{data[props.field]}</h2>
        </>
    );
};

export const h3bodyTemplate = (data, props) => {
    return (
        <>
            <span className="p-column-title">{props.header}</span>
            <h3>{data[props.field]}</h3>
        </>
    );
};

export const h4bodyTemplate = (data, props) => {
    return (
        <>
            <span className="p-column-title">{props.header}</span>
            <h4>{data[props.field]}</h4>
        </>
    );
};

export const h5bodyTemplate = (data, props) => {
    return (
        <>
            <span className="p-column-title">{props.header}</span>
            <h5>{data[props.field]}</h5>
        </>
    );
};

export const htmlValueTemplate = (data, props, maxLength=100) => {
    try {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {(data[props.field].length > maxLength)
                    ? <div dangerouslySetInnerHTML={{ __html: data[props.field].slice(0, maxLength) + "..." }} />
                    : <div dangerouslySetInnerHTML={{ __html: data[props.field] }} />
                }
            </>
        );
    } catch (error) {
        return (<div>-</div>)
    }
};

/*
 * CUSTOM TABLE TEMPLATES *********************************************************************************************************************************
 */

export const genderBodyTemplate = (rowData) => {
    return (
        <>
            {rowData?.gender === 'M' ? (
                <>
                    <i>♂</i>
                    <span>&nbsp;{rowData.gender}</span>
                </>
            ):(
                <>
                    <i>♀</i>
                    <span>&nbsp;{rowData.gender}</span>
                </>
            )}
        </>
    )
};

export const descriptionBodyTemplate = (data, props, maxLength=100) => {
    return (
        <>
            <span className="p-column-title">{props.header || 'Descripción'}</span>
            <p>
                {(data?.description?.length > maxLength)
                    ? data?.description?.slice(0, maxLength) + "..."
                    : data?.description
                }
            </p>
        </>
    );
};

export const imageBodyTemplate = (data, props) => {
    try {
        return (
            <>
                <span className="p-column-title">Imagen</span>
                <img
                    src={ data[props.field] } 
                    alt={ `_blank` } 
                    className="product-image" />
            </>
        );
    } catch (error) {
        return <div></div>;
    }
}

export const logoBodyTemplate = (data, props) => {
    return (
        <>
            <span className="p-column-title">Logo</span>
            <img src={ data[props.field] } alt={ `_blank` } className="product-image" />
        </>
    );
}

export const idBodyTemplate = (rowData) => {
    return (
        <>
            <span className="p-column-title">ID</span>
            { rowData?.id }
        </>
    );
}

export const identificationBodyTemplate = (rowData) => {
    return (
        <>
            <span className="p-column-title">Identificación</span>
            { rowData?.identification }
        </>
    );
}

/* NAMES TEMPLATES *************************************************************************/

export const nameBodyTemplate = (rowData) => {
    return (
        <>
            <span className="p-column-title">Nombre</span>
            { rowData?.name }
        </>
    );
}

export const namesBodyTemplate = (rowData) => {
    return (
        <>
            <span className="p-column-title">Nombres</span>
            { rowData?.names }
        </>
    );
}

export const lastNamesBodyTemplate = (rowData) => {
    return (
        <>
            <span className="p-column-title">Apellidos</span>
            { rowData?.last_names }
        </>
    );
}

export const fullNamePersonBodyTemplate = (data, props) => {
    return (
        <>
            <span className="p-column-title">Nombre</span>
            <i style={{ fontSize: '.99rem' }}>
                {`${data[props.field]?.names} ${data[props.field]?.last_names}`}
            </i>
        </>
    );
}

/**
 * @param {object} data -> object with status attribute (int*)
 * @param {object} customStatus -> status options list
 * @returns jsx template
 */
export const statusBodyTemplate = (data, tableData={}, customStatus=tableStatusDefault) => {

    // Get status body
    const status = getTableStatusById(data?.status, customStatus);

    return (
        <div>
            <span className="p-column-title">Estado</span>
            <span className={ 'product-badge ' + status?.class }>
                { status?.label }
            </span>
        </div>
    );
}

export const planNameBodyTemplate = (rowData) => {

    return (
        <>
            <span className="p-column-title">Plan</span>
            { rowData?.plan?.name }
        </>
    );
}

export const emailBodyTemplate = (data) => {
    return (
        <>
            <span className="p-column-title">Plan</span>
            <i>
                { data?.email }
            </i>
        </>
    );
}