import {combineReducers} from 'redux';
import {LoginReducer as LoginState} from './reducer/user.reducer';
import {PlanReducer as PlanState} from './reducer/plan.reducer';
import {RepositoryReducer as RepositoryState} from './reducer/repository.reducer';
import {SocialNetworkReducer as SocialNetworkState} from './reducer/social-network.reducer'
import {ModelReducer as ModelState} from './reducer/model.reducer'
import {RolNavigationReducer as RolNavigationState} from './reducer/rol-navigation.reducer'
import {AppUsersSocialNetworkReducer as AppUsersSocialNetworkState} from './reducer/app-users-social-network.reducer'
import {ToolboxReducer as ToolboxState} from './reducer/toolbox.reducer';
import {KnowledgeReducer as KnowledgeState} from './reducer/knowledge.reducer';
import {SystemsReducer as SystemsState} from './reducer/complex-systems.reducer';
import {SurveyReducer as SurveyState} from './reducer/survey.reducer';
import {CategoriesReducer as CategoriesState} from './reducer/categories.reducer';
import {OperativizationReducer as OperativationState} from './reducer/operativization.reducer'
import {TeamReducer as TeamState} from './reducer/team.reducer';
import { ProcessReducer as ProcessState } from './reducer/process.reducer';
import { UserPreferencesReducer as UserPreferencesState } from './reducer/user-preferences.reducer';
import ReducerModel from "./reducer/graph-modelizer.reducer";

export default combineReducers ({
    LoginState,
    ReducerModel,
    PlanState,
    RepositoryState,
    ModelState,
    SocialNetworkState,
    RolNavigationState,
    AppUsersSocialNetworkState,
    ToolboxState,
    KnowledgeState,
    SystemsState,
    SurveyState,
    CategoriesState,
    TeamState,
    OperativationState,
    ProcessState,
    UserPreferencesState,
});
