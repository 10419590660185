import React from 'react';
import { useSelector } from 'react-redux';
import { updateAllTool } from '../../../../../../redux/actions/toolbox.action';
import { TextEditor } from '../../../../../components/UI';

const ToolResults = ({ values, handleInputChange, showMessage }) => {

    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const handleSubmit = (e) => {

        e.preventDefault();

        const body = {
            changes: values?.changes,
        }

        updateAllTool(body, values?.id, user?.access_token, role).then(data => {
            showMessage('success', 'Cambios y Resultados Actualizado', '');
        });
    }

    const customhandleInputChange = (e, name) => {
        handleInputChange({ target: { name: name, value: e?.htmlValue } });
    }

    return (
        <div>
            <div className="p-fluid card">
                <form onSubmit={ handleSubmit }>
                    <div className="p-field">
                        <TextEditor
                            onInputChange={ customhandleInputChange }
                            name='changes'
                            value={ values?.changes }
                            title="Cambios - Resultados"
                            subtitle="Describa la los cambios y resultados"
                            submitted={ false }
                            messageError="Cambios y resultados requerido"
                            onSave={ () => { return; } }
                            actionButtonDisabled={ false }
                            helpInformation=""
                            btnSaveProps={{ type: 'submit' }} />
                    </div>
                </form>
            </div> 
        </div>
    )
}

export default ToolResults;