import { createAction } from 'redux-actions';
import api from "../../api";
import * as urls from  '../../api/urls';
import { uploadImages } from './generics.action';

export const setLoadingSystemsAction = createAction('SET_LOADING_SYSTEMS_ACTION');
export const setSystemsDataAction = createAction('SET_SYSTEMS_DATA_ACTION');
export const setSystemsErrorAction = createAction('SET_SYSTEMS_ERROR_ACTION');
export const setResetSystemsAction = createAction('SET_RESET_SYSTEMS_ACTION');


export const setLoadingSubsystemsLevelAction = createAction('SET_LOADING_SUBSYSTEMS_LEVEL_ACTION');
export const setSubsystemsLevelDataAction = createAction('SET_SUBSYSTEMS_LEVEL_DATA_ACTION');
export const setSubsystemsLevelErrorAction = createAction('SET_SUBSYSTEMS_LEVEL_ERROR_ACTION');
export const setResetSubsystemsLevelAction = createAction('SET_RESET_SUBSYSTEMS_LEVEL_ACTION');


export const setLoadingCreateSystemAction = createAction('SET_LOADING_CREATE_SYSTEM_ACTION');
export const setSystemCreateDataAction = createAction('SET_SYSTEM_CREATE_DATA_ACTION');
export const setSystemCreateErrorAction = createAction('SET_SYSTEM_CREATE_ERROR_ACTION');
export const setResetCreateSystemAction = createAction('SET_RESET_CREATE_SYSTEM_ACTION');


export const getSystems = (history) => async (dispatch,getState) =>{


    dispatch(setResetSystemsAction());
    dispatch(setLoadingSystemsAction(true));

    try{
        const token = getState()?.LoginState?.data?.access_token;
        const {data, status} = await api.get(urls.urlListSystems, {
            headers: {
              Authorization: 'Bearer ' + token  
            }
           });


        if(status === 200){
            dispatch(setSystemsDataAction(data));
            dispatch(
                setSystemsErrorAction({
                    message:null
                })
            );
        }
        else if(status === 403){
            console.log("FORBIDEEEN");
            history.push('/access');
        }
       

    }
    catch(error){
        console.log(error);
        dispatch(
            setSystemsErrorAction({
                message:"Error al recuperar información"
            })
        );

    }

    dispatch(setLoadingSystemsAction(false));
};


export const getSystemsByLevel = (history, level) => async (dispatch,getState) =>{


    dispatch(setResetSubsystemsLevelAction());
    dispatch(setLoadingSubsystemsLevelAction(true));

    try{
        const token = getState()?.LoginState?.data?.access_token;
        const {data, status} = await api.get(`${urls.urlListSystemsByLevel}/${level}`, {
            headers: {
              Authorization: 'Bearer ' + token  
            }
           });


        if(status === 200){
            dispatch(setSubsystemsLevelDataAction(data));
            dispatch(
                setSubsystemsLevelErrorAction({
                    message:null
                })
            );
        }
        else if(status === 403){
            console.log("FORBIDEEEN");
            history.push('/access');
        }
       

    }
    catch(error){
        console.log(error);
        dispatch(
            setSubsystemsLevelErrorAction({
                message:"Error al recuperar información"
            })
        );

    }

    dispatch(setLoadingSubsystemsLevelAction(false));
};


export const createSystem = async (dataSystem,token) => {
    
    let dataCreate = {
        name: dataSystem?.name,
        description: dataSystem?.description,
        position: dataSystem?.position,
        level: dataSystem?.level,
        subsystem_parent_id: dataSystem?.subsystem_parent_id ? dataSystem?.subsystem_parent_id:undefined
      }
    const response = await api.post(urls.urlCreateSystem,dataCreate, {
        headers: {
          Authorization: 'Bearer ' + token  
        }
       });
  
    return response.data;
  };

/*
export const createSystem = (dataSystem) =>async (dispatch,getState)=>{

    const token = getState()?.LoginState?.data?.access_token;
    let dataCreate = {
        name: dataSystem?.name,
        description: dataSystem?.description,
        position: dataSystem?.position,
        level: dataSystem?.level,
        subsystem_parent_id: dataSystem?.subsystem_parent_id
      }

    dispatch(setResetCreateSystemAction());
    dispatch(setLoadingCreateSystemAction(false));
    try{

        const {data, status} = await api.post(urls.urlCreateSystem,dataCreate, {
            headers: {
              Authorization: 'Bearer ' + token  
            }
           });

        if(status === 200){
            dispatch(setSystemsDataAction(data));
            dispatch(
                setSystemCreateErrorAction({
                    message:null
                })
            );
        }

    }
    catch(error){
        dispatch(
            setSystemCreateErrorAction({
                message:"Error al recuperar información"
            })
        );

    }

    dispatch(setLoadingCreateSystemAction(false));

};
*/



export const updateSystem = async (dataSystem,token) => {
    
    let dataCreate = {
        name: dataSystem?.name,
        description: dataSystem?.description,
        position: dataSystem?.position,
        level: dataSystem?.level,
        subsystem_parent_id: dataSystem?.subsystem_parent_id ? dataSystem?.subsystem_parent_id:undefined
      }
    const url = `${urls.urlUpdateSystem}/${dataSystem.id}`;
    const response = await api.put(url,dataCreate, {
        headers: {
          Authorization: 'Bearer ' + token  
        }
       });
  
    return response.data;
  };

/*
export const updateSystem = (data) =>async (dispatch,getState)=>{

    const token = getState()?.LoginState?.data?.access_token;
    let dataCreate = {
        name: data?.name,
        description: data?.description,
        position: data?.position,
        level: data?.level,
        subsystem_parent_id: data?.subsystem_parent_id
      }
    const url = `${urls.urlUpdateSystem}/${data.id}`;
    console.log("ID UPDATE ",url);

    dispatch(setResetCreateSystemAction());
    dispatch(setLoadingCreateSystemAction(false));
    try{

        const {data, status} = await api.put(url,dataCreate, {
            headers: {
              Authorization: 'Bearer ' + token  
            }
           });

        if(status === 200){
            dispatch(setSystemsDataAction(data));
            dispatch(
                setSystemCreateErrorAction({
                    message:null
                })
            );
        }

    }
    catch(error){
        console.log(error);
        dispatch(
            setSystemCreateErrorAction({
                message:"Error al recuperar información"
            })
        );

    }

    dispatch(setLoadingCreateSystemAction(false));

};
*/

/*
export const cleanTool = () =>async (dispatch,getState)=>{


    dispatch(setResetCurrentToolAction());
    dispatch(setLoadingCurrentToolAction(false));
    dispatch(setCurrentToolDataAction({}));
    dispatch(
        setCurrentToolErrorAction(null)
    );

};*/