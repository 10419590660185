import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Latex from "react-latex";

import "./styles.css";

export default function CalculationMethod() {
  const [expression, setExpression] = useState(" ");

  const save = () =>{}

  return (
<>
    <div className="p-grid">
            

            <div className="p-col-12">
                <div className="card">
                    <h3>Método de cálculo</h3>
                    <div className="p-fluid p-formgrid p-grid">
                    <div className="p-field p-col-12 p-md-12">
                    </div>

                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="expression">Ingrese la expresión</label>
                            <InputText id="expression" value={expression} onChange={(e)=>{setExpression(e.target.value)}}/>
                        </div>  
                        <div className="p-field p-col-12 p-md-12">
                            <label htmlFor="expression">Vista previa</label>
                            <br></br>
                            <center><h3>
                            <Latex displayMode={true}>{`$ ${expression} $`}</Latex> 
                            </h3></center>
                        </div> 
                       

                      
                <Button label= "Guardar" className="p-button-rounded p-mr-2 p-mb-2" onClick={save}/>
                </div> 

            </div>
            </div>
        </div>



    
    </>
  );
}

const MathInput = ({ onChange, ...rest }) => {
  const ref = useRef();
  useEffect(() => {
    //Guppy.use_osk(new GuppyOSK({ goto_tab: "arithmetic", attach: "focus" }));
    //const guppy = new Guppy(ref.current);
    //guppy.event("change", onChange);
  }, []);

  return <div ref={ref} {...rest} />;
};
