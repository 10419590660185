import React, { useEffect, useState } from 'react';
import Modeler from 'bpmn-js/lib/Viewer';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import {
  importXML,
  _downloadPNG,
  _downloadSVG,
  _downloadXML,
} from '../../../utils/helpers/bpmnHelpers';
import { BLANK_XML, eventsBpmn, PIZZA_EXAMPLE } from '../utils/variables';
import { getXMLProcessById } from '../../../../../redux/actions/process.actions';
import { useSelector } from 'react-redux';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import '../../../../assets/scss/BPMN-JS.scss';

const ViewerBPMN = () => {

  const history = useHistory();
  const process = useSelector((state) => state.ProcessState.process);
  const user = useSelector((state) => state.LoginState.data);
  const role = useSelector((state) => state.RolNavigationState?.state);

  const [diagramXML, setDiagramXML] = useState('');
  const [diagramSVG, setDiagramSVG] = useState('');

  const menuItems = [
    {
      label: 'Image (SVG)',
      icon: 'pi pi-image',
      command: function (e) {
        _downloadSVG(diagramSVG);
      },
    },
    {
      label: 'Image (PNG)',
      icon: 'pi pi-image',
      command: function () {
        _downloadPNG(diagramSVG);
      },
    },
    {
      separator: true,
    },
    {
      label: 'File (XML)',
      icon: 'pi pi-file',
      command: function () {
        _downloadXML(diagramXML);
      },
    },
    {
      label: 'File (PDF)',
      icon: 'pi pi-file',
      disabled: true,
      command: function () {
        console.log('PDF');
      },
    },
  ];

  useEffect(() => {
    const container = document.getElementById('container');
    container.innerHTML = '';

    const modelerValues = {
      container,
      keyboard: {
        bindTo: document,
      },
    };

    const modeler = new Modeler({ ...modelerValues });
    const eventBus = modeler.get('eventBus');
    const overlays = modeler.get('overlays');
    const canvas = modeler.get('canvas');

    getXMLProcessById(process?.id, user?.access_token, role).then((data) => {
      if (data?.xml_body) {
        importXML(setDiagramXML, setDiagramSVG, modeler, data?.xml_body); //Import xml for data base of activity
      } else {
        importXML(setDiagramXML, setDiagramSVG, modeler, BLANK_XML); //Import blank xml if activity haven't xml created
      }

      eventsBpmn.forEach(function (event) {
        eventBus.on(event, function (e) {
          if (event === 'shape.added') {
            const element = e?.element;

            if (element?.type === 'bpmn:Task') {
              // canvas.addMarker(element?.id, 'task-completed');
              // overlays.add(element?.id, {
              //     position: { top:0, right:0 },
              //     html: `<div class='p-button p-button-rounded'>${ 1 }</div>`
              // });
            }
          }
        });
      });
    });

    return () => {
      eventsBpmn.forEach(function (event) {
        eventBus.off(event); //Clean all events of bpmn modeler of events list
      });
    };
  }, [user, process]);

  const optionsTemplate = () => {
    const leftTemplate = (
      <>
        <SplitButton
          onClick={(e) => _downloadXML(diagramXML)}
          label="Download"
          icon="pi pi-download"
          model={menuItems}
          className="p-button-info"
        ></SplitButton>
      </>
    );

    const rightTemplate = (
      <>
        <Button
          style={{ width: '93px' }}
          className="p-button-danger p-mx-1"
          label="Atrás"
          icon="pi pi-arrow-left"
          onClick={() => history.goBack()}
        />
      </>
    );

    return (
      <div
        className="card p-mb-3 p-pb-2 p-shadow-3"
        style={{ borderRadius: '5px', border: '1px #ccc solid' }}
      >
        <div className="p-grid">
          <div className="p-col" style={{ textAlign: 'left' }}>
            {leftTemplate}
          </div>
          <div className="p-col" style={{ textAlign: 'right' }}>
            {rightTemplate}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <h2 style={{ textAlign: 'center' }}>{process?.name}</h2>

      {optionsTemplate()}

      <div
        className="p-shadow-10 p-p-2"
        id="container"
        style={{
          height: '95vh',
          background: '#eee',
          borderRadius: '10px',
          color: '#000',
        }}
      ></div>
    </div>
  );
};

export default ViewerBPMN;
