import React from 'react';
import { useSelector } from 'react-redux';
import NroInfoCard from '../../../components/UI/NroInfoCard';

const Processes = props => {

    const { model } = useSelector(state => state.ModelState);

    return (
        <div className='animate__animated animate__zoomIn'>
            <div className="card p-shadow-10">

                <h5 className='p-text-center'>Resumen de los Procesos</h5>

                <div className='p-grid p-mt-3'>
                    <div className="p-col-12">
                        <div className="p-grid" style={{ margin: '-1rem' }}>
                            <NroInfoCard
                                color='white'
                                label='Procesos Finalizados'
                                value={ 3 } />

                            <NroInfoCard
                                color='blue' icon='pi pi-spinner'
                                label='Procesos en Ejecución'
                                value={ 6 } />

                            <NroInfoCard
                                color='gray' icon='pi pi-compass'
                                label='Procesos no Iniciados'
                                value={ 9 } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Processes.propTypes = {}

export default Processes