import {useDispatch,useSelector} from 'react-redux';

import { useHistory } from 'react-router-dom';
import { getAllKnowledge,getKnowledgeById,cleanItem,createKnowledgeAditionalData,createKnowledge } from '../actions/knowledge.action';

export const useKnowledge = () =>{
    const history = useHistory();
    const state = useSelector((state) => state.KnowledgeState);
    const dispatch = useDispatch();

    const listKnowledge = () =>{
        dispatch(getAllKnowledge(history));
    }
    
    const insertKnowledge = (data) =>{
        dispatch(createKnowledge(data));
    }
    /*
    const insertComplementaryTool = (data) =>{
        dispatch(createComplementaryTool(data));
    }
    */
    const getCurrentItem = (item_id) =>{
        dispatch(getKnowledgeById(history,item_id));
    }
    /*
    const getDistinctTool = (tool_id) =>{
        dispatch(getToolsDistinct(history,tool_id));
    }
    */
    const removeItem = () =>{
        dispatch(cleanItem());
    }
    const insertKnowledgeData = (data) =>{
        dispatch(createKnowledgeAditionalData(data));
    }
    


    return {
        ...state,
        /*
        listTools,
        insertTool,
        insertComplementaryTool,
        getCurrentTool,
        getDistinctTool,
        insertToolData,
        removeTool,
        toolboxData: state?.data,
        currentTool:state?.dataCurrent,
        distinctTool:state?.dataDistinct,
        complementaryTool:state?.dataComplementary*/
        listKnowledge,
        getCurrentItem,
        removeItem,
        insertKnowledgeData,
        knowledgeData:state?.data,
        currentKnowledge: state?.dataCurrent,
        loading:state?.loading
    }
}