import 'react-app-polyfill/ie11';
import React, { Suspense }from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import AppWrapper from './app/layouts/AppWrapper';
import { Provider } from 'react-redux';
import store from './redux/store';
import './config/i18next-config';
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<BrowserRouter>
		<Provider store={ store }>
			<Suspense fallback={null}>
				<AppWrapper></AppWrapper>
			</Suspense>
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
