import { Menubar } from 'primereact/menubar';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RolesEnum } from '../../enums/roles';
import {
    ProSidebar,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
    Menu as ProMenu,
    MenuItem,
    SubMenu,
} from 'react-pro-sidebar';
import { PanelMenu } from 'primereact/panelmenu';
import { Button } from 'primereact/button';
import { gearthComponetsTypes as gearth } from '../utils/types/gearthTypes';
import '../../assets/scss/sidebar.scss';
import {
    getTableStatusById,
    tableAcquiredModelStatus,
    tableModelStatus,
} from '../utils/constants/uiConstants';

const Menu = ({ windowSize, keyTab }) => {
    const history = useHistory();
    const location = useLocation();
    const { t: tGlobal } = useTranslation(['global']);
    const { state } = useSelector((state) => state.RolNavigationState);
    const { model } = useSelector((state) => state.ModelState);
    const { ui } = useSelector((state) => state.UserPreferencesState);
    const status = getTableStatusById(
        model?.status,
        state === RolesEnum.Designer ? tableModelStatus : tableAcquiredModelStatus
    );

    const handleKey = (key) => {
        history.push('?tab=' + key, {
            modelId: location.state?.modelId,
        });
    };

    const menuItems = [
        {
            key: gearth.identity,
            label: 'Identidad',
            icon: 'pi pi-globe',
            command: (e) => handleKey(gearth.identity),
        },
        {
            label: 'Modelización',
            icon: 'pi pi-th-large',
            items: [
                {
                    key: gearth.complexSystem,
                    label: tGlobal('titles.complex-system'),
                    icon: 'pi pi-share-alt',
                    command: (e) => handleKey(gearth.complexSystem),
                },
            ],
        },
        {
            label: 'Operativos',
            icon: 'pi pi-fast-forward',
            items: [
                {
                    key: gearth.processes,
                    label: tGlobal('titles.processes'),
                    icon: 'pi pi-list',
                    command: (e) => handleKey(gearth.processes),
                },
                {
                    disabled: state === RolesEnum.Executor ? false : true,
                    label: 'Solucionador de Problemas',
                    icon: 'pi pi-book',
                },
                {
                    disabled: state === RolesEnum.Executor ? false : true,
                    label: 'Monitoreo y Evaluación',
                    icon: 'pi pi-chart-line',
                    command: (e) => history.push('/apps/monitoring-and-evaluation'),
                },
            ],
        },
        {
            label: 'Insumos',
            icon: 'pi pi-tags',
            items: [
                {
                    key: gearth.tools,
                    label: tGlobal('titles.tools'),
                    icon: 'pi pi-inbox',
                    command: (e) => handleKey(gearth.tools),
                },
                {
                    key: gearth.knowledgeBase,
                    label: tGlobal('titles.knowledge-base'),
                    icon: 'pi pi-folder-open',
                    command: (e) => handleKey(gearth.knowledgeBase),
                },
                {
                    key: gearth.indicatorsSystem,
                    label: tGlobal('titles.indicators-system'),
                    icon: 'pi pi-sitemap',
                    command: (e) => handleKey(gearth.indicatorsSystem),
                },
            ],
        },
        {
            label: 'Sustento',
            icon: 'pi pi-file',
            items: [
                {
                    key: gearth.principlesLawsTheory,
                    label: tGlobal('titles.principles-laws-theory'),
                    icon: 'pi pi-book',
                    command: (e) => handleKey(gearth.principlesLawsTheory),
                },
                {
                    key: gearth.keyTopicsTrends,
                    label: tGlobal('titles.key-topics-trends'),
                    icon: 'pi pi-flag',
                    command: (e) => handleKey(gearth.keyTopicsTrends),
                },
            ],
        },
        {
            separator: true,
        },
        {
            key: gearth.appRed,
            label: tGlobal('titles.app-red'),
            icon: 'pi pi-comments',
            command: (e) => handleKey(gearth.appRed),
        },
        {
            key: gearth.appInfo,
            label: tGlobal('titles.app-info'),
            icon: 'pi pi-tablet',
            command: (e) => handleKey(gearth.appInfo),
        },
    ];

    const getIcon = (icon) => <i className={icon}></i>;

    /**
     * This function check if parent items is active
     * @param {object} item -> parent item from submenu items
     * @returns bool -> if submenu is open
     */
    const isOpened = (item) => {
        try {
            const index = item.items.findIndex((subItem) => subItem.key === keyTab);
            if (index === -1) return false;
            else return true;
        } catch (error) {
            return false;
        }
    };

    // Return template
    if (windowSize > 900) {
        return (
            <div className="p-col-2">
                <ProSidebar
                    style={{
                        minHeight: '500px',
                        maxHeight: '700px',
                        color: ui?.color_scheme === 'dark' ? 'white' : 'black',
                        zIndex: 1,
                    }}
                >
                    <SidebarHeader>
                        <h5 className="p-text-center p-mb-1">{model?.name}</h5>
                        <div className="p-text-center p-mb-3">
                            <span className={status?.class}>{status?.label}</span>
                        </div>
                    </SidebarHeader>

                    <SidebarContent>
                        <ProMenu iconShape="round">
                            {menuItems.map((item, index) =>
                                item?.items && item?.items.length > 0 ? (
                                    <SubMenu
                                        key={index}
                                        icon={getIcon(item?.icon)}
                                        title={item?.label}
                                        // if is opened, return open property in true state
                                        {...(isOpened(item) && { open: true })}
                                    >
                                        {item.items.map((subItem, jindex) => (
                                            <MenuItem
                                                key={jindex}
                                                className={
                                                    subItem?.disabled && 'slider-menu-item-disabled'
                                                }
                                                icon={getIcon(subItem?.icon)}
                                                onClick={
                                                    subItem?.disabled ? undefined : subItem?.command
                                                }
                                                active={subItem?.key === keyTab ? true : false}
                                            >
                                                {subItem?.label}
                                            </MenuItem>
                                        ))}
                                    </SubMenu>
                                ) : item?.separator ? (
                                    <hr key={index} />
                                ) : (
                                    <MenuItem
                                        key={index}
                                        icon={getIcon(item?.icon)}
                                        onClick={item?.command}
                                        active={item?.key === keyTab ? true : false}
                                    >
                                        {item?.label}
                                    </MenuItem>
                                )
                            )}
                        </ProMenu>
                    </SidebarContent>

                    <SidebarFooter>
                        <div className="p-text-center p-p-1">
                            <Button
                                className="p-button-text p-button-danger"
                                label="Ir a Consola"
                                icon="pi pi-arrow-left"
                                onClick={() => {
                                    if (state === RolesEnum.Designer) {
                                        history.push({
                                            pathname: '/creators/model-designer',
                                            state: RolesEnum.Designer,
                                        });
                                    } else {
                                        history.push({
                                            pathname: '/executor/models',
                                            state: RolesEnum.Executor,
                                        });
                                    }
                                }}
                            />
                        </div>
                    </SidebarFooter>
                </ProSidebar>
            </div>

            // <PanelMenu model={menuItems} style={{width: '240px'}}></PanelMenu>
        );
    } else {
        return (
            <div className="p-col-12">
                <Menubar model={menuItems} />
            </div>
        );
    }
};

export default Menu;
