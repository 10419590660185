import {useDispatch, useSelector} from 'react-redux';
import {getTeams, createTeam} from '../actions/team.actions';
import {useHistory} from 'react-router-dom';

export const useTeam = () =>{
  const history = useHistory();
  const state = useSelector((state)=> state.TeamState);
  const dispatch = useDispatch();

  const listTeams = () =>{
    dispatch(getTeams(history));
  }
  const insertTeam = (data) =>{
    dispatch(createTeam(data));
  }

  return {
    ...state,
    listTeams,
    insertTeam,
    teamsData: state?.data
  }
}
