export const NAMES_REGEX = /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]{1,1000}$/

// Email format
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const PHONE_REGEX = /^(()?\d{3}())?(-|\s)?\d{3}(-|\s)?\d{4}$/

// Only alphanumeric
export const USERNAME_REGEX = /^[a-zA-Z0-9]+$/;

export const DESC_REGEX = '^(.|\s)*[a-zA-Z]+(.|\s)*$'

/**
 * At least One Upper Case Character
 * At least one Lower Case character
 * At least one digit
 * At least one symbol/special character @$!%*#?&^_-
 * Minimum 4 characters/digits
 * https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
 */
export const PASSWORD_REGEX = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/

// Only alphanumeric universal regex
export const ALPHANUMERIC_REGEX = /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]+)$/