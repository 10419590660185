import React, { useEffect, useRef, useState } from 'react';
import SidebarPricing from './SidebarPricing';
import DetailsTemplate from './DetailsTemplate';
import { Galleria } from 'primereact/galleria';
import { useParams, useHistory } from 'react-router';
import { Rating } from 'primereact/rating';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { isPurchased } from '../../../../redux/actions/payment-method.actions';
import { useSelector } from 'react-redux';
import { retrievePublishedModelById } from '../../../../redux/actions/publisheds/model.actions';
import { acquireModel } from '../../../../redux/actions/acquireds/model.actions';
import { RolesEnum } from '../../../enums/roles';
import { Toast } from 'primereact/toast';

const galleriaResponsiveOptions = [
    {
        breakpoint: "1024px",
        numVisible: 5,
    },
    {
        breakpoint: "960px",
        numVisible: 4,
    },
    {
        breakpoint: "768px",
        numVisible: 3,
    },
];

const PRODUCT_TYPE = 'model';

const ModelDetails = () => {

    const toast = useRef(null);
    const user = useSelector((state) => state.LoginState.data);
    const history = useHistory();
    const { id } = useParams();

    const [ model, setModel ] = useState({});
    const [ sidebarPricing, setSidebarPricing ] = useState(false);
    const [ purchased, setPurchased ] = useState(false);
    const [ images, setImages ] = useState( imagesExamples );
    const [ loaded, setLoaded ] = useState(false);

    if (!model) history.push('/models');

    const product = {
        name: model?.name,
        code: model?.id,
        type: PRODUCT_TYPE,
        price: 10, // In dollars
    }

    useEffect(() => {
        retrievePublishedModelById(id, user?.access_token).then(data => {
            setModel(data);
            setImages([
                {
                    itemImageSrc: data?.image,
                    thumbnailImageSrc: data?.image,
                    alt: data?.description,
                    title: data?.name,
                },
                ...imagesExamples,
            ]);
        });
    }, [ user, id ])

    useEffect(() => {
        if (model?.id) {
            isPurchased(user?.id, model?.id, PRODUCT_TYPE).then(response => {
                if (response?.purchased) {
                    setPurchased(true);
                }
                setLoaded(true);
            })
        }
    }, [ user, model ])

    const showMessage = (severity, summary, detail) => {
        toast?.current?.show({
          severity: severity,
          summary: summary,
          detail: detail,
          life: 3000,
        });
    };

    const handleAdquired = () => {
        if (model?.id && user?.team?.id) {
            acquireModel(model?.id, user?.team?.id, user?.access_token).then((response) => {
                showMessage('success', 'Modelo Adquirido', model?.name);
                history.push({
                    pathname: '/executor/models',
                    state: RolesEnum.Executor,
                });         
            }).catch((err) => {
                showMessage('error', 'Modelo no Adquirido', 'Error inesperado')
            });
        }    
    }

    const galleriaItemTemplate = (item) => (
        <div>
            <img 
                alt={ item?.alt } 
                src={item?.itemImageSrc} 
                style={{ width: '100%', display: 'block' }} />
        </div>
    );

    const galleriaThumbnailTemplate = (item) => (
        <img 
            alt={ item?.alt } 
            src={item?.thumbnailImageSrc} 
            style={{ width: '100%', display: 'block', padding: '5px' }} />
    );

    const getPayButton = () => {

        if (!loaded) {
            return;
        }

        if (!purchased) {
            return (
                <Button
                    style={{ width: '100%', margin: 'auto' }}
                    label={`BUY NOW`}
                    className='p-button-info p-my-3'
                    onClick={() => setSidebarPricing(true) } />
            );
        } else {
            return (
                <Button
                    disabled={ true }
                    style={{ width: '100%', margin: 'auto' }}
                    label={`Purchased!`}
                    className='p-button-success p-my-3' />
            );
        }
    }

    return (
        <>
            <Toast ref={toast} />

            <SidebarPricing
                open={ sidebarPricing }
                setOpen={ setSidebarPricing }
                product={ product }
                callback={ () => {
                    setPurchased(true);
                    setTimeout(() => {
                        setSidebarPricing(false);
                    }, 3000);
                }} />

            <div className='p-grid center p-m-1 p-m-md-2 p-m-lg-3'>
                <div className='p-col-12 p-md-7 p-lg-7 p-xl-6'>
                    <div>
                        <Galleria
                            value={ images }
                            responsiveOptions={ galleriaResponsiveOptions }
                            numVisible={ 5 } circular
                            style={{ maxWidth: '800px', margin: 'auto' }}
                            item={ galleriaItemTemplate } 
                            thumbnail={ galleriaThumbnailTemplate } />
                    </div>                        
                </div>
                <div className='p-col-12 p-md-5 p-lg-5 p-xl-4'>
                    <div>
                        <a>
                            <h2 className='p-text-center'>{ model?.name }</h2>
                        </a>
                        <div style={{ fontSize: '.9rem', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <Rating 
                                className='p-d-inline-block'
                                value={ model?.qualification } readOnly cancel={ false } />
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className='p-mx-1'>{ model?.qualification } (100 Reviews)</a>
                        </div>
                    </div>

                    <Divider layout='horizontal' align='center' />

                    <div>
                        <div>
                            <p>{ model?.description }</p>
                            <p className='p-mx-1'>
                                <b>Owner:</b>
                                <i className='pi pi-user p-mx-2'></i>
                                <i>{ model?.owner }</i>
                            </p>
                            <p>
                                <b>Downloads:</b>
                                <i className='pi pi-download p-mx-2'></i>
                                { model?.download_nos }
                            </p>
                            <p style={{ fontSize: '2rem' }}>
                                <b>Price: </b> ${ product?.price }
                            </p>
                        </div>
                        <div>
                            {/* { getPayButton() } */}
                            <Button
                                style={{ width: '100%', margin: 'auto' }}
                                label={'Adquirir'}
                                className='p-button-info p-my-3'
                                onClick={ handleAdquired } />
                        </div>
                    </div>
                </div>
                <div className='p-col-12 p-md-12 p-lg-12 p-xl-10 p-my-3'>
                        <DetailsTemplate model={ model } />
                </div>
            </div>
        </>
    )
}

const imagesExamples = [
    {
        itemImageSrc: "https://cloudfront-us-east-1.images.arcpublishing.com/semana/OGB7QBLRVRBT7OO2QI4EE75OZ4.jpg",
        thumbnailImageSrc: "https://cloudfront-us-east-1.images.arcpublishing.com/semana/OGB7QBLRVRBT7OO2QI4EE75OZ4.jpg",
        alt: "Description for Image 1",
        title: "Calidad del Agua"
    },
    {
        itemImageSrc: "https://www.researchgate.net/profile/John-Krogstie/publication/27471147/figure/fig2/AS:667785822744576@1536223955130/BPMN-model-of-a-payment-process.png",
        thumbnailImageSrc: "https://www.researchgate.net/profile/John-Krogstie/publication/27471147/figure/fig2/AS:667785822744576@1536223955130/BPMN-model-of-a-payment-process.png",
        alt: "Description for Image 3",
        title: "Title 3"
    },
    {
        itemImageSrc: "https://www.researchgate.net/profile/Jean-Guzman/publication/273520854/figure/fig2/AS:667717921153034@1536207766548/Figura-1-Modelo-de-negocio-GTS-expresado-en-BPMN.png",
        thumbnailImageSrc: "https://www.researchgate.net/profile/Jean-Guzman/publication/273520854/figure/fig2/AS:667717921153034@1536207766548/Figura-1-Modelo-de-negocio-GTS-expresado-en-BPMN.png",
        alt: "Description for Image 4",
        title: "Title 4"
    },
    {
        itemImageSrc: "https://www.researchgate.net/profile/Martha-Delgado-Dapena/publication/317519259/figure/fig3/AS:533640980504576@1504241332338/Figura-3-Modelo-BPMN-del-proceso-de-negocio-Solicitud-de-Prueba.png",
        thumbnailImageSrc: "https://www.researchgate.net/profile/Martha-Delgado-Dapena/publication/317519259/figure/fig3/AS:533640980504576@1504241332338/Figura-3-Modelo-BPMN-del-proceso-de-negocio-Solicitud-de-Prueba.png",
        alt: "Description for Image 4",
        title: "Title 4"
    },
];

ModelDetails.propTypes = {

}

export default ModelDetails;