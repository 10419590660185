import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const EditFieldDialog = ({ values, setValues, label, fieldName, icon='user' }) => {

    const [ open, setOpen ] = useState(false);

    const dialogFooter = <div>
        <Button
            label="Save"
            onClick={() => setOpen(false)}
            icon="pi pi-save"
            className="p-button-success p-button-text" />
        <Button
            label="Cancel"
            onClick={() => setOpen(false)}
            icon="pi pi-times"
            className="p-button-danger p-button-text" />
    </div>;

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [fieldName]: target.value,
        });
    }

    return (
        <>
            <Button
                onClick={ () => setOpen(true) }
                className="p-button-rounded p-button-text"
                icon="pi pi-pencil" />

            <Dialog
                header={ `Edit ${ label }` }
                visible={ open }
                style={{ width: '340px' }} modal
                footer={ dialogFooter }
                onHide={() => setOpen(false)}>

                <div className="p-fluid">
                    <span className="p-input-icon-left">
                        <i className={`pi pi-${ icon }`} />
                        <InputText
                            placeholder={ `Enter your ${ label }` }
                            onChange={ handleInputChange }
                            value={ values[fieldName] } />
                    </span>
                </div>
            </Dialog>
        </>
    )
}

EditFieldDialog.propTypes = {
    label: PropTypes.string.isRequired,
}

export default EditFieldDialog
