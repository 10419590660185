import axios from "axios";
import { urlPrinciplesTheoriesAndLaws, urlPrincipleTheoryLawComponent } from "../../api/urls";

//////////////////////
///// PRINCIPLES THEORIES LAWS (PTL)
//////////////////////

export const getPLTs = () => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { data, status } = await axios.get(`${urlPrinciplesTheoriesAndLaws}/${modelId}/${role}`, {
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });

    if (status === 200) {
      return data;
    } else {
      throw new Error("Error fethching principles, theories, and laws.");
    }
  } catch (error) {
    throw error;
  }
};

export const createPLT = (plt) => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.post(`${urlPrinciplesTheoriesAndLaws}/${modelId}/${role}`,
      {
        model_id: modelId,
        label: plt.label,
        ptl_type: plt.ptl_type,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (status === 200) {
      return data;
    } else {
      throw new Error("Error creating principles theories and laws");
    }
  } catch (error) {
    throw error;
  }
};

export const updatePLT = (ptl) => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const modelId = getState().ModelState?.model?.id;
  const role = getState().RolNavigationState?.state;

  try {
    const { status, data } = await axios.put(`${urlPrinciplesTheoriesAndLaws}/${role}`,
      {
        id: ptl.id,
        model_id: modelId,
        label: ptl.label,
        ptl_type: ptl.ptl_type,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (status === 200) {
      return data;
    } else {
      throw new Error("Error updating principles, theories and laws.");
    }
  } catch (error) {
    throw error;
  }
};

export const deletePLT = (pltId) => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const role = getState().RolNavigationState?.state;

  try {
    const { status } = await axios.delete(`${urlPrinciplesTheoriesAndLaws}/${pltId}/${role}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (status !== 200) {
      throw new Error("Error deleting principles, theories, and laws");
    }
  } catch (error) {
    throw error;
  }
};

//////////////////////
///// PTL COMPONENTS
//////////////////////

export const getPLTComponents = (pltId) => async (dispatch, getState) => {

  const accessToken = getState().LoginState?.data?.access_token;
  const role = getState().RolNavigationState?.state;

  try { 
    const { status, data } = await axios.get(`${urlPrincipleTheoryLawComponent}/${pltId}/${role}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (status === 200) return data;
    else throw new Error("Error fetching plt components.");
  } catch (error) {
    throw error;
  }
};

export const updatePLTComponents = (pltId, attribute, pltComponent) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const role = getState().RolNavigationState?.state;

    try {
      const { status } = await axios.put(`${urlPrincipleTheoryLawComponent}/${pltId}/${role}`,
        {
          id: pltComponent.id,
          label: attribute,
          value: pltComponent[attribute],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200)
        throw new Error(
          `Error updating plt attribute: ${attribute}`
        );
    } catch (error) {
      throw error;
    }
  };
