import COLORS from "../../../constants/Colors";

class GraphGenerator {
  constructor(dataStructure) {
    this.territorialSystem = dataStructure;
    this.groups = this.generateGroups();
  }

  BFS(graph, root_id) {
    let nodes = [];
    let edges = [];

    let visited = [];
    let queue = [];

    visited.push(root_id);
    queue.push(root_id);

    while (queue.length > 0) {
      const s = queue.shift();
      // Getting nodes
      nodes.push({
        id: s,
        group: graph[s]?.groupId,
        label: graph[s]?.name,
        shapeProperties: {
          interpolation: false,
        },
        title:
          graph[s]?.definition?.length < 80
            ? graph[s]?.definition
            : graph[s]?.definition?.slice(0, 80) + "...",
      });

      for (let i = 0; i < graph[s]?.components?.length; i++) {
        const neighbour = graph[s]?.components[i];
        // Getting relationships

        edges.push({
          from: s,
          to: neighbour,
          width: 1,
          // dashes: target.interaction.key === "True" || target.interaction.key === "true",
          // label: target.interaction.intensity,
          // title: `State: ${target.interaction.state}, Key: ${target.interaction.key}`,
          color: {
            hover: this.groups[graph[s]?.groupId].color.border,
            color: this.groups[graph[s]?.groupId].color.background,
            highlight: this.groups[graph[s]?.groupId].color.border,
          },
        });

        if (!visited.includes(neighbour)) {
          visited.push(neighbour);
          queue.push(neighbour);
        }
      }
    }

    return { nodes: nodes, edges: edges };
  }

  get components() {
    let rootId;
    for (var key in this.territorialSystem) {
      if (this.territorialSystem[key]?.groupId == "0") {
        rootId = this.territorialSystem[key]?.id?.toString() || key.toString();
        break;
      }
    }
    const components = this.BFS(this.territorialSystem, rootId);
    return components;
  }

  get graph() {
    const { nodes, edges } = this.components;
    return {
      nodes: nodes,
      edges: edges,
    };
  }

  get options() {
    return {
      physics: {
        enabled: true,
        barnesHut: {
          gravitationalConstant: -2000,
          centralGravity: 0.3,
          springLength: 95,
          springConstant: 0.04,
          damping: 0.09,
          avoidOverlap: 0,
        },

        maxVelocity: 60,
        minVelocity: 1,
        solver: "barnesHut",
        stabilization: {
          enabled: true,
          iterations: 350,
          updateInterval: 350,
          onlyDynamicEdges: false,
          fit: true,
        },
        timestep: 0.4,
        adaptiveTimestep: true,
      },
      autoResize: true,
      groups: this.groups,
      layout: {
        hierarchical: false,
        improvedLayout: false,
      },
      edges: {
        arrows: {
          to: false,
        },
        smooth: {
          type: "dynamic",
          forceDirection: "none",
          roundness: 0.2,
        },
      },
      nodes: {
        shape: "dot",
        size: 25,
        borderWidth: 2,
        font: {
          size: 35,
          color: "#343434",
          strokeWidth: 2,
          strokeColor: "#ffffff",
        },
      },
      interaction: {
        hover: true,
        hideEdgesOnDrag: true,
      },
    };
  }

  generateGroups() {
    let groups = {};
    let i = 0;
    for (let id in this.territorialSystem) {
      if (groups[this.territorialSystem[id]?.groupId] === undefined) {
        groups[this.territorialSystem[id]?.groupId] = {
          color: {
            border: COLORS[i]?.outer,
            background: COLORS[i]?.inner,
            hover: {
              border: COLORS[i]?.outer,
              background: COLORS[i]?.hover,
            },
            highlight: {
              border: COLORS[i]?.outer,
              background: COLORS[i]?.hover,
            },
          },
        };
        i++;
      }
    }
    return groups;
  }
}

export default GraphGenerator;
