import * as actions from '../actions/process.actions';

const {handleActions} = require('redux-actions');


const initialState = {
    process: null,
    indicator_process:null
  };
  
  export const ProcessReducer = handleActions(
    {
      [actions.setProcessState]: (state, { payload }) => ({
        ...state,
        process: payload,
      }),
      [actions.setIndicatorProcessState]: (state, { payload }) => ({
        ...state,
        indicator_process: payload,
      }),
      [actions.setToolProcessState]: (state, { payload }) => ({
        ...state,
        tool_process: payload,
      }),
      [actions.setResourceProcessState]: (state, { payload }) => ({
        ...state,
        resource_process: payload,
      }),
      [actions.setResultProcessState]: (state, { payload }) => ({
        ...state,
        result_process: payload,
      }),
    },
    initialState
  );
