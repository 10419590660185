const ignoreOptionsGlobal = [
    {
        key: 'api',
        ignore: true,
    },
    {
        key: 'logic',
        ignore: true,
    },
    {
        key: 'conditional',
        ignore: true,
    },
    {
        key: 'layout',
        ignore: true,
    },
];

const ignoreDisplayAttrGlobal = [
    {
        key: 'prefix',
        ignore: true,
    },
    {
        key: 'suffix',
        ignore: true,
    },
    {
        key: 'tabindex',
        ignore: true,
    },
    {
        key: 'inputMask',
        ignore: true,
    },
    {
        key: 'displayMask',
        ignore: true,
    },
    {
        key: 'allowMultipleMasks',
        ignore: true,
    },
    {
        key: 'hidden',
        ignore: true,
    },
    {
        key: 'hideLabel',
        ignore: true,
    },
    {
        key: 'hideInput',
        ignore: true,
    },
    {
        key: 'customcssclass',
        ignore: true,
    },
    {
        key: 'hideLabel',
        ignore: true,
    },
    {
        key: 'hideLabel',
        ignore: true,
    },
]

export const editForm = {
    textfield: [ 
        ...ignoreOptionsGlobal,
        {
            key:  'display',
            components: [
                ...ignoreDisplayAttrGlobal,
            ]
        }
    ],
    number: [
        ...ignoreOptionsGlobal
    ],
    textarea: [
        ...ignoreOptionsGlobal
    ],
    password: [
        ...ignoreOptionsGlobal
    ],
    button: [
        ...ignoreOptionsGlobal,
        {
            key:  'display',
            components: [
                ...ignoreDisplayAttrGlobal,
            ]
        }
    ],
    checkbox: [
        ...ignoreOptionsGlobal
    ],
    selectboxes: [
        ...ignoreOptionsGlobal
    ],
    select: [
        ...ignoreOptionsGlobal
    ],
    radio: [
        ...ignoreOptionsGlobal
    ],
    datetime: [
        ...ignoreOptionsGlobal
    ],
    day: [
        ...ignoreOptionsGlobal
    ],
    time: [
        ...ignoreOptionsGlobal
    ],
    email: [
        ...ignoreOptionsGlobal
    ],
    url: [
        ...ignoreOptionsGlobal
    ],
    phonenumber: [
        ...ignoreOptionsGlobal
    ],
    tags: [
        ...ignoreOptionsGlobal
    ],
    address: [
        ...ignoreOptionsGlobal
    ],
    currency: [
        ...ignoreOptionsGlobal
    ],
    survey: [
        ...ignoreOptionsGlobal
    ],
    signature: [
        ...ignoreOptionsGlobal
    ],
    datamap: [
        ...ignoreOptionsGlobal
    ],
    file: [
        ...ignoreOptionsGlobal
    ],
};

// Ingore options menu
export const ignoreOptions = {
    basic: false,
    advanced: false,
    layout: false,
    data: false,
    premium: false,
}

// Schema of new options menu
export const customOptions = [
    {
        title: 'Basic',
        default: true,
        components: {
            textfield: true,
            textarea: true,
            number: true,
            password: true,
            button: true,
        },
    },
    {
        title: 'Selectable',
        default: false,
        components: {
            checkbox: true,
            selectboxes: true,
            select: true,
            radio: true,
        },
    },
    {
        title: 'Date / Time',
        default: false,
        components: {
            datetime: true,
            day: true,
            time: true,
        },
    },
    {
        title: 'Advanced',
        default: false,
        components: {
            email: true,
            url: true,
            phonenumber: true,
            tags: true,
            address: true,
            currency: true,
            survey: true,
            signature: true,
            datamap: true,
            file: true,
        },
    },
]

// Translation json
export const i18n = {
    sp: {
        // Inputs Groups
        'Basic': 'Básico',
        'Selectable': 'Seleccionable',
        'Advanced': 'Avanzado',

        // Fields translate
        'Text Field': 'Campo de Texto',
        'Text Area': 'Área de Texto',
        'Number': 'Campo Numérico',
        'Password': 'Contraseña',
        'Checkbox': 'Casilla',
        'Select Boxes': 'Seleccionar Casillas',
        'Select': 'Seleccionar',
        'Radio': 'Radio',
        'Button': 'Botón',
        'Email': 'Correo',
        'Url': 'Url',
        'Phone Number': 'Número telefónico',
        'Tags': 'Etiquetas',
        'Address': 'Dirección',
        'Date / Time': 'Fecha / Hora',
        'Day': 'Día',
        'Time': 'Hora',
        'Currency': 'Moneda',
        'Survey': 'Encuesta',
        'Signature': 'Firma',
        'Data Map': 'Mapa de Datos',
        'File': 'Archivo(s)',

        // Dialog menu
        'Display': 'Mostrar',
        'Data': 'Datos',
        'Validation': 'Validación',

        // Inputs options
        'Description': 'Descripción',
        'Autocomplete': 'Autocompletar',

        // Globals
        'Save': 'Guardar',
        'Cancel': 'Cancelar',
        'Remove': 'Eliminar',
        'Component': 'Componente',
        'Submit': 'Enviar',
        'Fields': 'Campos',
        'Preview': 'Vista previa',
        'Help': 'Ayuda',
        'Drag and Drop a form component': 'Arrastra y suelta un componente de formulario',
    },
}