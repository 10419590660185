import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'primereact/toast';
import {
  leftTemplate,
  leftTemplateTeam,
  rightTemplate,
} from './utils/templates';
import { Fieldset } from 'primereact/fieldset';
import { Toolbar } from 'primereact/toolbar';
import { useTranslation } from 'react-i18next';

const TeamTemplate = ({
  setIndex,
  values,
  handleInputChange,
  validities,
  handleCheckTeam,
}) => {
  const { t: tGlobal } = useTranslation(['global']);
  const toast = useRef(null);

  const inputTeam = {
    attributes: {
      name: 'team',
      placeholder: tGlobal('placeholder.write-team-name'),
      desc: tGlobal('team-name'),
      maxlength: 100,
      minlength: 3,
    },
  };

  const inputDesc = {
    attributes: {
      name: 'desc',
      placeholder: tGlobal('placeholder.write-team-desc'),
      desc: tGlobal('team-desc'),
      maxlength: 300,
      class: 'p-pb-2 p-mt-1',
      rows: 6,
    },
  };

  return (
    <>
      <Toast ref={toast} />

      <div className="p-grid center p-mb-1">
        <div className="p-col-12 p-lg-5 p-md-6">
          <div className="">
            <Fieldset legend="Team Info" toggleable>
              <Toolbar
                className="p-mb-3"
                left={leftTemplateTeam(
                  values,
                  handleInputChange,
                  inputTeam,
                  validities?.team,
                  handleCheckTeam
                )}
                right={rightTemplate('users', inputTeam, validities?.team)}
              />
              <Toolbar
                className="p-mb-3"
                left={leftTemplate(
                  values,
                  handleInputChange,
                  inputDesc,
                  validities?.desc,
                  true
                )}
                right={rightTemplate('bars', inputDesc, validities?.desc)}
              />
            </Fieldset>
          </div>
        </div>
      </div>
      {/* 
            <HandleSteps
                handleBack={() => { setIndex(i => i - 1) }}
                handleContinue={ handleContinue }
                finish={ true }
                submited={ submited } /> */}
    </>
  );
};

TeamTemplate.propTypes = {
  setIndex: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default TeamTemplate;
