import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import GooglePayButton from '@google-pay/button-react'
import { Toast } from 'primereact/toast';

// https://github.com/google-pay/google-pay-button
const GooglePayment = React.memo(({ submited, setSubmited, product, customer }) => {

    const toast  = useRef(null);

    return (
        <div>
            <Toast ref={ toast } />

            <GooglePayButton
                environment="TEST"
                buttonSizeMode='fill'
                buttonColor="black"
                paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                        {
                            type: 'CARD',
                            parameters: {
                                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                allowedCardNetworks: ['MASTERCARD', 'VISA'],
                            },
                            tokenizationSpecification: {
                                type: 'PAYMENT_GATEWAY',
                                parameters: {
                                    gateway: 'example',
                                    gatewayMerchantId: 'exampleGatewayMerchantId',
                                },
                            },
                        },
                    ],
                    merchantInfo: {
                        merchantId: 'BCR2DN6TQ7Z3L7ZG',
                        merchantName: 'Gearth Merchant',
                    },
                    transactionInfo: {
                        totalPriceStatus: 'FINAL',
                        totalPriceLabel: 'Total',
                        totalPrice: '0.10',
                        currencyCode: 'USD',
                        countryCode: 'US',
                    },
                    emailRequired: true,
                    shippingAddressRequired: true,
                }}
                onLoadPaymentData={paymentRequest => {
                    console.log('load payment data', paymentRequest);
                }}
                onCancel={() => {
                    toast.current.show({ 
                        severity: 'warn', 
                        summary: 'You cancelled the Google payment.', 
                        life: 3000,
                    });
                }}
                onError={() => {
                    toast.current.show({
                        severity: 'error', 
                        summary: 'There was an error with the Google payment. Try again, please.', 
                        life: 3000
                    });
                }}
            />
        </div>
    )
});

GooglePayment.propTypes = {
    customer: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
}

export default GooglePayment
