export const VARIABLE_INTEGER = 'Entero';

export const SUB_VARIABLE_INTEGER_SCALE = {label:'Escalar', id:1};
export const SUB_VARIABLE_INTEGER_ORDINAL = {label:'Ordinal', id:2};
export const SUB_VARIABLE_INTEGER_NOMINAL = {label:'Nominal', id:3};
export const MEASURE_TYPE_STRING = { id: 4, label: "N/A"};

export const VARIABLE_DOUBLE = 'Decimal';
export const VARIABLE_STRING = 'Texto';

export const QUESTION_TYPE_OPEN = {id:1, label:'open'};
export const QUESTION_TYPE_CLOSE = {id:2, label:'close'};
export const QUESTION_TYPE_MULTIPLE = {id:2, label:'múltiple'};

export const type_questions = [
    { label: 'Selección múltiple', id: 'OM' },
    { label: 'Selección única', id: 'OU' },
    { label: 'Pregunta abierta', id: 'PA' },
];

export const type_options = [
    {label: VARIABLE_INTEGER, id:1},
    {label: VARIABLE_DOUBLE, id:2},
    {label: VARIABLE_STRING, id:3},
];

export const type_sub_variable_integer = [
    SUB_VARIABLE_INTEGER_NOMINAL,
    SUB_VARIABLE_INTEGER_ORDINAL

];
