import React, { useEffect, useState } from 'react';
import ActivityCard from './ActivityCard';
import ActivityFilters from './ActivityFilters';
import { useSelector } from 'react-redux';
import { listActivityByModel } from '../../../../redux/actions/activity.actions';

const ActivitiesList = props => {

    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ activities, setActivities ] = useState([]);
    const [ membersOptions, setMembersOptions ] = useState([]);
    const { model } = useSelector(state => state.ModelState);

    useEffect(() => {
        if (model?.id) {
            listActivityByModel(model?.id, user?.access_token, role).then(data => {
                setActivities(data);
            });
        }
    }, [ user, model, role ])

    const getProgressAvg = (list = []) => {
        if (list && list.length) {
            let total = 0;
            const nro = list.length
            
            list.forEach((activity) => {
                total += activity?.progress;
            })
            
            return Math.round(total/nro) + '%';
        } else {
            return 0;
        }
    }
    
    return (
        <div className='animate__animated animate__zoomIn'>
            <div className="card p-shadow-10">

                <h5 className='p-text-center'>Lista de Actividades</h5>

                <ActivityFilters
                    setActivities={ setActivities }
                    membersOptions={ membersOptions }
                    setMembersOptions={ setMembersOptions } />

                <div className="card schedule p-mb-0">
                    <p>Resultados</p>
                    <ul className='p-grid'>
                        <li className='p-col p-m-1'>
                            <div className="schedule-header">
                                <h6>Número de resutados</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { activities.length }
                                    </span>
                                </span>
                            </div>
                            <span>{ activities.length } actividades</span>
                        </li>
                        <li className='p-col p-m-1'>
                            <div className="schedule-header">
                                <h6>Progreso medio</h6>
                                <span>
                                    <span style={{ fontSize: '.9rem' }}>
                                        { getProgressAvg(activities) }
                                    </span>
                                </span>                                                
                            </div>
                            <span>
                                { getProgressAvg(activities) } promedio del progreso
                            </span>
                        </li>
                    </ul>
                </div>
                
                <div className='p-grid '>
                {
                    activities.map((activity) => (
                        <ActivityCard 
                            key={ activity?.id }
                            activity={ activity } />
                    ))
                }
                </div>
            </div>
        </div>
    )
}

ActivitiesList.propTypes = {}

export default ActivitiesList