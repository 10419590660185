import React, { useMemo } from 'react';
import LoginTemplate from './LoginTemplate';
import AwesomeSlider from 'react-awesome-slider';
import computerSchema from '../../assets/img/slider/slider-computer-schema.jpg';
import worldPoints from '../../assets/img/slider/slider-world-points.jpg';
import computer from '../../assets/img/slider/slider-computer.jpg';
import world from '../../assets/img/slider/slider-world.jpg';
import 'react-awesome-slider/dist/styles.css';

export const Login = (props) => {

	const sliderTemplate = useMemo(() => slider(), [ ])

	return (
		<div className="p-grid" style={{ padding: '0px', margin: '0px' }}>

			<div className="p-col" style={{ padding: '0px', margin: '0px', minWidth: '300px' }}>
				{ sliderTemplate }
			</div>

			<div className="p-col" style={{ padding: '0px', margin: '0px', minWidth: '300px' }}>
				<div className="login-body" style={{ background: '#3E4754' }}>
					<div className="login-wrapper">
						<div className="login-panel card p-shadow-10 p-mx-5 p-mt-4" style={{ width: '90%', background: '#293241' }}>
							<LoginTemplate />
						</div>
						<div className="login-footer p-pb-3">
							<h4 className='color-success'>Gearth</h4>
							<h6 style={{ color: 'gray' }}>Copyright Ⓒ Gearth Inc</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

// https://reactjsexample.com/react-component-that-renders-a-media-gallery-slider-carousel/
// https://github.com/rcaferati/react-awesome-slider
const slider = () => {

	const className = 'p-shadow-10 p-p-6';
	const style = { 
		borderRadius: '17px' ,
	};

	return (
		<AwesomeSlider
			className="p-shadow-15"
			style={{ height: '100%', background: '#3E4754' }}
			bullets={ false }
			mobileTouch={ true }
			infinite={ true } >

			<div data-src={ computerSchema } >
				<div style={{ position: 'relative' }}>
					<h1 className={ className } style={ style }>Gearth</h1>
				</div>
			</div>

			<div data-src={ world } />

			<div data-src={ computer } />

			<div data-src={ worldPoints } />

		</AwesomeSlider>
	)
};

export default Login;