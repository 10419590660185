import axios from "axios";
import { urlConcept, urlKnowledge } from "../../api/urls";

//////////////////////
///// CONCEPTS
//////////////////////

export const getConcepts = () => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
        const { data, status } = await axios.get(`${urlConcept}/${modelId}/${role}`, {
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        });

        if (status === 200) {
            return data;
        } else {
            throw new Error("Error fethching concepts.");
        }
    } catch (error) {
        throw error;
    }
};

export const createConcept = (conceptLabel) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
        const { status, data } = await axios.post(`${urlConcept}/${modelId}/${role}`, { model_id:modelId, label:conceptLabel }, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (status === 200) {
            return data;
        } else {
            throw new Error("Error creating concept");
        }
    } catch (error) {
        throw error;
    }
};

export const updateConcept = (concept) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const modelId = getState().ModelState?.model?.id;
    const role = getState().RolNavigationState?.state;

    try {
        const { status, data } = await axios.put(`${urlConcept}/${role}`, {
            id: concept.id,
            model_id: modelId,
            label: concept.label,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (status === 200) {
            return data;
        } else {
            throw new Error("Error updating concept");
        }
    } catch (error) {
        throw error;
    }
};

export const deleteConcept = (conceptId) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const role = getState().RolNavigationState?.state;

    try {
        const { status } = await axios.delete(`${urlConcept}/${conceptId}/${role}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (status !== 200) {
            throw new Error("Error deleting concept");
        }
    } catch (error) {
        throw error;
    }
};

//////////////////////
///// KNOWLEDGE
//////////////////////

export const getKnowledge = (conceptId) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const role = getState().RolNavigationState?.state;

    try {
        const { status, data } = await axios.get(`${urlKnowledge}/${conceptId}/${role}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (status === 200) return data;
        else throw new Error("Error fetching knowledge");
        
    } catch (error) {
        throw error;
    }
};

export const updateKnowledge = (conceptId, attribute, knowledge) => async (dispatch, getState) => {

    const accessToken = getState().LoginState?.data?.access_token;
    const role = getState().RolNavigationState?.state;

    try {
        const { status } = await axios.put(`${urlKnowledge}/${conceptId}/${role}`, {
            id: knowledge.id,
            label: attribute,
            value: knowledge[attribute],
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (status !== 200) throw new Error(`Error updating knowledge attribute: ${attribute}`);
        
    } catch (error) {
        throw error;
    }
};
