//Assets

const getImageURL = (name) =>
  `https://andres-sapatanga-bucket.s3.amazonaws.com/img/${name}.png`;
const getImageURL2 = (name) =>
  `https://gearth-app.s3.amazonaws.com/images/${name}.png`;

const img_names = [
  "angular",
  "asp.net",
  "bootstrap4",
  "c#",
  "cordova",
  "flask",
  "fluter",
  "go",
  "ionic",
  "java",
  "javascript",
  "kotlin",
  "laravel",
  "mongodb",
  "mysql",
  "node",
  "phone-gap",
  "php",
  "php-cuadrado",
  "python",
  "react",
  "react-native",
  "ruby",
  "spring-boot",
  "symphony",
  "vue",
  "xamarin",
];

//Data
export const APPUSERS = [
  {
    id: 1,
    photo: getImageURL("java"),
    name: "Andrés",
    lname: "Sapatanga",
    email: "andr3s@gmail.com",
    gender: "Male",
    status: 1,
    created_at: "14-08-2021 6:00",
    updated_at: "14-08-2021 6:00",
  },
  {
    id: 2,
    photo: getImageURL("java"),
    name: "Kevin",
    lname: "Llivichuzcha",
    email: "kevin@gmail.com",
    gender: "Male",
    status: 1,
    created_at: "14-08-2021 6:00",
    updated_at: "14-08-2021 6:00",
  },
  {
    id: 3,
    photo: getImageURL("java"),
    name: "Lidia",
    lname: "Contento",
    email: "lidia@gmail.com",
    gender: "Female",
    identification: "0150305183",
    status: 2,
    created_at: "14-08-2021 6:00",
    updated_at: "14-08-2021 6:00",
  },
];

export const CHANNELS = [
  {
    id: 1,
    modelId: 1,
    teamId: 1,
    name: "Tiendas barriales",
    description:
      "Esta es una descripción corta que detalla el hilo conductor del canal, por ejemplo para el caso dado sería algo como APORTE DE LA TIENDA DENTRO DE LA COMUNIDAD",
    created_at: "14-08-2021 6:00",
    updated_at: "14-08-2021 6:00",
  },
  {
    id: 2,
    modelId: 1,
    teamId: 1,
    name: "Centros comerciales",
    description:
      "Esta es una descripción corta que detalla el hilo conductor del canal, por ejemplo para el caso dado sería algo como APORTE DEL CENTRO COMERCIAL EN LA COMUNIDAD",
    created_at: "15-08-2021 8:00",
    updated_at: "18-09-2021 14:00",
  },
  {
    id: 3,
    modelId: 2,
    teamId: 1,
    name: "Producción para exportación",
    description:
      "Esta es una descripción corta que detalla el hilo conductor del canal, por ejemplo para el caso dado sería algo como SAQUEMOS EL PRODUCTO PARA EXPORTAR",
    created_at: "21-10-2021 18:00",
    updated_at: "21-10-2021 18:00",
  },
];

export const people = [
  {
    id: 1,
    photo: getImageURL("java"),
    names: "Andrés",
    last_names: "Sapatanga",
    username: "andr3s@gmail.com",
    gender: "Male",
    identification: "0150305183",
    status: 1,
  },
  {
    id: 2,
    photo: getImageURL("java"),
    names: "Kevin",
    last_names: "Llivichuzcha",
    username: "kevin@gmail.com",
    gender: "Male",
    identification: "0150305183",
    status: 1,
  },
  {
    id: 3,
    photo: getImageURL("java"),
    names: "Lidia",
    last_names: "Contento",
    username: "lidia@gmail.com",
    gender: "Female",
    identification: "0150305183",
    status: 1,
  },
  {
    id: 4,
    photo: getImageURL("java"),
    names: "Andrés",
    last_names: "Guamán",
    username: "guaman@gmail.com",
    gender: "Male",
    identification: "0150305183",
    status: 1,
  },
  {
    id: 5,
    photo: getImageURL("java"),
    names: "Kelly",
    last_names: "Farfán",
    username: "kelly@gmail.com",
    gender: "Female",
    identification: "0150305183",
    status: 1,
  },
  {
    id: 6,
    photo: getImageURL("java"),
    names: "Pedro",
    last_names: "Cornejo",
    username: "pedro@gmail.com",
    gender: "Male",
    identification: "0150305183",
    status: 1,
  },
];

export const users = [
  {
    id: 1,
    username: "andr3s",
    password: "1234567890",
    rol: "Invited",
    team: 1,
    plan: 1,
    status: 1,
    person: 1,
  },
  {
    id: 2,
    username: "kevin",
    password: "1234567890",
    rol: "Admin",
    team: 1,
    plan: 2,
    status: 1,
    person: 2,
  },
  {
    id: 3,
    username: "lidia",
    password: "1234567890",
    rol: "Invited",
    team: 1,
    plan: 1,
    status: 1,
    person: 3,
  },
  {
    id: 4,
    username: "guaman",
    password: "1234567890",
    rol: "Invited",
    team: 1,
    plan: 1,
    status: 1,
    person: 4,
  },
  {
    id: 5,
    username: "kelly",
    password: "1234567890",
    rol: "Invited",
    team: 2,
    plan: 1,
    status: 1,
    person: 5,
  },
  {
    id: 6,
    username: "pedro_admin",
    password: "1234567890",
    rol: "Admin",
    team: 1,
    plan: 1,
    status: 1,
    person: 6,
  },
  {
    id: 7,
    username: "pedro",
    password: "1234567890",
    rol: "Invited",
    team: 1,
    plan: 1,
    status: 1,
    person: 6,
  },
];

export const variablesTypes = [
  {
    id: "1",
    label: "Integer",
    definition: "Some definition for integers",
    order: 1,
    format: [],
    measure_types: [
      {
        id: "1",
        label: "Scalar",
        definition: "Some scalar definition",
        order: 1,
        characteristics: [
          {
            id: "1",
            order: 1,
            label: "Value codification",
            definition: "some definition...",
            placeholder: "ingrese la codificación",
          },
          {
            id: "2",
            order: 2,
            label: "Lower boundary",
            definition: "some definition...",
            placeholder: "ingrese un entero",
          },
          {
            id: "3",
            order: 3,
            label: "upper boundary",
            definition: "some definition...",
            placeholder: "ingrese un entero",
          },
        ],
      },
      {
        id: "2",
        label: "Nominal",
        definition: "Some nominal definition",
        order: 2,
        characteristics: [
          {
            id: "1",
            order: 1,
            label: "Value codification",
            definition: "some definition...",
            placeholder: "ingrese la codificación",
          },
        ],
      },
      {
        id: "3",
        label: "Ordinal",
        definition: "Some ordinal definition",
        order: 3,
        characteristics: [
          {
            id: "1",
            order: 1,
            label: "Value codification",
            definition: "some definition...",
            placeholder: "ingrese un entero",
          },
        ],
      },
    ],
  },
  {
    id: "2",
    label: "Decimal",
    definition: "Some definition for decimals",
    order: 1,
    format: [],
    measure_types: [
      {
        id: "1",
        label: "Scalar",
        definition: "Some scalar definition",
        order: 1,
        format: [],
        characteristics: [
          {
            id: "1",
            order: 1,
            label: "Decimals",
            definition: "some definition...",
            placeholder: "ingrese un entero",
          },
          {
            id: "2",
            order: 2,
            label: "Lower boundary",
            definition: "some definition...",
            placeholder: "ingrese un entero",
          },
          {
            id: "3",
            order: 3,
            label: "upper boundary",
            definition: "some definition...",
            placeholder: "ingrese un entero",
          },
        ],
      },
    ],
  },
  {
    id: "3",
    label: "String",
    definition: "Some string definition",
    order: 3,
    format: [],
    measure_types: [
      {
        id: "1",
        label: "Text",
        definition: "Some text definition",
        order: 1,
        characteristics: [
          {
            id: "1",
            order: 1,
            label: "Min length",
            definition: "some definition...",
            placeholder: "ingrese un entero",
          },
          {
            id: "2",
            order: 2,
            label: "Max length",
            definition: "some definition...",
            placeholder: "ingrese un entero",
          },
        ],
      },
    ],
  },
  {
    id: "4",
    label: "Date",
    definition: "Some date definition",
    order: 4,
    format: [
      {
        id: "1",
        order: 1,
        label: "dd/mm/aa",
        definition: "some definition...",
        placeholder: "ingrese una fecha",
      },
      {
        id: "2",
        order: 2,
        label: "mm/dd/aa",
        definition: "some definition...",
        placeholder: "ingrese una fecha",
      },
    ],
    measure_types: [],
  },
  {
    id: "5",
    label: "Binary",
    definition: "Some binary definition",
    order: 5,
    format: [],
    measure_types: [
      {
        id: "1",
        label: "Nominal",
        definition: "Some text definition",
        order: 1,
        characteristics: [
          {
            id: "1",
            order: 1,
            label: "Value codification",
            definition: "some definition...",
            placeholder: "ingrese la codificación",
          },
        ],
      },
    ],
  },
];

export const dplans = [
  {
    id: 1,
    name: "Silver",
    price: 0,
    desc: "Plan type silver",
    members_number: 5,
    discount: 12.5,
    featured: true,
    status: 1,
  },
  {
    id: 2,
    name: "Gold",
    price: 10,
    desc: "Plan type gold",
    members_number: 10,
    discount: 12.5,
    featured: true,
    status: 1,
  },
  {
    id: 3,
    name: "Diamond",
    price: 30,
    desc: "Plan type diamond",
    members_number: 15,
    discount: 12.5,
    featured: true,
    status: 1,
  },
];

export const plans = [
  {
    id: 1,
    name: "Basic",
    price: 0,
    featured: false,
    monthly_updates: true,
    disk_space: 50,
    email_accounts: 50,
    domains: 5,
    sub_domains: 10,
    custom_domains: false,
    technical_support: false,
    monthly_bandwidth: 25,
  },
  {
    id: 2,
    name: "Standard",
    price: 49,
    featured: true,
    monthly_updates: true,
    disk_space: 150,
    email_accounts: 150,
    domains: 15,
    sub_domains: 20,
    custom_domains: false,
    technical_support: false,
    monthly_bandwidth: 25,
  },
  {
    id: 3,
    name: "Unlimited",
    price: 99,
    featured: false,
    monthly_updates: true,
    disk_space: 300,
    email_accounts: 300,
    domains: 30,
    sub_domains: 50,
    custom_domains: true,
    technical_support: true,
    monthly_bandwidth: "Unlimitied",
  },
];

const plan_features = [
  {
    id: 1,
    item_description: "Description 1",
    order: 1,
    status: 1,
    plan_id: 1,
  },
  {
    id: 2,
    item_description: "Description 2",
    order: 2,
    status: 1,
    plan_id: 1,
  },
  {
    id: 3,
    item_description: "Description 3",
    order: 3,
    status: 1,
    plan_id: 1,
  },
  {
    id: 4,
    item_description: "Description 1",
    order: 1,
    status: 1,
    plan_id: 2,
  },
  {
    id: 5,
    item_description: "Description 2",
    order: 2,
    status: 1,
    plan_id: 2,
  },
  {
    id: 6,
    item_description: "Description 3",
    order: 3,
    status: 1,
    plan_id: 2,
  },
  {
    id: 7,
    item_description: "Description 1",
    order: 1,
    status: 1,
    plan_id: 3,
  },
  {
    id: 8,
    item_description: "Description 2",
    order: 2,
    status: 1,
    plan_id: 3,
  },
  {
    id: 9,
    item_description: "Description 3",
    order: 3,
    status: 1,
    plan_id: 3,
  },
];

const teams = [
  {
    id: 1,
    name: "ISTA",
    logo: getImageURL("mongodb"),
    description: "Institución Educativa Superior Tecnológico",
    plan_id: 1,
    status: 1,
  },
  {
    id: 2,
    name: "FUTURA",
    logo: getImageURL("vue"),
    description: "Empresa de ASDASD",
    plan_id: 2,
    status: 1,
  },
  {
    id: 3,
    name: "GIGA",
    logo: getImageURL("angular"),
    description: "Grupo de Tecnología e Innovación",
    plan_id: 3,
    status: 0,
  },
  {
    id: 4,
    name: "UC",
    logo: getImageURL("xamarin"),
    description: "Gurpo de Investigación de la U. de Cuenca",
    plan_id: 1,
    status: 1,
  },
  {
    id: 5,
    name: "ISO",
    logo: getImageURL("php"),
    description: "Estándares",
    plan_id: 1,
    status: 0,
  },
];

export const roles = [
  {
    id: 1,
    name: "Super Admin",
    status: 0,
  },
  {
    id: 2,
    name: "Developer",
    status: 1,
  },
  {
    id: 3,
    name: "Admin",
    status: 1,
  },
  {
    id: 4,
    name: "Invited",
    status: 1,
  },
];

const payment_methods = [
  {
    id: 1,
    name: "PayPlay",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/PayPal_Logo_Icon_2014.svg/666px-PayPal_Logo_Icon_2014.svg.png",
    description: "Pago digital mediante PayPal",
    status: 1,
  },
  {
    id: 2,
    name: "Cash",
    logo: "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-11/256/Cash-icon.png",
    description: "Pago en efectivo",
    status: 1,
  },
  {
    id: 3,
    name: "PayPlay",
    logo: getImageURL("asp.net"),
    description: "Pago digital mediante PayPal",
    status: 1,
  },
  {
    id: 4,
    name: "PayPlay",
    logo: getImageURL("asp.net"),
    description: "Pago digital mediante PayPal",
    status: 1,
  },
  {
    id: 5,
    name: "PayPlay",
    logo: getImageURL("asp.net"),
    description: "Pago digital mediante PayPal",
    status: 1,
  },
];

const menu_roles = [
  {
    id: 1,
    name: "Menu Rol 1",
    role_id: 1,
    menu_id: 1,
    status: 1,
  },
  {
    id: 2,
    name: "Menu Rol 2",
    role_id: 1,
    menu_id: 1,
    status: 1,
  },
  {
    id: 3,
    name: "Menu Rol 3",
    role_id: 1,
    menu_id: 1,
    status: 1,
  },
  {
    id: 4,
    name: "Menu Rol 4",
    role_id: 1,
    menu_id: 1,
    status: 1,
  },
  {
    id: 5,
    name: "Menu Rol 5",
    role_id: 1,
    menu_id: 1,
    status: 1,
  },
];

export const dmenu = [
  {
    id: 1,
    name: "Menu 1",
    status: 1,
  },
  {
    id: 2,
    name: "Menu 2",
    status: 1,
  },
  {
    id: 3,
    name: "Menu 3",
    status: 1,
  },
  {
    id: 4,
    name: "Menu 4",
    status: 1,
  },
  {
    id: 5,
    name: "Menu 5",
    status: 1,
  },
];

export const subsystems = [
  {
    id: 1,
    name: "Medio Natural",
    category: "Sustentables",
    hashtag: "",
    img: getImageURL("java"),
    owner: "Andrés Sapatanga",
    desc_short: "Subsistema de nivel 1",
    desc_small: "Subsistema de nivel 1",
    qualification: 4.5,
    nro_dowloads: 1597,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
    status: 1,
    processes: [
      {
        id: 1,
        name: "Ubicación",
        description: "Hace referencia a....",
        status: 1,
      },
      {
        id: 2,
        name: "Relieve",
        description: "Hace referencia a....",
        status: 0,
      },
      {
        id: 3,
        name: "Suelo",
        description: "Hace referencia a....",
        status: 1,
      },
      {
        id: 4,
        name: "Clima",
        description:
          "Pasos a seguir para un correcto empredimiento en la bolsa de valores.",
        status: 1,
      },
    ],
  },
  {
    id: 2,
    name: "Socio- Cultural",
    category: "Sustentables",
    hashtag: "",
    img: getImageURL("angular"),
    owner: "Juan Paredes",
    desc_short: "Subsistema de nivel 1",
    desc_small: "Subsistema de nivel 1",
    qualification: 4.5,
    nro_dowloads: 100,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
    processes: [
      {
        id: 1,
        name: "Riesgos territoriales",
        description:
          "Se realizarán los pasos para invertir en la bolsa de valores de forma correcta",
        activities: [
          {
            name: "Llenar el formulario",
            description:
              "Llenar el formulario de requisitos para poder invertir en la bolsa",
          },
        ],
      },
      {
        id: 2,
        name: "Emprender en la bolsa de valores",
        description:
          "Pasos a seguir para un correcto empredimiento en la bolsa de valores.",
      },
    ],
  },
  {
    id: 3,
    name: "Medio construído",
    category: "Sustentables",
    hashtag: "aprender",
    img: getImageURL("node"),
    owner: "Juan Ramirez",
    desc_short: "Modelo de manejo sustentable de bosques en Ecuador",
    desc_small:
      "Modelo de competencia entre PYMES en donde se veran diferentes puntos de vista entre como avanzan en el dia a dia",
    qualification: 3.5,
    nro_dowloads: 50,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
  {
    id: 5,
    name: "Económico- Productivo",
    category: "Educacion",
    hashtag: "aprender",
    img: getImageURL("java"),
    owner: "El Pepe",
    desc_short:
      "Modelo de diagnóstico del uso de tecnologías en el desarrollo de los estudiantes",
    desc_small: "Modelo de rendimiento de estudiantes para su mejor educación",
    qualification: 4.5,
    nro_dowloads: 100,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
  {
    id: 6,
    name: "Político- Institucional",
    category: "Educacion",
    hashtag: "aprender",
    img: getImageURL("asp.net"),
    owner: "El Pepe",
    desc_short:
      "Modelo para determinar la planificación efectiva de áreas de estudio",
    desc_small: "Modelo de rendimiento de estudiantes para su mejor educación",
    qualification: 5,
    nro_dowloads: 100,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
];

export const modelos = [
  {
    id: 1,
    name: "Modelo de la calidad del Agua",
    category: "Sustentables",
    hashtag: "Agua, Calidad",
    img: getImageURL("java"),
    owner: "Andrés Sapatanga",
    desc_short: "Manejo responsable de las fuentes hídricas",
    desc_small:
      "Al usar este modelo, usted podrá determinar la calidad del agua y cómo esto se ve afectado por otros elementos de las grandes ciudades",
    qualification: 4.5,
    nro_dowloads: 1597,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
    status: 1,
    processes: [
      {
        id: 1,
        name: "Ubicación",
        description:
          "Se indicarán los procesos y actividades para conformar un equipo de trabajo",
        status: 1,
        activities: [
          {
            name: "Conseguir Ingredientes",
            description:
              "Obtención y preparación de los ingredientes previo a su preparación.",
          },
        ],
      },
      {
        id: 2,
        name: "Relieve",
        description:
          "Procesos recomendados para determinar inicialmente la calidad del agua",
        status: 0,
        activities: [
          {
            name: "Tomar fotos",
            description:
              "Obtención y preparación de los ingredientes previo a su preparación.",
          },
        ],
      },
      {
        id: 3,
        name: "Suelo",
        description:
          "Pasos a seguir para un correcto empredimiento en la bolsa de valores.",
        status: 1,
      },
      {
        id: 4,
        name: "Pizza's Order",
        description:
          "Pasos a seguir para un correcto empredimiento en la bolsa de valores.",
        status: 1,
        activities: [
          {
            id: "SelectAPizzaTask",
            name: "Select a Pizza",
            description: "Selección de pizza, entre diferentes sabores.",
            status: 1,
            schema: {
              schemaVersion: 2,
              exporter: {
                name: "form-js (https://demo.bpmn.io)",
                version: "0.3.0",
              },
              components: [
                {
                  text: "Pizza Menu",
                  type: "text",
                  id: "Field_0qqrbv5",
                },
                {
                  values: [
                    {
                      label: "Pizza Hawaiana",
                      value: "hawaiana",
                    },
                    {
                      label: "Pizza de Jamón",
                      value: "jamon",
                    },
                    {
                      label: "Pizza Napolitana",
                      value: "napolitana",
                    },
                    {
                      label: "Pizza Margarita",
                      value: "margarita",
                    },
                  ],
                  key: "pizza_choice",
                  label: "PIzza",
                  type: "select",
                  id: "Field_1j659ef",
                  description: "Escoja un tipo de Pizza",
                  validate: {
                    required: true,
                  },
                },
                {
                  action: "submit",
                  key: "field_7",
                  label: "Select",
                  type: "button",
                  id: "Field_005z2jn",
                },
              ],
              type: "default",
              id: "Form_0pwpjt6",
            },
          },
          {
            id: "_12",
            name: "Order Pizza",
            description: "Ordenar una pizza en el cajero.",
            status: 1,
          },
          {
            name: "Ask for the pizza",
            description: "Si la orden se atraza, preguntar por la pizza.",
            status: 1,
          },
          {
            name: "Pay the pizza",
            description: "Pagar por la pizza.",
            status: 2,
          },
          {
            name: "Eat the pizza",
            description: "Finalmente, comer la pizza.",
            status: 2,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Modelo de la calidad del Aire",
    category: "Sustentables",
    hashtag: "Aire, Calidad",
    img: getImageURL("angular"),
    owner: "Juan Paredes",
    desc_short:
      "Modelo del diagnóstico de afectación del aire en comunidades del Ecuador",
    desc_small:
      "Al usar este modelo, usted podrá determinar la calidad del aire y cómo esto se ve afectado por otros elementos de las grandes ciudades",
    qualification: 4.5,
    nro_dowloads: 100,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
    processes: [
      {
        id: 1,
        name: "Riesgos territoriales",
        description:
          "Se realizarán los pasos para invertir en la bolsa de valores de forma correcta",
        activities: [
          {
            name: "Llenar el formulario",
            description:
              "Llenar el formulario de requisitos para poder invertir en la bolsa",
          },
        ],
      },
      {
        id: 2,
        name: "Emprender en la bolsa de valores",
        description:
          "Pasos a seguir para un correcto empredimiento en la bolsa de valores.",
      },
    ],
  },
  {
    id: 3,
    name: "Manejo de bosques",
    category: "Sustentables",
    hashtag: "aprender",
    img: getImageURL("node"),
    owner: "Juan Ramirez",
    desc_short: "Modelo de manejo sustentable de bosques en Ecuador",
    desc_small:
      "Modelo de competencia entre PYMES en donde se veran diferentes puntos de vista entre como avanzan en el dia a dia",
    qualification: 3.5,
    nro_dowloads: 50,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
  {
    id: 5,
    name: "Incorporación de tecnología a la educación",
    category: "Educacion",
    hashtag: "aprender",
    img: getImageURL("java"),
    owner: "El Pepe",
    desc_short:
      "Modelo de diagnóstico del uso de tecnologías en el desarrollo de los estudiantes",
    desc_small: "Modelo de rendimiento de estudiantes para su mejor educación",
    qualification: 4.5,
    nro_dowloads: 100,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
  {
    id: 6,
    name: "Planificación de áreas de estudio",
    category: "Educacion",
    hashtag: "aprender",
    img: getImageURL("asp.net"),
    owner: "El Pepe",
    desc_short:
      "Modelo para determinar la planificación efectiva de áreas de estudio",
    desc_small: "Modelo de rendimiento de estudiantes para su mejor educación",
    qualification: 5,
    nro_dowloads: 100,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
  {
    id: 7,
    name: "Rendimiento académico de Estudiantes de acuerdo al nivel de ingreso económico",
    category: "Educacion",
    hashtag: "aprender",
    img: getImageURL("go"),
    owner: "El Pepe",
    desc_short: "Modelo de rendimiento de estudiantes",
    desc_small: "Modelo de rendimiento de estudiantes para su mejor educación",
    qualification: 3,
    nro_dowloads: 100,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
];
export const herramientas = [
  {
    id: 1,
    name: "Cliente SIG QGIS",
    category: "Sustentables",
    hashtag: "Agua, Calidad",
    img: getImageURL2("qgis"),
    owner: "Andrés Sapatanga",
    desc_short: "Manejo responsable de las fuentes hídricas",
    desc_small:
      "Al usar este modelo, usted podrá determinar la calidad del agua y cómo esto se ve afectado por otros elementos de las grandes ciudades",
    qualification: 4.5,
    nro_dowloads: 1597,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
    status: 1,
  },
  {
    id: 2,
    name: "Modelamiento mediante Grafos",
    category: "Sustentables",
    hashtag: "Aire, Calidad",
    img: getImageURL2("neo4j"),
    owner: "Juan Paredes",
    desc_short:
      "Modelo del diagnóstico de afectación del aire en comunidades del Ecuador",
    desc_small:
      "Al usar este modelo, usted podrá determinar la calidad del aire y cómo esto se ve afectado por otros elementos de las grandes ciudades",
    qualification: 4.5,
    nro_dowloads: 100,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
  {
    id: 3,
    name: "Visor Web de mapas",
    category: "Sustentables",
    hashtag: "aprender",
    img: getImageURL("node"),
    owner: "Juan Ramirez",
    desc_short: "Modelo de manejo sustentable de bosques en Ecuador",
    desc_small:
      "Modelo de competencia entre PYMES en donde se veran diferentes puntos de vista entre como avanzan en el dia a dia",
    qualification: 3.5,
    nro_dowloads: 50,
    date_create: "21-08-2021 00:00:00",
    date_update: "22-08-2021 00:00:00",
  },
];

const moments = [
  {
    id: 1,
    name: "Intuir - Idear",
    description:
      "Es el momento en donde se manifiestan las primeras intuiciones de cómo intervenir en la estructura de la realidad por parte de los diferentes actores y agentes del desarrollo territorial, estas intuiciones evolucionan hacia ideas, las mismas que se materializan en un equipo y un programa de trabajo. Este momento evolucionará y realimentará al resto de momentos.",
    status: 1,
    processes: [
      {
        id: 1,
        name: "Pizza's Preparation",
        description: "Se indicarán los pasos para hacer una pizza",
        status: 1,
        activities: [
          {
            name: "Conseguir Ingredientes",
            description:
              "Obtención y preparación de los ingredientes previo a su preparación.",
          },
        ],
      },
      {
        id: 2,
        name: "Pizza's Marketing",
        description:
          "Pasos recomendados para realizar un correcto Marketing de una Pizza",
        status: 0,
        activities: [
          {
            name: "Tomar fotos",
            description:
              "Obtención y preparación de los ingredientes previo a su preparación.",
          },
        ],
      },
      {
        id: 3,
        name: "Pizza's Sale",
        description:
          "Pasos a seguir para un correcto empredimiento en la bolsa de valores.",
        status: 1,
      },
      {
        id: 4,
        name: "Pizza's Order",
        description:
          "Pasos a seguir para un correcto empredimiento en la bolsa de valores.",
        status: 1,
        activities: [
          {
            id: "SelectAPizzaTask",
            name: "Select a Pizza",
            description: "Selección de pizza, entre diferentes sabores.",
            status: 1,
            schema: {
              schemaVersion: 2,
              exporter: {
                name: "form-js (https://demo.bpmn.io)",
                version: "0.3.0",
              },
              components: [
                {
                  text: "Pizza Menu",
                  type: "text",
                  id: "Field_0qqrbv5",
                },
                {
                  values: [
                    {
                      label: "Pizza Hawaiana",
                      value: "hawaiana",
                    },
                    {
                      label: "Pizza de Jamón",
                      value: "jamon",
                    },
                    {
                      label: "Pizza Napolitana",
                      value: "napolitana",
                    },
                    {
                      label: "Pizza Margarita",
                      value: "margarita",
                    },
                  ],
                  key: "pizza_choice",
                  label: "PIzza",
                  type: "select",
                  id: "Field_1j659ef",
                  description: "Escoja un tipo de Pizza",
                  validate: {
                    required: true,
                  },
                },
                {
                  action: "submit",
                  key: "field_7",
                  label: "Select",
                  type: "button",
                  id: "Field_005z2jn",
                },
              ],
              type: "default",
              id: "Form_0pwpjt6",
            },
          },
          {
            id: "_12",
            name: "Order Pizza",
            description: "Ordenar una pizza en el cajero.",
            status: 1,
          },
          {
            name: "Ask for the pizza",
            description: "Si la orden se atraza, preguntar por la pizza.",
            status: 1,
          },
          {
            name: "Pay the pizza",
            description: "Pagar por la pizza.",
            status: 2,
          },
          {
            name: "Eat the pizza",
            description: "Finalmente, comer la pizza.",
            status: 2,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Comprender - Aprender",
    description:
      "Todos los participantes comprenden los objetivos, metas, teorías, métodos y herramientas del MIT, para luego aprender colectivamente como se estructuran y funcionan los sistemas territorial, espacial y temático (tema del MIT). Este momento se materializa en un documento que defina el estado de situación de estos sistemas en un espaciotiempo específico.",
    status: 0,
    processes: [
      {
        id: 1,
        name: "Riesgos territoriales",
        description:
          "Se realizarán los pasos para invertir en la bolsa de valores de forma correcta",
        activities: [
          {
            name: "Llenar el formulario",
            description:
              "Llenar el formulario de requisitos para poder invertir en la bolsa",
          },
        ],
      },
      {
        id: 2,
        name: "Emprender en la bolsa de valores",
        description:
          "Pasos a seguir para un correcto empredimiento en la bolsa de valores.",
      },
    ],
  },
  {
    id: 3,
    name: "Crear - Proponer",
    description:
      "Con las capacidades adquiridas de los usuarios en los dos momentos anteriores, se inicia la creatividad colectiva de propuestas para cambiar la realidad territorial, espacial y temática (específica). Este momento se expresa en políticas, programas y proyectos, en modelos de gestión y en planes de manejo específicos.",
    status: 1,
  },
  {
    id: 4,
    name: "Implementar - Evaluar",
    description:
      "En este momento se ejecuta lo creado y propuesto y se verifican las transformaciones que producen en los sistemas. Se materializa este momento en la constatación y evaluación de dichas transformaciones a través de mecanismos de seguimiento y especialmente de la aplicación de variables e indicadores estructurados específicamente para cambios y transformaciones específicas.",
    status: 1,
  },
  {
    id: 5,
    name: "Capitalizar - Realimentar",
    description:
      "La aplicación de un MIT genera y transforma cierta cantidad de principios, teorías, métodos y herramientas –es decir conocimiento- sobre temas concretos del desarrollo territorial sostenible. La aplicación del mismo produce experiencias y aprendizaje. En este sentido este componente tiene el propósito de capitalizar dicho conocimiento y realimentar los procesos de elaboración e implementación de un MIT. Así garantizamos que el MIT y Gearth se actualicen y aprendan permanentemente.",
    status: 1,
  },
];
export const polls = [
  {
    id: "61b136e342b28de3381eb38e",
    name: "Prueba",
    description: "Prueba",
    process_id: 5,
    activity_id: 2,
    model_id: 232,
    created_at: "2021-12-08T22:51:15.649000",
    updated_at: "2022-03-07T17:22:43.868000",
    json_body: {
      questions: [
        {
          id: 3,
          questionText: "Question 3",
          options: [
            {
              id: 1,
              optionText: "Option 1"
            },
            {
              id: 2,
              optionText: "Option 2"
            },
            {
              id: 3,
              optionText: "Option 3"
            }
          ],
          open: false,
          type: "Not Categorical",
          type_option: "multiple",
          subsistemId: 1,
          variableId: 5
        },
        {
          id: 4,
          questionText: "Question 4",
          options: [],
          open: false,
          type: "Categorical",
          type_option: "open",
          subsistemId: 2,
          variableId: 6
        }
      ]
    },
    status: 1
  },
  {
    id: "61771532cab0a86fda4f395c",
    name: "Nueva Encuesta",
    description: "Survey on ho to prepare homemade pizza",
    to_post: true,
    process_id: 5,
    activity_id: 2,
    model_id: 229,
    creation_number:100,
    assigned_surveys:0,
    created_at: "2021-10-19T20:33:25.402000",
    updated_at: "2021-10-19T20:33:25.402000",
    json_body: {
      questions: [],
    },
    status: 1,
  },
  {
    id: "61b136e342b28de3381eb38w",
    name: "Prueba 2",
    description: "Prueba 2 de ejmplo",
    to_post:true,
    process_id: 5,
    activity_id: 2,
    model_id: 232,
    creation_number:40,
    assigned_surveys:0,
    created_at: "2021-12-08T22:51:15.649000",
    updated_at: "2022-03-07T17:22:43.868000",
    json_body: {
      questions: [
        {
          id: 3,
          questionText: "Question 3",
          options: [
            {
              id: 1,
              optionText: "Option 1"
            },
            {
              id: 2,
              optionText: "Option 2"
            },
            {
              id: 3,
              optionText: "Option 3"
            }
          ],
          open: false,
          type: "Not Categorical",
          type_option: "multiple",
          subsistemId: 1,
          variableId: 5
        },
        {
          id: 4,
          questionText: "Question 4",
          options: [],
          open: false,
          type: "Categorical",
          type_option: "open",
          subsistemId: 2,
          variableId: 6
        }
      ]
    },
    status: 1
  },
  {
    id: "61771532cab0a86fda4f395k",
    name: "Nueva Encuesta",
    description: "Survey on ho to prepare homemade pizza",
    to_post: true,
    process_id: 5,
    activity_id: 2,
    model_id: 232,
    creation_number:100,
    assigned_surveys:0,
    created_at: "2021-10-19T20:33:25.402000",
    updated_at: "2021-10-19T20:33:25.402000",
    json_body: {
      questions: [],
    },
    status: 1,
  },
];

const components = [
  {
    component: "container",
    attributes: {
      id: "form-example",
      class: "card p-shadow-10",
    },
    contents: [
      {
        component: "inputtext",
        attributes: {
          id: "name",
          name: "name",
          type: "text",
          placeholder: "Write your name ...",
        },
        events: {},
        icon: {
          icon: "pi pi-user",
          position: "left",
        },
      },
      {
        component: "inputtext",
        attributes: {
          id: "lname",
          class: "",
          name: "lname",
          type: "text",
          placeholder: "Write your last name ...",
        },
        icon: {
          icon: "pi pi-user",
          position: "left",
        },
        label: {
          label: "Last Name",
          float: true,
          class: "",
        },
        events: {},
      },
      {
        component: "button",
        attributes: {
          id: "print",
          class: "p-button-rounded p-button-secondary",
          label: "Print",
        },
        icon: {
          icon: "pi pi-check",
        },
        events: {},
      },
      {
        component: "inputtext",
        attributes: {
          id: "example",
          class: "",
          type: "text",
          placeholder: "Example",
        },
        events: {},
      },
      {
        component: "inputtext",
        attributes: {
          id: "country",
          class: "",
          type: "text",
          placeholder: "Write your Country ...",
        },
        icon: {
          icon: "pi pi-user",
          position: "left",
        },
        label: {
          label: "Country",
          float: false,
          class: "p-mx-1",
        },
        events: {},
      },
      {
        component: "inputtext",
        attributes: {
          id: "CI",
          class: "",
          type: "text",
          placeholder: "Write your Identification ...",
        },
        label: {
          label: "Identification",
          float: false,
          class: "p-mx-1",
        },
        containers: ["p-field", "p-fluid"],
        events: {},
      },
      {
        component: "inputtextarea",
        attributes: {
          id: "commentary",
          name: "commentary",
          class: "",
          autoresize: true,
          placeholder: "Write your commentary",
          rows: 5,
          cols: 50,
        },
        icon: {
          icon: "pi pi-user",
          position: "left",
        },
        label: {
          label: "Commentary",
          float: true,
          class: "p-mx-1",
        },
        containers: ["p-my-5"],
      },
      {
        component: "inputtextarea",
        attributes: {
          id: "opinion",
          autoresize: true,
          placeholder: "Write your Opinion",
          rows: 5,
          cols: 50,
        },
        icon: {
          icon: "pi pi-user",
          position: "left",
        },
        label: {
          label: "Opinion",
          float: false,
          class: "p-mx-1",
        },
        containers: ["p-field", "p-fluid"],
      },
      {
        component: "autocomplete",
        attributes: {
          id: "autocomplete",
          multiple: true,
          placeholder: "Select Color",
        },
        label: {
          label: "AutoComplete",
          float: true,
          class: "p-mx-1",
        },
      },
      {
        component: "autocomplete",
        attributes: {
          id: "autocomplete2",
          multiple: false,
          placeholder: "Select Color",
        },
        label: {
          label: "AutoComplete",
          class: "p-mx-1",
        },
        containers: ["p-field", "p-fluid"],
      },
      {
        component: "calendar",
        attributes: {
          id: "schedule",
          name: "schedule",
          placeholder: "Select your Schedule",
        },
        label: {
          label: "Schedule",
          class: "p-mx-1",
        },
        containers: ["p-field", "p-fluid", "card", "p-shadow-10 p-my-2"],
      },
      {
        component: "calendar",
        attributes: {
          id: "schedule2",
          placeholder: "Select your Schedule",
        },
        label: {
          label: "Schedule",
          float: true,
          class: "p-mx-1",
        },
      },
      {
        component: "inputnumber",
        attributes: {
          id: "age",
          name: "age",
          placeholder: "Enter your age ...",
          mode: "decimal",
        },
        label: {
          label: "Age",
          float: true,
        },
        icon: {
          icon: "pi pi-user",
          position: "left",
        },
        containers: ["p-my-5"],
      },
      {
        component: "chips",
        attributes: {
          id: "chips",
          placeholder: "Enter your chip ...",
        },
        label: {
          label: "Chips",
          float: true,
        },
        containers: ["p-my-3"],
      },
      {
        component: "chips",
        attributes: {
          id: "members",
          name: "members",
          placeholder: "Enter the members ...",
        },
        label: {
          label: "Members List",
        },
        containers: ["p-field", "p-fluid card", "p-col-6 p-shadow-20"],
      },
      {
        component: "slider",
        attributes: {
          id: "volume",
          name: "volume",
        },
        label: {
          label: "Slider",
        },
        containers: ["p-field", "p-col-5 p-shadow-20 p-mt-5"],
      },
      {
        component: "rating",
        attributes: {
          id: "qualification",
          name: "qualification",
          class: "",
        },
        label: {
          label: "Qualification",
          class: "p-mx-1",
        },
        containers: ["p-field", "p-col-5 p-shadow-20 p-mt-5"],
      },
      {
        component: "colorpicker",
        attributes: {
          id: "color",
          name: "color",
          class: "",
          defaultcolor: "#000",
          tooltip: "Color",
        },
        label: {
          label: "Color",
          class: "p-mx-1",
        },
        icon: {
          icon: "pi pi-user",
          position: "left",
        },
        containers: ["p-my-5"],
      },
      {
        component: "knob",
        attributes: {
          id: "downloaded",
          name: "downloaded",
          class: "",
          tooltip: "Downloaded",
          min: 0,
          max: 100,
          step: 2,
        },
        label: {
          label: "Downloaded",
          class: "p-mx-1",
        },
        containers: ["p-my-5"],
      },
    ],
  },
  {
    component: "container",
    attributes: {
      class: "card p-shadow-10",
    },
    contents: [
      {
        component: "button",
        attributes: {
          id: "print",
          class: "p-button-rounded p-button-secondary",
          label: "Print",
        },
        icon: {
          icon: "pi pi-check",
        },
        events: {},
      },
      {
        component: "radiobutton",
        attributes: {
          name: "gender",
        },
        options: [
          {
            id: "male",
            value: "male",
            label: {
              label: "Male",
              class: "",
            },
            containers: ["p-col"],
          },
          {
            id: "female",
            value: "female",
            label: {
              label: "Female",
              class: "p-shadow-10",
            },
            containers: ["p-col"],
          },
          {
            id: "other",
            value: "other",
            label: {
              label: "Other",
              class: "",
            },
            containers: ["p-col"],
          },
        ],
        containers: ["p-grid"],
      },
      {
        component: "checkbox",
        attributes: {
          name: "courses",
        },
        options: [
          {
            id: "language",
            value: "language",
            label: {
              label: "Language",
              class: "",
            },
            containers: ["p-col"],
          },
          {
            id: "english",
            value: "english",
            label: {
              label: "English",
              class: "p-shadow-10",
            },
            containers: ["p-col"],
          },
          {
            id: "physical",
            value: "physical",
            label: {
              label: "Physical",
              class: "",
            },
            containers: ["p-col"],
          },
        ],
        containers: ["p-grid"],
      },
      {
        component: "inputswitch",
        attributes: {
          id: "status",
          name: "status",
        },
        label: {
          label: "Status",
          class: "p-d-block",
        },
        containers: ["p-field", "p-fluid"],
      },
      {
        component: "listbox",
        attributes: {
          id: "country",
          name: "country",
          filter: true,
        },
        options: {
          options: [
            {
              id: 1,
              country: "Ecuador",
            },
            {
              id: 2,
              country: "España",
            },
            {
              id: 3,
              country: "Brazil",
            },
          ],
          label: "country",
        },
        label: {
          label: "Country",
          class: "p-d-block p-my-1",
        },
        containers: ["col"],
      },
      {
        component: "dropdown",
        attributes: {
          id: "city",
          name: "city",
        },
        options: {
          options: [
            {
              id: 1,
              city: "Cuenca",
            },
            {
              id: 2,
              city: "Guayaquil",
            },
            {
              id: 3,
              city: "Quito",
            },
          ],
          label: "city",
        },
        label: {
          label: "Country",
          float: true,
          class: "",
        },
        containers: ["p-my-6", "p-field", "p-fluid"],
      },
      {
        component: "multiselect",
        attributes: {
          id: "products",
          name: "products",
          placeholder: "Ingresa tus productos ...",
          class: "multiselect-custom",
          filter: true,
        },
        options: {
          options: [
            {
              id: 1,
              product: "Head Phones",
              price: 10,
            },
            {
              id: 2,
              product: "Pencil",
              price: 0.45,
            },
            {
              id: 3,
              product: "Phone",
              price: 190,
            },
          ],
          label: "product",
        },
        label: {
          label: "Products",
          float: true,
          class: "",
        },
        containers: ["p-my-6", "p-field", "p-fluid"],
      },
      {
        component: "togglebutton",
        attributes: {
          id: "isadmin",
          name: "isadmin",
        },
        label: {
          label: "Is Admin?",
          class: "p-d-block",
        },
        icon: {
          on: "pi pi-check",
          off: "pi pi-times",
          position: "left",
        },
        containers: ["p-field"],
      },
      {
        component: "selectbutton",
        attributes: {
          id: "plan",
          name: "plan",
          multiple: false,
        },
        options: {
          options: [
            {
              id: 1,
              plan: "Gold",
              price: 5,
            },
            {
              id: 2,
              plan: "Plate",
              price: 7,
            },
            {
              id: 3,
              plan: "Diamond",
              price: 10,
            },
          ],
          label: "plan",
        },
        label: {
          label: "Plans",
          class: "p-d-block",
        },
        containers: ["p-field"],
      },
      {
        component: "selectbutton",
        attributes: {
          id: "ingredients",
          name: "ingredients",
          multiple: true,
        },
        options: {
          options: [
            {
              id: 1,
              ingredient: "Pepperoni",
            },
            {
              id: 2,
              ingredient: "Salami",
            },
            {
              id: 3,
              ingredient: "Pineapple",
            },
            {
              id: 4,
              ingredient: "Sauce",
            },
          ],
          label: "ingredient",
        },
        label: {
          label: "Pizza's Ingredients",
          class: "p-d-block",
        },
        containers: ["p-field"],
      },
      {
        component: "inputmask",
        attributes: {
          id: "date",
          name: "date",
          mask: "99/99/9999",
          slotchar: "-",
        },
        label: {
          label: "Date",
          float: true,
        },
        containers: ["p-my-3"],
      },
      {
        component: "datatable",
        attributes: {
          id: "clients",
        },
        label: {
          label: "Data Table",
          float: true,
        },
        containers: ["card"],
      },
    ],
  },
];


export const peopleAssign =[
  {
    id:1,
    userId:1,
    surveyId:'61b136e342b28de3381eb38e',
    numberSurveys:0
  },
  {
    id:3,
    userId:1,
    surveyId:'61b136e342b28de3381eb38w',
    numberSurveys:0
  },
  {
    id:4,
    userId:3,
    surveyId:'61771532cab0a86fda4f395k',
    numberSurveys:0
  },
  {
    id:5,
    userId:4,
    surveyId:'61b136e342b28de3381eb38e',
    numberSurveys:0
  },
]

//Methods | Controllers


export const filterAssignByUser = (id) =>{
  return peopleAssign.filter((p) => {
    if (p.userId === id) {
      return p;
    }else{
      return null;
    }
  }

  );
}

export const findUserByCredentials = (username, password) => {
  return users.find((u) => {
    if (u.username === username && u.password === password) {
      return u;
    } else {
      return null;
    }
  });
};

export const findPollsByProcess = (id) => {
  return polls.filter((p) => {
    if (p.process_id === id) {
      return p;
    } else {
      return null;
    }
  });
};
export const findPollsById = (id) => {
  return polls.find((p) => {
    if (p.id === id) {
      return p;
    } else {
      return null;
    }
  });
};

export const findSurveryByModel = (id) =>{
  return polls.filter((p)=>{
    if (p.model_id === id && p.to_post === true )  {
      return p;
    }else{
      return null;
    }
  });
};

export const findPersonById = (id) => {
  return people.find((p) => {
    if (p.id === id) {
      return p;
    } else {
      return null;
    }
  });
};

export const findUserByPerson = (id) => {
  return users.find((u) => {
    if (u.person === id) {
      return u;
    } else {
      return null;
    }
  });
};

export const findRoleById = (id) => {
  return roles.find((r) => {
    if (r.id === id) {
      return r;
    } else {
      return null;
    }
  });
};

export const findPlanById = (id) => {
  return plans.find((p) => {
    if (p.id === id) {
      return p;
    } else {
      return null;
    }
  });
};

export const findModelById = (id) => {
  return modelos.find((model) => {
    if (model.id.toString() === id.toString()) {
      return model;
    } else {
      return null;
    }
  });
};

export const findPlanFByPlan = (id) => {
  return plan_features.filter((d) => d.plan_id === id);
};

export const findAllRoles = () => {
  return roles;
};

export const findAllTeams = () => {
  return teams;
};

export const findAllPlans = () => {
  return plans;
};

export const findAllPaymentMethods = () => {
  return payment_methods;
};

export const findAllMenuRoles = () => {
  return menu_roles;
};

export const findAllComponents = () => {
  return components;
};

export const findAllMoments = () => {
  return moments;
};

// FROM VERTICA
export const findAllModels = () => {
  return modelos;
};

export const findAllSubsystems = () => {
  return subsystems;
};

export const findAllPeople = () => {
  return people;
};

export const findAllTerritorialSystemModels = () => {
  return territorialSystemModels;
};

export const TerritorialSystem = {
  1000000: {
    name: "Sistema Territorial",
    groupId: "1000000",
    components: ["50", "51", "52", "53", "54"],
  },
  50: {
    name: "Medio Natural",
    groupId: "50",

    components: ["1", "2", "3", "4", "5"],
  },
  1: {
    name: "Aire",
    groupId: "50",
    components: [],
  },
  2: {
    name: "Agua",
    groupId: "50",
    components: ["200"],
  },
  200: {
    name: "Agua subterranea",
    groupId: "50",
    components: [],
  },
  3: {
    name: "Clima",
    groupId: "50",
    components: [],
  },
  4: {
    name: "Fauna",
    groupId: "50",
    components: [],
  },
  5: {
    name: "Flora",
    groupId: "50",
    components: [],
  },
  51: {
    name: "Político Institucional",
    groupId: "51",
    components: ["9", "10", "11"],
  },
  9: {
    name: "Derecho",
    groupId: "51",
    components: [],
  },
  10: {
    name: "Políticas",
    groupId: "51",
    components: [],
  },
  11: {
    name: "Organizaciones Sociales",
    groupId: "51",
    components: [],
  },
  52: {
    name: "Económico Productivo",
    groupId: "52",
    components: ["15", "16", "17"],
  },
  15: {
    name: "Primario",
    groupId: "52",
    components: [],
  },
  16: {
    name: "Secundario",
    groupId: "52",
    components: [],
  },
  17: {
    name: "Terciario",
    groupId: "52",
    components: [],
  },
  53: {
    name: "Físico Espacial",
    groupId: "53",
    components: ["22", "23"],
  },
  22: {
    name: "Construcción",
    groupId: "53",
    components: ["500"],
  },
  500: {
    name: "Vivienda",
    groupId: "53",
    components: ["5000"],
  },
  5000: {
    name: "Tecnología",
    groupId: "53",
    components: [],
  },
  23: {
    name: "Espacio Público",
    groupId: "53",
    components: [],
  },
  54: {
    name: "Socio Cultural",
    groupId: "54",
    components: ["26", "27"],
  },
  26: {
    name: "Demografía",
    groupId: "54",
    components: [],
  },
  27: {
    name: "Educación",
    groupId: "54",
    components: [],
  },
};

// export const MIT = {
//     "1000000": {
//         name: "Gestión sostenible de bienes públicos",
//         groupId: "1000000",
//         components: ["3", "4", "2"]
//     },
//     "2": {
//         name: "Agua",
//         groupId: "50",
//         components: ["20"]
//     },
//     "20": {
//         name: "Calidad del agua",
//         groupId: "50",
//         components: []
//     },
//     "3": {
//         name: "Construcción",
//         groupId: "51",
//         components: []
//     },
//     "4": {
//         name: "Espacio Público",
//         groupId: "51",
//         components: ["10", "11"]
//     },
//     "10": {
//         name: "Calidad de espacios públicos",
//         groupId: "51",
//         components: []
//     },
//     "11": {
//         name: "Comfort de bienes muebles en parques",
//         groupId: "51",
//         components: []
//     },
// };

// export const TerritorialSystem2 = {
//     "50": {
//         name: "Medio Natural",
//         componets: {
//             "1": {
//                 name: "Aire",
//                 components: {
//                     "1": {
//                         name: "Aire ecuatorial"
//                     },
//                     "2": {
//                         name: "Aire tropical"
//                     }
//                 }
//             },
//             "2": {
//                 name: "Agua",
//                 components: {
//                     "3": {
//                         name: "Agua dulce"
//                     },
//                     "4": {
//                         name: "Agua salada"
//                     },
//                 }
//             },
//             "3": {
//                 name: "Clima",
//                 components: {}
//             },
//             "4": {
//                 name: "Fauna",
//                 components: {}
//             }
//         }
//     },
//     "51": {
//         name: "Político Institucional",
//         componets: {
//             "5": {
//                 name: "Derecho",
//                 components: {}
//             },
//             "6": {
//                 name: "Políticas",
//                 components: {}
//             },
//             "7": {
//                 name: "Organizaciones Sociales",
//                 components: {}
//             },
//             "8": {
//                 name: "Gobierno",
//                 components: {}
//             }
//         }
//     },
//     "52": {
//         name: "Económico Productivo",
//         componets: {
//             "9": {
//                 name: "Primario",
//                 components: {}
//             },
//             "10": {
//                 name: "Secundario",
//                 components: {}
//             },
//             "11": {
//                 name: "Terciario",
//                 components: {}
//             },
//             "12": {
//                 name: "Comunicación",
//                 components: {}
//             }
//         }
//     },
//     "53": {
//         name: "Físico Espacial",
//         componets: {
//             "13": {
//                 name: "Construcción",
//                 components: {}
//             },
//             "14": {
//                 name: "Espacio Público",
//                 components: {
//                     "15": {
//                         name: "Parques"
//                     }
//                 }
//             },
//             "15": {
//                 name: "Infraestructura",
//                 components: {}
//             },
//             "16": {
//                 name: "Vialidad",
//                 components: {
//                     "22": {
//                         name: "Carreteras"
//                     }
//                 }
//             }
//         }
//     },
//     "54": {
//         name: "Socio Cultural",
//         componets: {
//             "17": {
//                 name: "Demografía",
//                 components: {}
//             },
//             "18": {
//                 name: "Educación",
//                 components: {}
//             },
//             "19": {
//                 name: "Cultura",
//                 components: {}
//             },
//             "20": {
//                 name: "Estilo de Vida",
//                 components: {}
//             }
//         }
//     },

// }

export const territorialSubSystems = [
  {
    name: "Medio Natural",
    components: [
      { id: "1", name: "Aire", subsystemId: "50" },
      { id: "2", name: "Agua", subsystemId: "50" },
      { id: "3", name: "Clima", subsystemId: "50" },
      { id: "4", name: "Fauna", subsystemId: "50" },
      { id: "5", name: "Flora", subsystemId: "50" },
      { id: "6", name: "Relieve", subsystemId: "50" },
      { id: "7", name: "Suelo", subsystemId: "50" },
      { id: "8", name: "Ubicación", subsystemId: "50" },
    ],
    id: "50",
  },
  {
    name: "Político Institucional",
    components: [
      { id: "9", name: "Derecho", subsystemId: "51" },
      { id: "10", name: "Políticas", subsystemId: "51" },
      { id: "11", name: "Organizaciones Sociales", subsystemId: "51" },
      { id: "12", name: "Gobierno", subsystemId: "51" },
      { id: "13", name: "Legal", subsystemId: "51" },
      { id: "14", name: "OTB", subsystemId: "51" },
    ],
    id: "51",
  },
  {
    name: "Económico Productivo",
    components: [
      { id: "15", name: "Primario", subsystemId: "52" },
      { id: "16", name: "Secundario", subsystemId: "52" },
      { id: "17", name: "Terciario", subsystemId: "52" },
      { id: "18", name: "Comunicación", subsystemId: "52" },
      { id: "19", name: "Servicios", subsystemId: "52" },
      { id: "20", name: "Mano de Obra", subsystemId: "52" },
      { id: "21", name: "Servicios", subsystemId: "52" },
    ],
    id: "52",
  },
  {
    name: "Físico Espacial",
    components: [
      { id: "22", name: "Construcción", subsystemId: "53" },
      { id: "23", name: "Espacio Público", subsystemId: "53" },
      { id: "24", name: "Infraestructura", subsystemId: "53" },
      { id: "25", name: "Vialidad", subsystemId: "53" },
    ],
    id: "53",
  },
  {
    name: "Socio Cultural",
    components: [
      { id: "26", name: "Demografía", subsystemId: "54" },
      { id: "27", name: "Educación", subsystemId: "54" },
      { id: "28", name: "Cultura", subsystemId: "54" },
      { id: "29", name: "Estilo de Vida", subsystemId: "54" },
      { id: "30", name: "Movilidad", subsystemId: "54" },
      { id: "31", name: "Salud", subsystemId: "54" },
    ],
    id: "54",
  },
];

// FROM VERTICA
export const territorialSystemModels = [
  {
    id: "neo4j_12345",
    userId: "11111",
    name: "My new territorial system model 1",
    description: "Some description 1",
    // erased: true or false
  },
  {
    id: "neo4j_12346",
    userId: "11111",
    name: "My new territorial system model 2",
    description: "Some description 2",
  },
  {
    id: "neo4j_12347",
    userId: "11111",
    name: "My new territorial system model 3",
    description: "Some description 3",
  },
  {
    id: "neo4j_12348",
    userId: "11111",
    name: "My new territorial system model 4",
    description: "Some description 4",
  },
];

const MODEL_DATA_FOR_TESTING = {
  //Only estructure and data reference
  name: "Test Model",
  description: "This model was created for testing",
  long_description:
    "The test model aims to test: processes, forms, surveys and social.",
  user_id: 3,
  price: 100.0,
  image: "https://pngimage.net/wp-content/uploads/2018/06/test-icon-png-7.png",
  category_id: 1,
  team_id: 4,
  hashtag: "Test",
  id: 194,
  thumbnail: null,
  status: 1,
  code: "m_194",
  user: {
    username: "Andr3s",
    id: 3,
    status: 1,
    person: {
      names: "Freddy Andrés",
      last_names: "Sapatanga Quituizaca",
      identification: "0150305183",
      gender: "M",
      id: 4,
      status: true,
      created_at: "2021-08-19T05:03:36.788908+00:00",
      updated_at: "2021-08-19T05:03:36.788908+00:00",
    },
    team: {
      name: "Gearth Developers",
      description: null,
      logo: null,
      id: 4,
      status: 1,
      plan_id: 1,
      created_at: "2021-08-19T05:03:36.783727+00:00",
      updated_at: "2021-08-19T05:03:36.783727+00:00",
      plan: {
        name: "Plan básico",
        description: "Plan gratuito para realizar pruebas",
        team_members_nos: 5,
        model_nos: 2,
        team_app_nos: 6,
        web_storage: 1,
        social_app_storage: 1,
        neo4j_nodes_no: 60,
        neo4_relationships_no: 210,
        firebase_storage_time: 1,
        price: 0.0,
        featured: true,
        discount: 0.0,
        id: 1,
        status: 1,
        created_at: null,
        updated_at: null,
      },
    },
    role: {
      name: "Super Administrador",
      description: "Rol con todos los privilegios",
      id: 1,
      status: 1,
      created_at: null,
      updated_at: null,
    },
    created_at: "2021-08-19T05:03:36.791635+00:00",
    updated_at: "2021-08-19T05:03:36.791635+00:00",
  },
  stage1: false,
  stage2: false,
  stage3: false,
  stage4: false,
  created_at: "2021-10-21T22:08:42.568294+00:00",
  updated_at: "2021-10-21T22:08:42.568294+00:00",
};

export const indicadoresObject = [
    {
        "id": "4373e9fd-5d6b-429d-90b1-3793178ea0c5",
        "model_id": 237,
        "component_id": 194,
        "name": "Agua",
        "concepts": [
            {
                "id": "8921bea0-86b9-488b-b0c0-d2b525c7d334",
                "name": "variable 1 del sub sistema agua",
                "definition": "definición de la variable",
                "model_id": 237,
                "parent_id": "4373e9fd-5d6b-429d-90b1-3793178ea0c5",
                "dimensions": [
                    {
                        "id": "9232fede-32d0-421b-bc5e-36265adf0eee",
                        "name": "Dimensión 1 de la variable 1 del AGUA",
                        "definition": "ALGUNA DEF DE ESTO",
                        "model_id": 237,
                        "parent_id": "8921bea0-86b9-488b-b0c0-d2b525c7d334",
                        "indicators": [
                            {
                                "model_id": 237,
                                "id": "d92cca8f-f5ad-4637-af51-5a7a8e37dcb3",
                                "parent_id": "9232fede-32d0-421b-bc5e-36265adf0eee",
                                "name": "indicador 2 de la dimensión 1 de la variable 1 del AGUA",
                                "definition": "algo de definición",
                                "context": "algo del contexto",
                                "instruments": "algo de los instrumento",
                                "interpretation": "algo de la interpretación ",
                                "periodicity": "algo de la periodicidad",
                                "territorial_unit": "algo de la unidad territorial",
                                "social_group": "algo del grupo social",
                                "infix_expression": "otra variable de indicador * y otra var",
                                "variables": [
                                    {
                                        "id": "134748e6-3932-4814-9331-16f62f379806",
                                        "indicator_id": "d92cca8f-f5ad-4637-af51-5a7a8e37dcb3",
                                        "model_id": 237,
                                        "label": "otra variable de indicador",
                                        "definition": "modo de calcular este indicador",
                                        "variable_type": {
                                            "id": 1,
                                            "label": "Integer",
                                            "definition": "Integer value definition"
                                        },
                                        "measure_type": {
                                            "id": 1,
                                            "label": "Scalar",
                                            "definition": "Some definition for scalar value"
                                        },
                                        "lower_boundary": -10,
                                        "upper_boundary": 10
                                    },
                                    {
                                        "id": "7bc58f34-2cda-4a64-b604-12011fcb06ec",
                                        "indicator_id": "d92cca8f-f5ad-4637-af51-5a7a8e37dcb3",
                                        "model_id": 237,
                                        "label": "y otra var",
                                        "definition": "y otro modo de calcular",
                                        "variable_type": {
                                            "id": 2,
                                            "label": "Decimal",
                                            "definition": "Decimal value definition"
                                        },
                                        "measure_type": {
                                            "id": 2,
                                            "label": "Scalar",
                                            "definition": "Some definition for scalar value"
                                        },
                                        "decimals": 2,
                                        "lower_boundary": "-inf",
                                        "upper_boundary": "inf"
                                    }
                                ],
                                "tags": [
                                    {
                                        "id": "0bf49fd9-cd7f-4038-a495-f502cd37c59b",
                                        "indicator_id": "d92cca8f-f5ad-4637-af51-5a7a8e37dcb3",
                                        "model_id": 237,
                                        "label": "este es un tag",
                                        "parent_type": "Indicator"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "3fe1d741-1d31-42e1-b74f-fa7e8bc0af9b",
        "model_id": 237,
        "component_id": 198,
        "name": "Flora",
        "concepts": [
            {
                "id": "ca7cb0fb-28d7-4873-975f-525522e25a0d",
                "name": "variable A de la flora",
                "definition": "Algo de la flora",
                "model_id": 237,
                "parent_id": "3fe1d741-1d31-42e1-b74f-fa7e8bc0af9b",
                "dimensions": [
                    {
                        "id": "12ffb564-bcf8-4f9e-ab2a-fbf13a8982c5",
                        "name": "Dimensión 1 de la variable 1 de la FLORA",
                        "definition": "ALGO DE ELLO",
                        "model_id": 237,
                        "parent_id": "ca7cb0fb-28d7-4873-975f-525522e25a0d",
                        "indicators": [
                            {
                                "model_id": 237,
                                "id": "f2f22272-e48e-4710-b062-09cc4112fa99",
                                "parent_id": "12ffb564-bcf8-4f9e-ab2a-fbf13a8982c5",
                                "name": "Nombre del indicador 1 de la dimensión 1 de la variable 1 de la flora",
                                "definition": "def",
                                "context": "context",
                                "instruments": "instruments",
                                "interpretation": "interpretation algo",
                                "periodicity": "periodicity description",
                                "territorial_unit": "territorial unit desc",
                                "social_group": "social group desc",
                                "infix_expression": "var 1 del indicador / var2 del indicador",
                                "variables": [
                                    {
                                        "id": "a9cfb3a3-6707-4963-a9dd-23d32679e31e",
                                        "indicator_id": "f2f22272-e48e-4710-b062-09cc4112fa99",
                                        "model_id": 237,
                                        "label": "var 1 del indicador",
                                        "definition": "manera de calcular la var 1 del indicador",
                                        "variable_type": {
                                            "id": 1,
                                            "label": "Integer",
                                            "definition": "Integer value definition"
                                        },
                                        "measure_type": {
                                            "id": 2,
                                            "label": "Nominal",
                                            "definition": "Some definition for nominal value"
                                        },
                                        "value_codification": "hombre:1, mujer:2"
                                    },
                                    {
                                        "id": "581a597a-9cdf-4c55-b489-dc7668a7b390",
                                        "indicator_id": "f2f22272-e48e-4710-b062-09cc4112fa99",
                                        "model_id": 237,
                                        "label": "var2 del indicador",
                                        "definition": "modo de calcular la var 2 del indicador",
                                        "variable_type": {
                                            "id": 3,
                                            "label": "String",
                                            "definition": "String value definition"
                                        }
                                    }
                                ],
                                "tags": []
                            }
                        ]
                    }
                ]
            }
        ]
    }
]
