import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Divider } from "primereact/divider";

const ActionsMenuDialog = (props) => {
  const [layout, setLayout] = useState("grid");

  const menuOptions = [{ label: "Crear" }];

  const itemTemplate = (data) => {
    if (!data) {
      return;
    }
    return (
      <>
        <div className="p-col-12 p-md-3">
          <div className="product-grid-item card">
            <div className="product-grid-item-top" style={{ marginBottom: 15 }}>
              <div className="product-name" style={{ marginBottom: 10 }}>
                <h6>Crear sub sistema</h6>
              </div>
            </div>

            <div className="actions">
              <Button
                icon="pi pi-plus"
                className="p-button-rounded p-button-success p-mr-3"
                onClick={() => props.onCreateComponent()}
              />
            </div>
          </div>
        </div>

        <div className="p-col-12 p-md-3">
          <div className="product-grid-item card">
            <div className="product-grid-item-top" style={{ marginBottom: 15 }}>
              <div className="product-name" style={{ marginBottom: 10 }}>
                <h6>Editar sub sistema</h6>
              </div>
            </div>

            <div className="actions">
              <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-warning p-mr-2"
                onClick={() => props.onEditComponent()}
              />
            </div>
          </div>
        </div>

        <div className="p-col-12 p-md-3">
          <div className="product-grid-item card">
            <div className="product-grid-item-top" style={{ marginBottom: 15 }}>
              <div className="product-name" style={{ marginBottom: 10 }}>
                <h6>Eliminar sub sistema</h6>
              </div>
            </div>

            <div className="actions">
              <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                onClick={() => props.onDeleteComponent()}
              />
            </div>
          </div>
        </div>

        <div className="p-col-12 p-md-3">
          <div className="product-grid-item card">
            <div className="product-grid-item-top" style={{ marginBottom: 15 }}>
              <div className="product-name" style={{ marginBottom: 10 }}>
                <h6>Agregar al modelo</h6>
              </div>
            </div>

            <div className="actions">
              <Button
                icon="pi pi-eye"
                className="p-button-rounded p-button-info"
                onClick={() => props.onAddToModel()}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const dialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          props.setActionsMenuDialog(false);
        }}
      />
    </>
  );

  const DataTitle = () => {
    return (
      <>
        <div style={{ marginRight: 10 }}>
          <h5>Acción a realizar sobre: {props?.component?.name}</h5>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        visible={props.actionsMenuDialog}
        style={{ width: "550px" }}
        header={props.title}
        modal
        className="p-fluid"
        footer={dialogFooter}
        onHide={() => {
          props.setActionsMenuDialog(false);
        }}
      >
        <DataTitle />
        <DataView
          value={menuOptions}
          layout={layout}
          rows={1}
          itemTemplate={itemTemplate}
        />
      </Dialog>
    </>
  );
};

export default ActionsMenuDialog;
