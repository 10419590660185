import React, { useEffect, useState } from 'react'
import UpdateTool from '../Update';
import { deleteElement, listElementsByModel } from '../../../../../redux/actions/toolbox.action';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { GenericDialog } from '../../../../components/UI';
import * as tableTemplates from '../../../utils/templates/tableTemplates'

const ListTool = ({ values, setValues, emptyElement, elements, setElements, showMessage }) => {

    const paginatorTemplate = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown';
    const user = useSelector((state)=> state?.LoginState?.data);
    const model = useSelector((state) => state.ModelState.model);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ globalFilter, setGlobalFilter ] = useState([]);
    const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
    const [ openWorkDialog, setOpenWorkDialog ] = useState(false);

    useEffect(() => {
        listElementsByModel(model?.id, user?.access_token, role).then((data) => {
            setElements(data);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ user, model ])

    const handleSelection = ({ value }) => {
        if (value) setValues(value);
        else setValues(emptyElement);
    }

    const handleDeleteElement = () => {
        deleteElement(values?.id, user?.access_token, role).then((response) => {
            showMessage('success', 'Herramienta eliminada', values?.name);
            setValues(emptyElement);
            listElementsByModel(model?.id, user?.access_token, role).then((data) => {
                setElements(data);
            });
        }).catch(err => {
            showMessage('error', 'Error', 'No se pudo eliminar la herramienta');
        }).finally(() => {
            setOpenDeleteDialog(false);
        })
    }

    const buttonsTemplate = (data) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <Button
                    icon="pi pi-briefcase"
                    className="p-button-success p-button-rounded p-mb-1" 
                    title="Trabajar"
                    onClick={ () => {
                        setValues(data);
                        setOpenWorkDialog(true);
                    }} />
                <Button
                    icon="pi pi-trash" 
                    className="p-button-danger p-button-rounded" 
                    title="Eliminar"
                    onClick={ () => {
                        setValues(data);
                        setOpenDeleteDialog(true);
                    }} />
            </div>
        );      
    }

    const nameAndDescriptionTemplate = (data) => {
        return (
            <div>
                <h6>{ data?.name }</h6>
                <p>
                {(data?.description?.length > 75)
                    ? data?.description?.slice(0, 75) + "..."
                    : data?.description
                }
                </p>
            </div>
        );
    }

    return (
        <div>
            <div className="p-grid crud-demo">
                <DataTable
                    value={ elements } rowHover  
                    className="datatable-responsive"
                    selectionMode='single' 
                    selection={ values } onSelectionChange={ handleSelection }
                    dataKey="id" paginator 
                    rows={ 10 } rowsPerPageOptions={[5, 10, 25]} 
                    paginatorTemplate={ paginatorTemplate }
                    globalFilter={ globalFilter } 
                    emptyMessage="0 herramientas creadas" >
                    
                    <Column selectionMode="single" headerStyle={{ width: '3rem' }} />
                    <Column field="image" header="Imagen" body={ tableTemplates.imageBodyTemplate } />
                    <Column field="name" header="Nombre" sortable body={ nameAndDescriptionTemplate } />
                    <Column style={{ width: '4rem' }} body={ buttonsTemplate  } />

                </DataTable>
            </div>

            <GenericDialog
                visible={ (openDeleteDialog && values?.id) ? true:false }
                header={"Confirmar"}
                onCancelAction={() => {
                    setOpenDeleteDialog(false);
                    setValues(emptyElement);
                } }
                onConfirmationAction={() => handleDeleteElement() }
                onHide={ () => setOpenDeleteDialog(false) }
                message={<span>¿Desea eliminar <b>{values?.name}</b>?</span>}
                condition={ values } />
                
            {(openWorkDialog && values?.id) &&
                <UpdateTool
                    open={ (openWorkDialog && values?.id) ? true:false }
                    setOpen={ setOpenWorkDialog }
                    element={ values } />
            }
        </div>
    );
}

ListTool.propTypes = {}

export default ListTool