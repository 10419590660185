import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextEditor } from '../../../components/UI';
import { updateProcess } from "../../../../redux/actions/process.actions";

const MethodProcess = ({ showMessage, values, handleInputChange }) => {

    const process = useSelector((state) => state.ProcessState?.process);
    const user = useSelector((state) => state.LoginState.data);
    const role = useSelector((state) => state.RolNavigationState?.state);

    const [ hasChanged, setHasChanged ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);
  
    useEffect(() => {
        if (values?.method_process !== process?.method_process) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [ values?.method_process, process?.method_process ]);

    const onUpdateBasicInfo = async (attribute) => {
        setSubmitted(true);
        if (hasChanged) {

            const data = { method_process: values?.method_process }

            updateProcess(process?.id, data, user?.access_token, role).then(data => {
                setHasChanged(false);
                showMessage("success", "Método actualizado", "");
            })
        }
        setSubmitted(false);
    };

    return (
        <>
            <TextEditor 
                title="Método" 
                subtitle="Ingrese la metodología referente al proceso"
                messageError="Error al almacenar el método"
                value={ values?.method_process }
                submitted={submitted}
                onSave={onUpdateBasicInfo}
                actionButtonDisabled={!hasChanged}
                onInputChange={(e) => handleInputChange({ target: { name: 'method_process', value: e?.htmlValue } })}
                helpInformation="Aquí va la información de ayuda que aclara a los diseñadores sobre qué colocarn en el método." />
        </>
    );   
}

export default MethodProcess;