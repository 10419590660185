import React, { useEffect, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { _downloadPNG, _downloadSVG, _downloadXML } from '../../utils/helpers/bpmnHelpers';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';
import { listProcessByModel } from '../../../../redux/actions/acquireds/process.actions';
import { useSelector } from 'react-redux';

const ProcessViewerOptions = ({ diagramSVG, diagramXML, setProcess, process }) => {

    const { model } = useSelector(state => state.ModelState);
    const [ processesOptions, setProcessesOptions ] = useState([]);
    const user = useSelector((state) => state.LoginState.data);

    useEffect(() => {
        if (model?.id) {
            listProcessByModel(model?.id, user?.access_token).then(listResponse => {
                if (listResponse) setProcessesOptions(listResponse);
            });
        }
    }, [ user, model ])
    

    const menuItems = [
        {
            label: 'Image (SVG)',
            icon: 'pi pi-image',
            command: function(e) {
                _downloadSVG(diagramSVG);
            },
        },
        {
            label: 'Image (PNG)',
            icon: 'pi pi-image',
            command: function() {
                _downloadPNG(diagramSVG);
            },
        },
        {
            separator: true,
        },
        {
            label: 'File (XML)',
            icon: 'pi pi-file',
            command: function() {
                _downloadXML(diagramXML);
            },
        },
        {
            label: 'File (PDF)',
            icon: 'pi pi-file',
            disabled: true,
            command: function() {
              console.log('PDF')
            },
        },
    ];

    return (
        <div>
            <Fieldset legend='Opciones' toggleable className='p-mb-3' >
                <div className='p-grid' >
                    <div className="p-col">
                        <Dropdown
                            value={ process } 
                            onChange={(e) => setProcess(e.value)} 
                            options={ processesOptions }
                            optionLabel='name'
                            placeholder="Seleccione un processo" />
                    </div>

                    <div className="p-col">
                        <SplitButton
                            onClick={ (e) => _downloadXML(diagramXML) } 
                            label="Descargar" icon="pi pi-download" model={ menuItems } 
                            className="p-button-info"
                            disabled={(process?.xml_body) ? false:true} />
                    </div>
                </div>
            </Fieldset>
        </div>
    )
}

ProcessViewerOptions.propTypes = {}

export default ProcessViewerOptions;