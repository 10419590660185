import * as actions from '../actions/app-users-social-network.actions';

const {handleActions} = require('redux-actions');

const initialState ={
    appUsers:[],
    error:null,
};

export const AppUsersSocialNetworkReducer = handleActions(
    {
        [actions.setAppUsers]: (state,{payload}) =>({
            ...state,
            appUsers: payload
        }),
        [actions.reset]: (state) =>({
            ...state,
            appUsers: []
        }),
        [actions.setAppUsersErrorAction]: (state,{payload}) =>({
            ...state,
            error:payload
        }),
    },
    initialState,
);
