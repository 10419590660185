import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { assignedSurveysList } from '../../../../redux/actions/survey.actions';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import * as tableTemplates from './../utils/tabletemplates';

const AssignedSurveys = () =>{

    const user = useSelector((state)=> state.LoginState?.data);
    const [ assigndeSurveyData, setAssignedSurveyData ] = useState();
    const [ globalFilter, setGlobalFilter ] = useState();

    const loadAssignedSurvey = async() =>{
        assignedSurveysList(user?.access_token).then(response =>{
            setAssignedSurveyData(response);
        }).catch( err =>{
            console.log(err);
        })
    }

    useEffect(()=>{
        loadAssignedSurvey();
    },[user]);

    const header = (
        <div className="table-header">
  
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar ..." />
          </span>
        </div>
      );

    return (

        <DataTable value={assigndeSurveyData} dataKey='id' paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            globalFilter={ globalFilter } selectionMode="single" emptyMessage="No tiene ninguna asignación" header={ header }>

            <Column field="user" header="User" sortable body={tableTemplates.userBodyTemplate}/> 
            <Column field="survey" header="Survey" sortable body={tableTemplates.surveyBodyTemplate}/> 
            <Column field="assignments" header="N° Assigned" sortable body={tableTemplates.assigmentBodyTemplate}/> 
        </DataTable>
    )
}

export default AssignedSurveys;