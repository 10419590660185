import React, { useRef, useState, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Messages } from "primereact/messages";

import {
  // ObservationUnit,
  ConceptRepresentation,
  ConceptSpecification,
  Indicators,
  Indices,
} from "../Core/Operationalization";

import Spinner from "../../components/UI/spinner/Spinner";

import * as operativizationActions from "../../../redux/actions/operativization.actions";

const VariableOperationalization = (props) => {
  const dispatch = useDispatch();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [observationUnitsModified, setObservationUnitsModified] =
    useState(false);

  const [canContinue, setCanContinue] = useState(true);

  const loadState = useCallback(async () => {
    try {
      // Retrieving Observation Units
      await dispatch(operativizationActions.getObservationUnits());

      // If all has loaded I can work with the next button
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    loadState().then(() => {
      setHasLoaded(true);
    });
  }, []);

  const message = useRef();

  const [index, setIndex] = useState(0);

  const wizardItems = [
    { label: "Variables" },
    { label: "Dimensiones" },
    { label: "Indicadores" },
    { label: "Índices" },
  ];

  useEffect(() => {
    if (error) {
      errorMessage("error", error.message);
      setHasLoaded(false);
    }
  }, [error]);

  const errorMessage = (status, content) => {
    message?.current?.show({ severity: status, content: content });
  };

  const renderSwitch = (i) => {
    switch (i) {
      case 0:
        return <ConceptRepresentation canContinue={setCanContinue} />;
      case 1:
        return <ConceptSpecification canContinue={setCanContinue} />;
      case 2:
        return <Indicators canContinue={setCanContinue} />;
      default:
        return <Indices canContinue={setCanContinue} />;
    }
  };

  const onContinueButtonHandler = async () => {
    setError(false);
    try {
      switch (index) {
        case 0:
          setIndex(index + 1);
          break;
        case 1:
          setIndex(index + 1);
          break;
        case 2:
          setIndex(index + 1);
          break;
        default:
          return;
      }
    } catch (error) {
      setError(error);
    }
  };

  const getButtons = () => {
    const classBtn = (type) => `p-button-${type} p-px-6`;
    const continueButton = (
      <Button
        icon="pi pi-arrow-right"
        onClick={onContinueButtonHandler}
        className={classBtn(observationUnitsModified ? "success" : "info")}
        iconPos="right"
        label={observationUnitsModified ? "Guardar y Continuar" : "Siguiente"}
        disabled={!hasLoaded || !canContinue}
      />
    );

    const backButton = (
      <Button
        icon="pi pi-arrow-left"
        onClick={() => setIndex(index + -1)}
        className={classBtn("info")}
        label="Atrás"
      />
    );

    return (
      <Toolbar
        className="p-mb-4 p-toolbar"
        left={index > 0 ? backButton : null}
        right={index < 3 ? continueButton : null}
        style={{ border: "0px" }}
      />
    );
  };

  // Aditionally of a visual indicator it helps to not to render components until info
  // has been fetched. Helps to mainatain states in sync.
  if (!hasLoaded) {
    return <Spinner />;
  }

  return (
    <>
      <h3 style={{ textAlign: "center" }}>Operativización de Variables</h3>

      <div className="card">
        <div className="p-md-9" style={{ margin: "auto" }}>
          <Steps activeIndex={index} model={wizardItems} readonly={true} />
        </div>
        {getButtons()}

        <div className="card p-shadow-10">{renderSwitch(index)}</div>
        <Messages ref={message} />

        {getButtons()}
      </div>
    </>
  );
};

export default VariableOperationalization;
