// Activity Assignment Status 
export const FINALISEDS_STATUS = 3;
export const RUNNING_STATUS = 2;
export const UNINITIATEDS_STATUS = 1;
export const UNASSIGNEDS_STATUS = 0;

// Options for Dropdowns with assignments status
export const assignmentStatusOptions = [
    { id: UNASSIGNEDS_STATUS, label: 'No asignado' },
    { id: UNINITIATEDS_STATUS, label: 'No iniciado' },
    { id: RUNNING_STATUS, label: 'En ejecución' },
    { id: FINALISEDS_STATUS, label: 'Finalizado' },
];

export const getAssignmentStatusById = (id) => {
    
    const status = assignmentStatusOptions.find((status) => status.id === id);

    if (status) return status;
    else return {};
}