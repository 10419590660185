import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";

const IndicatorVariablesList = (props) => {
  const [variables, setVariables] = useState(props.data);
  const [layout, setLayout] = useState("grid");

  useEffect(() => {
    setVariables(props.data);
  }, [props.data]);

  const itemTemplate = (data) => {
    if (!data) {
      return;
    }

    return (
      <div className="p-col-12 p-md-3">
        <div className="product-grid-item card">
          <div className="product-grid-item-top" style={{ marginBottom: 15 }}>
            <div className="product-name" style={{ marginBottom: 10 }}>
              <h6>{data.label.length > 10
                ? data.label.substring(0, 10) + "..."
                : data.label}</h6>
            </div>
          </div>
          <div className="actions">
            <Button
              icon={data.definition ? "pi pi-eye" : "pi pi-pencil"}
              className={
                data.definition
                  ? "p-button-rounded p-button-success p-mr-2"
                  : "p-button-rounded p-button-warning p-mr-2"
              }
              onClick={() => props.onEditVariable(data)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <DataView
        value={variables}
        layout={layout}
        paginator
        rows={8}
        itemTemplate={itemTemplate}
      />
    </>
  );
};

export default IndicatorVariablesList;
