export const _parseEquation = (expression) => {
  const postfix = _infixToPostfix(expression);
  return postfix;
};

const _transformData = (objectList) => {
  let data = {};
  for (let i = 0; i < objectList.length; i++) {
    data[objectList[i].label] = objectList[i];
  }
  return data;
};

export const getTags = (newTags, prevTags) => {
  prevTags = _transformData(prevTags);
  // Removing duplicates
  const uniques = [...new Set(newTags)];
  const tags = uniques.map((c) => {
    return {
      id: prevTags[c] !== undefined ? prevTags[c].id : null,
      indicator_id: prevTags[c] !== undefined ? prevTags[c].indicator_id : null,
      label: c,
      parent_type: prevTags[c] !== undefined ? prevTags[c].parent_type : null,
      model_id: prevTags[c] !== undefined ? prevTags[c].model_id : null,
    };
  });
  return tags;
};

export const getVariables = (expression) => {
  const regExp = /^\d+$/;
  const operators = ["+", "-", "*", "/", "^", "(", ")"];
  const postfix = _parseEquation(expression);
  const varsNames = postfix.filter(
    (c) => !regExp.test(c) && !operators.includes(c)
  );
  // Removing duplicates
  const uniques = [...new Set(varsNames)];
  const vars = uniques.map((c) => {
    return {
      id: null,
      parent_id: null,
      label: c,
      definition: "",
      variable_type_id: "",
      measure_type_id: null,
      variable_type_format_id: null,
      measure_type_characteristics_values: [],
    };
  });
  return vars;
};

const _infixToPostfix = (infixExpression) => {
  var outputQueue = [];
  var operatorStack = [];
  var operators = {
    "^": {
      precedence: 4,
      associativity: "Right",
    },
    "/": {
      precedence: 3,
      associativity: "Left",
    },
    "*": {
      precedence: 3,
      associativity: "Left",
    },
    "+": {
      precedence: 2,
      associativity: "Left",
    },
    "-": {
      precedence: 2,
      associativity: "Left",
    },
  };
  const infix = _decomposeExpression(infixExpression);
  for (var i = 0; i < infix.length; i++) {
    var token = infix[i];
    if (!["+", "-", "*", "/", "(", ")"].includes(token)) {
      outputQueue.push(token);
    } else if ("^*/+-".indexOf(token) !== -1) {
      var o1 = token;
      var o2 = operatorStack[operatorStack.length - 1];
      while (
        "^*/+-".indexOf(o2) !== -1 &&
        ((operators[o1].associativity === "Left" &&
          operators[o1].precedence <= operators[o2].precedence) ||
          (operators[o1].associativity === "Right" &&
            operators[o1].precedence < operators[o2].precedence))
      ) {
        outputQueue.push(operatorStack.pop());
        o2 = operatorStack[operatorStack.length - 1];
      }
      operatorStack.push(o1);
    } else if (token === "(") {
      operatorStack.push(token);
    } else if (token === ")") {
      while (operatorStack[operatorStack.length - 1] !== "(") {
        outputQueue.push(operatorStack.pop());
      }
      operatorStack.pop();
    }
  }
  while (operatorStack.length > 0) {
    outputQueue.push(operatorStack.pop());
  }
  return outputQueue;
};

const _decomposeExpression = (expression) => {
  const infix = expression
    .split(/([+\-*/^()])/)
    .map((c) => c.trim())
    .filter((c) => ![""].includes(c));
  return infix;
};
