import axios from "axios";
import * as urls from  '../../../api/urls/acquireds';

export const listProcessByModel = async (model_id, token) => {

    const response = await axios.get(urls.urlListProcessByModel + model_id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}