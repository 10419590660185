import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Toolbar } from 'primereact/toolbar';
import {
  createSurveyAssignment,
  listAsignmentByUser,
  listSurveyPublished,
  updateSurveyAssignment,
} from '../../../../redux/actions/survey.actions';

const emptyFunc = () => {
  return;
};

const emptyUserId = null;

const ChooseSurvey = React.memo(
  ({ open, setOpen, userId = emptyUserId, callback = emptyFunc }) => {
    const user = useSelector((state) => state.LoginState.data);
    const model = useSelector((state) => state.ModelState?.model);
    const toast = useRef(null);
    const history = useHistory();

    const [surveys, setSurveys] = useState([]);
    const [assignedSurveys, setAssinedSurveys] = useState([]);
    const [numSurveysAssign, setNumSurveysAssign] = useState([]);

    const loadSurveys = useCallback(async () => {
      listSurveyPublished(model?.id, user?.access_token)
        .then((response) => {
          setSurveys(response);
          callback();
        })
        .catch(() => {
          toast.current.show({
            severity: 'warn',
            sumary: 'Alerta',
            detail: 'Error de conexion',
            life: 3000,
          });
        });
    }, []);

    const loadAssignmentByUser = async () => {
      listAsignmentByUser(userId, user?.access_token)
        .then((response) => {
          setAssinedSurveys(response);
          callback();
        })
        .catch(() => {
          toast.current.show({
            severity: 'warn',
            sumary: 'Alerta',
            detail: 'Error de  conexion',
            life: 3000,
          });
        });
    };

    useEffect(() => {
      loadSurveys(userId);
      if (userId !== null) {
        loadAssignmentByUser();
      }
    }, [userId]);

    const handleChangeNumber = (num, id) => {
      setNumSurveysAssign({
        ...numSurveysAssign,
        [id]: num,
      });
    };

    const assginedsValue = (survey) => {
      const index = assignedSurveys.findIndex(
        (s) => s?.survey?.survey_template?.id === survey.id
      );
      return assignedSurveys[index]?.assignments || 0;
    };

    const handleChangeAssign = async (surveyId, userId) => {
      const index = assignedSurveys.findIndex(
        (s) => s?.survey?.survey_template?.id === surveyId
      );
      let numAssign = numSurveysAssign[surveyId];
      if (index === -1) {
        console.log(numAssign);
        let newAssignSurveys = {
          model_id: model?.id,
          user_id: userId,
          survey_id: surveyId,
          assignments: numAssign,
        };
        createSurveyAssignment(newAssignSurveys, user?.access_token)
          .then(() => {
            loadAssignmentByUser();
            toast.current.show({
              severity: 'success',
              sumary: 'Exito',
              detail: 'Assignment Created',
              life: 3000,
            });
          })
          .catch(() => {
            toast.current.show({
              severity: 'warn',
              sumary: 'Alerta',
              detail: 'Error de  conexion',
              life: 3000,
            });
          });
      } else {
        updateSurveyAssignment(
          assignedSurveys[index].id,
          numAssign,
          user?.access_token
        )
          .then(() => {
            loadAssignmentByUser();
            toast.current.show({
              severity: 'success',
              sumary: 'Exito',
              detail: 'Assignment Updated',
              life: 3000,
            });
          })
          .catch(() => {
            toast.current.show({
              severity: 'warn',
              sumary: 'Alerta',
              detail: 'Error de  conexion',
              life: 3000,
            });
          });
      }
    };

    const leftToolbarTemplate = (survey) => {
      return (
        <div className="p-col-12 p-md-9">
          <div className="p-inputgroup">
            <InputNumber
              value={assginedsValue(survey)}
              onValueChange={(e) => handleChangeNumber(e.value, survey?.id)}
              showButtons
              mode="decimal"
            ></InputNumber>
            <Button
              style={{ width: '10rem' }}
              label="Assign"
              className="p-button-info"
              onClick={() => {
                handleChangeAssign(survey?.id, userId);
              }}
            />
          </div>
        </div>
      );
    };

    const rightToolbarTemplate = () => {
      return (
        <>
          <h6> 60 encuestas disponibles</h6>
        </>
      );
    };

    const footerDialog = () => (
      <Button
        className="p-button-succes"
        label="Cerrar"
        onClick={() => {
          setOpen(false);
        }}
      />
    );

    return (
      <>
        <Toast ref={toast} />
        <Dialog
          visible={open}
          style={{ width: '800px' }}
          header="Surveys available"
          modal
          className="p-fluid"
          onHide={() => {
            setOpen(false);
          }}
          footer={footerDialog}
        >
          <div>
            {surveys.length === 0 ? (
              <h6>No tienes encuestas publicadas por asignar</h6>
            ) : null}
            {surveys.map((s) => {
              return (
                <div key={s.id} className="p-mt-4 p-shadow-1 p-grid">
                  <div className="p-col" style={{ textAlign: 'left' }}>
                    <h6 className="p-mb-1">{s.name}</h6>
                    <span>{s.description}</span>
                  </div>
                  <div className="p-col" style={{ textAlign: 'right' }}>
                    <Button
                      label="View Survey"
                      icon="pi pi-eye"
                      className="p-button-success p-mr-2 p-mb-2"
                      onClick={() =>
                        history.push({
                          pathname: '/view/Survey',
                          state: { formId: s?.id },
                        })
                      }
                    />
                    <Toolbar
                      className="p-toolbar"
                      left={() => leftToolbarTemplate(s)}
                      right={() => rightToolbarTemplate(s)}
                    ></Toolbar>
                  </div>
                </div>
              );
            })}
          </div>
        </Dialog>
      </>
    );
  }
);

ChooseSurvey.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default ChooseSurvey;
