
import React, {useEffect, useRef, useState} from 'react';
import PurposeProcess from "../Purpose";
import TheoryProcess from "../Theory";
import MethodProcess from "../Method";
import ToolsProccess from "../Tools";
import ResourcesProcess from "../Resources";
import ResultsProcess from "../Results";
import IndicatorsProccess from "../Indicator";
import BuilderBPMN from "../BPMN/Builder";
import RiskProcess from "../Risk";
import LearningProcess from "../Learning";
import EnvironmentProcess from "../Environment";
import useForm from '../../../../hooks/useForm';
import { TabMenu } from "primereact/tabmenu";
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

const Process = ({ open, setOpen }) => {

    const process = useSelector((state) => state.ProcessState?.process);
    const toast  = useRef(null);

    const { values, setValues, handleInputChange } = useForm(process);
    const [ selectedTab, setSelectedTab ] = useState(0);

    useEffect(() => {
        setValues(process);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ process ]);

    const wizardItems = [
        {id: 0, label: 'Propósito', icon: 'pi pi-th-large'},
        {id: 1, label: 'Teoría', icon: 'pi pi-users'},
        {id: 2, label: 'Método', icon: 'pi pi-clone'},
        {id: 3, label: 'Herramientas', icon: 'pi pi-cloud'},
        {id: 4, label: 'Recursos', icon: 'pi pi-folder'},
        {id: 5, label: 'Indicadores', icon: 'pi pi-chart-bar'},
        {id: 6, label: 'Actividades', icon: 'pi pi-sitemap'},
        {id: 7, label: 'Resultados', icon: 'pi pi-briefcase'},
        {id: 8, label: 'Riesgos', icon: 'pi pi-exclamation-triangle'},
        {id: 9, label: 'Aprendizaje', icon: 'pi pi-book'},
        {id: 10, label: 'Entorno', icon: 'pi pi-eye'},
    ];

    const tabChangeHandler = (tab) => {
        setSelectedTab(tab.id);
    };

    const headerDialog = () => (
        <div>
            <h5>Proceso: { process?.name }</h5>
        </div>
    );

    const showMessage = (severity, summary, detail) => {
        toast?.current?.show({
            severity: severity,
            summary: summary,
            detail: detail,
            life: 3000,
        });
    };

    return (
        <div>
            <Dialog
                contentStyle={{ height: '90%' }}
                style={{ width: '90%', height: '90%' }} modal
                visible={ open }
                header={ headerDialog }
                onHide={ () => setOpen(false) } >

                <div>

                    <Toast ref={ toast } />

                    <TabMenu
                        className='p-mt-1'
                        activeItem={ selectedTab }
                        model={ wizardItems }
                        onTabChange={(e) => tabChangeHandler(e.value)} />

                    <div className='p-mt-5'>
                        {
                            selectedTab === 0 ?
                                <PurposeProcess
                                    process={ process }
                                    values={ values }
                                    handleInputChange={ handleInputChange }
                                    showMessage={ showMessage } />
                            :selectedTab === 1 ?
                                <TheoryProcess
                                    process={ process }
                                    values={ values }
                                    handleInputChange={ handleInputChange }
                                    showMessage={ showMessage } />
                            :selectedTab === 2 ?
                                <MethodProcess 
                                    process={ process }
                                    values={ values }
                                    handleInputChange={ handleInputChange }
                                    showMessage={ showMessage } />
                            :selectedTab === 3 ?
                                <ToolsProccess 
                                    process={ process } 
                                    showMessage={ showMessage } />
                            :selectedTab === 4 ?
                                <ResourcesProcess 
                                    process={ process } 
                                    showMessage={ showMessage } />
                            :selectedTab === 5 ?
                                <IndicatorsProccess 
                                    dataCurrent={[]} process={ process } 
                                    showMessage={ showMessage } />
                            :selectedTab === 6 ?
                                <BuilderBPMN 
                                    process={ process } 
                                    showMessage={ showMessage } />
                            :selectedTab === 7 ?
                                <ResultsProcess 
                                    process={ process } 
                                    showMessage={ showMessage } />
                            :selectedTab === 8 ?
                                <RiskProcess 
                                    process={ process }
                                    values={ values }
                                    handleInputChange={ handleInputChange }
                                    showMessage={ showMessage } />
                            :selectedTab === 9 ?
                                <LearningProcess 
                                    process={ process }
                                    values={ values }
                                    handleInputChange={ handleInputChange }
                                    showMessage={ showMessage } />
                            :selectedTab === 10 ?
                                <EnvironmentProcess 
                                    process={ process }
                                    values={ values }
                                    handleInputChange={ handleInputChange }
                                    showMessage={ showMessage } />
                            : 'Not found (404)'
                        }
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Process;