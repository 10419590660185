import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import ConceptsList from '../../../../components/UI/ConceptsList';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import Spinner from '../../../../components/UI/spinner/Spinner';
import {
  PickNodesGraph,
  PickNodesGraphGenerator,
} from '../../../../components/Graphs';
import { buildGraphStructure, buildDataStructure } from '../utils';
import * as operativizationActions from '../../../../../redux/actions/operativization.actions';
import {
  CreateObjectDialog,
  DeleteObjectDialog,
} from '../../../../components/UI';

const emptyConcept = {
  id: null,
  parent_id: null,
  name: '',
  definition: '',
};

const ConceptRepresentation = (props) => {
  const toast = useRef(null);

  const observationUnitsState = useSelector(
    (state) => state?.OperativationState?.observationUnits
  );
  const conceptsState = useSelector(
    (state) => state?.OperativationState?.concepts
  );
  const modelName = useSelector((state) => state?.ModelState?.model?.name);

  const [observationUnitsAndConcepts, setObservationUnitsAndConcepts] =
    useState([]);
  const [concept, setConcept] = useState(emptyConcept);
  const [observationUnitId, setObservationUnitId] = useState(null);

  const [graphData, setGraphData] = useState({});
  const [parent, setParent] = useState();

  const generator = new PickNodesGraphGenerator(graphData);
  const graph = generator?.graph;
  const options = generator?.options;

  const [visibleFullScreen, setVisibleFullScreen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);

  const dispatch = useDispatch();

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _concept = { ...concept };
    _concept[`${name}`] = val;
    setConcept(_concept);
  };

  const showMessage = (severity, summary, detail) => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };

  useEffect(() => {
    if (error) {
      setError(null);
      showMessage('error', 'Error', error.message);
    }
  }, [error]);

  useEffect(() => {
    const graphData = buildGraphStructure(
      [...observationUnitsAndConcepts],
      modelName,
      '0',
      '1'
    );
    setGraphData(graphData);
    props.canContinue(conceptsState.length > 0 ? true : false);
  }, [observationUnitsAndConcepts]);

  const loadConcepts = useCallback(async () => {
    try {
      // Retrieving Concepts
      let _concepts = await dispatch(operativizationActions.getConcepts());

      // Adding groupId for visualization
      let _observatioUnits = [...observationUnitsState];
      for (let i = 0; i < observationUnitsState?.length; i++) {
        _observatioUnits[i]['groupId'] = '1';
        // _observatioUnits[i]["definition"] = "Unidad de observación";
      }
      for (let i = 0; i < _concepts?.length; i++) {
        _concepts[i]['groupId'] = '2';
        // _concepts[i]["definition"] = "Variable";
      }

      const data = buildDataStructure([[..._observatioUnits], [..._concepts]]);
      setObservationUnitsAndConcepts(data);
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    loadConcepts().then(() => {
      setLoading(false);
    });
  }, []);
  const deleteConcept = async () => {
    setLoading(true);
    setError(null);
    try {
      await dispatch(operativizationActions.deleteConcept(concept.id));
      const index = observationUnitsAndConcepts.findIndex(
        (c) => c.id == concept.parent_id
      );
      let _aux = [...observationUnitsAndConcepts];
      _aux[index].components = _aux[index].components.filter(
        (c) => c.id !== concept.id
      );
      setObservationUnitsAndConcepts(_aux);
      showMessage('success', 'Éxito', 'Variable Eliminada!');
    } catch (error) {
      setError(error);
    }

    setDeleteDialog(false);
    setConcept(emptyConcept);
    setLoading(false);
  };

  const createConcept = async () => {
    setSubmitted(true);
    setError(null);

    if (concept?.name.trim() && concept?.definition.trim()) {
      try {
        setLoading(true);

        if (!concept?.id) {
          // Creating new concept
          const _newConcept = await dispatch(
            operativizationActions.createConcept(concept, observationUnitId)
          );
          const index = observationUnitsAndConcepts.findIndex(
            (c) => c.id === observationUnitId
          );
          let _aux = [...observationUnitsAndConcepts];
          _aux[index].components = [_newConcept, ..._aux[index].components];
          setObservationUnitsAndConcepts(_aux);
          showMessage('success', 'Éxito', 'Variable Creada!');
          setObservationUnitId(null);
        } else {
          // Updating existing concept
          const _upConcept = await dispatch(
            operativizationActions.updateConcept(concept)
          );
          let _aux = [...observationUnitsAndConcepts];
          const index = _aux.findIndex((c) => c.id === concept.parent_id);
          const indexConcept = _aux[index].components.findIndex(
            (c) => c.id === concept.id
          );
          _aux[index].components[indexConcept] = _upConcept;
          setObservationUnitsAndConcepts(_aux);
          showMessage('success', 'Éxito', 'Variable Actualizada!');
        }
        setConcept(emptyConcept);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
      setCreateDialog(false);
      setSubmitted(false);
    }
  };

  // On Buttons Cliks
  const onDeleteConcept = (c) => {
    setConcept(c);
    setDeleteDialog(true);
  };

  const onCreateConcept = (id) => {
    setObservationUnitId(id);
    setParent(observationUnitsState.find((c) => c.id === id));

    setConcept(emptyConcept);
    setCreateDialog(true);
  };

  const onEditConcept = (data) => {
    setConcept(data);
    setCreateDialog(true);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <h5>Representación de Variables</h5>
      <Toast ref={toast} />

      {observationUnitsAndConcepts.map((c) => (
        <ConceptsList
          data={c}
          key={c.id}
          onCreateConcept={onCreateConcept}
          onDeleteConcept={onDeleteConcept}
          onEditConcept={onEditConcept}
        />
      ))}

      <DeleteObjectDialog
        concept={concept}
        setDeleteDialog={setDeleteDialog}
        deleteDialog={deleteDialog}
        deleteConcept={deleteConcept}
      />

      <CreateObjectDialog
        createDialog={createDialog}
        setCreateDialog={setCreateDialog}
        targetObject={concept}
        submitted={submitted}
        createTargetObject={createConcept}
        onInputChange={onInputChange}
        setSubmitted={setSubmitted}
        title={`Sub Sistema ${parent?.name}`}
        subtitle={'Información de la Variable'}
      />

      <Button
        label={visibleFullScreen ? 'Cerrar' : 'Ver'}
        type="button"
        icon={
          visibleFullScreen
            ? 'pi pi-angle-double-down'
            : 'pi pi-angle-double-up'
        }
        className="p-button-warning"
        onClick={() => {
          setVisibleFullScreen(!visibleFullScreen);
        }}
        style={{
          position: 'fixed',
          bottom: '2%',
          right: '1%',
          width: '80px',
          height: '45px',
          zIndex: 10001,
        }}
      />

      <Sidebar
        visible={visibleFullScreen}
        onHide={() => setVisibleFullScreen(false)}
        baseZIndex={1000}
        fullScreen
      >
        <PickNodesGraph graph={graph} options={options} />
      </Sidebar>
    </>
  );
};

export default ConceptRepresentation;
