import axios from "axios";
import * as urls from  '../../api/urls';
import {createAction} from 'redux-actions';

export const setLoadingTeamAction = createAction('SET_LOADING_PLAN_ACTION');
export const setTeamDataAction = createAction('SET_TEAM_DATA_ACTION');
export const setTeamErrorAction = createAction('SET_TEAM_ERROR_ACTION');
export const setResetTeamAction = createAction('SET_RESET_TEAM_ACTION');

export const getTeams = (history) => async(dispatch, getState) =>{

    dispatch(setResetTeamAction());
    dispatch(setLoadingTeamAction(true));

    try {
      const token = getState()?.LoginState?.data?.access_token;
      const {data, status} = await axios.get(`${urls.URL_BASE}${urls.urlListTeam}`,{
        headers:{
          Authorization: 'Bearer '+ token
        }
      });

      if (status === 200) {
        dispatch(setTeamDataAction(data));
        dispatch(
          setTeamErrorAction({
            message:'Exito'
          })
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setTeamErrorAction({
          message:'Error al recuperar la información'
        })
      );
    }

    dispatch(setLoadingTeamAction(false));
};

export const createTeam =(data) => async(dispatch, getState)=>{
    const token = getState()?.LoginState?.data?.access_token;
    console.log(data);
    /*let dataCreate ={

    }*/
};

export const getTeamMembers = (teamId, token) => async (dispatch, getState) => {
    try {
        const { data, status } = await axios.get(
          `${urls.URL_BASE}${urls.urlTeamsUsersNoApp}/${teamId}`,
          {
            headers: {
                Authorization: "Bearer " + token,
            },
          }
        );

        if (status === 200) {
            return data;
        } else {
            console.log(data);
        }
    } catch (error) {
        console.log(error);
    }
  };

export const checkTeamAvailability = (team_name) => {

    return new Promise(function (resolve, reject) {

        axios.post(`${urls.URL_BASE}${urls.urlCheckTeamAvailability}`, {name: team_name}).then(
            response => {
                var result = response.data;
                resolve(result);
            }, error => {
                reject(error);
            }
        );
    });
}

export const getMembersById = async (id, token) => {
  
    const response = await axios.get(`${urls.URL_BASE}${urls.urlListMembersByTeamId}${id}`,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}


export const updateTeam = async (team_id, data, token) => {

    const body = {
        ...data,
    }

    const response = await axios.put(`${urls.URL_BASE}${urls.urlUpdateTeam}${ team_id }`, body, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const getTeamById = async (team_id, token) => {
  
    const response = await axios.get(urls.URL_BASE + urls.urlRetrieveTeam + team_id ,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

  return response.data;
}