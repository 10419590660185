import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'primereact/radiobutton';
import { images } from '../../assets/img/index';
import useCardBrand from '../../../redux/hooks/useCardBrand';
import { Button } from 'primereact/button';

const SavedPaymentMethods = ({ paymentMethod, value, setValue }) => {

    const { getSvgProps } = useCardBrand();
    const brand = paymentMethod?.brand.toLowerCase();



    const handleChange = ({ target }) => {
        setValue( target?.value );
    }

    return (
        <div className='p-grid'>
            <div className="p-col-12 p-p-0" style={{ maxWidth: '400px', margin: 'auto' }} >
                <div className="p-field-radiobutton center p-shadow-1 p-my-2 p-pb-2 p-pt-3" >

                    <svg {...getSvgProps({ type: brand, images })} />  

                    <label htmlFor={ paymentMethod?.id.toString() } >
                        <span className='p-mx-2'>**** **** **** { paymentMethod?.last4 }</span>
                    </label>

                    <RadioButton
                        id={ paymentMethod?.id.toString() }
                        className='p-mx-2'
                        name={ paymentMethod?.id.toString() } value={ paymentMethod } 
                        checked={ value?.code === paymentMethod?.code }
                        onChange={ handleChange } />

                    <div style={{ width: '2rem', height: '2rem' }} >
                        {
                            (value?.code === paymentMethod?.code) &&
                            <Button
                                className='p-button-rounded p-button-danger p-button-outlined'
                                style={{ width: '2rem', height: '2rem' }}
                                icon='pi pi-times' tooltip='Delete Selected'
                                onClick={() => setValue(null)} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

SavedPaymentMethods.propTypes = {
    paymentMethod: PropTypes.object.isRequired,
};

export default SavedPaymentMethods;