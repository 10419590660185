import axios from "axios";
import { setDeleteModelErrorAction, setModelState } from "../model.actions";
import * as urls from  '../../../api/urls/acquireds';

export const acquireModel = async (pModelId, team_id, token) => {
    
    const response = await axios.post(urls.urlAcquireModel + pModelId + '/' + team_id, {}, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const listAcquiredModelsByTeam = async (teamId, token) => {

    const response = await axios.get(urls.urlListModelByTeam + teamId, {
        headers: {
            Authorization: 'Bearer ' + token  
        }
    });
    
    return response.data;
}

export const retrieveAcquiredModelById = (model_id, teamId) => async (dispatch, getState) => {

    const token = getState()?.LoginState?.data?.access_token;

    const { data, status } = await axios.get(urls.urlRetrieveModelById + model_id, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    if (status === 200) {
        dispatch(setModelState(data));
        dispatch(setDeleteModelErrorAction({ message: "Success"}));
    } else {
        throw "No se pudo obtener el modelo adquirido";
    }
}

export const retrieveAcquiredModelByIdAsync = async (model_id, teamId, token) => {

    const response = await axios.get(urls.urlRetrieveModelById + model_id, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    return response.data;
}

export const getAcquiredModelsByTeam = async (team_id, token) => {

    const response = await axios.get(urls.urlListModelByTeam + team_id, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}