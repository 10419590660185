export const isFormValid = (values, unitTimeType, setErrors) => {

    let response = true;

    // Description validate *********************************************************************************************

    if (!values?.desc || values?.desc === undefined || values?.desc === null) {
        setErrors( errors => ({
            ...errors,
            desc: {
                status: true,
                message: 'Description is required'
            }
        }));
        response = false;
    } else {
        setErrors( errors => ({
            ...errors,
            desc: {
                status: false,
                message: ''
            }
        }));
    }
    
    // Duration validate *********************************************************************************************

    if (!values?.duration || values?.duration === undefined || values?.duration === null) {
        setErrors( errors => ({
            ...errors,
            duration: {
                status: true,
                message: 'Duration is required'
            }
        }));
        response = false;
    } else if (!unitTimeType || !unitTimeType?.id) {
        setErrors( errors => ({
            ...errors,
            duration: {
                status: true,
                message: 'Time type is required'
            }
        }));
        response = false;
    } else {
        setErrors( errors => ({
            ...errors,
            duration: {
                status: false,
                message: ''
            }
        }));
    }

    // Budget validate *********************************************************************************************

    if (values?.budget === undefined || values?.budget === null) {

        console.log(values);

        setErrors( errors => ({
            ...errors,
            budget: {
                status: true,
                message: 'Budget is required'
            }
        }));
        response = false;
    } else {
        setErrors( errors => ({
            ...errors,
            budget: {
                status: false,
                message: ''
            }
        }));
    }

    return response;
}