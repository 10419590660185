import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router'

const PublicRoute = ({ isAuthenticated, component: Component, ...rest }) => {

    // Public route will render the component passed to parametrs if user isn´t logged
    // If was logged, it'll rediret to dashboard
    return (
        <Route
            { ...rest }
            component={(props) => 
                (!isAuthenticated)
                    ? <Component { ...props } />
                    : <Redirect to="/" />
            } />
    )
}

PublicRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired,
}

export default PublicRoute;