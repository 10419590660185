import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import useForm from '../../../../hooks/useForm';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import InputGender from '../../../components/Register/InputGender';
import { Toolbar } from 'primereact/toolbar';

import {
  registerUserInfoApp,
  updateAppUser,
} from '../../../../redux/actions/user.action';

const emptyFunc = () => {
  return;
};

const emptyUserInfoApp = {
  username: '',
  names: '',
  last_names: '',
  gender: 'M',
  password: '',
  description: '',
  team_id: '',
  role_name: 'info-app',
  profile_picture_url: '',
};

const NewUserInfoApp = React.memo(
  ({
    open,
    setOpen,
    userInfoApp = emptyUserInfoApp,
    setUserInfoApp = emptyFunc,
    callback = emptyFunc,
  }) => {
    const { t } = useTranslation(['global']);

    const [submitted, setSubmitted] = useState(false);
    const [emailFormat, setEmailFormat] = useState(false);

    const { values, setValues, handleInputChange } = useForm(
      userInfoApp != null ? userInfoApp : emptyUserInfoApp
    );
    const user = useSelector((state) => state.LoginState.data);
    const toast = useRef(null);

    useEffect(() => {
      setValues(userInfoApp != null ? userInfoApp : emptyUserInfoApp);
    }, [userInfoApp]);

    const saveUserInfoApp = async () => {
      setSubmitted(true);

      if (values?.id) {
        if (
          values?.username &&
          values?.username.trim() &&
          values?.names &&
          values?.names.trim() &&
          values?.last_names &&
          values?.last_names.trim() &&
          values?.password &&
          values?.password.trim()
        ) {
          const data = {
            id: values?.id,
            names: values?.names,
            last_name: values?.last_names,
            gender: values?.gender,
            password: values?.password,
          };

          updateAppUser(data, user?.access_token)
            .then((response) => {
              console.log(response);
              toast.current.show({
                severity: 'success',
                sumary: 'Usuario editado',
                detail:
                  values?.names + ' ' + values?.last_names + ' actualizado',
                life: 3000,
              });
              hideDialog();
              callback();
            })
            .catch((err) => {
              if (err?.response?.data?.detail) {
                toast.current.show({
                  severity: 'error',
                  sumary: 'Error al editar el usuario',
                  detail: err?.response?.data?.detail?.msg,
                  life: 3000,
                });
                hideDialog();
                callback();
              } else {
                toast.current.show({
                  severity: 'error',
                  sumary: 'Error al editar el usuario',
                  detail: 'Error al crear el usuario',
                  life: 3000,
                });
                hideDialog();
                callback();
              }
            });
        }
      } else {
        if (
          values?.username.trim() &&
          values?.names.trim() &&
          values?.last_names.trim() &&
          values?.identification.trim() &&
          values?.password.trim()
        ) {
          const re = /\S+@\S+\.\S+/;

          if (re.test(values?.username)) {
            registerUserInfoApp(values, user?.access_token, user?.team?.id)
              .then(() => {
                toast.current.show({
                  severity: 'success',
                  sumary: 'Usuario registrado',
                  detail: values?.names + ' ' + values?.last_names + ' creado',
                  life: 3000,
                });
                hideDialog();
                callback();
              })
              .catch((err) => {
                if (err?.response?.data?.detail) {
                  toast.current.show({
                    severity: 'error',
                    sumary: 'Error al registrar el usuario',
                    detail: err?.response?.data?.detail,
                    life: 3000,
                  });
                  hideDialog();
                  callback();
                } else {
                  toast.current.show({
                    severity: 'error',
                    sumary: 'Error al registrar el usuario',
                    detail: 'Error al crear el usuario',
                    life: 3000,
                  });
                  hideDialog();
                  callback();
                }
              });
          } else {
            setEmailFormat(true);
          }
        }
      }
    };

    const hideDialog = () => {
      setUserInfoApp(emptyUserInfoApp);
      setValues(emptyUserInfoApp);
      setSubmitted(false);
      setOpen(false);
      setEmailFormat(false);
    };

    const userInfoAppDialogFooter = (
      <>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={saveUserInfoApp}
        />
      </>
    );

    return (
      <>
        <Toast ref={toast} />
        <Dialog
          visible={open}
          style={{ width: '750px' }}
          header="Editar usuario"
          modal
          className="p-fluid"
          footer={userInfoAppDialogFooter}
          onHide={hideDialog}
        >
          <div className="p-shadow-1 p-grid">
            <div className="p-col" style={{ textAlign: 'left' }}>
              <div className="p-field">
                <label htmlFor="names">{t('adminInfo.table.names')}</label>
                <InputText
                  id="names"
                  name="names"
                  placeholder="Inrgese el nombre"
                  value={values?.names}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  className={submitted && !values?.names ? 'p-invalid' : ''}
                />
                {submitted && !values?.names && (
                  <small className="p-invalid color-danger">
                    EL nombre es requerido
                  </small>
                )}
              </div>

              <div className="p-field">
                <label htmlFor="last_names">
                  {t('adminInfo.table.last_names')}
                </label>
                <InputText
                  id="last_names"
                  name="last_names"
                  placeholder="Ingrese el apellido"
                  value={values?.last_names}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  className={
                    submitted && !values?.last_names ? 'p-invalid' : ''
                  }
                />
                {submitted && !values?.last_names && (
                  <small className="p-invalid color-danger">
                    El apellido es requerido
                  </small>
                )}
              </div>
            </div>
            <div className="p-col">
              <div className="p-field">
                <label htmlFor="username">Email</label>
                <InputText
                  id="username"
                  name="username"
                  placeholder="Enter email as user"
                  value={values?.username}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  disabled={values?.id ? true : false}
                  className={
                    (submitted && !values?.username) ||
                    (submitted && emailFormat)
                      ? 'p-invalid'
                      : ''
                  }
                />
                {submitted && !values?.username && (
                  <small className="p-invalid color-danger">
                    Email requerido
                  </small>
                )}
                {emailFormat && (
                  <small className="p-invalid color-danger">
                    Formato incorrecto
                  </small>
                )}
              </div>

              <div className="p-field">
                <label htmlFor="password">Password</label>
                <InputText
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={values?.password}
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  className={submitted && !values?.password ? 'p-invalid' : ''}
                />
                {submitted && !values?.password && (
                  <small className="p-invalid color-danger">
                    Contraseña requerida
                  </small>
                )}
              </div>

              <div className="p-field">
                <label htmlFor="gender">Sexo</label>
                <Toolbar
                  className="p-mb-3"
                  left={
                    <>
                      <InputGender
                        values={values}
                        handleInputChange={handleInputChange}
                        info={true}
                      />
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
);

NewUserInfoApp.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default NewUserInfoApp;
