import axios from "axios";
import * as urls from  '../../api/urls';

export const createConnectionShape = async (data, token, role) => {

    const body = {
        type: data?.type,
        parent_shape_id:  data?.parent_shape_id,
        shape_id: data?.shape_id,
        parent_shape_type_label: data?.parent_shape_type_label,
        shape_type_label: data?.shape_type_label,
        process_id: data?.process_id,
    };

    const response = await axios.post(urls.urlCreateConnectionShape + role, body, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deletePreviousConnection = async (parent_shape_id, shape_id, process_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.delete(urls.urlDeletePreviousConnection + parent_shape_id + '/' + shape_id + '/' + process_id + stage_role, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}

export const deleteNextConnection = async (parent_shape_id, shape_id, process_id, token, role) => {

    const stage_role = '/' + role;

    const response = await axios.delete(urls.urlDeleteNextConnection + parent_shape_id + '/' + shape_id + '/' + process_id + stage_role, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });

    return response.data;
}