import axios from 'axios';
import * as urls from '../../api/urls';

export const getPersons = async(token) =>{
  const response = await axios.get(
    `${urls.URL_BASE}${urls.urlListPersons}`,{
      headers:{
        Authorization: 'Bearer '+ token
      },
    }
  );
  return response.data;
};

export const sendInvitation = (datosPerson, token) => async(dispatch, getState) =>{

  const{username,team_id} = datosPerson;

  const datos ={
    username:username,
    team_id:team_id,
  };
  try {
    const {data, status} = await axios.post(
      `${urls.URL_BASE}${urls.urlSendInvitation}`,datos,{
        headers:{
          Authorization: 'Bearer '+ token
        },
      }
    )

    if (status === 200) {
      return 200;
    }
  } catch (e) {
      return 500;
  }

};

export const getInvitedPeople = async(token) =>{
  const response = await axios.get(
    `${urls.URL_BASE}${urls.urlPeopleInvited}`,{
      headers:{
        Authorization: 'Bearer '+ token
      },
    }
  );

  return response.data;
};
