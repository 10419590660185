import React, { useState } from 'react';
import PlanInfo from '../Register/PaymentMethod/PlanInfo';
import SubscriptionTimeline from './SubscriptionTimeline';
import { findPlanById } from '../../../api/data';
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button';

const PlanManage = props => {

    const [ plan ] = useState( findPlanById(2) );
    const { t } = useTranslation(['global']);

    return (
        <>
            <div className='card'>
                <h2>{ t("plan.manage.title") }</h2>
                
                <div className='p-grid'>
                    <div className='p-col'>
                        <PlanInfo plan={ plan } />
                        <div className='p-text-center p-mb-3 p-mt-2'>
                            <Button
                                className='p-button-outlined p-button-success p-mt-1 p-mr-1'
                                style={{ width: '100%', maxWidth: '250px', }}
                                label={ t('plan.manage.btn-update') } />
                            <Button
                                className='p-button-outlined p-button-danger p-mt-1 p-mr-1'
                                style={{ width: '100%', maxWidth: '250px', }}
                                label={ t('plan.manage.btn-cancel') } />
                        </div>
                    </div>

                    <div className='p-col'>
                        <SubscriptionTimeline />
                    </div>
                </div>
            </div>
        </>
    );
}

PlanManage.propTypes = {};

export default PlanManage;